import axios from "axios";
import {Form} from "../../../../../utils/Form";
import Loading from "../../../../../utils/Loading";
import alertMessageActions from "../../../../../actions/alertMessage";
import env from "../../../../../env";
import React from "react";

function getToken() {
    return new Promise((resolve, reject) => {
        axios.get(env.API_URL + '/paypal/get-client-token', {
            withCredentials: true
        })
        .then((response) => {
            resolve(response.data.clientToken);
        }).catch(() => {
            reject(null)
        });
    });
}

export async function initPaypal(assinatura_selecionada, forma_pagamento_de, ciclo, dispatch) {

    const token = await getToken()
    Loading.add();

    window.braintree.client.create({
        authorization: token
    }).then(function (clientInstance) {
        // Create a PayPal Checkout component.
        return window.braintree.paypalCheckout.create({
            client: clientInstance
        });
    }).then(function (paypalCheckoutInstance) {
        // Set up PayPal with the checkout.js library
        return window.paypal.Button.render({
            env: (env.APP_ENV == 'production' ? 'production':'sandbox'),
            locale: 'pt_BR',
            commit: false,
            style: {
                color: 'blue',
                label: 'pay',
                shape: 'rect',
                size: 'large'
            },

            payment: function () {
                return paypalCheckoutInstance.createPayment({
                    flow: 'vault',
                    billingAgreementDescription: 'Acordo de cobrança com Estadão',
                    intent: 'sale',
                    locale: 'pt_BR',
                    //amount: amount,
                    currency: 'BRL',
                    enableShippingAddress: false,
                    shippingAddressEditable: false
                });
            },

            onAuthorize: async function (data, actions) {

                console.log('onAuthorize');
                await paypalCheckoutInstance.tokenizePayment(data).then(function (payload) {

                    Loading.add();

                    const bodyFormData = new FormData();
                    const dataToSend = Form.objectToFormData({
                        assinatura_selecionada,
                        forma_pagamento_de,
                        ciclo,
                        nonce: payload.nonce,
                        forma_pagamento_para: 4
                    }, bodyFormData)

                    return axios.post(
                        env.API_URL + `/subscription/${assinatura_selecionada}/change-payment-method`,
                        dataToSend, {
                            headers: {
                                "Content-type": "application/x-www-form-urlencoded"
                            },
                            withCredentials: true,
                            validateStatus: function (status) {
                                return true;
                            }
                        }).then((response) => {
                            Loading.remove();
                            dispatch(alertMessageActions.setAlert('green', response.message || 'Salvo com sucesso!', false));
                            dispatch({type: "REDIRECT", redirect: "/"});
                        }).catch((reject) => {
                            Loading.remove();
                            reject(null)
                        });

                });
            },

            onError: function (err) {
                console.error('paypal login error', err);
            }
        }, 'paypal-button');

    }).then(function () {
        Loading.remove();
        console.log('Ready');
    }).catch(function (err) {
        console.log(err);
    });
}
