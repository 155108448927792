import React, { Component } from 'react'
import ContentLoader from "./../content-loader/content-loader"
import { connect } from 'react-redux'
import Titulo from "../modules/Titulo";

const MensagemBoasVindasLoader = props => (
    <ContentLoader
        height={37}
        width={240}
        style={{width: '240', height: '37'}}
        primaryColor="#eaeaea"
        secondaryColor="#e4e4e4"
        {...props}
    >
        <rect x="0" y="0" rx="2" ry="2" width="240" height="37" />
    </ContentLoader>
)

class MensagemBoasVindas extends Component {
    render() {
        return this.props.user.id ? (
                <Titulo groupItem={'Olá, ' + this.props.user.nome} titulo="home"/>
        ) : <MensagemBoasVindasLoader />
    }
}

const mapStateToProps = state => {
    return { ...state }
}

export default connect(mapStateToProps)(MensagemBoasVindas)