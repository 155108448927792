import React, {Component} from 'react';

class BlocoDeEventos extends Component {
    render() {
        const content = this.props.content;
        return (
            <div className="slide-item evento justify-content-center text-center"
                 style={{
                     backgroundImage: "url('" + content.img + "')",
                     backgroundColor: content.color
                 }}>
                <a href={content.link ? content.link + "?utm_source=meu_estadao&utm_medium=meu_estadao&utm_campaign=meu_estadao" : "#"}
                   data-dtkey="eventos" title={content.title} rel="noopener noreferrer" target="_blank" onClick={e => {
                    !content.link && e.preventDefault()
                }} className="text-center link">
                    <div className="date">
                        <p className="nomargin">
                            {content.date}
                        </p>
                    </div>
                    <div className="content-text">
                        <p>
                            {content.title}
                        </p>
                    </div>
                </a>
            </div>
        )
    }
}

export default BlocoDeEventos;