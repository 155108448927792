import Menu from './menu.js';
import './polyfills';

const menuInstance = new Menu();
(Array.isArray(window.ESTADAO_MENU) ? window.ESTADAO_MENU : []).forEach(callFunction => {
    menuInstance.push(callFunction);
})
window.ESTADAO_MENU = menuInstance;


