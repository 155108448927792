import './less/app.less';
import './polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store'
import Layout from './components/layout/layout'
import * as serviceWorker from './serviceWorker';
import InstallApp from "./install-app";
import alertMessageActions from "./actions/alertMessage";
import ToastContainer from './components/toast/toast';

ReactDOM.render(
    <Provider store={store}>
        <ToastContainer />
        <Layout />
        <InstallApp />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
    onUpdate: (registration) => {
        store.dispatch(alertMessageActions.setAlert('green', 'Nova atualização disponível, feche e abra novamente para ver as modificações.'));
    }
});
