if(!Element.prototype.getClientRect){
    Element.prototype.getClientRect = function(){
        let rect = this.getClientRects();
        return {
            "x":rect[0].left,
            "left":rect[0].left,
            "y":rect[0].top,
            "top":rect[0].top,
            "height":rect[0].height,
            "width":rect[0].width
        };
    };
}