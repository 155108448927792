const asyncActionType = type => ({
    PENDING: `${type}_PENDING`,
    SUCCESS: `${type}_SUCCESS`,
    FAILURE: `${type}_FAILURE`,
    CACHED: `${type}_CACHED`,
});

export const API = 'API';
export const APP_ENGINE = 'APP_ENGINE';
export const API_LOGIN = 'API_LOGIN';

export const GET_FAQ = asyncActionType('GET_FAQ');
export const GET_ASSINATURA = asyncActionType('GET_ASSINATURA');
export const GET_ASSINATURAS = asyncActionType('GET_ASSINATURAS');
export const GET_NEWS = asyncActionType('GET_NEWS');
export const GET_TAGS = asyncActionType('GET_TAGS');
export const GET_PARTNERS = asyncActionType('GET_PARTNERS');
export const GET_NEWSPAPER = asyncActionType('GET_NEWSPAPER');
export const GET_NOTICACAO = asyncActionType('GET_NOTICACAO');
export const GET_TEMPO = asyncActionType('GET_TEMPO');
export const GET_NEWSLETTER = asyncActionType('GET_NEWSLETTER');
export const GET_TUTORIAIS = asyncActionType('GET_TUTORIAIS');
export const GET_SUBSCRIPTION_MESSAGES = asyncActionType('GET_SUBSCRIPTION_MESSAGES');
export const GET_HISTORICO_PAGAMENTO = asyncActionType('GET_HISTORICO_PAGAMENTO');



export const GET_USER = {
    USER: asyncActionType('GET_USER')
};

export const GET_SUBSCRIPTION = {
    MESSAGES: asyncActionType('GET_SUBSCRIPTION_MESSAGES'),
    ASSINATURA: asyncActionType('GET_SUBSCRIPTION_ASSINATURA'),
    ASSINATURAS: asyncActionType('GET_SUBSCRIPTION_ASSINATURAS'),
    PARCELA_EM_ABERTO: asyncActionType('GET_SUBSCRIPTION_PARCELA_EM_ABERTO'),
    BUSCAR_NFE: asyncActionType('GET_SUBSCRIPTION_BUSCAR_NFE'),
    INADIMPLENTE_STATUS: asyncActionType('GET_SUBSCRIPTION_INADIMPLENTE_STATUS')
};

export const GET_UTILS = {
    ASSUNTOS_FALE_CONOSCO: asyncActionType('GET_UTILS_ASSUNTOS_FALE_CONOSCO'),
    MOTIVOS_CANCELAMENTO: asyncActionType('GET_UTILS_MOTIVOS_CANCELAMENTO'),
    ULTIMAS_NOTICIAS: asyncActionType('GET_UTILS_ULTIMAS_NOTICIAS'),
    ASSUNTOS_DO_MOMENTO: asyncActionType('GET_UTILS_ASSUNTOS_DO_MOMENTO'),
    JORNAL_DE_HOJE: asyncActionType('GET_UTILS_JORNAL_DE_HOJE'),
    TEMPO: asyncActionType('GET_UTILS_TEMPO')
};
