import React from 'react';
import Input from './Input';
import InputMask from 'react-input-mask';

class InputPhone extends Input {
    constructor(props){
        super(props);
        this.state.mask = !this.props.value || this.props.value.replace(/[^0-9]/g,"").length <= 10 ? "(99) 9999-9999?" : "(99) 9 9999-9999";
    }

    onChange = (event) => {
        let count = event.target.value.replace(/[^0-9]/g,"").length;
        let mask = "(99) 9999-9999?";
        if(count > 10){
            mask = "(99) 9 9999-9999";
        }

        if(this.state.mask !== mask){
            this.setState({mask: mask});
        }

        this.handleChange(event);
    }

    beforeMaskedValueChange = (newState, oldState, userInput) => {
        var { value } = newState;
        var selection = newState.selection;

        if(value.replace(/[^0-9]/g,"").length === 11 && oldState.value.replace(/[^0-9]/g,"").length === 10){
            selection.start++;
            selection.end++;
        }

        this.handleChange({target: {value: value}});

        return {
            value,
            selection
        };
    }

    render(){
        return (
            <div className={this.state.className.join(" ")+(this.state.isset ? " isset" : "")}>
                {!this.state.load ? (
                    <div>
                        <InputMask maskChar={null} readOnly={this.state.readonly} ref={el => this.input = el} mask={this.state.mask} className={(!this.state.label ? "no-label" : "")} formatChars={{ 9: '[0-9]', '?': '[-\0-9]' }}  type={this.state.type} id={this.state.id} name={this.state.name} onChange={(e) => this.onChange(e)} beforeMaskedValueChange={this.beforeMaskedValueChange}  defaultValue={this.state.value}/>
                        {this.state.label ? (
                            <label htmlFor={this.state.id}>{this.state.label}</label>
                        ) : ""}
                    </div>
                ) : this.loader()}
            </div>
        )
    }
}

export default InputPhone;
