import {Form, Input, Textarea} from "../../../utils/Form";
import React, {Component} from 'react';
import Grid from "../../modules/Grid";
import {connect} from "react-redux";
import subscriptionActions from "../../../actions/subscription";
import Loading from "../../../utils/Loading";
import alertMessageActions from "../../../actions/alertMessage";

class Formulario extends Component {

    sendMessage() {
        let form = document.getElementById('form-correcao-redacao');
        if (form) {
            let bodyFormData = new FormData(form);
            bodyFormData.append('pessoa_contato', this.props.assinatura.recebedor.documento);
            Loading.add();
            this.props.dispatch(alertMessageActions.removeAlert());
            Form.send("/utils/essay-correction", bodyFormData).then((response) => {
                Loading.remove();
                window.scrollTo(0, 0);
                let {data, status} = response;
                if (status === 200) {
                    this.props.dispatch(alertMessageActions.setAlert('green', data.message || 'Correção enviada com sucesso!', 120000), false);
                } else if (data.error) {
                    let message = data.error.message || "Ops algo deu errado!";
                    Form.showErrors(message);
                }
            }).catch((e) => {
                Loading.remove();
            });

        }
    }

    render() {
        if (!this.props.assinatura && this.props.assinatura_selecionada) {
            this.props.dispatch(subscriptionActions.getDados(this.props.assinatura_selecionada))
        }
        return (
          <div>
              <div className='correcao-redacao-content'>
                  <form id='form-correcao-redacao' action='' className="block-single">
                      <div className='row'>
                        <Grid cols="12 12 12 12 12" >
                            <Input name='url' id='url' label="* Link da página com erro" />
                        </Grid>
                        <Grid cols='12 12 12 12 12'>
                            <Textarea name='comment' id='comment' label="* Comentário"/>
                        </Grid>
                        <Grid cols="12 12 6 6 6" >
                            <Input name='name' id='name' label="Seu nome" />
                        </Grid>
                        <Grid cols="12 12 6 6 6" >
                            <Input name='email' id='email' label="Seu email" />
                        </Grid>
                      </div>
                  </form>
              </div>
              <button className="btn blue salvar-dados ajustes" data-dtkey="salvar_dados" onClick={() => {
                  this.sendMessage()
              }}><i className="icon-paper-plane"/> Enviar
              </button>
          </div>)
    }
}

const mapStateToProps = state => {
    return {
        usuario: state.user,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.assinatura[state.assinaturas.assinatura_selecionada] : null
    }
}

export default connect(mapStateToProps)(Formulario)