import React, { Component } from 'react';
import {connect} from "react-redux"

class NewsletterStatus extends Component {
    render() {
        let inscricoes = 0;

        let newsletterTotal = Object.keys(this.props.newsletter).length;

        let assinaturas = this.props.newsletter;
        for(let i in assinaturas){
            if(assinaturas[i].aceite === 'S')
                inscricoes++;
        }

        return (
            <div className="newsletter-status">
                <div className="newsletter-desktop">
                    <div className="status-block">
                        <h3 className="titulo">
                            Status
                        </h3>
                        <div className="content">
                            <div className="line inscritos">
                                <div className="label col-9">
                                    Inscritas
                                </div>
                                <div className="base col-3">
                                    <span className="value">{inscricoes}</span>
                                </div>
                            </div>
                            <div className="line total">
                                <div className="label col-9">
                                    Total
                                </div>
                                <div className="base col-3">
                                    <span className="value">{newsletterTotal}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="newsletter-head-mobile">
                    <h3 className="titulo">
                        Newsletter
                    </h3>
                    <p>
                        Olá, <b>{this.props.user.nome}</b>, seu e-mail cadastrado é: <b>{this.props.user.email}</b>.
                    </p>
                    <a href="meus-dados">
                        <button type="button" className="alterar-email-btn">
                            Alterar e-mail
                        </button>
                    </a>
                    <p className="box">
                        Você se inscreveu em {inscricoes} newsletters
                    </p>
                    <p>
                        Selecione abaixo as newsletters que deseja receber em seu e-mail.
                    </p>
                </div>
            </div>

        )
    }
}


const mapStateToProps = state => {
    return {...state}
}

export default connect(mapStateToProps)(NewsletterStatus);