// Options modalPosition => [center,left,right]
import Loading from '../../utils/Loading';
import smoothscroll from 'smoothscroll-polyfill';

Element.prototype.getClientRect = function(){
    let rect = this.getClientRects();

    try{
        return {
            "x":rect[0].left,
            "left":rect[0].left,
            "y":rect[0].top,
            "top":rect[0].top,
            "height":rect[0].height,
            "width":rect[0].width
        };

    }catch(error){
        return {
            "x":0,
            "left":0,
            "y":0,
            "top":0,
            "height":0,
            "width":0
        }
    }
};

class Tutorial {

    padding = 15;
    tutorialJSON = {};
    currentStep = 0;
    numberActive = this.currentStep + 1;
    stepsQty = 0;
    nextStep = 0;
    prevStep = 0;

    onBoardActive = false;
    eventResizeActive = false;
    instancedTimer = 0;
    instancedEvent;
    statusContinuous;
    clientBounded = {};

    constructor() {
        try {

            smoothscroll.polyfill();
            let req = new XMLHttpRequest();
            req.open('GET', '/data/tutorial.json');
            req.onreadystatechange = () => {
                if(req.readyState === 4 && req.status === 200) {
                    this.tutorialJSON = req.responseText;
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    });
                    setTimeout(() => {
                        this.getPosY();
                    }, 200);
                }

            };
            req.send();

            this.instancedEvent = setInterval(() => {
                this.instancedTimer++;
            }, 1000);


            document.addEventListener("DOMContentLoaded",()=>{

                this.rootHeight = document.getElementById('root').clientHeight;
                if(window.location.hash === "#with-tutorial"){
                    console.log('started-turorial');
                    this.start();
                }

            });

        } catch (error) {
            //
            console.log(error);
        }
    }

    getPosY() {
        this.tutorialJSON = typeof this.tutorialJSON === "string" ? JSON.parse(this.tutorialJSON) : this.tutorialJSON;
        let element;
        let clientRect;
        for (let k in this.tutorialJSON) {
            if (this.tutorialJSON.hasOwnProperty(k)) {

                if (this.tutorialJSON[k].data.selectorId.length > 0) {
                    element = document.getElementById(this.tutorialJSON[k].data.selectorId);
                    if (element) {
                        clientRect = element.getClientRect();
                        this.tutorialJSON[k].data.top = Math.round(clientRect.top);
                    }
                }
                if (this.tutorialJSON[k].data.querySelectorEl.length > 0) {
                    element = document.querySelector(this.tutorialJSON[k].data.querySelectorEl);
                    if (element) {
                        clientRect = element.getClientRect();
                        this.tutorialJSON[k].data.top = Math.round(clientRect.top);
                    }
                }
                if (typeof this.tutorialJSON[k].data.top == 'undefined') {

                    setTimeout(() => {
                        this.getPosY();
                    }, 200);
                    return false;
                }
            }
        }

        if (!element) {
            setTimeout(() => {
                this.getPosY();
            }, 200);
            return false
        }
    }
    activeTutorial() {

        document.querySelector('.overlay-tutorial').setAttribute("class", "overlay-tutorial active");
    }

    desactiveTutorial() {
        this.onBoardActive = false;
        document.querySelector('.overlay-tutorial').setAttribute("class", "overlay-tutorial");
        document.querySelector('.overlay-tutorial').innerHTML = "";
        document.querySelector('body #root').style.marginTop = "0";

    }

    renderModal(object) {

        try {

            let overlay = document.querySelector(".overlay-tutorial");

            overlay.innerHTML = "";

            overlay.style.height = document.querySelector('body').clientHeight + 'px';
            overlay.innerHTML = this.getModalHTML(object);



            this.fixElements(object);
            overlay.querySelector('.modal-information').innerHTML = this.renderHTMLInformation();
            this.checkButtons();

        } catch (error) {
            console.log(error);
            this.desactiveTutorial();
        }
    }

    fixElements(object) {
        object.html += '<div class="modal-current-number">' + this.numberActive + '</div>';
        document.querySelector('.modal-target').innerHTML = object.html;
        this.fixNumberPosition(object);
    }

    fixNumberPosition(object){
        let currentNumberElement = document.querySelector('.modal-current-number');
        if(this.tutorialJSON[this.currentStep].data.fixNumberPos !== "bottom"){

            if(window.innerWidth >= 768){

                if(object.x <= 10 && currentNumberElement){
                    currentNumberElement.style.left = '39px';
                }
                if(object.y <= 10 && currentNumberElement){
                    currentNumberElement.style.top = '39px';
                }
            }else{
                if(object.x <= 10 && currentNumberElement){
                    currentNumberElement.style.left = '25px';
                }
                if(object.y <= 10 && currentNumberElement){
                    currentNumberElement.style.top = '39px';
                }
            }
        }else{

            if(window.innerWidth >= 768){

                if(object.x <= 10 && currentNumberElement){
                    currentNumberElement.style.left = '39px';
                }
            }else{
                if(object.x <= 10 && currentNumberElement){
                    currentNumberElement.style.left = '25px';
                }
            }
            currentNumberElement.style.top = (object.y+object.height)-20+'px';
        }
        if(this.tutorialJSON[this.currentStep].data.hasOwnProperty('mobile') && this.tutorialJSON[this.currentStep].data.mobile.fixNumberPos === "bottom"){
            if(window.innerWidth < 768) {
                currentNumberElement.style.top = (object.y + object.height) - 20 + 'px';
            }
        }
    }


    renderStyle(object) {
        try {
            object.x = object.x > 0 ? object.x : 0;
            if (this.tutorialJSON[this.currentStep].data.hasOwnProperty('styleSelectorFix') || this.tutorialJSON[this.currentStep].data.hasOwnProperty('mobile')) {

                if(this.tutorialJSON[this.currentStep].data.hasOwnProperty('styleSelectorFix') && window.innerWidth >= 768){
                    return 'position:absolute;left:' + object.x + 'px;top:' + object.y + 'px;width:' + object.width + 'px;'+this.tutorialJSON[this.currentStep].data.styleSelectorFix;
                }

                if (this.tutorialJSON[this.currentStep].data.mobile.hasOwnProperty('styleSelectorFix') && window.innerWidth < 768) {
                    return 'position:absolute;left:' + object.x + 'px;top:' + object.y + 'px;width:' + object.width + 'px;'+this.tutorialJSON[this.currentStep].data.mobile.styleSelectorFix;
                }

            }
            if (this.tutorialJSON[this.currentStep].data.extraStyle) {
                return 'position:absolute;left:' + object.x + 'px;top:' + object.y + 'px;width:' + object.width + 'px;height:' + object.height + 'px;' + this.tutorialJSON[this.currentStep].data.extraStyle;
            }
            return 'position:absolute;left:' + object.x + 'px;top:' + object.y + 'px;width:' + object.width + 'px;height:' + object.height + 'px';
        } catch (error) {
            return "";
        }
    }

    renderInfoStyle(object) {
        try {
            let position = this.tutorialJSON[this.currentStep].data['modalPosition'];
            let style;
            let top = object.height + object.y + 'px';
            if((object.height-30) < 40){
                top = (object.height-30)+object.y+'px';
            }
            let left;
            let right;
            if (this.tutorialJSON[this.currentStep].data.hasOwnProperty('mobile')) {

                if (this.tutorialJSON[this.currentStep].data.mobile.hasOwnProperty('styleInfoFix') && window.innerWidth < 768) {
                    return this.tutorialJSON[this.currentStep].data.mobile.styleInfoFix;
                }
                if (this.tutorialJSON[this.currentStep].data.hasOwnProperty('styleInfoFix') && window.innerWidth >= 768) {
                    return this.tutorialJSON[this.currentStep].data.styleInfoFix;
                }

            }
            switch (position) {
                case "center":
                    left = object.x + (object.width / 2)+'px';
                    style = 'position:absolute;top:' + top + ';left:' + left + ';margin-left:-134px';
                    if (window.innerWidth < 768) {
                        style = 'position:relative;top:' + top + ';margin:0 auto';
                    }
                    break;

                case "left":
                    left = object.x;
                    style = 'position:absolute;top:' + top + ';left:' + left;
                    break;

                case "right":
                    right = window.innerWidth - (object.x + (object.width)) - this.padding;
                    if(window.innerWidth > 1300){
                        right = (window.innerWidth - 1200) / 2;
                    }
                    style = 'position:absolute;top:' + top + ';right:' + right + 'px;';
                    break;

                case "bottom":
                    style = 'position:absolute;bottom:0px;left:0px;width:100%;';
                    break;

                case "relative-center":
                    style = 'position:relative;margin:0 auto;margin-top:' + top;
                    break;
                default:
                    style = "";
            }
            return style;


        } catch (error) {
            console.log(error);
            return "";
        }
    }

    arrowFix() {

        let arrow = document.querySelector('.arrow-up,.arrow-down');
        if (!arrow) {
            setTimeout(() => {
                this.arrowFix();
            }, 200);
            return false;
        }
        let style = "";

        if (this.tutorialJSON[this.currentStep].data.hasOwnProperty('arrowHPos') && window.innerWidth > 767) {

            let arrowHPos = this.tutorialJSON[this.currentStep].data.arrowHPos;

            switch (arrowHPos) {
                case "left":
                    style = "25px";
                    arrow.style.left = style;
                    break;
                case "center":
                    style = "calc(50% - 14px)";
                    arrow.style.left = style;
                    break;

                case "right":
                    style = "25px";
                    arrow.style.right = style;
                    break;
                default:
                //
            }
        } else if ((this.tutorialJSON[this.currentStep].data.hasOwnProperty('mobile') || this.tutorialJSON[this.currentStep].data.hasOwnProperty('arrowHPos')) && window.innerWidth < 768) {

            let arrowHPos = "";
            if (this.tutorialJSON[this.currentStep].data.hasOwnProperty('mobile') && this.tutorialJSON[this.currentStep].data.mobile.hasOwnProperty('arrowHPos')) {
                arrowHPos = this.tutorialJSON[this.currentStep].data.mobile.arrowHPos;
            }
            if (this.tutorialJSON[this.currentStep].data.hasOwnProperty('arrowHPos')) {
                arrowHPos = this.tutorialJSON[this.currentStep].data.arrowHPos;
            }

            switch (arrowHPos) {
                case "left":
                    style = "25px";
                    arrow.style.left = style;
                    break;
                case "center":
                    style = "calc(50% - 14px)";
                    arrow.style.left = style;
                    break;

                case "right":
                    style = "25px";
                    arrow.style.right = style;
                    break;
                default:
                //
            }
        }

    }

    getModalHTML(object) {
        this.clientBounded = object;
        let style = this.renderStyle(object);
        let styleInfo = this.renderInfoStyle(object);
        if (this.tutorialJSON[this.currentStep].data.arrowVPos !== "bottom") {
            return '<div class="modal-target ' + object.class + '" style="' + style + '"></div><div class="modal-information" style="' + styleInfo + '"></div>';
        } else if (this.tutorialJSON[this.currentStep].data.arrowVPosMob === "bottom" && window.innerWidth < 768) {
            return '<div class="modal-information" style="' + styleInfo + '"></div><div class="modal-target ' + object.class + '" style="' + style + '"></div>';
        } else {
            return '<div class="modal-information" style="' + styleInfo + '"></div><div class="modal-target ' + object.class + '" style="' + style + '"></div>';
        }
    }

    renderHTMLInformation() {
        let current = this.currentStep;
        current++;
        let html = '<div class="modal-header">';
        let clientElement = this.clientBounded;
        if (window.innerWidth < 768 && this.tutorialJSON[this.currentStep].data.hasOwnProperty('mobile')) {

            if (this.tutorialJSON[this.currentStep].data.mobile.arrowVPos !== "bottom") {
                html += '<div class="arrow-up"></div>';
            }
            html += '<div class="modal-step">' + current + '/' + this.stepsQty + '</div>';
            html += '<div class="modal-title">' + this.tutorialJSON[this.currentStep].data.title + '</div>';
            html += '</div>';

            html += '<div class="modal-content"><p>' + this.tutorialJSON[this.currentStep].data.description + '</p>';
            let textSair = (this.numberActive === this.stepsQty)? 'Concluído':'Sair';
            let classClose = (this.numberActive === this.stepsQty)? 'default':'';
            html += '<div class="buttons"><button data-action="close" class="'+classClose+'" data-dtkey="tutorial" data-step="'+this.numberActive+'">'+textSair+'</button> <div class="right"><button class="prev" data-action="prev" data-dtkey="tutorial" data-step="'+this.numberActive+'">Anterior</button><button class="next" data-action="next" data-dtkey="tutorial" data-step="'+this.numberActive+'">Próximo</button></div></div>';

            //Condição que inverte a seta do modal-information exclusivamente no mobile.
            if (this.tutorialJSON[this.currentStep].data.mobile.arrowVPos === "bottom") {
                html += '<div class="arrow-down"></div>';
                if (!this.tutorialJSON[this.currentStep].data.hasOwnProperty('mobile') && !this.tutorialJSON[this.currentStep].data.hasOwnProperty('styleInfoFix')) {

                    setTimeout(() => {
                        let modalInformation = document.querySelector('.modal-information');
                        let height = modalInformation.clientHeight + 13;
                        modalInformation.style.top = (clientElement.y - height) + 'px';
                    }, 100);
                }
            }

        } else {
            if (this.tutorialJSON[this.currentStep].data.arrowVPos !== "bottom") {
                html += '<div class="arrow-up"></div>';
            }
            html += '<div class="modal-step">' + current + '/' + this.stepsQty + '</div>';
            html += '<div class="modal-title">' + this.tutorialJSON[this.currentStep].data.title + '</div>';
            html += '</div>';

            html += '<div class="modal-content"><p>' + this.tutorialJSON[this.currentStep].data.description + '</p>';

            let textSair = (this.numberActive === this.stepsQty)? 'Concluído':'Sair';
            let classClose = (this.numberActive === this.stepsQty)? 'default':'';
            html += '<div class="buttons"><button data-action="close"  class="'+classClose+'" data-dtkey="tutorial" data-step="'+this.numberActive+'">'+textSair+'</button> <div class="right"><button class="prev" data-action="prev" data-dtkey="tutorial" data-step="'+this.numberActive+'">Anterior</button><button class="next" data-action="next" data-dtkey="tutorial" data-step="'+this.numberActive+'">Próximo</button></div></div>';

            //Condição que inverte a seta do modal-information.
            if (this.tutorialJSON[this.currentStep].data.arrowVPos === "bottom") {
                html += '<div class="arrow-down"></div>';
                if (!this.tutorialJSON[this.currentStep].data.hasOwnProperty('mobile') && !this.tutorialJSON[this.currentStep].data.hasOwnProperty('styleInfoFix')) {

                    setTimeout(() => {
                        let modalInformation = document.querySelector('.modal-information');
                        let height = modalInformation.clientHeight + 13;
                        modalInformation.style.top = (clientElement.y - height) + 'px';
                    }, 100);

                }
            }

        }

        html += '</div>';
        this.arrowFix();
        return html;
    }

    checkButtons() {
        setTimeout(() => {
            let buttons = document.querySelectorAll('.modal-content .buttons [data-action]');

            if (!buttons) {
                setTimeout(() => {
                    this.checkButtons();
                }, 200);
            }

            if (buttons.length > 0) {
                for (let key in buttons) {
                    if (buttons.hasOwnProperty(key)) {
                        let action = buttons[key].getAttribute("data-action") ? buttons[key].getAttribute("data-action") : false;
                        switch (action) {
                            case "close":
                                buttons[key].addEventListener("click", () => {
                                    this.closeTutorial()
                                });
                                break;
                            case "prev":
                                if (this.prevStep < 0) {
                                    buttons[key].setAttribute("disabled", "disabled");
                                } else {
                                    buttons[key].removeAttribute("disabled");
                                    buttons[key].addEventListener("click", () => {
                                        this.gotoPrev();
                                    })
                                }
                                break;
                            case "next":
                                if (!this.tutorialJSON.hasOwnProperty(this.nextStep)) {
                                    buttons[key].setAttribute("disabled", "disabled");
                                } else {
                                    buttons[key].removeAttribute("disabled");
                                    buttons[key].addEventListener("click", () => {
                                        this.gotoNext();
                                    })
                                }
                                break;

                            default:
                            //

                        }
                    }
                }
            }

        }, 300);

    }

    gotoPrev() {
        this.currentStep--;
        if (this.currentStep < 0) {
            this.currentStep = 0;
        }
        this.prevStep = this.currentStep - 1;
        this.nextStep = this.currentStep + 1;
        this.numberActive = this.currentStep+1;
        this.refresh();
        this.statusContinuous = 'desc';
    }

    gotoNext() {
        this.currentStep++;
        this.prevStep = this.currentStep - 1;
        this.nextStep = this.currentStep + 1;
        this.numberActive = this.currentStep+1;
        this.statusContinuous = 'asc';
        this.refresh();
    }

    closeTutorial() {
        this.desactiveTutorial();
    }

    addEvents() {
        this.onBoardActive = true;
        let widthW = window.innerWidth;
        window.addEventListener("resize", () => {
            if(!this.eventResizeActive && widthW !== window.innerWidth){
                this.eventResizeActive = true;
                if(this.onBoardActive){
                    this.desactiveTutorial();
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth',
                    });
                    setTimeout(()=>{
                        this.getPosY();
                        setTimeout(()=>{
                            this.start();
                            this.eventResizeActive = false;
                        },600)
                    },500)
                }else{
                    this.getPosY();
                }
            }
        });

        let overlay = document.querySelector('.overlay-tutorial');
        overlay.addEventListener("click", (e) => {
            try {
                let element = e.target;
                if (element.getAttribute("class")) {
                    if (element.getAttribute("class").indexOf('overlay-tutorial') > -1) {
                        this.desactiveTutorial();
                    }
                }
            } catch (error) {
                console.log(error);
            }
        })
    }

    start() {
        this.addEvents();
        try {
            if(this.rootHeight !== document.getElementById('root').clientHeight){
                this.rootHeight = document.getElementById('root').clientHeight;
                this.getPosY();
                Loading.add();
                setTimeout(() => {
                    this.start();
                }, 1000);
                return false;
            }
            if (this.instancedTimer > 2) {
                clearInterval(this.instancedEvent);
                Loading.remove();
                this.tutorialJSON = typeof this.tutorialJSON === "string" ? JSON.parse(this.tutorialJSON) : this.tutorialJSON;
                this.stepsQty = Object.keys(this.tutorialJSON).length;
                let element = '';
                let key = parseInt(this.currentStep);
                this.prevStep = this.currentStep - 1;
                this.nextStep = this.currentStep + 1;

                let selectorId = this.tutorialJSON[key].data['selectorId'].length > 0 ? this.tutorialJSON[key].data['selectorId'] : false;
                let querySelector = this.tutorialJSON[key].data['querySelectorEl'].length > 0 ? this.tutorialJSON[key].data['querySelectorEl'] : false;

                if (selectorId) {
                    element = document.getElementById(selectorId);
                }
                if (querySelector) {
                    element = document.querySelector(querySelector);
                }
                if (!selectorId && !querySelector) {
                    return false;
                }
                if(this.tutorialJSON[key].data.top < 0){
                    if(/Edge/.test(navigator.userAgent)){

                        window.scrollTo(0,0);

                    }else{

                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth',
                        });
                    }
                    this.getPosY();
                    Loading.add();
                    setTimeout(() => {
                        this.start();
                    }, 1000);
                    return false;
                }
                let clientElement = element.getClientRect();
                clientElement.y = this.tutorialJSON[key].data.top;

                setTimeout(() => {
                    clientElement = Object.assign({},element.getClientRect());
                    clientElement.class = element.getAttribute("class") ? element.getAttribute("class") : "";
                    clientElement.html = element.innerHTML;
                    clientElement = this.updateRectPadding(clientElement);
                    clientElement.y = this.tutorialJSON[key].data.top;
                    window.scrollTo({
                        top: this.tutorialJSON[key].data.top - 40,
                        left: 0,
                        behavior: 'smooth',
                    });

                    this.activeTutorial();
                    this.renderModal(clientElement);
                    setTimeout(() => {
                        if(document.querySelector('.modal-information')){
                            document.querySelector('.modal-information').setAttribute("class", 'modal-information active');
                        }
                    }, 100);
                    Loading.remove();
                }, 200)
            } else {
                Loading.add();
                setTimeout(() => {
                    this.start();
                }, 1000);
            }

        } catch (error) {
            //
            console.log(error);
        }

    }

    refresh() {
        try {
            if(this.rootHeight !== document.getElementById('root').clientHeight){
                this.rootHeight = document.getElementById('root').clientHeight;
                this.getPosY();
                Loading.add();
                setTimeout(() => {
                    this.refresh();
                }, 1000);
                return false;
            }
            this.tutorialJSON = typeof this.tutorialJSON === "string" ? JSON.parse(this.tutorialJSON) : this.tutorialJSON;
            this.stepsQty = Object.keys(this.tutorialJSON).length;
            let element = '';
            let key = parseInt(this.currentStep);

            if (this.tutorialJSON.hasOwnProperty(key)) {
                let selectorId = this.tutorialJSON[key].data['selectorId'].length > 0 ? this.tutorialJSON[key].data['selectorId'] : false;
                let querySelector = this.tutorialJSON[key].data['querySelectorEl'].length > 0 ? this.tutorialJSON[key].data['querySelectorEl'] : false;

                if (selectorId) {
                    element = document.getElementById(selectorId);
                }
                if (querySelector) {
                    element = document.querySelector(querySelector);
                }
                if (!selectorId && !querySelector) {
                    return false;
                }
                if(!element){
                    if(this.statusContinuous === 'asc'){
                        this.gotoNext();
                    }else{
                        this.gotoPrev();
                    }
                    return false;
                }
                let clientElement = element.getClientRect();
                clientElement = Object.assign({},element.getClientRect());
                clientElement.y = this.tutorialJSON[key].data.top;

                setTimeout(() => {
                    clientElement = element.getClientRect();
                    clientElement.class = element.getAttribute("class") ? element.getAttribute("class") : "";
                    clientElement.html = element.innerHTML;
                    clientElement = this.updateRectPadding(clientElement);
                    clientElement.y = this.tutorialJSON[key].data.top;
                    window.scrollTo({
                        top: this.tutorialJSON[key].data.top - 40,
                        left: 0,
                        behavior: 'smooth',
                    });
                    this.renderModal(clientElement);
                    setTimeout(() => {
                        if(document.querySelector('.modal-information')){
                            document.querySelector('.modal-information').setAttribute("class", 'modal-information active');
                        }
                        Loading.remove();
                    }, 100);
                }, 200)
            }

        } catch (error) {
            //
            console.log(error);
        }

    }

    updateRectPadding(clientElement) {

        clientElement.width = clientElement.width + (this.padding * 2);
        clientElement.height = clientElement.height + (this.padding * 2);
        clientElement.x = clientElement.x - this.padding;
        clientElement.y = clientElement.y - this.padding;

        return clientElement;
    }
}

export default Tutorial;
