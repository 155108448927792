import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from "../../modules/Grid";
import {Form, Input, Select, InputCEP} from "../../../utils/Form";
import Loading from "../../../utils/Loading";
import alertMessageActions from '../../../actions/alertMessage';
import userActions from '../../../actions/user';
import axios from "axios";
import env from "../../../env";

class Formulario extends Component {
    state = {
        uf: null,
        states: [],
        state_load: false,
        citys: [],
        city_load: false
    }

    componentDidMount() {
        this._updateStates();
        if(this.props.endereco.uf){
            this._updateCitys(this.props.endereco.uf);
        }
    }

    _updateStates(){
        return new Promise((resolve, reject) => {
            if(!this.state.state_load){
                this.setState({
                    state_load: true
                });
                axios.get(env.API_URL + "/utils/address/states", {withCredentials: true})
                    .then(response => {
                        let {data} = response, states = {};
                        for (let i in data) {
                            if(data.hasOwnProperty(i))
                                states[data[i].uf] = data[i].uf;
                        }

                        this.setState({
                            states: states,
                            state_load: false
                        });

                        resolve(states);

                    }).catch((response) => {
                        this.setState({
                            state_load: false
                        });
                        reject(response);
                    });
            }else{
                resolve(this.state.states);
            }
        });
    }

    _updateCitys(uf){
        return new Promise((resolve, reject) => {
            if(uf && !this.state.city_load){
                this.setState({
                    city_load: true
                });
                axios.get(env.API_URL+"/utils/address/"+uf+"/citys", {withCredentials: true})
                    .then(response => {
                        let {data} = response, citys = {};
                        for (let i in data) {
                            if(data.hasOwnProperty(i))
                                citys[data[i].id_cidade] = data[i].cidade;
                        }

                        this.setState({
                            citys: citys,
                            city_load: false
                        });

                        resolve(citys);
                    }).catch((response) => {
                        this.setState({
                            city_load: false
                        });
                        reject(response);
                    });
            }else{
                resolve(this.state.citys);
            }
        })
    }

    sendUpdate(){
        let form = document.getElementById('form-meus-dados');
        let salvarDados;
        let obj = {};
        obj['genericCategory'] = 'engajamento';
        obj['genericAction'] = 'meu_estadao';
        if(form){
            let bodyFormData = new FormData(form);
            Loading.add();
            Form.send("/user/address", bodyFormData).then((response) => {
                Loading.remove();
                window.scrollTo(0, 0);
                let {data, status} = response;
                if(status === 200){
                    this.props.dispatch(alertMessageActions.setAlert('green', 'Salvo com sucesso!'));
                    this.props.dispatch(userActions.getUser(true));
                    obj['genericLabel'] = 'salvar_endereco_sucesso';
                    salvarDados = new CustomEvent('alertPush', {'detail': obj});
                    window.dispatchEvent(salvarDados);
                }else if(data.error){
                    let message = data.error.message || "Ops algo deu errado!";
                    Form.showErrors(message);
                    obj['genericLabel'] = 'salvar_endereco_recusado';
                    salvarDados = new CustomEvent('alertPush', {'detail': obj});
                    window.dispatchEvent(salvarDados);
                }
            }).catch((e) => {
                Loading.remove();
            });
        }
    }

    changeState = e => {
        return this._updateCitys(this.refs.uf.select.value);
    }

    setCitySerchValue = () => {}

    render(){
        let endereco = this.props.endereco;
        let load = !this.props.user.id;
        return(
            <div>
                <div className="title-mobile">Endereço</div>
                <div className="block-single">
                    <form id="form-meus-dados" action="" method="POST" className="content-form">
                        <div className="row">
                            <Grid cols="12 12 4 4 3">
                                <InputCEP search={{
                                    'endereco': 'logradouro',
                                    'bairro': 'bairro',
                                    'uf': 'uf',
                                    'id_cidade': 'localidade'
                                }} callback={(response) => {
                                    this.setCitySerchValue = (citys) => {
                                        if(!response.erro){
                                            let city = response.localidade.toLowerCase().removeAssents();
                                            for(let i in citys){
                                                if(citys.hasOwnProperty(i)){
                                                    if(citys[i].toLowerCase().removeAssents().indexOf(city) > -1){
                                                        document.querySelector('#input_id_cidade').setValue(i);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }} name="cep" label="CEP" value={endereco.cep} load={load}  />
                            </Grid>
                            <Grid cols="12 12 6 5 7" >
                                <Input name="endereco" label="Endereço" value={endereco.endereco} load={load} readonly="readonly" />
                            </Grid>
                            <Grid cols="12 12 2 3 2">
                                <Input name="numero" label="Número" value={endereco.numero} load={load} />
                            </Grid>
                        </div>
                        <div className="row">
                            <Grid cols="12 12 4 4 4">
                                <Input name="complemento" label="Complemento" value={endereco.complemento} load={load}  />
                            </Grid>
                            <Grid cols="12 12 3 3 3">
                                <Input name="bairro" label="Bairro" value={endereco.bairro} load={load} readonly="readonly" />
                            </Grid>
                            <Grid cols="12 12 2 2 2" >
                                <Select ref="uf" data={this.state.states} onChange={
                                    (e) => {
                                        this.changeState(e).then((citys) => {
                                            this.setCitySerchValue(citys);
                                        });
                                    }
                                } name="uf" label="UF" value={endereco.uf} load={this.state.state_load} readonly="readonly" />
                            </Grid>
                            <Grid cols="12 12 3 3 3">
                                <Select data={this.state.citys} name="id_cidade" label="Cidade" value={endereco.id_cidade} load={this.state.city_load} readonly="readonly" />
                            </Grid>
                        </div>
                    </form>
                </div>
                <button className="btn blue salvar-dados" data-dtkey="salvar_dados" onClick={() => {this.sendUpdate()}}><i className="icon-floppy"></i> Salvar dados</button>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        endereco: state.user.endereco || {}
    }
}

export default connect(mapStateToProps)(Formulario)