import React, {Component} from 'react';
import Cache from './utils/CacheControl';

class InstallApp extends Component {

    state = {
        show: false
    }

    constructor(props){
        super(props);

        this.cache = new Cache('installAPP');
        let obj = {
            'genericCategory':'conversao',
            'genericAction':'instalar_show',
            'genericLabel':'meu_estadao_app'
        };
        let alertPush = new CustomEvent('alertPush', {'detail': obj});
        window.dispatchEvent(alertPush);
    }

    deferredPrompt = null;

    componentDidMount() {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
            this.setState({
                show: true
            });
        });
    }

    installAPP = e => {
        this.close();
        if(this.deferredPrompt){
            this.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                this.deferredPrompt = null;
            });
        }
    }

    close = e => {
        if(this.refs.modal){
            this.cache.set(true);
            this.refs.modal.remove();
        }
    }

    render(){
        return this.state.show && !this.cache.get(43200) && (
            <div ref="modal" id="add-to-home-screen">
                <div className="center">
                    <div className="inner">
                        <div className="row nomargin">
                            <div className="col-4 nopadding">
                                <img src="/static/icons/icon-96x96.png" alt="meuEstadão" />
                            </div>
                            <div className="col-8">
                                <p>Instale o atalho do meuEstadão em seu dispositivo.</p>
                            </div>
                        </div>
                        <div className="control">
                            <button className="install" data-dtkey="install_app" onClick={this.installAPP}>Instalar</button>
                            <button className="close" data-dtkey="install_app_cancelar" onClick={this.close}>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default InstallApp;