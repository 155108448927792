import React, { Component } from 'react'
import Grid from "../../modules/Grid";
import {Input, Checkbox, Select} from "../../../utils/Form";

class CartaoDeCredito extends Component {
    constructor(props){
        super(props);

        this.handleChange = this.handleChange.bind(this);
    }

    state = {
        flag: null
    }

    handleChange(e){
        let number = (e.target ? e.target.value : "").replace(/ /g,"");
        let flag = null;
        let card_list = {
            "AMEX":	/^3[47]\d{13}$/,
            "DINERS": /^3(0[0-5]|[68]\d)\d{11}$/,
            "MASTERCARD": /^(5[1-5]\d{4}|677189)\d{10}$/,
            "VISA": /^4\d{12}(\d{3})?$/,
            //"ELO": /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
        };

        for(let i in card_list){
            if(number.match(card_list[i])){
                flag = i;
                break;
            }
        }

        if(this.state.flag !== flag){
            this.setState({
                flag: flag
            })
        }
    }

    render(){
        let current_year = (new Date()).getFullYear();
        let list_year = {};
        for(let i = current_year; i <= (current_year + 10); i++){
            list_year[i] = i;
        }

        return (
            <div className="credit-card">
                <form id="form-alterar-forma-de-pagamento-cartao-de-credito" action="" method="POST" className="content-form">
                    <input type="hidden" name="forma_pagamento_para" value="J" />
                    <div className="row">
                        <Grid cols="4 3 2 2 2" class={"bandeira" + (this.props.disabled ? " disabled" : "")}>
                            <span>
                                <i className="icon-credit-card-alt" data-flag={this.state.flag}/>
                            </span>
                        </Grid>
                        <Grid cols="8 9 10 10 10">
                            <Input readonly={this.props.disabled} type="tel" inputProps={{maskChar: "", onChange: this.handleChange}} mask="9999 9999 9999 9999" name="cartao[numero]" label="Número do Cartão" />
                        </Grid>
                    </div>
                    <div className="row">
                        <Grid cols="12">
                            <Input readonly={this.props.disabled} name="cartao[titular]" label="Nome Impresso no Cartão" />
                        </Grid>
                    </div>
                    <div className="row">
                        <Grid cols="4 2 2 2 2">
                            <p className="text-blue"><span>Validade</span></p>
                        </Grid>
                        <Grid cols="8 5 5 5 5">
                            <div className="row">
                                <Grid cols="5">
                                    <Select data={{
                                        "01":"01",
                                        "02":"02",
                                        "03":"03",
                                        "04":"04",
                                        "05":"05",
                                        "06":"06",
                                        "07":"07",
                                        "08":"08",
                                        "09":"09",
                                        "10":"10",
                                        "11":"11",
                                        "12":"12"
                                    }} search={false} readonly={this.props.disabled} name="cartao[mes]"  label="Mês" />
                                </Grid>
                                <Grid cols="2">
                                    <p className="text-blue" style={{textAlign:"center"}}><span>/</span></p>
                                </Grid>
                                <Grid cols="5">
                                    <Select data={list_year} search={false} readonly={this.props.disabled} name="cartao[ano]" mask="9999" label="Ano"  />
                                </Grid>
                            </div>
                        </Grid>
                    </div>

                    {!this.props.manter ? (
                        <div className="row">
                            <Grid cols="12">
                                <Checkbox readonly={this.props.disabled} name="altera_meio_pagamento" label="Manter o cartão acima como forma de pagamento para demais parcelas" value="1"/>
                            </Grid>
                        </div>
                    ) : (
                        <input type="hidden" name="altera_meio_pagamento" value="1" />
                    )}
                </form>
            </div>
        )
    }
}

export default CartaoDeCredito