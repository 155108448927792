import {GET_UTILS} from '../utils/asyncActionType'

const INITIAL_STATE = {
    assuntos_fale_conosco: {},
    motivos_cancelamento: {},
    ultimas_noticias: [],
    assuntos_do_momento: [],
    jornal_de_hoje: {
        label: null,
        image: null,
        alt_image: null
    },
    tempo:  {
        temperatura_minima: null,
        temperatura_maxima: null,
        status: null,
        uf: null
    }
};


export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_UTILS.ASSUNTOS_FALE_CONOSCO.SUCCESS:
        case GET_UTILS.ASSUNTOS_FALE_CONOSCO.CACHED:
            return { ...state, ...{assuntos_fale_conosco: action.payload} };

        case GET_UTILS.MOTIVOS_CANCELAMENTO.SUCCESS:
        case GET_UTILS.MOTIVOS_CANCELAMENTO.CACHED:
            return { ...state, ...{motivos_cancelamento: action.payload} };

        case GET_UTILS.ULTIMAS_NOTICIAS.SUCCESS:
        case GET_UTILS.ULTIMAS_NOTICIAS.CACHED:
            return { ...state, ...{ultimas_noticias: action.payload} };

        case GET_UTILS.ASSUNTOS_DO_MOMENTO.SUCCESS:
        case GET_UTILS.ASSUNTOS_DO_MOMENTO.CACHED:
            return { ...state, ...{assuntos_do_momento: action.payload} };

        case GET_UTILS.JORNAL_DE_HOJE.SUCCESS:
        case GET_UTILS.JORNAL_DE_HOJE.CACHED:
            return { ...state, ...{jornal_de_hoje: action.payload} };

        case GET_UTILS.TEMPO.SUCCESS:
        case GET_UTILS.TEMPO.CACHED:
            return { ...state, ...{tempo: action.payload} };

        default:
            return state;
    }
}