import { GET_USER } from "../utils/asyncActionType";
import subscriptionActions from "./../actions/subscription";
import alertMessageActions from "./../actions/alertMessage";
import Cookie from "../utils/Cookie";

const INITIAL_STATE = {
  id: "",
  email: "",
  nome: "",
  sobrenome: "",
  cpf: "",
  foto_url: "",
  assinante: "",
  notification: 1,
  processando: false,
  has_cookie: true
};

let assinaturas = false;
let msg_nao_assinante = false;
let msg_suspenso = false;

export default {
  user(state = INITIAL_STATE, action) {
    switch (action.type) {
      case GET_USER.USER.SUCCESS:
      case GET_USER.USER.CACHED:
        let user = { ...state, ...action.payload };

        if (Cookie.get("OESP_INFO")) {
          const oespInfo = JSON.parse(Cookie.get("OESP_INFO"));
          if (oespInfo.produtos && oespInfo.produtos.includes("BLOCK")) {
              user.block = true;
          } else {
              user.block = false;
          }
        } else {
          user.block = false;
        }

        if (user.assinante && !assinaturas && !user.colaborador) {
          assinaturas = true;

          action.asyncDispatch(subscriptionActions.getProdutos('/user/subscriptions/active'));
        } else if (!user.assinante && user.block && !msg_suspenso) {
          msg_suspenso = true;

          action.asyncDispatch(subscriptionActions.getProdutos('/user/subscriptions/active'));

          action.asyncDispatch(
            alertMessageActions.setAlert(
              "red",
              'Sua assinatura está suspensa temporariamente. Clique em "Meus Pagamentos" e regularize.',
              false
            )
          );
        } else if (user.has_cookie && !user.assinante && !user.block && !msg_nao_assinante) {
          msg_nao_assinante = true;

          action.asyncDispatch(subscriptionActions.getProdutos('/user/subscriptions/active'));

          action.asyncDispatch(
            alertMessageActions.setAlert(
              "red",
              "Você ainda não é assinante.",
              false
            )
          );
        } else if (user.colaborador && !msg_nao_assinante) {
          msg_nao_assinante = true;

          action.asyncDispatch(subscriptionActions.getProdutos());

          action.asyncDispatch(
            alertMessageActions.setAlert(
              "blue",
              "Você está logado como colaborador.",
              false
            )
          );
        }

        return user;
      case GET_USER.USER.FAILURE:
      case GET_USER.USER.PENDING:
      default:
        return state;
    }
  },
  /*,

    user_sessions(state = [], action){
        switch (action.type) {
            case GET_USER.SESSIONS.SUCCESS:
            case GET_USER.SESSIONS.CACHED:
                return action.payload;
            case GET_USER.SESSIONS.FAILURE:
            case GET_USER.SESSIONS.PENDING:
            default:
                return state
        }
    }
    */
};
