import {
  API,
  GET_HISTORICO_PAGAMENTO,
  GET_SUBSCRIPTION,
} from "../utils/asyncActionType";

let last_order = null;
export default {
  getMessages(order) {
    if (last_order === order) {
      return { type: null };
    }
    last_order = order;
    return {
      type: API,
      payload: {
        url: "/subscription/" + order + "/messages",
        enable_reload: true,
        cache: 30,
        force_cache: 30,
        ...GET_SUBSCRIPTION.MESSAGES,
      },
    };
  },
  getHistoricoPagamentos(ordem, config = {}) {
    return {
      type: API,
      payload: {
        ...{
          url: "/subscription/" + ordem + "/payment-history",
          ordem: ordem,
          enable_reload: false,
          attempts: 0,
          test_response: (response) => {
            return response;
          },
          ...GET_HISTORICO_PAGAMENTO,
        },
        ...config,
      },
    };
  },

  getProdutos(subsUrl = "/user/subscriptions") {
    const produtos = {
      type: API,
      payload: {
        url: subsUrl,
        cache: 1440,
        force_cache: 1440,
        ...GET_SUBSCRIPTION.ASSINATURAS,
      },
    };

    return produtos;
  },

  setSelectedSubscription(subscription) {
    return {
      type: "SET_SELECTED_SUBSCRIPTION",
      payload: { subscription },
    };
  },

  getDados(ordem, config = {}) {
    return {
      type: API,
      payload: {
        ...{
          url: "/user/subscription/" + ordem,
          ordem: ordem,
          enable_reload: false,
          cache: 1440,
          force_cache: 720,
          attempts: 0,
          test_response: (response) => {
            return response.assinatura;
          },

          ...GET_SUBSCRIPTION.ASSINATURA,
        },
        ...config,
      },
    };
  },

  consultaParcelaEmAberto(ordem, config = {}) {
    return {
      type: API,
      payload: {
        ...{
          url: "/subscription/" + ordem + "/payment-outstanding-installments",
          ordem: ordem,
          enable_reload: true,
          cache: 1440,
          attempts: 0,
          test_response: (response) => {
            return response;
          },
          ...GET_SUBSCRIPTION.PARCELA_EM_ABERTO,
        },
        ...config,
      },
    };
  },

  getInadimplenteStatus(config = {}) {
    return {
      type: API,
      payload: {
        ...{
          url: "/subscription/dues/status",
          enable_reload: false,
          cache: 1440,
          test_response: (response) => {
            return response;
          },
          ...GET_SUBSCRIPTION.INADIMPLENTE_STATUS,
        },
        ...config,
      },
    };
  },

  buscarNFE(ordem, config = {}) {
    return {
      type: API,
      payload: {
        ...{
          url: "/subscription/" + ordem + "/invoices",
          ordem: ordem,
          enable_reload: false,
          cache: 1440,
          force_cache: 1440,
          attempts: 0,
          test_response: (response) => {
            return response.chave;
          },
          ...GET_SUBSCRIPTION.BUSCAR_NFE,
        },
        ...config,
      },
    };
  },
};
