import React, {Component} from 'react'
import Loading from "../../../utils/Loading";
import {connect} from "react-redux";
import axios from "axios";
import env from "../../../env";

class Formulario extends Component {
/*
    componentDidMount() {
        this.props.dispatch(userActions.getSessions())
    }*/

    removeSession(id_list){
        Loading.add();
        var bodyFormData = new FormData();
        id_list.map((id,i) => {
            bodyFormData.set('id['+i+']', id);
            return true;
        });

        axios.post(env.API_URL + '/user/remove-sessions', bodyFormData, {
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            },
            withCredentials: true
        }).then((response) => {
            Loading.remove();
            /*this.props.dispatch(userActions.getSessions())*/
        }).catch(() => {
            Loading.remove();
        });
    }

    render() {
        let user_session = [];
        let clear_all_tokens = [];
        Object.keys(this.props.user_sessions).map((key, i) => {
            let session = this.props.user_sessions[i];
            let options = { day: '2-digit', month:'long', year:'numeric'};
            let days = session['date_login'].split(" ")[0];
            let dateSplit = days.split("/");
            let data = new Date(parseInt(dateSplit[2]),parseInt(dateSplit[1])-1,parseInt(dateSplit[0]));
            session.date_string = data.toLocaleDateString('pt-BR', options);
            if(!session.me){
                clear_all_tokens.push(session.id);
            }
            user_session.push(session);
            return true;
        });

        user_session.sort((a,b) => {
            if(a.me){
                return -2;
            }
            if(b.me){
                return 2;
            }

            return a.date_login_time > b.date_login_time ? -1 : 1;
        });

        let sessions_length = (user_session.length < 10)? '0'+user_session.length:user_session.length;
        return (
            <div className="controle-de-acesso">
                <div className="top">
                    <p>
                        Você possui <span><b>{sessions_length}</b></span> dispositivos conectados, porém o limite é de <span><b>03</b></span>.<br/>
                        Confira seus acessos e caso encontre algum dispositivo desconhecido, clique em desconectar.
                    </p>
                </div>
                <div className="top">
                    <button className="btn blue float-right" onClick={() => this.removeSession(clear_all_tokens)}>Desconectar todos os dispositivos</button>
                </div>
                {user_session.map((session, key) => {
                    return (
                        <div key={key} className="item block-single">
                            <div className="field">
                                {!session.me ? (
                                    ''
                                ) : (
                                    <p className="owner">Acessando agora</p>
                                )}
                                <p><b>{session.browser === 'unknown' ? "Dispositivo não identificado" : session.browser+"  em "}{session.platform === 'unknown' ? "" : session.platform}</b></p>
                                <p>{session.date_string !== 'Invalid Date'? 'Primeiro acesso em '+session.date_string:''}</p>
                            </div>
                            <div className="field action">
                                {!session.me ? (
                                    <button className="btn" onClick={() => this.removeSession([session.id])}>Desconectar</button>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        //user_sessions: state.user_sessions,
    }
}

export default connect(mapStateToProps)(Formulario)
