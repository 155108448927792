import env from '../env'

const INITIAL_STATE = {
};

export default (state = INITIAL_STATE, action) => {
    if (env.APP_DEBUG) {
        console.log('assinaturaReducer :: state ::', state);
        console.log('assinaturaReducer :: INITIAL_STATE ::', INITIAL_STATE);
        console.log('assinaturaReducer :: action ::', action);
    }

    switch (action.type) {
        case 'SET_ALERT':
            return { ...state, ...action.alert };
        default:
            return state
    }
}