import React, {Component} from 'react'
import DocumentHead from "../../utils/DocumentHead"
import {connect} from "react-redux"
import partnersActions from '../../actions/partners'
import AlertMessage from "../alert-message/alertMessage";
import Assinaturas from "../assinaturas";
import Titulo from "../modules/Titulo";
import Grid from '../modules/Grid'
import BlocoDeEventos from "../blocks/events/events";

class EventosEstadao extends Component {
    componentDidMount() {
        DocumentHead({
            title: 'Eventos Estadão – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });
        this.props.dispatch(partnersActions.getPartners());
    }


    render() {
        let eventos = [];
        if(this.props.partners.eventos){
            Object.assign(eventos, this.props.partners.eventos);
            eventos.sort(function(a, b) {
                if(!a.order){
                    return 0;
                }
                let date_a = new Date(a.order);
                let date_b = new Date(b.order);
                return date_a.getTime() - date_b.getTime();
            });
        }
        let now = new Date();

        return (
            <div className="container justify-content-center">
                <div className="header-content row nomargin">
                    <Grid cols='12 12 12 6 6' class='text-left nopadding welcome-wrapper align-center'>
                        <Titulo groupItem="Eventos Estadão"/>
                    </Grid>
                    <Grid cols='12 12 12 6 6' class='text-left nopadding text-right hidden-xs hidden-sm hidden-md'>
                        <Assinaturas />
                    </Grid>
                    <AlertMessage />
                </div>

                <div className="events-container">
                    {eventos && Object.keys(eventos)
                        .filter((key) => {
                            let date = new Date(eventos[key].order);
                            return  eventos[key].order && date.getTime() >= now.getTime();
                        })
                        .map((key) => {
                                return(
                                    <div key={key} className="item">
                                        <BlocoDeEventos content={eventos[key]} />
                                    </div>
                                );
                            }
                        )}
                </div>

            </div>
        )

    }
}

const mapStateToProps = state => {
    return {...state}
}

export default connect(mapStateToProps)(EventosEstadao);