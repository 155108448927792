import React, {Component} from 'react'
import {connect} from 'react-redux'
import partnersActions from '../../actions/partners'
import Grid from '../modules/Grid'
import DocumentHead from '../../utils/DocumentHead'
import MensagemBoasVindas from '../welcomeMessage/welcomeMessage'
import Assinatura from '../assinaturas'
import AlertMessage from '../alert-message/alertMessage'
import BlocoDeNoticias from '../blocks/news/news'
import BlocoDeTags from '../blocks/tags/tags'
import BlocoDeParceiros from '../blocks/partners/partners'
import BlocoDeEventos from '../blocks/events/events'
import BlocoDePublicacao from '../blocks/publications/publications'
import Atividades from "../blocks/atividades/atividades";
import Servicos from "../blocks/servicos/servicos";
import LineScroll from "./line-scroll";
import Banner from "./banner";
import alertMessageActions from "../../actions/alertMessage";
import utilsActions from "../../actions/utils";
import axios from "axios";
import env from '../../env'

class Home extends Component {

    componentDidMount() {
        DocumentHead({
            title: 'meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });

        this.props.dispatch(partnersActions.getPartners());
        if (!this.props.alert.message) {
            this.checkAlertMessage().then((result) => {
                let status = result.status === "true" ? true : false;
                if (status) {
                    this.props.dispatch(alertMessageActions.setAlert(result.class, result.mensagem, false));
                }
            }).catch(() => {
            });

        }
    }

    checkAlertMessage(){

        return new Promise((resolve, reject) => {
            axios.get(env.API_URL +'/utils/get-messages').then((response) => {
                let result = response.data;
                if(result.status){
                    resolve(result.content);
                }
            }).catch(() => {
                reject(null)
            });
        });

    }

    componentWillUnmount(){
        this.props.dispatch(alertMessageActions.removeAlert());
    }

    renderUserSection = () => {

        return (
            <div className={"container"}>
                <div className="header-content row">
                    <Grid cols='12 12 6 6 6' class='text-left'>
                        <MensagemBoasVindas/>
                    </Grid>
                    <Grid cols='12 12 6 6 6' class='text-right'>
                        <Assinatura/>
                    </Grid>
                    <AlertMessage dtkey="barra_vermelha"/>
                </div>
                <div className="row">
                    <Grid cols='12 12 12 6 6'>
                        <Servicos id="servicos-mais-acessados" link="#"/>
                    </Grid>
                    <Grid cols='12 12 12 6 6'>
                        <Atividades id="minha-lista-de-atividades" link="#"/>
                    </Grid>
                </div>
            </div>
        );
    };

    renderBlockSection = () => {
        return (
            <div className="row">
                <Grid cols='12 12 12 6 6'>
                    <BlocoDeNoticias id="estadao-agora" link="#" class="ultimas-noticias" title="ESTADÃO agora"
                                     link_text="Listar todos"/>
                </Grid>
                <Grid cols='12 12 12 6 6'>
                    <BlocoDeTags id="assuntos-do-momento" link="#" class="meus-interesses float-right"
                                 title="Assuntos do momento" titleMobile="Assuntos"
                                 link_text="Personalizar | Listar todos"/>
                </Grid>
            </div>
        );
    }

    homeMainClass = () => {
        return "container home justify-content-center";
    };

    render() {
        let eventos = [];
        if (this.props.partners.eventos) {
            Object.assign(eventos, this.props.partners.eventos);
        }

        return (
            <div className="home justify-content-center">
                { this.renderUserSection() }

                <div className={"container"}>
                    <div className="row">
                        <Grid cols='12'>
                            <Banner/>
                        </Grid>
                    </div>

                    <LineScroll id="eventos-estadao" className="eventos" title="Eventos Estadão"
                                more={{link: "/eventos-estadao", text: "Listar todos", dtkey: "eventos_listar_todos"}}
                                content={eventos} block={BlocoDeEventos}/>

                    <LineScroll id="publicacoes" className="publicacoes" title="Publicações"
                                more={{link: "/publicacoes", text: "Listar todos", dtkey: "publicacoes_listar_todos"}}
                                content={this.props.partners.publicacao} block={BlocoDePublicacao}/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {...state}
}

export default connect(mapStateToProps)(Home)
