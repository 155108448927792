import React, {Component} from 'react'

import Grid from '../../modules/Grid'
import Assinatura from '../../assinaturas'
import Titulo from "../../modules/Titulo";
import AlertMessage from '../../alert-message/alertMessage'
import Formulario from "./formulario";
import DocumentHead from "../../../utils/DocumentHead";


class Index extends Component {
    componentDidMount() {
        DocumentHead({
            title: 'Correção da Redação – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });
    }
    render() {
        return (
          <div className="container correcao-redacao justify-content-center">
              <div className="row header-content">
                  <Grid cols='12 12 12 6 6'>
                      <Titulo groupItem="Correção da Redação" titulo="correção da redação"/>
                  </Grid>
                  <Grid cols='12 12 12 6 6' class='hidden-xs hidden-sm hidden-md'>
                      <Assinatura/>
                  </Grid>
                  <AlertMessage/>
              </div>
              <div className="row data-form">
                  <Grid cols='12 12 12 3 3'>
                      <div className="block-single text">
                          <p className='legenda-entrega lateral'>
                                Utilize o formulário abaixo para informar sobre erros encontrados no Estadão.
                          </p>
                      </div>
                  </Grid>
                  <Grid cols='12 12 12 9 9'>
                    <Formulario/>
                  </Grid>
              </div>
          </div>

        )
    }
}
export default Index