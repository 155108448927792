import { GET_FAQ } from '../utils/asyncActionType'

const INITIAL_STATE = { articles: [] }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_FAQ.SUCCESS:
            return { ...state, articles: action.payload }
        case GET_FAQ.PENDING:
            return state
        case GET_FAQ.FAILURE:
            return state
        default:
            return state
    }
}