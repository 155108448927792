let testLocalStorage = null;
export default class Cache{
    constructor(key, token){
        this.key = key;
        if(token){
           this.key += this.generateHash(token);
        }
    }

    generateHash(string){
        var hash = 0, i, chr;
        if (!string || string.length === 0) return hash;
        for (i = 0; i < string.length; i++) {
            chr   = string.charCodeAt(i);
            hash  = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    set(value){
        if(this.test()) {
            let insert = new Date();
            localStorage.setItem(this.key, JSON.stringify({
                "value": value,
                "insert": insert.getTime()
            }));
        }
    }

    get(expiration_minutes_or_function){
        try{
            if(this.test()){
                let value = JSON.parse(localStorage.getItem(this.key));
                if(expiration_minutes_or_function && value && value.insert){
                    let expires = new Date();
                    expires.setTime(value.insert);
                    if(typeof expiration_minutes_or_function === 'function'){
                        expires = expiration_minutes_or_function(expires);
                    }else{
                        expires.setMinutes(expires.getMinutes() + expiration_minutes_or_function);
                    }
                    value.expires = expires.getTime();
                }
                if(value && (!value.expires || value.expires > (new Date().getTime())) ){
                    return value.value;
                }
            }
        }catch (e) {}

        return null;
    }

    test(){
        if(testLocalStorage === null){
            try {
                localStorage.setItem('testLocalStorage', 'testLocalStorage');
                localStorage.removeItem('testLocalStorage');
                testLocalStorage = true;
            } catch(e) {
                testLocalStorage = false;
            }
        }

        return testLocalStorage;
    }
}