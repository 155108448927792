import { GET_FAQ } from '../utils/asyncActionType'

const INITIAL_STATE = { pending: false }

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_FAQ.SUCCESS:
            return { ...state, pending: false }
        case GET_FAQ.PENDING:
            return { ...state, pending: true }
        default:
            return state
    }
}