import React, { Component } from 'react'
import { connect } from 'react-redux'
import DocumentHead from '../../utils/DocumentHead'
import ContentLoader from "./../content-loader/content-loader"
import env from "../../env";
import Grid from "../modules/Grid";
import Cookie from "../../utils/Cookie";
import FotoUsuario from "../meus-dados/foto-usuario";

const PortalDoAssinanteIframeLoader = props => (
    <ContentLoader
        height={'440'}
        width={'960'}
        style={{width: '100%',maxWidth: "960", height: '440', margin: '0 auto', display: 'table'}}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="15" rx="5" ry="5" width="25%" height="25" />
        <rect x="75%" y="10" rx="5" ry="5" width="25%" height="35" />

        <rect x="0" y="60" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="85" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="110" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="135" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="160" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="185" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="210" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="235" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="260" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="285" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="310" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="335" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="360" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="385" rx="5" ry="5" width="100%" height="15" />

        <rect x="0" y="410" rx="5" ry="5" width="100%" height="15" />
    </ContentLoader>
)

class PortalDoAssinanteIframe extends Component {
    componentDidMount() {
        DocumentHead({
            title: 'meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });

        Cookie.set('MEU_IFRAME', '1', 360);

        window.addEventListener('message', this._updatePostMessage );

        this.refs.portal_do_assinante_wrapper.classList.add('hide');
        this.refs.PortalDoAssinanteIframeLoader.style.display = null;

        this.refs.portal_do_assinante_iframe.addEventListener('load', () => {
            this.refs.portal_do_assinante_wrapper.classList.remove('hide');
            this.refs.PortalDoAssinanteIframeLoader.style.display = "none";
        });
    }

    componentDidUpdate() {
        this.refs.portal_do_assinante_wrapper.classList.add('hide');
        this.refs.PortalDoAssinanteIframeLoader.style.display = null;

        this.refs.portal_do_assinante_iframe.addEventListener('load', () => {
            this.refs.portal_do_assinante_wrapper.classList.remove('hide');
            this.refs.PortalDoAssinanteIframeLoader.style.display = "none";
        });
    }

    componentWillMount() {
        window.removeEventListener('message', this._updatePostMessage );
    }

    _updatePostMessage(e){
        let URL = env.URL_PORTAL_DO_ASSINANTE.replace('https://', '').replace('http://','');
        if (e.origin.indexOf(URL)) {
            let data;
            try{
                data = JSON.parse(e.data);
            }catch (e) {
                data = {};
            }

            switch (data.event) {
                case "updateHeight":
                    let header = document.querySelector('header');
                    let footer = document.querySelector('footer');
                    let winHeight = window.innerHeight;
                    let total_height = parseFloat(data.params.height) + header.clientHeight + footer.clientHeight;
                    if(total_height > winHeight){
                        total_height = parseFloat(data.params.height) + 30;
                    }else{
                        total_height = winHeight - header.clientHeight - footer.clientHeight;
                    }

                    let element = document.getElementById('portal-do-assinante-iframe');
                    if(element){
                        element.height = total_height+"px";
                    }
                break;

                case "updateURL":
                    var a = document.createElement("a");
                    a.href = data.params.url;

                    var pathname = a.pathname;
                    pathname += window.location.search;
                    pathname = pathname.replace('iframe=1', "").replace('/home', "");
                    if(pathname && pathname.charAt(0) !== "/"){
                        pathname = "/"+pathname;
                    }
                    if(window.location.pathname+window.location.search !== "/assinante"+pathname){
                        window.history.pushState({},"", "/assinante"+pathname);
                    }
                    break;

                // no default
            }
        }
    }

    render() {
        var pathname = window.location.pathname;
        pathname = pathname ? pathname.replace('/assinante', "") : "";
        pathname = pathname ? pathname : "/home";
        pathname += window.location.search ?  window.location.search+"&iframe=1" : "?iframe=1";

        return (
            <div>
                <div className="PortalDoAssinanteIframeLoader" ref="PortalDoAssinanteIframeLoader">
                    <PortalDoAssinanteIframeLoader />
                </div>
                {pathname.indexOf('/dados-cadastrais/') > -1 ? (
                    <div ref="portal_do_assinante_wrapper" className="portal-do-assinante-wrapper meus-dados container">
                        <br />
                        <div className="row data-user">
                            <Grid cols="12 12 12 4 4">
                                <FotoUsuario/>
                            </Grid>
                            <Grid cols="12 12 12 8 8">
                                <iframe ref="portal_do_assinante_iframe" title="Portal do Assinante" id="portal-do-assinante-iframe" height="500" src={env.URL_PORTAL_DO_ASSINANTE+pathname} scrolling="no"></iframe>
                            </Grid>
                        </div>
                    </div>
                ) : (
                    <div ref="portal_do_assinante_wrapper" className="portal-do-assinante-wrapper">
                        <iframe ref="portal_do_assinante_iframe" title="Portal do Assinante" id="portal-do-assinante-iframe" height="500" src={env.URL_PORTAL_DO_ASSINANTE+pathname} scrolling="no"></iframe>
                    </div>
                )}

            </div>
        )
    }
}

const mapStateToProps = state => {
    return { ...state }
}

export default connect(mapStateToProps)(PortalDoAssinanteIframe)
