function retira_acentos(str) {
    var com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
    var sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
    var novastr="";
    for(var i=0; i<str.length; i++) {
        var troca = false;
        for (var a=0; a<com_acento.length; a++) {
            if (str.substr(i,1)==com_acento.substr(a,1)) {
                novastr+=sem_acento.substr(a,1);
                troca=true;
                break;
            }
        }
        if (troca==false) {
            novastr+=str.substr(i,1);
        }
    }
    return novastr;
}

function retira_espacos(str) {
    str = str.replaceAll('-', '_');
    str = str.replaceAll(' ', '_');
    return str
}

export function normalizarString(str) {
    if (typeof str != 'string'){
        return "";
    }

    str = retira_espacos(str);
    str = retira_acentos(str);
    str = str.toLowerCase();

    return str;
}