import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars'
import { Link } from 'react-router-dom'
import ContentLoader from "./../content-loader/content-loader"

const LineScrollLoader = props => (
    <ContentLoader
        height={'220'}
        width={'2000'}
        style={{width: '100%', height: '220'}}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}>
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="220"/>
    </ContentLoader>
)

class LineScroll extends Component {
    render() {
        const content = this.props.content;
        const Block = this.props.block;
        let count = 0;
        return (
            <div id={this.props.id} className={`slider-mobile slider ${this.props.className}`}>
                <div className="title row nomargin align-center">
                    <div className="col-6 nopadding text-left">
                        <h2>{this.props.title}</h2>
                    </div>
                    <div className="col-6 nopadding text-right">
                        {content && Object.keys(content).length > 5 && this.props.more && (<Link data-dtkey={this.props.more.dtkey || ""} to={this.props.more.link}>{this.props.more.text} <i className="icon-angle-right" /></Link>)}
                    </div>
                </div>
                {content ? (
                    <Scrollbars className="scroll-area"
                        renderTrackHorizontal={props => <div {...props} className="track-horizontal" />}
                        renderTrackVertical={props => <div {...props} className="track-vertical" />}>
                        <div className="slider-container">
                            {Object.keys(content)
                                .filter( (key) => {
                                    count++;
                                    return count <= 5;
                                })
                                .map((key,i) => {
                                    return (
                                        <Block key={key} content={content[i]}/>
                                    )
                                }
                            )}
                        </div>
                    </Scrollbars>
                ) : (
                    <div className="block-cards-body">
                        <LineScrollLoader/>
                    </div>
                )}
            </div>
        )
    }
}

export default LineScroll;