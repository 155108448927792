import React, {Component} from 'react';
import RoutesConfig from '../../Routes';
import Header from './header/header';
import Footer from './footer/footer';
import {BrowserRouter, Redirect} from 'react-router-dom'
import {connect} from "react-redux";

class Layout extends Component {
    state = {
        redirect: false
    }

    componentDidMount() {
        setInterval(() => {
            if(this.refs && this.refs.header){
                let header = this.refs.header.refs.html;
                let footer = this.refs.footer.refs.html;
                let innerContent = this.refs.inner;
                let winHeight = window.innerHeight;
                let inner_height = winHeight - (header.clientHeight + footer.clientHeight);
                if(inner_height > 0){
                    innerContent.style.minHeight = inner_height+"px";
                }else{
                    innerContent.style.minHeight = "auto";
                }
            }
        },1000)
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.redirect !== this.state.redirect){
            this.setState({
                redirect: this.props.redirect
            })
        }
    }

    render() {
        return (
            <BrowserRouter>
                <div>
                    {this.state.redirect && (
                        <Redirect to={this.state.redirect} push />
                    )}
                    <Header ref="header"/>
                    <div ref="inner" className="inner-content">
                        <RoutesConfig layout={this}/>
                    </div>
                    <div className="overlay-tutorial">
                    </div>
                    <Footer ref="footer"/>
                </div>
            </BrowserRouter>
        )
    }
};

const mapStateToProps = state => {
    return {
        redirect: state.redirect
    }
};

export default connect(mapStateToProps)(Layout)