import React, { Component } from 'react';
import ContentLoader from "./../../content-loader/content-loader"
import { connect } from 'react-redux'
import utilsAction from '../../../actions/utils';


const TempoLoader = props => (
    <ContentLoader
        height={15}
        width={70}
        speed={2}
        style={{width: '70px', height: '15px'}}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
    >
    <rect x="3" y="0" rx="15" ry="15" width="15" height="15" />
    <rect x="25" y="0" rx="15" ry="15" width="15" height="15" />
    <rect x="47" y="0" rx="15" ry="15" width="15" height="15" />
    </ContentLoader>
);

class Tempo extends Component {
    componentDidMount() {
        this.props.dispatch(utilsAction.getTempo());
    }

    render() {
        if(this.props.utils.tempo.status){
            return (
                <a href="https://www.estadao.com.br/clima-e-tempo/sp-sao-paulo" target="_blank"  rel="noopener noreferrer" className="previsao-do-tempo">
                    <span>SP</span> <span className="icon" data-status={this.props.utils.tempo.status}></span> <span className="maximo">{this.props.utils.tempo.temperatura_maxima}°</span> <span className="minimo">{this.props.utils.tempo.temperatura_minima}°</span>
                </a>
            )
        }else{
            return (
                <div className="previsao-do-tempo">
                    <TempoLoader/>
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(Tempo)
