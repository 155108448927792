import Cookie from './../cookie';

const INITIAL_STATE = {
    id: '',
    email: '',
    nome: '',
    sobrenome: '',
    cpf: '',
    foto_url: '',
    assinante: false,
    subscriptions: []
};


export default function user(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "SET_USER":
            if (action.payload.id) {
                let oesp_info;

                try {
                    oesp_info = JSON.parse(Cookie.get('OESP_INFO') || "{}") || {};
                } catch (e) {
                    oesp_info = {};
                }

                oesp_info.id = action.payload.id;
                oesp_info.nome = action.payload.nome;
                oesp_info.sobrenome = action.payload.sobrenome;
                oesp_info.assinante = action.payload.assinante;
                oesp_info.foto_url = action.payload.foto_url;
                oesp_info.email = action.payload.email;

                if (oesp_info) {
                    let produtos = [];

                    if (oesp_info.produtos) {
                        produtos = oesp_info.produtos;

                        if (produtos.includes("BLOCK")) {
                            oesp_info.block = true;
                            state.block = true;
                        } else {
                            oesp_info.block = false;
                            state.block = false;
                        }
                    }
                }

                Cookie.set('OESP_INFO', JSON.stringify(oesp_info), 7);
            }

            return {...state, ...action.payload};
        default:
            return state;
    }
}