import React, {Component} from 'react'
import Grid from '../../modules/Grid'

class Fatura extends Component {
    render() {

        let assinaturas = this.props.assinatura_dados;
        let pagador = this.props.pagador;
        let current_datetime = new Date();
        let formatted_date = current_datetime.getDate() + "/" + (current_datetime.getMonth() + 1) + '/' + current_datetime.getFullYear();

        return pagador && (
          <div className='fatura-container'>
              <div className='row'>
                  <Grid cols='12'>
                      <table className='table-fatura'>
                          <tbody>
                          <tr>
                              <td>
                                  <h1 className='text-center'>
                                      Fatura Ref. Assinatura
                                  </h1>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <table className='table-fatura'>
                          <tbody>
                          <tr>
                              <td><b>Número: </b>{assinaturas.numero_ordem ? assinaturas.numero_ordem : ''}/{assinaturas.numero_item ? assinaturas.numero_item : ''}/{assinaturas.ciclo}</td>
                              <td><b>Data: </b>{formatted_date}</td>
                          </tr>
                          </tbody>
                      </table>
                      <table className='table-fatura'>
                          <tbody>
                          <tr>
                              <td>
                                  <b>CPF/CNPJ: </b> {pagador.documento ? pagador.documento : ''}
                              </td>
                          </tr>
                          <tr>
                              <td>
                                  <b>Nome/Razão Social: </b> {pagador.nome ? pagador.nome : ''}
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <table className="table-fatura">
                          <tbody>
                          <tr>
                              <td>
                                  <b className="text-left">Assinatura Contratada: </b>
                              </td>
                              <td>
                                  {this.props.usuario && this.props.usuario.subscriptions ? this.props.usuario.subscriptions[this.props.assinatura_selecionada].label_produto_midia: '' }
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <table className="table-fatura">
                          <tbody>
                          <tr>
                              <td>
                                  <b className="text-left">Valor Total da Cobrança: </b>
                              </td>
                              <td className="text-right">
                                  R$ {assinaturas.valor?assinaturas.valor.toLocaleString('pt-BR'):''}
                              </td>
                          </tr>
                          </tbody>
                      </table>
                      <table className="table-fatura">
                          <tbody>
                          <tr>
                              <td className="description">
                                  <p className='obs-recibo'>
                                      Assinante, em razão das alterações promovidas pela Lei Federal nº 157/16, o 'Estadão Digital' passou a se sujeitar às normas fiscais e tributárias do Município de
                                      São Paulo. Por essa razão, a partir de junho de 2018, o Estadão adotará o procedimento previsto na Instrução Normativa SF/SUREM nº 22*, editada pela Secretaria
                                      Municipal de Finanças, e passará a emitir uma única nota fiscal de serviços (NFS-e) contendo a soma do valor mensal cobrado de cada assinante. Por esse motivo,
                                      esclarecemos que não haverá emissão de nota fiscal (NFS-e) individual para os assinantes do 'Estadão Digital'.
                                      <br />
                                      <a href='http://notadomilhao.prefeitura.sp.gov.br/cidadao/informacoes-gerais/legislacao-arquivos/instrucao-normativa-sf-surem-no-22-de-12-de-dezembro-de-2017'>Clique aqui</a> para acessar o link da Instrução Normativa junto à prefeitura de São Paulo para maiores esclarecimentos.
                                      <br />
                                      <i>* Lembrando que, essa fatura será emitida somente para parcelas pagas</i>
                                  </p>
                              </td>
                          </tr>
                          </tbody>
                      </table>
                  </Grid>
              </div>
          </div>
        )
    }
}


export default Fatura;