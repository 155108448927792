export default {
    setAlert(color, message, timeout = 0, link = false) {
        return {
            type: 'SET_ALERT',
            alert: {
                color: color,
                message: message,
                timeout: timeout !== 0 ? timeout : 5000,
                link: link
            }
        }
    },
    setAlertEmail(color, message, timeout = 0, link = false) {
        return {
            type: 'SET_ALERT',
            alert: {
                color: color,
                message: message,
                timeout: timeout !== 0 ? timeout : 10000,
                link: link
            }
        }
    },
    removeAlert() {
        return {
            type: 'SET_ALERT',
            alert: {
                color: "",
                message: "",
                timeout: false,
                link: false
            }
        }
    }
}