import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "../../modules/Grid";
import { Form, Input, InputPhone } from "../../../utils/Form";
import Loading from "../../../utils/Loading";
import alertMessageActions from "../../../actions/alertMessage";
import Cookie from "../../../utils/Cookie";
import store from "../../../store";

class Formulario extends Component {
  // Select all method
  selectAll(e) {
    const checked = e.target.checked;
    if (checked === true) {
      let checkboxes = document.getElementsByName("plano");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = true;
        document.getElementById(i).classList.add("selected");
      }
    } else {
      let checkboxes = document.getElementsByName("plano");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
        document.getElementById(i).classList.remove("selected");
      }
    }
  }

  // Select one element method
  selectPlano(e, index) {
    if (e.target.checked === false) {
      if (typeof document.getElementsByName("select-all")[0] !== "undefined") {
        document.getElementsByName("select-all")[0].checked = false;
      }
      document.getElementById(index).classList.remove("selected");
    }

    let check = true;
    let checkboxes = document.getElementsByName("plano");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked !== true) {
        check = false;
      }
    }
    if (check === true) {
      if (typeof document.getElementsByName("select-all")[0] !== "undefined") {
        document.getElementsByName("select-all")[0].checked = true;
      }
    }

    if (e.target.checked === true) {
      document.getElementById(index).classList.add("selected");
    }
  }

  // Return assinaturas
  assinaturas() {
    let userPlans = this.props.assinaturas.list;
    let assinatura_list = {};
    for (let i in userPlans) {
      assinatura_list[i] = i + " - " + userPlans[i].label_produto_midia;
    }
    userPlans = Object.keys(userPlans);
    if (Object.keys(userPlans).length > 0) {
      return (
        <div className="assinaturas">
          {/* Select all */}
          {userPlans.length > 1 ? (
            <div className="select-all">
              <input
                type="checkbox"
                id="select-all"
                name="select-all"
                onClick={(e) => {
                  this.selectAll(e);
                }}
                value="select-all"
              />

              <label htmlFor="selectall">Selecionar todas</label>
            </div>
          ) : (
            ""
          )}

          {/* Show the plans */}
          {userPlans.map((plan, index) => {
            return (
              <div key={index} id={index}>
                <input
                  type="checkbox"
                  name="plano"
                  value={plan}
                  onClick={(e) => {
                    this.selectPlano(e, index);
                  }}
                />

                <label htmlFor="selectall">{assinatura_list[plan]}</label>
              </div>
            );
          })
          // (this.optionsRender())
          }
        </div>
      );
    } else {
      return (
        <div className="assinaturas">
          <p>Você não possui nenhuma assinatura</p>
        </div>
      );
    }
  }

  // Assinaturas do usuario box
  assinaturasBox() {
    return (
      <div className="col-6 bx">
        <div className="row right-section">
          <div>
            <p>Selecione o plano de assinatura que terá o e-mail alterado</p>

            {this.assinaturas()}
          </div>
        </div>
      </div>
    );
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  sendUpdate() {
    let usuario = this.props.usuario;
    let form = document.getElementById("form-meus-dados");
    let salvarDados;
    let email_novo_conf = document.getElementById("input_email_novo_conf")
      .value;
    let email_novo = document.getElementById("input_email_novo").value;

    if (email_novo_conf === "" || email_novo_conf === "") {
      let obj = {};
      store.dispatch(
        alertMessageActions.setAlert(
          "red",
          "Por favor informar todos os campos!"
        )
      );
      salvarDados = new CustomEvent("alertPush", { detail: obj });
      window.dispatchEvent(salvarDados);
      return;
    }

    if (email_novo_conf !== email_novo) {
      let message = {
        email_novo: ["O campo email não pode ser diferente"],
        email_novo_conf: ["O campo email não pode ser diferente"],
      };
      Form.showErrors(message);
      return;
    }

    if (form) {
      let updated = true;
      // send the request for non-sub user
      if (usuario.assinante === false) {
        const asyncLoopEmail = async () => {
          Loading.add();
          let bodyFormData = new FormData(form);

          await Form.send("/user/email", bodyFormData)
            .then((response) => {
              let { data, status } = response;
              if (status === 200) {
                Loading.remove();
                Cookie.set("EMAIL_ALTERADO", true);
                store.dispatch(
                  alertMessageActions.setAlertEmail(
                    "green",
                    "E-mail de acesso ao site, a comunicados e a newsletters atualizado com sucesso!"
                  )
                );
                this.sleep(10000);
              } else {
                let message = data.message || "Ops algo deu errado!";
                Form.showErrors(message);
                Loading.remove();
                updated = false;
              }
            })
            .catch((e) => {
              Loading.remove();
              updated = false;
            });

          if (updated === true) {
            store.dispatch(
              alertMessageActions.setAlertEmail(
                "green",
                "E-mail de acesso ao site, a comunicados e a newsletters atualizado com sucesso!"
              )
            );
            await this.sleep(10000);
            window.location.href = "https://acesso.estadao.com.br/login/logout";
          }
          return true;
        };

        asyncLoopEmail();
      }

      // send the request for sub user
      if (usuario.assinante === true) {
        // Loading.add();
        const assinaturasChecked = document.querySelectorAll(
          "input[type=checkbox]:checked"
        );

        const asyncLoop = async () => {
          Loading.add();
          for (let i = 0; i < assinaturasChecked.length; i++) {
            if (assinaturasChecked[i].value !== "select-all") {
              console.log(assinaturasChecked[i].value);
              // send request here
              let obj = {};
              obj["email"] = email_novo;
              let id = assinaturasChecked[i].value;
              let url = "/subscription/" + id + "/email";

              // eslint-disable-next-line no-loop-func
              await Form.send(url, obj)
                .then((response) => {
                  let { data, status } = response;
                  if (status === 200 && +data.message.status === 0) {
                  } else {
                    let message = data.message || "Ops algo deu errado!";
                    Form.showErrors(message);
                    updated = false;
                    return;
                  }
                  // eslint-disable-next-line no-loop-func
                })
                .catch((e) => {
                  updated = false;
                  let message = e.message || "Ops algo deu errado!";
                  Form.showErrors(message);
                  return;
                });
            }
          }

          Loading.remove();
          Cookie.set("EMAIL_ALTERADO", true);
          if (updated === true) {
            store.dispatch(
              alertMessageActions.setAlertEmail(
                "green",
                "E-mail de acesso ao site, a comunicados e a newsletters atualizado com sucesso!"
              )
            );
            await this.sleep(10000);
            window.location.href = "https://acesso.estadao.com.br/login/logout";
          }
        };

        asyncLoop();
      }
    }
  }

  render() {
    let usuario = this.props.usuario;

    let subscriptions = [];

    if (usuario.subscriptions !== undefined) {
      subscriptions = Object.keys(usuario.subscriptions);
    }

    return (
      <div>
        <div className="block-single redefinir-email">
          <div className="row">
            <Grid cols="12 12 12 12 12">
              <div className="title-inside">
                <p>
                  {`Preencha os campos abaixo para trocar o seu e-mail de acesso ao ${
                    subscriptions.length > 1 ? "portal" : "site"
                  } Estadão e de recebimento de comunicados e newsletters.`}
                </p>
              </div>
            </Grid>
          </div>

          <form
            id="form-meus-dados"
            action=""
            method="POST"
            className="content-form alterar-email "
          >
            {/* Assinaturas box */}
            {this.assinaturasBox()}

            {/* Email box */}
            <div className="col-6 bx">
              <div className="row">
                <Input
                  readonly={true}
                  type="email"
                  id="email_antigo"
                  name="email_antigo"
                  label="E-mail atual"
                  value={usuario.email}
                />
              </div>

              <div className="row">
                <Input
                  type="email"
                  id="email_novo"
                  name="email_novo"
                  label="Novo e-mail"
                />
              </div>

              <div className="row">
                <Input
                  type="email"
                  id="email_novo_conf"
                  name="email_novo_conf"
                  label="Confirmar novo e-mail"
                />
              </div>

              <input
                hidden
                id="token"
                value={Cookie.get("PORTAL_AUTH")}
                name="token_usuario"
              />
            </div>
          </form>
        </div>

        <div className="bottom-block-button-notice">
          <div className="atention-notice">
            <span>
              <strong>Atenção!</strong>
              Esta troca não altera o seu e-mail de comunicados de cobrança.
              Para alteração, fale com o{" "}
              <a href="https://chat.directtalk.com.br/static/?token=28bff6d6-a7ee-4dea-8831-5d4c30513424_59faca87-5fb6-44c8-9098-ba86f386281e_20304d29-ac93-4254-a597-46bc404e0a72">
                nosso atendimento.
              </a>
            </span>
          </div>
          <button
            className="btn blue salvar-dados"
            data-dtkey="salvar_dados"
            onClick={() => {
              this.sendUpdate();
            }}
          >
            <i className="icon-floppy" />
            Salvar novo e-mail
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.user,
    ...state,
    config: state.config,
  };
};

export default connect(mapStateToProps)(Formulario);
