import { normalizarString } from "./normalizeText"

/**
 * quando o mesmo nome de evento é reutilizado, 
 * é necessário limpar os parâmetros para cada novo evento
 * nesse projeto todos os eventos estão sendo chamados de 'meu_estadao'
 */
const defaultParams = {
    acesso_header: undefined,
    secao: undefined,
    nome_campo: undefined,
    nome_newsletter: undefined,
    status_newsletter: undefined,
    componente: undefined
};

export const sendGTMGA4Event = (obj) => {
    if (obj.nome_campo != undefined) {
        obj.nome_campo = normalizarString(obj.nome_campo);    
    }
    obj = typeof obj === 'object' ? {...defaultParams, ...obj} : defaultParams

    // console.log('EVENT-DEBUG', JSON.stringify(obj))
    window.dataLayerGA4 = window.dataLayerGA4 || []
    window.dataLayerGA4.push(obj)
}