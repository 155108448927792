import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "../../modules/Grid";
import { Form, Input, InputPhone } from "../../../utils/Form";
import Loading from "../../../utils/Loading";
import alertMessageActions from "../../../actions/alertMessage";
import Cookie from "../../../utils/Cookie";
import store from "../../../store";
import GoogleSubscribe from "../../meus-pagamentos/historico-pagamento/google-subscribe";
import Facebook from "../../meus-pagamentos/historico-pagamento/facebook";
import axios from "axios";

class Formulario extends Component {
  // Select all method
  selectAll(e) {
    const checked = e.target.checked;
    if (checked === true) {
      let checkboxes = document.getElementsByName("plano");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = true;
        document.getElementById(i).classList.add("selected");
      }
    } else {
      let checkboxes = document.getElementsByName("plano");
      for (var i = 0, n = checkboxes.length; i < n; i++) {
        checkboxes[i].checked = false;
        document.getElementById(i).classList.remove("selected");
      }
    }
  }

  // Select one element method
  selectPlano(e, index) {
    if (e.target.checked === false) {
      if (typeof document.getElementsByName("select-all")[0] !== "undefined") {
        document.getElementsByName("select-all")[0].checked = false;
      }
      document.getElementById(index).classList.remove("selected");
    }

    let check = true;
    let checkboxes = document.getElementsByName("plano");
    for (var i = 0, n = checkboxes.length; i < n; i++) {
      if (checkboxes[i].checked !== true) {
        check = false;
      }
    }
    if (check == true) {
      if (typeof document.getElementsByName("select-all")[0] !== "undefined") {
        document.getElementsByName("select-all")[0].checked = true;
      }
    }

    if (e.target.checked === true) {
      document.getElementById(index).classList.add("selected");
    }
  }

  // Return assinaturas
  assinaturas() {
    let userPlans = this.props.assinaturas.list;
    let assinatura_list = {};
    for (let i in userPlans) {
      assinatura_list[i] = i + " - " + userPlans[i].label_produto_midia;
    }
    userPlans = Object.keys(userPlans);
    if (Object.keys(userPlans).length > 0) {
      return (
        <div className="assinaturas">
          {/* Select all */}
          {userPlans.length > 1 ? (
            <div className="select-all">
              <input
                type="checkbox"
                id="select-all"
                name="select-all"
                onClick={(e) => {
                  this.selectAll(e);
                }}
                value="select-all"
              />

              <label htmlFor="selectall">Selecionar todas</label>
            </div>
          ) : (
            ""
          )}

          {/* Show the plans */}
          {userPlans.map((plan, index) => {
            return (
              <div key={index} id={index}>
                <input
                  type="checkbox"
                  name="plano"
                  value={plan}
                  onClick={(e) => {
                    this.selectPlano(e, index);
                  }}
                />

                <label htmlFor="selectall">{assinatura_list[plan]}</label>
              </div>
            );
          })
          // (this.optionsRender())
          }
        </div>
      );
    } else {
      return (
        <div className="assinaturas">
          <p>Você não possui nenhuma assinatura</p>
        </div>
      );
    }
  }

  // Assinaturas do usuario box
  assinaturasBox() {
    return (
      <div className="col-6 bx">
        <div className="row right-section">
          <div>
            <p>
              Selecione o plano de assinatura que terá o e-mail de acesso
              alterado
            </p>

            {this.assinaturas()}
          </div>
        </div>
      </div>
    );
  }

  handleImageSocialMedia() {
    let socialIcon = "";

    switch (this.props.socialMediaType) {
      case "google":
        socialIcon = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="177.935"
            height="56.958"
            viewBox="0 0 177.935 56.958"
          >
            <path
              data-name="Red e"
              d="M14.41 28.517a13.722 13.722 0 0 1-10.956-4.97 15.428 15.428 0 0 1-2.609-4.588A13.652 13.652 0 0 1 0 14.412 15.709 15.709 0 0 1 .707 9.9a15.543 15.543 0 0 1 2.328-4.7 12.488 12.488 0 0 1 4.258-3.7 13.815 13.815 0 0 1 6.5-1.5 12.334 12.334 0 0 1 6.37 1.675A13.419 13.419 0 0 1 24.14 5.36a17.747 17.747 0 0 1 2.051 3.685 13.737 13.737 0 0 1 .581 1.675L7.358 18.564a5.885 5.885 0 0 0 1.357 2.173 6.7 6.7 0 0 0 2.164 1.5 8.977 8.977 0 0 0 3.571.695h.039a7.752 7.752 0 0 0 3.029-.619 9.156 9.156 0 0 0 2.231-1.362 9.775 9.775 0 0 0 1.851-1.98l4.926 3.261a10.314 10.314 0 0 1-.746.979 15.558 15.558 0 0 1-2.257 2.16 14.924 14.924 0 0 1-3.783 2.16 14.656 14.656 0 0 1-5.33.986zm-.293-23.065a5.242 5.242 0 0 0-.92.082 9.545 9.545 0 0 0-4.426 1.879 6.7 6.7 0 0 0-2.01 2.751 7.785 7.785 0 0 0-.524 2.485 6.285 6.285 0 0 0 .032 1.081L19.385 8.5a8.167 8.167 0 0 0-1.426-1.523A7.108 7.108 0 0 0 16.3 5.928a5.368 5.368 0 0 0-2.183-.476z"
              transform="translate(151.163 15.664)"
              fill="#ef4131"
            />
            <path
              data-name="Green l"
              transform="translate(141.919 1.49)"
              fill="#31aa52"
              d="M0 0h6.494v41.921H0z"
            />
            <path
              data-name="Blue g"
              d="M14.033 41.3a14.073 14.073 0 0 1-6.09-1.311 13.346 13.346 0 0 1-3.952-2.885 14.9 14.9 0 0 1-2.135-2.885 10.384 10.384 0 0 1-.64-1.311l5.513-2.267a8.191 8.191 0 0 0 1.579 2.586 7.652 7.652 0 0 0 2.333 1.778 8.281 8.281 0 0 0 3.68.808 7.89 7.89 0 0 0 4.014-.967 6.314 6.314 0 0 0 2.265-2.278 8.759 8.759 0 0 0 1.008-2.668 10.571 10.571 0 0 0 .228-2.12v-2.866a7.47 7.47 0 0 1-1.886 1.779 9.83 9.83 0 0 1-2.518 1.225 12.025 12.025 0 0 1-3.708.557 13.054 13.054 0 0 1-4.79-.961 14.548 14.548 0 0 1-4.424-2.785 13.794 13.794 0 0 1-3.248-4.464 14.382 14.382 0 0 1-1.262-6 14.8 14.8 0 0 1 1.2-5.98 13.371 13.371 0 0 1 3.154-4.44 14.235 14.235 0 0 1 4.424-2.8A14.5 14.5 0 0 1 13.8 0h.321a10.508 10.508 0 0 1 3.405.551 9.847 9.847 0 0 1 2.404 1.213 8.3 8.3 0 0 1 1.9 1.764V.911l6 .051v26.451a15.914 15.914 0 0 1-.794 5.022 13.26 13.26 0 0 1-2.475 4.459 11.944 11.944 0 0 1-4.291 3.191 15.34 15.34 0 0 1-6.237 1.215zm.2-35.718a7.237 7.237 0 0 0-3.085.688 7.907 7.907 0 0 0-2.52 1.877 8.83 8.83 0 0 0-1.7 2.783 9.637 9.637 0 0 0 0 6.817 8.83 8.83 0 0 0 1.7 2.783 7.907 7.907 0 0 0 2.52 1.877 7.262 7.262 0 0 0 6.171 0 7.907 7.907 0 0 0 2.52-1.877 8.83 8.83 0 0 0 1.7-2.783 9.637 9.637 0 0 0 0-6.817 8.83 8.83 0 0 0-1.7-2.783 7.907 7.907 0 0 0-2.52-1.877 7.238 7.238 0 0 0-3.083-.684z"
              transform="translate(109.824 15.654)"
              fill="#3182f6"
            />
            <path
              data-name="Yellow O"
              d="M14.612 28.506a14.837 14.837 0 0 1-5.688-1.12 14.589 14.589 0 0 1-4.644-3.054A14.192 14.192 0 0 1 1.148 19.8a13.977 13.977 0 0 1 0-11.1A14.191 14.191 0 0 1 4.28 4.175 14.589 14.589 0 0 1 8.924 1.12a15 15 0 0 1 11.375 0 14.589 14.589 0 0 1 4.645 3.055 14.192 14.192 0 0 1 3.132 4.531 13.976 13.976 0 0 1 0 11.1 14.192 14.192 0 0 1-3.132 4.531 14.589 14.589 0 0 1-4.644 3.049 14.837 14.837 0 0 1-5.688 1.12zm-.1-22.917a7.8 7.8 0 0 0-3.2.681 8.177 8.177 0 0 0-2.603 1.857 8.676 8.676 0 0 0-1.76 2.754 9.132 9.132 0 0 0 0 6.745 8.676 8.676 0 0 0 1.76 2.754 8.177 8.177 0 0 0 2.611 1.857 7.847 7.847 0 0 0 6.394 0 8.177 8.177 0 0 0 2.611-1.857 8.676 8.676 0 0 0 1.76-2.754 9.132 9.132 0 0 0 0-6.745 8.676 8.676 0 0 0-1.76-2.754 8.177 8.177 0 0 0-2.611-1.857 7.8 7.8 0 0 0-3.197-.681z"
              transform="translate(77.932 15.65)"
              fill="#fdbe07"
            />
            <path
              data-name="Red O"
              d="M14.612 28.506a14.837 14.837 0 0 1-5.688-1.12 14.589 14.589 0 0 1-4.644-3.054A14.192 14.192 0 0 1 1.148 19.8a13.977 13.977 0 0 1 0-11.1A14.191 14.191 0 0 1 4.28 4.175 14.589 14.589 0 0 1 8.924 1.12a15 15 0 0 1 11.375 0 14.589 14.589 0 0 1 4.645 3.055 14.192 14.192 0 0 1 3.132 4.531 13.976 13.976 0 0 1 0 11.1 14.192 14.192 0 0 1-3.132 4.531 14.589 14.589 0 0 1-4.644 3.049 14.837 14.837 0 0 1-5.688 1.12zm-.1-22.917a7.8 7.8 0 0 0-3.2.681 8.177 8.177 0 0 0-2.603 1.857 8.676 8.676 0 0 0-1.76 2.754 9.132 9.132 0 0 0 0 6.745 8.676 8.676 0 0 0 1.76 2.754 8.177 8.177 0 0 0 2.611 1.857 7.847 7.847 0 0 0 6.394 0 8.177 8.177 0 0 0 2.611-1.857 8.676 8.676 0 0 0 1.76-2.754 9.132 9.132 0 0 0 0-6.745 8.676 8.676 0 0 0-1.76-2.754 8.177 8.177 0 0 0-2.611-1.857 7.8 7.8 0 0 0-3.197-.681z"
              transform="translate(46.224 15.65)"
              fill="#ef4131"
            />
            <g data-name="Blue G">
              <path
                data-name="Rectangle 1"
                d="M0 0h19.292L19.1 5.962H0z"
                transform="translate(22.921 20.122)"
                fill="#3182f6"
              />
              <path
                data-name="Oval 1"
                d="M22.875 44.158h-.24a22.959 22.959 0 0 1-12.656-3.78 22.7 22.7 0 0 1-3.35-2.695 22.361 22.361 0 0 1-2.764-3.266 21.9 21.9 0 0 1-2.086-3.747 21.644 21.644 0 0 1 0-17.185 21.916 21.916 0 0 1 2.087-3.75 22.367 22.367 0 0 1 2.763-3.268 22.71 22.71 0 0 1 7.195-4.732A22.983 22.983 0 0 1 22.634 0a24.433 24.433 0 0 1 8.933 1.6 19.2 19.2 0 0 1 6.849 4.5L34 10.4a13.969 13.969 0 0 0-4.929-3.156 17.464 17.464 0 0 0-6.248-1.1A16.583 16.583 0 0 0 16.469 7.4a16.306 16.306 0 0 0-5.191 3.414 15.861 15.861 0 0 0-3.5 5.064 15.621 15.621 0 0 0 0 12.4 15.861 15.861 0 0 0 3.5 5.064 16.306 16.306 0 0 0 5.191 3.414 16.583 16.583 0 0 0 6.357 1.252 18.974 18.974 0 0 0 6.262-.981 12.734 12.734 0 0 0 8.032-7.937 20.38 20.38 0 0 0 1.115-7.013c.015-.662 0-1.321-.047-1.956h5.635v.221a23.826 23.826 0 0 1-.506 9.717 18.251 18.251 0 0 1-3.974 7.331 18.663 18.663 0 0 1-3.307 2.913 20.538 20.538 0 0 1-3.939 2.12 23.141 23.141 0 0 1-4.433 1.295 25.973 25.973 0 0 1-4.79.439z"
                fill="#3182f6"
              />
            </g>
          </svg>
        );
        break;
      case "facebook":
        socialIcon = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="187.465"
            height="36.607"
            viewBox="0 0 187.465 36.607"
          >
            <defs>
              <clipPath id="ly2cjp905a">
                <path
                  data-name="Retângulo 2241"
                  fill="none"
                  d="M0 0h187.465v36.607H0z"
                />
              </clipPath>
            </defs>
            <g data-name="Grupo 2412">
              <path
                data-name="Caminho 3280"
                d="M189.071 12.166a7.845 7.845 0 0 0-7.316 3.031V0l-7.461.9v35.178h7.461V33.99a13.773 13.773 0 0 0 3.13 1.993c4.347 1.558 10.079.288 12.085-5.527a19.386 19.386 0 0 0 .285-11.326c-1.075-4.19-3.864-6.5-8.184-6.964m1.115 15.234a3.84 3.84 0 0 1-4.366 3.364 5.187 5.187 0 0 1-1.488-.329 5.079 5.079 0 0 1-2.456-3.778c-.121-1.65-.251-5.1.554-6.729a3.838 3.838 0 0 1 3.457-1.982c2.246-.074 3.946 1.04 4.28 3.32a21.544 21.544 0 0 1 .022 6.133"
                transform="translate(-86.013)"
                fill="#1977f3"
              />
              <path
                data-name="Caminho 3281"
                d="M240.492 24.374a14.917 14.917 0 0 0-7.8 0 9.392 9.392 0 0 0-7.306 7.381 25.039 25.039 0 0 0-.427 6.57 10.045 10.045 0 0 0 8.151 9.648c.067.017.137.028.206.044l.035.007c.184.037.368.071.554.1l.186.016h.005c.232.043.511.074.8.1a15.008 15.008 0 0 0 3.127.053.067.067 0 0 0 .016 0h.152a10.229 10.229 0 0 0 9.793-8.158 17.761 17.761 0 0 0 .02-7.885 9.8 9.8 0 0 0-7.515-7.871m.316 14.421a7.19 7.19 0 0 1-.086.461 4.221 4.221 0 0 1-8.31-.441 28.609 28.609 0 0 1-.033-5.055 4.252 4.252 0 0 1 8.355-.886 22.765 22.765 0 0 1 .074 5.922"
                transform="translate(-110.992 -11.774)"
                fill="#1977f3"
              />
              <path
                data-name="Caminho 3282"
                d="M17.5 12.532h-5.325V8.991c.389-3.348 5.919-2.712 5.919-2.712V.525h-.006c-.19-.045-11-2.611-13.146 5.617a.007.007 0 0 1 0 .006c0 .018-.01.036-.014.054a41.784 41.784 0 0 0-.31 6.331H0v6.3h4.9v17.131h7.137V18.831H17.5l.615-6.3z"
                transform="translate(0 -.055)"
                fill="#1977f3"
              />
              <path
                data-name="Caminho 3283"
                d="M52.15 27.253c-.088-.1-.174-.19-.249-.268l-.033-.034c-.1-.108-.176-.18-.176-.18-1.681-1.709-2.315-2.134-3.632-2.522A9.111 9.111 0 0 0 36.507 30.5a20.179 20.179 0 0 0-.411 9.521c1.215 6.864 6.32 9.163 11.762 8.03a7.04 7.04 0 0 0 4.062-2.835 2.332 2.332 0 0 0 .231-.374v3.02h6.962v-23.6h-6.962v2.987zm-.613 11.875a3.775 3.775 0 0 1-4.086 3.41 3.951 3.951 0 0 1-4.016-3.28 20.448 20.448 0 0 1 .149-6.669 3.762 3.762 0 0 1 4.076-2.88 3.706 3.706 0 0 1 3.868 3.391 28.261 28.261 0 0 1 .009 6.029"
                transform="translate(-17.627 -11.762)"
                fill="#1977f3"
              />
              <path
                data-name="Caminho 3284"
                d="M105.374 46.217c-.323-1.182-.691-2.348-1.046-3.474l-.336-1.252s-8.728 4.19-10.092-3.771a17.163 17.163 0 0 1 .1-3.966 4.277 4.277 0 0 1 4.2-3.982 9.381 9.381 0 0 1 2.432.1c1.081.182 2.137.508 3.321.8.411-1.259.832-2.5 1.224-3.758.062-.2.113-.366.153-.518l.2-.753a.889.889 0 0 0-.327-.305 3.91 3.91 0 0 0-1.076-.5 14.63 14.63 0 0 0-.642-.214l-.317-.1a18.335 18.335 0 0 0-7.037-.454c-5.04.532-8.46 3.506-9.409 8.416a19.111 19.111 0 0 0 .1 7.789 9.466 9.466 0 0 0 7.031 7.508 17.285 17.285 0 0 0 7.6.371 23.926 23.926 0 0 0 3.705-1.116l.419-.147-.2-.671z"
                transform="translate(-42.622 -11.808)"
                fill="#1977f3"
              />
              <path
                data-name="Caminho 3285"
                d="M147.663 37.044a39.993 39.993 0 0 0-.243-4.88 8.727 8.727 0 0 0-7.651-7.915 19.783 19.783 0 0 0-5.084-.08c-4.6.439-8.091 3.3-8.807 7.616a27.421 27.421 0 0 0-.139 8.019c.53 3.952 2.455 6.469 5.783 7.678l.045.005c1.52.454 8.956 2.4 15.512-.894l-.263-.965a.017.017 0 0 1-.01 0c-.3-1.288-.669-2.561-1.012-3.837l-.037-.125s-.136.049-.378.126h-.017a12.76 12.76 0 0 0-.85.256c-.052.017-.1.033-.156.049a28.051 28.051 0 0 1-3.909.817 20.488 20.488 0 0 1-3.81.022c-2.082-.344-3.675-1.46-3.6-4.1H146.763s.877-.011.913-.02v-.259zm-6.711-3.217h-8.062c.042-1.6.243-3.094 1.668-4a4.455 4.455 0 0 1 4.742-.135c1.574.869 1.7 2.439 1.652 4.137"
                transform="translate(-61.937 -11.866)"
                fill="#1977f3"
              />
              <path
                data-name="Caminho 3286"
                d="M348.434 12.771h-7.914l-6.874 10.746V.042l-7.461.9v35.18h7.461V25.357l6.874 10.78h7.914l-7.459-11.7z"
                transform="translate(-160.969 -.021)"
                fill="#1977f3"
              />
              <path
                data-name="Caminho 3287"
                d="M290.731 24.367a14.912 14.912 0 0 0-7.8 0 9.393 9.393 0 0 0-7.3 7.38 25.017 25.017 0 0 0-.427 6.571 10.043 10.043 0 0 0 8.149 9.648c.068.017.139.028.207.044l.033.007c.184.037.37.071.555.1.062.006.125.012.186.015a.005.005 0 0 0 .005 0c.234.043.511.075.8.1a14.991 14.991 0 0 0 3.128.053H288.433a10.233 10.233 0 0 0 9.795-8.159 17.785 17.785 0 0 0 .021-7.886 9.8 9.8 0 0 0-7.517-7.87m.315 14.42a7.22 7.22 0 0 1-.086.462 4.221 4.221 0 0 1-8.31-.442 28.557 28.557 0 0 1-.035-5.054 4.253 4.253 0 0 1 8.356-.887 22.788 22.788 0 0 1 .074 5.922"
                transform="translate(-135.784 -11.77)"
                fill="#1977f3"
              />
            </g>
          </svg>
        );
        break;

      default:
        break;
    }
    return socialIcon;
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async sendUpdate() {
    console.log();
    let salvarDados;

    let email_novo = document.getElementById("input_email_novo").value;
    let senha_nova = document.getElementById("input_senha_nova").value;
    let senha_nova_confirm = document.getElementById("input_senha_nova_confirm")
      .value;
    let token = document.getElementById("token").value;

    if (email_novo === "" || senha_nova === "" || senha_nova_confirm === "") {
      let obj = {};
      store.dispatch(
        alertMessageActions.setAlert(
          "red",
          "Por favor informar todos os campos!"
        )
      );
      salvarDados = new CustomEvent("alertPush", { detail: obj });
      window.dispatchEvent(salvarDados);
      window.scrollTo({ top: 0 });
      return;
    }

    if (senha_nova !== senha_nova_confirm) {
      let obj = {};
      store.dispatch(
        alertMessageActions.setAlert(
          "red",
          "As senhas informadas estão divergentes"
        )
      );
      salvarDados = new CustomEvent("alertPush", { detail: obj });
      window.dispatchEvent(salvarDados);
      window.scrollTo({ top: 0 });
      return;
    }

    let updated = true;

    const asyncLoopEmail = async () => {
      Loading.add();
      let bodyFormData = {
        token_usuario: token,
        email_novo,
        senha_nova,
        senha_nova_confirm,
      };

      await Form.send("/desconectar-login-social", bodyFormData)
        .then((response) => {
          let { data } = response;

          if (data.code === 200) {
            Loading.remove();
            Cookie.set("EMAIL_ALTERADO", true);
            store.dispatch(
              alertMessageActions.setAlertEmail(
                "green",
                "Conta social removida. Alteração de e-mail e senha realizada com sucesso."
              )
            );
            this.sleep(10000);
          } else {
            let message = data.message || "Ops algo deu errado!";
            Form.showErrors(message);
            Loading.remove();
            updated = false;
          }
        })
        .catch((e) => {
          Loading.remove();
          updated = false;
        });

      if (updated === true) {
        store.dispatch(
          alertMessageActions.setAlertEmail(
            "green",
            "Conta social removida. Alteração de e-mail e senha realizada com sucesso."
          )
        );
        await this.sleep(10000);
        window.location.href = "https://acesso.estadao.com.br/login/logout";
      }
      return true;
    };

    asyncLoopEmail();
  }

  sendUpdatex() {
    let usuario = this.props.usuario;
    let form = document.getElementById("form-meus-dados");
    let salvarDados;
    let email_novo_conf = document.getElementById("input_email_novo_conf")
      .value;
    let email_novo = document.getElementById("input_email_novo").value;

    if (email_novo_conf == "" || email_novo_conf == "") {
      let obj = {};
      store.dispatch(
        alertMessageActions.setAlert(
          "red",
          "Por favor informar todos os campos!"
        )
      );
      salvarDados = new CustomEvent("alertPush", { detail: obj });
      window.dispatchEvent(salvarDados);
      return;
    }

    if (email_novo_conf !== email_novo) {
      let obj = {};
      let message = {
        email_novo: ["O campo email não pode ser diferente"],
        email_novo_conf: ["O campo email não pode ser diferente"],
      };
      Form.showErrors(message);
      return;
    }

    if (form) {
      let updated = true;
      // send the request for non-sub user
      if (usuario.assinante == false) {
        const asyncLoopEmail = async () => {
          Loading.add();
          let bodyFormData = new FormData(form);

          await Form.send("/user/email", bodyFormData)
            .then((response) => {
              let { data, status } = response;
              if (status === 200) {
                Loading.remove();
                Cookie.set("EMAIL_ALTERADO", true);
                store.dispatch(
                  alertMessageActions.setAlertEmail(
                    "green",
                    "Você receberá um e-mail para confirmar a alteração de e-mail de acesso e redefinir sua senha. Se a mensagem não chegar à sua caixa de entrada, confira na caixa de spam. "
                  )
                );
                this.sleep(10000);
              } else {
                let message = data.message || "Ops algo deu errado!";
                Form.showErrors(message);
                Loading.remove();
                updated = false;
              }
            })
            .catch((e) => {
              Loading.remove();
              updated = false;
            });

          if (updated === true) {
            store.dispatch(
              alertMessageActions.setAlertEmail(
                "green",
                "Você receberá um e-mail para confirmar a alteração de e-mail de acesso e redefinir sua senha. Se a mensagem não chegar à sua caixa de entrada, confira na caixa de spam. "
              )
            );
            await this.sleep(10000);
            window.location.href = "https://acesso.estadao.com.br/login/logout";
          }
          return true;
        };

        asyncLoopEmail();
      }

      // send the request for sub user
      if (usuario.assinante == true) {
        // Loading.add();
        const assinaturasChecked = document.querySelectorAll(
          "input[type=checkbox]:checked"
        );

        const asyncLoop = async () => {
          Loading.add();
          for (let i = 0; i < assinaturasChecked.length; i++) {
            if (assinaturasChecked[i].value !== "select-all") {
              console.log(assinaturasChecked[i].value);
              // send request here
              let obj = {};
              obj["email"] = email_novo;
              let userToken = document.getElementById("token").value;
              let id = assinaturasChecked[i].value;
              let url = "/subscription/" + id + "/email";

              await Form.send(url, obj)
                .then((response) => {
                  let { data, status } = response;
                  if (status === 200 && data.message.status == 0) {
                  } else {
                    let message = data.message || "Ops algo deu errado!";
                    Form.showErrors(message);
                    updated = false;
                    return;
                  }
                })
                .catch((e) => {
                  updated = false;
                  let message = e.message || "Ops algo deu errado!";
                  Form.showErrors(message);
                  return;
                });
            }
          }

          Loading.remove();
          Cookie.set("EMAIL_ALTERADO", true);
          if (updated === true) {
            store.dispatch(
              alertMessageActions.setAlertEmail(
                "green",
                "Você receberá um e-mail para confirmar a alteração de e-mail de acesso e redefinir sua senha. Se a mensagem não chegar à sua caixa de entrada, confira na caixa de spam. "
              )
            );
            await this.sleep(10000);
            window.location.href = "https://acesso.estadao.com.br/login/logout";
          }
        };

        asyncLoop();
      }
    }
  }

  render() {
    return (
      <div className="desconectar-social-box">
        <div className="row social-box-title social-box-title-mobile">
          <div className="col-12">
            <div className="title-inside">
              Sua assinatura está vinculada à sua conta do
              <span className="social-media-name">
                {" "}
                {this.props.socialMediaType}
              </span>
            </div>

            <div className="text-inside">
              Caso queira alterar o seu login de acesso, digite um novo endereço
              de e-mail e crie uma nova senha.
            </div>
          </div>
        </div>

        <div className="social-image-box">{this.handleImageSocialMedia()}</div>

        <div className="social-form-box">
          <div className="row">
            <div className="social-box-title social-box-title-desktop">
              <div className="col-12 col-lg-10">
                <div className="title-inside">
                  Sua assinatura está vinculada à sua conta do
                  <span className="social-media-name">
                    {" "}
                    {this.props.socialMediaType}
                  </span>
                </div>

                <div className="text-inside">
                  Caso queira alterar o seu login de acesso, digite um novo
                  endereço de e-mail e crie uma nova senha.
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-8">
              <Input
                type="email"
                id="email_novo"
                name="email_novo"
                label="Novo e-mail"
              />
            </div>

            <div className="col-12 col-lg-8">
              <Input
                type="password"
                id="senha_nova"
                name="senha_nova"
                label="Nova Senha"
              />
            </div>

            <div className="col-12 col-lg-8">
              <Input
                type="password"
                id="senha_nova_confirm"
                name="senha_nova_confirm"
                label="Confirmar Nova Senha"
              />
            </div>

            <input
              hidden
              id="token"
              value={Cookie.get("PORTAL_AUTH")}
              name="token_usuario"
            />

            <div className="col-12">
              <button
                className="btn blue salvar-dados"
                data-dtkey="salvar_dados"
                onClick={() => {
                  this.sendUpdate();
                }}
              >
                <i className="icon-floppy" />
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderx() {
    let usuario = this.props.usuario;

    return (
      <div>
        <div className="block-single redefinir-email">
          <div className="row">
            <Grid cols="12 12 12 12 12">
              <div className="title-inside">Alterar e-mail de acesso</div>
            </Grid>
          </div>

          <form
            id="form-meus-dados"
            action=""
            method="POST"
            className="content-form alterar-email "
          >
            {/* Assinaturas box */}
            {this.assinaturasBox()}

            {/* Email box */}
            <div className="col-6 bx">
              <div className="row">
                <Input
                  readonly={true}
                  type="email"
                  id="email_antigo"
                  name="email_antigo"
                  label="E-mail atual"
                  value={usuario.email}
                />
              </div>

              <div className="row">
                <Input
                  type="email"
                  id="email_novo"
                  name="email_novo"
                  label="Novo e-mail"
                />
              </div>

              <div className="row">
                <Input
                  type="email"
                  id="email_novo_conf"
                  name="email_novo_conf"
                  label="Confirmar novo e-mail"
                />
              </div>

              <input
                hidden
                id="token"
                value={Cookie.get("PORTAL_AUTH")}
                name="token_usuario"
              />
            </div>
          </form>
        </div>

        <button
          className="btn blue salvar-dados"
          data-dtkey="salvar_dados"
          onClick={() => {
            this.sendUpdate();
          }}
        >
          <i className="icon-floppy" />
          Salvar dados
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.user,
    ...state,
    config: state.config,
  };
};

export default connect(mapStateToProps)(Formulario);
