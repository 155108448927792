import { API, GET_PARTNERS } from '../utils/asyncActionType'

export default {
    getPartners() {
        return {
            type: API,
            payload: {
                url: '/data/parceiros.json',
                cache: 1440,
                force_cache: 1440,
                ...GET_PARTNERS
            }
        }
    }
}