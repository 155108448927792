import React from "react";

export default function PagarBoleto(props) {
    let mouseLeave = props.mouseLeave;
    let action = props.action;
    return (
        <div className="menu1" onMouseLeave={() => mouseLeave()}>
            <div className="menu2">
                <div className="menu3">
                    <div className="menu4">
                        {props.itens.map((element) => {
                            return  <div className="menu8">
                                        { element == "icon-barcode" ? <div onClick={() => action(props.record, element)} className="menu9">Copiar código de barra</div> : "" }
                                        { element == "icon-print" ? <div onClick={() => action(props.record, element)} className="menu9">Visualizar boleto</div> : "" }                                            
                                        { element == "icon-mail" ? <div onClick={() => action(props.record, element)} className="menu9">Enviar 2ª via por e-mail</div> : "" }
                                    </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}