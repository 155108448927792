import React from 'react'

export default props => {

    const cols = props.cols ? props.cols.split(' ') : []
    let classes = props.class ? props.class + ' ' : ''

    if (cols[0]) classes += `col-${cols[0]}`
    if (cols[1]) classes += ` col-sm-${cols[1]}`
    if (cols[2]) classes += ` col-md-${cols[2]}`
    if (cols[3]) classes += ` col-lg-${cols[3]}`
    if (cols[4]) classes += ` col-xl-${cols[4]}`

    return (
        <div className={classes}>
            { props.children }
        </div>
    )

}