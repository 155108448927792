export const APP_URL = 'https://meu.estadao.com.br';
export const APP_ENV = 'production';
export const APP_DEBUG = false;
export const APP_DEBUG_TIMEOUT = 0;
export const API_URL = 'https://meu.estadao.com.br/app';
export const URL_ACESSO = "https://acesso.estadao.com.br";
export const URL_PORTAL_DO_ASSINANTE = "https://assinante.estadao.com.br";
export const APP_ENGINE_URL = "https://estadao-gamification-prod.appspot.com/";
export const API_LOGIN =  "https://api-users.estadao.com.br/";
export const API_RECOMENTATION =  "https://estadao-recommendation.appspot.com/";
export const IMG_URL =  "https://img.estadao.com.br/fotos/";
