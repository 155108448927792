import React, {Component} from 'react'
import DocumentHead from "../../utils/DocumentHead"
import {connect} from "react-redux"
import partnersActions from '../../actions/partners'
import AlertMessage from "../alert-message/alertMessage";
import Assinaturas from "../assinaturas";
import Titulo from "../modules/Titulo";
import Grid from '../modules/Grid'
import BlocoDePublicacao from "../blocks/publications/publications";

class Publicacoes extends Component {

    componentDidMount() {
        DocumentHead({
            title: 'Publicações – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });
        this.props.dispatch(partnersActions.getPartners());
    }


    render() {
        const publicacao = this.props.partners.publicacao;

        return (
            <div className="container justify-content-center">
                <div className="header-content row nomargin">
                    <Grid cols='12 12 12 6 6' class='text-left nopadding welcome-wrapper align-center'>
                        <Titulo groupItem="Publicações"/>
                    </Grid>
                    <Grid cols='12 12 12 6 6' class='text-left nopadding text-right hidden-xs hidden-sm hidden-md'>
                        <Assinaturas />
                    </Grid>
                    <AlertMessage />
                </div>

                <div className="publishing-container">
                    {publicacao && Object.keys(publicacao).map((key) => {
                        return (
                            <div key={key} className="item">
                                <BlocoDePublicacao content={publicacao[key]} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {...state}
}

export default connect(mapStateToProps)(Publicacoes);