import React, { Component } from 'react'
import ContentLoader from "./../../content-loader/content-loader"
import { Scrollbars } from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import utilsActions from '../../../actions/utils';

const BlocoDeTagsLoader = props => (
    <ContentLoader
        height={'410'}
        width={'1000'}
        style={{width: '100%', height: '410'}}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}>
        <rect x="0" y="10" rx="5" ry="5" width="300" height="35"/>
        <rect x="0" y="65" rx="5" ry="5" width="100%" height="365"/>
    </ContentLoader>
)

class BlocoDeTags extends Component {
    componentDidMount() {
        this.props.dispatch(utilsActions.getAssuntosDoMomento());
    }

    render() {
        const content = this.props.utils.assuntos_do_momento;
        return Object.keys(content).length > 1 ? (
            <div className="block-cards-body" id="assuntos-do-momento">
                <div className="header">
                    <h3 className="title">Assuntos do momento</h3>
                </div>

                <div className="block">
                    <Scrollbars style={{ height: 280, right: 0,padding: "17px", margin:0}}
                                renderTrackHorizontal={({ style, ...props }) => <div {...props} style={{ ...style, display: 'none' }}/>}
                                renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, width: '8px', height: '150px', borderRadius: '5px', background: '#ced4da' }}/>
                                }>
                        <ul className="tags">
                            {Object.keys(content).map((i, key) => {
                                let item = content[i];
                                return (item.titulo && item.url) && (
                                    <li key={key}>
                                        <a href={item.url} data-dtkey="tags" target="_blank" rel="noopener noreferrer" title={item.titulo}>
                                           <strong>{item.titulo}</strong>
                                        </a>
                                    </li>
                                )
                            })}
                        </ul>
                    </Scrollbars>
                </div>
            </div>
        ) : (
            <div className="block-cards-body">
                <BlocoDeTagsLoader/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return state
}

export default connect(mapStateToProps)(BlocoDeTags)
