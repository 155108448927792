import React, { Component } from 'react'
import { connect } from 'react-redux'
import ContentLoader from "./../content-loader/content-loader"
import {Select} from '../../utils/Form'

import subscriptionActions from './../../actions/subscription'
import Assinatura from './assinatura'
import SeloSuperior from './selo-superior'
import { sendGTMGA4Event } from '../../utils/tagmanager'

class Assinaturas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            GTMGA4eventSended: false,
        };

        this.setSubscription = this.setSubscription.bind(this);
    }

    setSubscription(e) {
        this.props.dispatch(subscriptionActions.setSelectedSubscription(e.target.value))
    }

    render() {
        const assinaturas = this.props.assinaturas;
        let assinaturaList = assinaturas.list;
        const assinatura_selecionada = assinaturas.assinatura_selecionada;
        const totalAssinaturas = Object.keys(assinaturaList).length;

        const user = this.props.user;
        let assinatura_list = {};
        if (user.id && totalAssinaturas) {
            for (let i in assinaturaList) {
                assinatura_list[i] = `${i} - ${assinaturaList[i].label_produto_midia}`;
            }
        }

        const colaborador = user.email.indexOf('estadao.com') >= 0;
        const colaboradorAssinante = colaborador && totalAssinaturas > 1;
        if (assinaturas.loaded) {
            if (user.id || !user.assinante) {
                if (!colaborador || colaboradorAssinante) {
                    if (totalAssinaturas > 0) {
                        return <Assinatura
                            assinaturaList={assinatura_list}
                            assinaturaSelecionada={assinatura_selecionada}
                            setSubscription={this.setSubscription}
                        />
                    } else {
                        if (!this.state.GTMGA4eventSended) {
                            sendGTMGA4Event({
                                event: 'botoes',
                                tipo_pagina: 'meu_estadao',
                                editoria: 'meu_estadao',
                                tipo_acao: 'show',
                                posicao: "selo_superior",
                                squad: 'conversao'
                            })

                            this.setState({GTMGA4eventSended: true})
                        }

                        return <SeloSuperior />
                    }
                } else {
                    return <></>
                }
            }
        } else {
            return (
                <ContentLoader
                    height={55}
                    width={300}
                    style={{width: '100%', maxWidth: 300, height: '55', float: 'right'}}
                    primaryColor="#eaeaea"
                    secondaryColor="#e4e4e4">
                    <rect x="0" y="0" rx="5" ry="5" width="300" height="55"/>
                </ContentLoader>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        ...state
    }
}

export default connect(mapStateToProps)(Assinaturas)
