import React, { Component } from 'react'

class BlocoDeParceiros extends Component {
    render() {
        const content = this.props.content;
        return (
            <div className="slide-item partners publishing">
                <a href={content.link ? content.link : "#"} data-dtkey="parceiros" rel="noopener noreferrer" target="_blank" onClick={e => {!content.link && e.preventDefault()}} className="link" title={content.title}>
                    <img src={content.img} alt={content.title}/>
                </a>
            </div>
        )
    }
}

export default BlocoDeParceiros;