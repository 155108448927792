import React, {Component} from 'react';
import {connect} from "react-redux";
import axios from "axios";
import env from "../../env";

class Banner extends Component {

    state = {
        'bannersLoaded': false,
        'banners':{}
    };

    constructor(props) {
        super(props);
        this.getBanners().then(response => {
            if(!response.hasOwnProperty('links')){
                response.links = {};
            }
            this.setState({
                'bannersLoaded': true,
                'banners':response
            });

        })
            .catch(err => {
                setTimeout(() => {
                    this.getBanners();
                }, 1000)
            })
    }

    getBanners() {
        return new Promise((resolve, reject) => {
            axios.get(env.API_URL+'/utils/get-banners').then((response) => {
                let result = response.data;
                if(result.status){
                    resolve(result.content);
                }
            }).catch(() => {
                reject(null)
            });
        });
    }

    render() {
        try{

            let link = this.props.user.assinante ? "/" : "https://www.estadao.com.br/vempensarcomagente";

            let assinante_desktop = typeof this.state.banners.assinante !== 'undefined'? this.state.banners.assinante.desktop.split("/"):{};
            let assinante_mobile = typeof this.state.banners.assinante !== 'undefined'? this.state.banners.assinante.mobile.split("/"):{};
            let nao_assinante_desktop = typeof this.state.banners.nao_assinante !== 'undefined'? this.state.banners.nao_assinante.desktop.split("/"):{};
            let nao_assinante_mobile = typeof this.state.banners.nao_assinante !== 'undefined'? this.state.banners.nao_assinante.mobile.split("/"):{};

            let link_mobile = link + "?utm_source=meu_estadao_bannermobile&utm_medium=meu_estadao_bannermobile&utm_campaign=meu_estadao_bannermobile&utm_content=meu_estadao_bannermobile";
            link = link + "?utm_source=meu_estadao_bannerdesk&utm_medium=meu_estadao_bannerdesk&utm_campaign=meu_estadao_bannerdesk&utm_content=meu_estadao_bannerdesk";

            let linkObj = {
                assinante_desktop: typeof this.state.banners.links !== 'undefined' && typeof this.state.banners.links[assinante_desktop[Object.keys(assinante_desktop).length - 1]] !== 'undefined' ? this.state.banners.links[assinante_desktop[Object.keys(assinante_desktop).length - 1]]:{link:link,target:"_blank"},
                assinante_mobile: typeof this.state.banners.links !== 'undefined' && typeof this.state.banners.links[assinante_mobile[Object.keys(assinante_mobile).length - 1]] !== 'undefined' ? this.state.banners.links[assinante_mobile[Object.keys(assinante_mobile).length - 1]]:{link:link_mobile,target:"_blank"},
                nao_assinante_desktop: typeof this.state.banners.links !== 'undefined' && typeof this.state.banners.links[nao_assinante_desktop[Object.keys(nao_assinante_desktop).length - 1]] !== 'undefined' ? this.state.banners.links[nao_assinante_desktop[Object.keys(nao_assinante_desktop).length - 1]]:{link:link,target:"_blank"},
                nao_assinante_mobile: typeof this.state.banners.links !== 'undefined' && typeof this.state.banners.links[nao_assinante_mobile[Object.keys(nao_assinante_mobile).length - 1]] !== 'undefined' ? this.state.banners.links[nao_assinante_mobile[Object.keys(nao_assinante_mobile).length - 1]]:{link:link_mobile,target:"_blank"},
            };
            return (

                <div className="banner-wrapper">
                    {
                        this.state.bannersLoaded ? (
                            this.props.user.assinante ? (
                                <div>
                                    <a href={linkObj.assinante_desktop.link} target={linkObj.assinante_desktop.target} rel="noopener noreferrer" title="Clube+ Estadão">
                                        <img
                                            src={this.state.banners.assinante.desktop}
                                            alt="Clube+ Estadão"/>
                                    </a>

                                    <a href={linkObj.assinante_mobile.link} target={linkObj.assinante_mobile.target} rel="noopener noreferrer" title="Clube+ Estadão">
                                        <img
                                            src={this.state.banners.assinante.mobile}
                                            className="mobile" alt="Clube+ Estadão"/>
                                    </a>
                                </div>
                            ) : (
                                <div>
                                    <a href={linkObj.nao_assinante_desktop.link} target={linkObj.nao_assinante_desktop.target} rel="noopener noreferrer" title="Clube+ Estadão">
                                        <img
                                            src={this.state.banners.nao_assinante.desktop}
                                            alt="Clube+ Estadão"/>
                                    </a>

                                    <a href={linkObj.nao_assinante_mobile.link} target={linkObj.nao_assinante_mobile.target} rel="noopener noreferrer" title="Clube+ Estadão">
                                        <img
                                            src={this.state.banners.nao_assinante.mobile}
                                            className="mobile" alt="Clube+ Estadão"/>
                                    </a>
                                </div>
                            )
                        ) : (
                            ''
                        )
                    }
                </div>
            )
        }catch(err){
            return "";
        }

    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ?  state.assinatura[state.assinaturas.assinatura_selecionada] : null,
    }
};

export default connect(mapStateToProps)(Banner)
