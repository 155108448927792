import React from 'react';
import Input from './Input';
import store from "../../store";
import alertMessageActions from "../../actions/alertMessage";

class File extends Input {
    constructor(props){
        super(props);

        this.handleClear = this.handleClear.bind(this);
    }

    handleClear(){
        this.input && (this.input.value = "");
        this.forceUpdate();
    }

    validateSize(e) {
        let FileSize = e.target.files[0].size / 1024 / 1024; // in MiB
        console.log(e.target.files[0].size, FileSize);
        if (FileSize > 2) {
            store.dispatch(alertMessageActions.setAlert('red', "Arquivo excedeu o limite de 2MB"));
            return false;
        }
        return true;
    }

    handleChange = (e) => {
        let value = e.target.value || "";
        if(this.validateSize(e)){
            let isset = false;
            if(Object.keys(value).length > 0){
                isset = true;
            }
            if(this.state.isset !== isset){
                this.setState({
                    isset: isset
                });
            }else{
                this.forceUpdate();
            }
        }
    }

    render(){
        return (
            <div className={"file "+this.state.className.join(" ")+(this.input && this.input.value ? " isset" : "")}>
                {!this.state.load ? (
                    <div>
                        <span className="value">{this.input ? this.input.value.split("\\").pop() : ""}</span>
                        <input readOnly={this.state.readonly} accept={"image/*"} ref={el => el && (this.input = el)}  type="file" id={this.state.id} name={this.state.name} className={(!this.state.label ? "no-label" : "")} onChange={(e) => this.handleChange(e)} defaultValue={this.state.value}/>
                        {this.state.label ? (
                            <label htmlFor={this.state.id}>{this.state.label}</label>
                        ) : ""}
                        <i className="archive icon-attach"/>
                        <i className="remove icon-cancel-1" onClick={this.handleClear}/>
                    </div>
                ) : this.loader()}
            </div>
        )
    }
}

export default File;
