import { API, GET_USER } from '../utils/asyncActionType'
import Cookie from "../utils/Cookie";

export default {
    getUser(enable_reload) {
        if(!Cookie.get('PORTAL_AUTH')){
            return {type: GET_USER.USER.SUCCESS, payload: {id: 1,assinante: false, has_cookie: false}};
        }
        return {
            type: API,
            payload: {
                enable_reload: enable_reload,
                url: '/user',
                cache: 1440,
                attempts: 2,
                test_response: (response) => {
                    return response.id;
                },
                ...GET_USER.USER
            }
        }
    }/*,

    getSessions(){
        return {
            type: API,
            payload: {
                enable_reload: true,
                url: '/user/sessions',
                cache: 1440,
                attempts: 2,
                ...GET_USER.SESSIONS
            }
        }
    }*/
}
