import store from './../store';
import Cache from './../cache';
import Cookie from './../cookie';
import configAction from "./config";

let requestUser = false;
let requestSubscriptions = false;

export default {
    getUser(force = false) {
        const {config, user} = store.getState();

        if (!user.id && !requestUser) {

            let cache = new Cache(config.meuEstadaoUrl + '/app/user', Cookie.get('PORTAL_AUTH'));
            let cache_response = cache.get(60);

            if (cache_response && cache_response.id) {
                store.dispatch({type: "SET_USER", payload: {...cache_response}});
                this.getSubscriptions();
            } else {
                let self = this;
                requestUser = true;
                var xhttp = new XMLHttpRequest();
                xhttp.withCredentials = true;
                xhttp.onreadystatechange = function () {
                    if (this.readyState === 4 && this.status === 200) {
                        let response = JSON.parse(this.responseText);
                        cache.set(response);
                        store.dispatch({type: "SET_USER", payload: {...response}});
                        self.getSubscriptions();
                    }

                    if (this.readyState === 4) {
                        requestUser = false;
                    }
                };
                xhttp.open("GET", config.meuEstadaoUrl + '/app/user', true);
                xhttp.send();
            }
        }
    },

    getSubscriptions() {
        if (!requestSubscriptions) {
            const {config, user} = store.getState();
            if (user.assinante || user.block) {
                let assinatura_selecionada_cache = new Cache('assinatura_selecionada');
                let assinatura_selecionada = assinatura_selecionada_cache.get(43200);

                let appUrl = '/app/user/subscriptions/active';
                let cache = new Cache(config.meuEstadaoUrl + appUrl, Cookie.get('PORTAL_AUTH'));
                let cache_response = cache.get(1440);

                if (cache_response) {
                    if (!assinatura_selecionada || !cache_response.subscriptions.hasOwnProperty(assinatura_selecionada)) {
                        configAction.setConfig({
                            assinatura_selecionada: Object.keys(cache_response.subscriptions)[0]
                        });
                    }
                    store.dispatch({type: "SET_USER", payload: {...cache_response}})
                } else {
                    requestSubscriptions = true;
                    var xhttp = new XMLHttpRequest();
                    xhttp.withCredentials = true;
                    xhttp.onreadystatechange = function () {
                        if (this.readyState === 4 && this.status === 200) {
                            let response = {subscriptions: JSON.parse(this.responseText)};
                            cache.set(response);
                            if (!assinatura_selecionada || !response.subscriptions.hasOwnProperty(assinatura_selecionada)) {
                                configAction.setConfig({
                                    assinatura_selecionada: Object.keys(response.subscriptions)[0]
                                });
                            }
                            store.dispatch({type: "SET_USER", payload: {...response}})
                        }
                        if (this.readyState === 4) {
                            requestSubscriptions = false;
                        }
                    };

                    let appUrl = '/app/user/subscriptions/active';
                    xhttp.open("GET", config.meuEstadaoUrl + appUrl, true);
                    xhttp.send();
                }
            }
        }
    }
}