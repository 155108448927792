import React, {Component} from 'react'
import {connect} from "react-redux"
import axios from "axios";
import env from '../../../env'
import Loading from "../../../utils/Loading";
import ContentLoader from "../../content-loader/content-loader";

class NewsletterUltima extends Component {
    constructor(props){
        super(props);        
    }

    loader() {
        return (
          <ContentLoader
            height={'30vh'}
            width={'100%'}
            style={{ width: '100%', maxWidth: '100%', height: '30vh', float: 'left',marginBottom:'15px' }}
            primaryColor="#eaeaea"
            secondaryColor="#e4e4e4">
              <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
          </ContentLoader>
        )
    }

    setHtmlNewsletter(html){
        this.setState({            
            html_newsletter: html
        })
    }
    
    componentDidMount() {
        let chave_newsletter = (this.props.chaveNewsletter !== 'undefined' && this.props.chaveNewsletter) ? this.props.chaveNewsletter:'';

        Loading.add();
        if(chave_newsletter !== ''){
            this.getUltimaNewsletter(chave_newsletter);
        }        
    }

    getUltimaNewsletter(url){
        axios.get(url).then((response) => {
            let body = response.data.replace("min-width: 320px !important;", "");
            body = body.replace("min-width: 375px !important;", "");
            body = body.replace("min-width: 100% !important;", "");
                
            document.querySelector('.newsletter-ultima').innerHTML = body;

            Loading.remove()
        }).catch((err) => console.log(err))
    }

    render() {
        return (
            <div className="newsletter-ultima"></div>
        )                   
    }
}

const mapStateToProps = state => {
    return {...state}
}

export default connect(mapStateToProps)(NewsletterUltima);