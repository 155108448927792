import React, {Component} from 'react'

import Grid from '../../modules/Grid'
import Assinatura from '../../assinaturas'
import Titulo from "../../modules/Titulo";
import DocumentHead from "../../../utils/DocumentHead";
import Clausulas from "./clausulas";
import Status from "./status";


class Contratos extends Component {
    componentDidMount() {
        DocumentHead({
            title: 'Contratos – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });
    }

    render() {
        

        return (
            <div className="container contratos-assinatura-wrapper justify-content-center">
                <div className="row header-content">
                    <Grid cols='12 12 12 6 6'>
                        <Titulo groupItem="Meus pagamentos" titulo="contrato de assinatura"/>
                    </Grid>
                    <Grid cols='12 12 12 6 6' class='hidden-xs hidden-sm hidden-md'>
                        <Assinatura/>
                    </Grid>
                </div>
                <div className="row data-form">
                    <Grid cols='12 12 12 3 3'>
                        <Status/>
                    </Grid>
                    <Grid cols='12 12 12 9 9'>
                        <div className="block-single text conteudo">
                            <Clausulas/>
                        </div>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default Contratos