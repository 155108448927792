import React, {Component} from 'react'
import ContentLoader from "react-content-loader";

class Status extends Component {
    render() {
        let meioPagamento = null;//'Não foi possivel recuperar esta informação';
        let plano = null;//'Não foi possivel recuperar esta informação';
        let flag = false;

        if (this.props.assinatura_dados) {
            if (this.props.assinatura_dados.tipo_publicacao === 'SWG') {
                meioPagamento = 'Assinatura via Google';
                flag = true;
            }else{
                meioPagamento = this.props.assinatura_dados.meio_de_pagamento;
            }
        }

        if (this.props.assinatura) {
            plano = this.props.assinatura.label_produto_midia
        }


        return (
          <div className='block-single text contratos-status'>
              <h3>Status</h3>
              <ul className='status-list'>
                  {!flag && (
                      <li>
                          <h4 className='status-titulo'>Plano:</h4>
                          {plano ? (
                              <p className='status-valor'>{plano}</p>
                          ) : (
                              <ContentLoader
                                  height={30}
                                  width={300}
                                  style={{width: '100%', height: '30', marginTop: "2px"}}
                                  speed={1}
                                  primaryColor="#f3f3f3"
                                  secondaryColor="#ecebeb">
                                  <rect x="0" y="0" rx="5" ry="5" width="300" height="30" />
                              </ContentLoader>
                          )}
                      </li>
                  )}
                  <li>
                      {!flag && (
                          <h4 className='status-titulo'>Forma de Pagamento:</h4>
                      )}

                      {meioPagamento ? (
                          <p className='status-valor'>{meioPagamento}</p>
                      ) : (
                          <ContentLoader
                              height={30}
                              width={300}
                              style={{width: '100%', height: '30', marginTop: "2px"}}
                              speed={1}
                              primaryColor="#f3f3f3"
                              secondaryColor="#ecebeb">
                              <rect x="0" y="0" rx="5" ry="5" width="300" height="30" />
                          </ContentLoader>
                      )}
                  </li>
              </ul>
          </div>
        )
    }
}

export default Status;