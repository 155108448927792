import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import JornalDeHoje from '../../newspaper/newspaper'
import Tempo from './tempo'
import Menu from './menu'
import Notificacao from "../../notificacao/notificacao"
import Grid from '../../modules/Grid'

class Header extends Component {
    render() {
        return (
            <header ref="html">
                <div className="top">
                    <div className="container">
                        <div className="row align-center">
                            <Grid cols='12 12 3 3 3' class='hidden-xs hidden-sm'>
                                <JornalDeHoje dtkey="jornal_digital_topo"/>
                            </Grid>
                            <Grid cols='9 9 7 6 6'>
                                <section className="band">
                                    <Link to="/">
                                        <h1 className="logo">Estadão - Portal do Estado de S. Paulo</h1>
                                    </Link>
                                </section>
                                <div className="bottom-band hidden-xs hidden-sm">
                                    <ul>
                                        <li>
                                            <a className="eldorado" rel="noopener noreferrer" href='https://www.estadao.com.br/'>
                                                Estadão
                                            </a>
                                        </li>
                                        <li>
                                            <a className="eldorado" rel="noopener noreferrer" href="https://eldorado.estadao.com.br" target="_blank" title="Eldorado">
                                                <i className="icon-play"></i><span>Eldorado FM</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </Grid>
                            <Grid cols='3 3 2 3 3'>
                                <Notificacao />
                            </Grid>
                        </div>
                    </div>
                </div>
                <Menu />
            </header>
        )
    }
}

export default Header