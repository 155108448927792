import { API, GET_TUTORIAIS } from '../utils/asyncActionType'

export default {
    getList() {
        return {
            type: API,
            payload: {
                url: '/data/tutoriais.json',
                cache: 1440,
                force_cache: 1440,
                ...GET_TUTORIAIS
            }
        }
    }
}