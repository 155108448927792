import React, {Component} from 'react';
import ReactContentLoader from 'react-content-loader'

class ContentLoader extends Component {
    componentDidMount() {
        if(this.refs.hasOwnProperty('content_loader')){
            this.refs['content_loader'].querySelector('svg').removeAttribute('viewBox');
        }
    }

    componentDidUpdate() {
        if(this.refs.hasOwnProperty('content_loader')){
            this.refs['content_loader'].querySelector('svg').removeAttribute('viewBox');
        }
    }

    render(){
        let props = this.props.hasOwnProperty("mobileprops") && window.innerWidth <= 768 ? this.props.mobileprops : this.props;
        return <span className="content-loader" ref="content_loader">
            <ReactContentLoader {...props} >
                {props.children ? props.children : this.props.children}
            </ReactContentLoader>
        </span>
    }
}

export default ContentLoader;
