import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import env from "../../../env";
import Loading from "../../../utils/Loading";
import Assinatura from "../../assinaturas";
import ContentLoader from "../../content-loader/content-loader";
import { sendGTMGA4Event } from "../../../utils/tagmanager";

class NewsletterInscricao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_input: "",
      inscrito_newsletter: false,
      isset: false,
      newsletter_selecionada: {},
      errors: {
        email: "",
      },
    };

    this.changeEmail = this.changeEmail.bind(this);
    this.cleanEmail = this.cleanEmail.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);
  }
  loader() {
    return (
      <ContentLoader
        height={"30vh"}
        width={"100%"}
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "30vh",
          float: "left",
          marginBottom: "15px",
        }}
        primaryColor="#eaeaea"
        secondaryColor="#e4e4e4"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
    );
  }

  componentDidMount() {
    if (this.props.user.email) {
      this.setState({
        email_input: this.props.user.email,
      });
    }

    this.setNewsletterSelecionada(this.props.newsletterSelecionada);
    this.sendGTMGA4Event({
      key: this.props.newsletterSelecionada.chave,
    });
  }

  setInscrito(status) {
    this.setState({
      inscrito_newsletter: status,
    });
  }

  setNewsletterSelecionada(item) {
    this.setState({
      newsletter_selecionada: item,
    });
  }

  sendSubscribe = (e, id_newsletter, news) => {
    e.preventDefault();

    let errors = this.state.errors;

    if (!this.validaEmail(this.state.email_input)) {
      errors.email = "Por favor, digite um email válido";
      this.setState({
        errors: errors,
      });
      return false;
    }

    Loading.add();

    var bodyFormData = new FormData();

    bodyFormData.set("id_newsletter", id_newsletter);
    bodyFormData.set("status", "S");
    bodyFormData.set("email", this.state.email_input);

    axios
      .post(env.API_URL + "/anonimo/newsletter", bodyFormData, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      })
      .then((response) => {
        this.setInscrito(true);
        this.sendGTMGA4Event({
          key: news.chave,
          status: news.assinante === "E" ? "gratis_por_30_dias" : "S",
        });
        Loading.remove();
      })
      .catch((error) => {
        // adicionado a fim de debug em qa e local
        if (
          window.location.hostname === "devl.meu.estadao.com.br" ||
          window.location.hostname === "qameu.estadao.com.br"
        ) {
          console.log(error);
        }

        Loading.remove();

        errors.email = error.response.data.message;
        this.setState({
          errors: errors,
        });
        this.sendGTMGA4Event({
          key: news.chave,
          status: "erro",
        });
        return false;
      });
  };

  getStatusNewsletter = (status) => {
    if (!status) return undefined;

    if (status === "S") return "inscricao";
    else if (status === "N") return "desinscricao";

    return status;
  };

  sendGTMGA4Event = ({ key, status }) => {
    const status_newsletter = this.getStatusNewsletter(status);

    sendGTMGA4Event({
      event: !status ? "newsletter_show_assinar" : "newsletter_assinar",
      secao: "modal_newsletter",
      componente: "modal",
      nome_newsletter: key,
      status_newsletter,
    });
  };

  changeEmail(event) {
    let errors = this.state.errors;
    errors.email = "";

    this.setState(
      {
        email_input: event.target.value,
        errors: errors,
      },
      () => {}
    );
  }

  cleanEmail(e, chave_newsletter) {
    e.preventDefault();
    this.updateStateInscrito(chave_newsletter, false);
    this.setState(
      {
        email_input: "",
        chave_newsletter: "",
      },
      () => {}
    );
  }

  changeIsset(event) {
    if (this.state.email_input !== "") {
      this.setState(
        {
          isset: true,
        },
        () => {}
      );
    }
  }

  cleanErrors(event) {
    let errors = this.state.errors;

    errors.email = "";
    this.setState({
      errors: errors,
    });
  }

  validaEmail(email) {
    var emailValue = email;

    if (emailValue.length <= 0) {
      return false;
    }
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(emailValue)) {
      return false;
    }

    return true;
  }

  render() {
    let subFolderImage = window.screen.width > 786 ? "desk" : "mobile";
    const { errors } = this.state;

    let news = [];
    news = this.state.newsletter_selecionada;

    if (typeof news.chave !== "undefined") {
      let img_src = news.imagem;

      let news_assinante = false;

      let class_main = "main-news";
      if (
        news.chave === "assinantes" ||
        news.chave === "brpoliticofiquedeolho" ||
        news.chave === "brpoliticoanalisa"
      ) {
        news_assinante = true;
        class_main += " -exclusiveSubscriber";
      }

      if (this.props.user.email) {
        class_main += " -logged -sairConta";
      }

      if (this.state.inscrito_newsletter) {
        class_main += " -buttonActive";
      }

      let exibe_form = true;
      if (news_assinante === true) {
        exibe_form = false;
        if (news.chave === "assinantes" && this.props.assinanteOESP === "S") {
          exibe_form = true;

          class_main += " -subscriber";
        } else if (
          (news.chave === "brpoliticofiquedeolho" ||
            news.chave === "brpoliticoanalisa") &&
          this.props.assinanteBRP === "S"
        ) {
          exibe_form = true;

          class_main += " -subscriber";
        }
      }

      let myAttr = {};
      if (errors.email.length > 0) {
        myAttr = { "data-error": errors.email };
      } else {
        myAttr = {};
      }
      return (
        <div className="content-news" key={news.chave}>
          <h2 className="logo">Estadão - Portal do Estado de S. Paulo</h2>
          <header className="header-news">
            <div className="header-news-info">
              <figure className="header-news-figure">
                <img
                  className="header-news-img"
                  src={img_src}
                  alt={news.nome_newsletter}
                />
              </figure>
              <h2 className="header-news-title title-figure">
                {news.nome_newsletter}
              </h2>
            </div>
            <div className="header-news-box">
              <h2 className="header-news-title">{news.nome_newsletter}</h2>
              <p className="header-news-desc">{news.descricao_newsletter}</p>
              <div class="news-dias">{news.dias}</div>
              {news.chave === "assinantes" && (
                <p className="header-news-excl">
                  Exclusivo para assinantes{" "}
                  <i
                    className={
                      this.props.assinanteOESP === "S"
                        ? "icon-lock-open-alt"
                        : "icon-round-lock"
                    }
                  />
                </p>
              )}
              {(news.chave === "brpoliticofiquedeolho" ||
                news.chave === "brpoliticoanalisa") && (
                <p className="header-news-excl">
                  Exclusivo para assinantes do BR Político
                  <i
                    className={
                      this.props.assinanteBRP === "S"
                        ? "icon-lock-open-alt"
                        : "icon-round-lock"
                    }
                  />
                </p>
              )}
            </div>
          </header>
          <div className={class_main}>
            <div className="mold-form-news form-newsletter">
              {exibe_form && (
                <form
                  className="form-news"
                  name="formNewsletter"
                  onSubmit={(e) =>
                    this.sendSubscribe(e, news.id_newsletter, news)
                  }
                >
                  {news_assinante ? (
                    <label className="form-news-label">
                      Essa newsletter é exclusiva para nossos assinantes.
                    </label>
                  ) : (
                    <label className="form-news-label" htmlFor="email">
                      Olá! Para receber nossa newsletter, prossiga com seu
                      email:
                    </label>
                  )}
                  <div className="group-field">
                    <div
                      className={
                        this.state.email_input !== ""
                          ? "form-field isset"
                          : "form-field"
                      }
                      {...myAttr}
                    >
                      <input
                        type="email"
                        id="email"
                        name="email"
                        disabled={
                          this.state.inscrito_newsletter ||
                          this.props.user.email
                        }
                        onChange={this.changeEmail}
                        onFocus={this.cleanErrors}
                        value={this.state.email_input}
                      />
                      <label className="field-label-floating" htmlFor="email">
                        E-mail
                      </label>
                      {this.props.user.email && (
                        <p className="sair-conta">
                          Esse email não é seu?{" "}
                          <a
                            href={
                              env.URL_ACESSO +
                              "/login/logout?r=" +
                              env.URL_ACESSO +
                              "/login/?r=" +
                              window.location.href
                            }
                          >
                            Saia da conta
                          </a>{" "}
                          para continuar
                        </p>
                      )}
                      <p className="lgpd-news-modal">
                        Ao se cadastrar, você concorda com os{" "}
                        <a
                          href="https://www.estadao.com.br/termo-de-uso"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <b>Termos de Uso</b>
                        </a>{" "}
                        e{" "}
                        <a
                          href="https://www.estadao.com.br/termo-de-uso#politica-privacidade"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <b>Política de privacidade</b>
                        </a>
                      </p>
                    </div>
                    {this.state.inscrito_newsletter ? (
                      <button
                        className="bt-news -inscrever btn-success"
                        id="btnInscrever"
                        disabled
                      >
                        <i className="fa icon-ok-circled" /> Inscrito
                      </button>
                    ) : (
                      <button
                        className="bt-news -inscrever"
                        type="submit"
                        data-dtkey="newsletter"
                        data-editoria={news.chave}
                        data-inscrito="false"
                        disabled={errors.email.length > 0 ? true : false}
                      >
                        Inscrever
                      </button>
                    )}
                  </div>
                </form>
              )}
            </div>
            <div className="mold-news-btns">
              {news_assinante && (
                <h2 className="news-title">
                  Olá! Essa newsletter é enviada apenas para os assinantes do{" "}
                  {news.chave === "brpoliticofiquedeolho" ||
                  news.chave === "brpoliticoanalisa"
                    ? "BR Político"
                    : "Estadão"}
                  .
                </h2>
              )}
              {((news.chave === "assinantes" &&
                this.props.assinanteOESP === "N") ||
                ((news.chave === "brpoliticofiquedeolho" ||
                  news.chave === "brpoliticoanalisa") &&
                  this.props.assinanteBRP === "N")) && (
                <div className="news-btns">
                  <div className="assinaturas">
                    <Assinatura />
                  </div>

                  <a
                    className="news-link"
                    href={env.URL_ACESSO + "/login/?r=" + window.location.href}
                    title="Já sou assinante"
                  >
                    <span>Já sou assinante</span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (state) => {
  return { ...state };
};

export default connect(mapStateToProps)(NewsletterInscricao);
