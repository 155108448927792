import React, { Component } from "react";
import Grid from "../../modules/Grid";
import { Form, Input } from "../../../utils/Form";
import Loading from "../../../utils/Loading";
import alertMessageActions from "../../../actions/alertMessage";
import store from "../../../store";

class Formulario extends Component {
  sendUpdate() {
    let form = document.getElementById("form-meus-dados");
    let salvarDados;
    let obj = {};
    obj["genericCategory"] = "engajamento";
    obj["genericAction"] = "meu_estadao";
    if (form) {
      let bodyFormData = new FormData(form);
      Loading.add();
      Form.send("/user/password", bodyFormData)
        .then((response) => {
          Loading.remove();
          window.scrollTo(0, 0);
          let { data, status } = response;
          if (status === 200) {
            store.dispatch(
              alertMessageActions.setAlert(
                "green",
                data.message || "Salvo com sucesso!"
              )
            );
            store.dispatch({ type: "REDIRECT", redirect: "/" });
            obj["genericLabel"] = "salvar_alterar_senha_sucesso";
            salvarDados = new CustomEvent("alertPush", { detail: obj });
            window.dispatchEvent(salvarDados);
            return;
          } else if (data.error) {
            let message = data.error.message || "Ops algo deu errado!";
            Form.showErrors(message);
            obj["genericLabel"] = "salvar_alterar_senha_recusado";
            salvarDados = new CustomEvent("alertPush", { detail: obj });
            window.dispatchEvent(salvarDados);
          }
        })
        .catch((e) => {
          Loading.remove();
        });
    }
  }

  render() {
    return (
      <div>
        <div className="block-single redefinir-senha">
          <div className="row">
            <Grid cols="12 12 12 12 12">
              <div className="title-inside">Alterar senha</div>
            </Grid>
          </div>
          <form
            id="form-meus-dados"
            action=""
            method="POST"
            className="content-form"
          >
            <div className="row">
              <Grid cols="12 12 12 6 6">
                <Input
                  type="password"
                  name="senha_atual"
                  label="Senha atual"
                  passwordInput={true}
                />
              </Grid>
            </div>
            <div className="row text-right esqueci-minha-senha">
              <Grid cols="12 12 12 6 6">
                <a
                  href="https://acesso.estadao.com.br/login/esqueci-minha-senha/"
                  target="_self"
                >
                  Esqueci minha senha
                </a>
              </Grid>
            </div>
            <div className="row">
              <Grid cols="12 12 12 6 6">
                <Input
                  type="password"
                  name="nova_senha"
                  label="Nova senha"
                  passwordInput={true}
                />
              </Grid>
            </div>
            <div className="row">
              <Grid cols="12 12 12 6 6">
                <Input
                  type="password"
                  name="repetir_nova_senha"
                  label="Confirmar nova senha"
                  passwordInput={true}
                />
              </Grid>
            </div>
            {/*
                        <div className="row">
                            <Grid cols="12 12 12 6 6">
                                <Input type="checkbox" className="checkbox" name="desconectar_dispositivos" label="<span></span>Desconectar de todos dispositivos" value={true}/>
                            </Grid>
                        </div>
                        */}
          </form>
        </div>

        <button
          className="btn blue salvar-dados"
          data-dtkey="salvar_dados"
          onClick={() => {
            this.sendUpdate();
          }}
        >
          <i className="icon-floppy" /> Salvar dados
        </button>
      </div>
    );
  }
}

export default Formulario;
