const INITIAL_STATE = {
    messages: []
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_TOAST':
            return { messages: [action.toast, ...state.messages] };
        case 'REMOVE_TOAST':
            return { messages: state.messages.filter(message => message.id !== action.id) };
        default:
            return state
    }
}