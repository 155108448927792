import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from "../../../../modules/Grid";
import {Input} from "../../../../../utils/Form";
import {initPaypal} from "./init-paypal";

class Paypal extends Component {
    constructor(props){
        super(props);
    }

    state = {
        flag: null
    }

    componentDidMount() {
        const assinatura_selecionada = this.props.selecionada;
        const forma_pagamento_de = this.props.codigoMeioPagamento(assinatura_selecionada)
        const ciclo = this.props.ciclo(assinatura_selecionada)
        initPaypal(assinatura_selecionada, forma_pagamento_de, ciclo, this.props.dispatch);
    }

    render() {

        return (
            <>
            <div className="paypal-form">
                <div className="paypal-texts">
                    <div className="h1-paypal">PayPal é a sua carteira digital!</div>
                    <div className="h2-paypal">
                        <b>One Touch</b>
                    </div>
                    <div className="text-paypal">
                        Pague com um click
                    </div>
                    <div className="h2-paypal">
                        <b>Carteira</b>
                    </div>
                    <div className="text-paypal">
                        Pague com seu cartão de crédito ou débito
                    </div>
                    <div className="h2-paypal">
                        <b>Tecnologia</b>
                    </div>
                    <div className="text-paypal">
                        Cartão protegido por criptografia
                    </div>
                </div>

                <div className="row">
                    <Grid cols="12 12 12 12 12">
                        <div id="paypal-button"></div>
                    </Grid>
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        codigoMeioPagamento: (codigo) => state.assinatura[codigo].assinatura.codigo_meio_de_pagamento,
        ciclo: (codigo) => state.assinatura[codigo].assinatura.ciclo,
        selecionada: state.assinaturas.assinatura_selecionada,
    }
}

export default connect(mapStateToProps)(Paypal)
