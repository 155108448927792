import React, { Component } from "react";
import DocumentHead from "../../utils/DocumentHead";
import { connect } from "react-redux";
import NewsletterAssinante from "./newsletter-assinante/newsletter-assinante";
import NewsletterAnonimo from "./newsletter-anonimo/newsletter-anonimo";

class Newsletter extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    DocumentHead({
      title: "Newsletter – meuEstadão | Portal do Assinante",
      description: "Painel do Estadão",
    });
  }

  render() {
    let match = this.props.match ? this.props.match : "";
    let params = match.params ? match.params : "";
    return this.props.user.email ? (
      <NewsletterAssinante
        modalChave={
          params.chave
            ? params.chave
            : window.location.pathname.split("/").pop()
        }
      />
    ) : (
      <NewsletterAnonimo
        modalChave={
          params.chave
            ? params.chave
            : window.location.pathname.split("/").pop()
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state };
};

export default connect(mapStateToProps)(Newsletter);
