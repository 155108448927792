import React, { Component } from 'react'
import { connect } from 'react-redux';
import ContentLoader from "./../content-loader/content-loader"

import AlertMessage from "../alert-message/alertMessage";
import Assinaturas from "../assinaturas";
import Titulo from "../modules/Titulo";
import Grid from '../modules/Grid'

import tutoriaisAction from './../../actions/tutoriais'
import DocumentHead from "../../utils/DocumentHead";

const TutoriaisLoader = props => (
    <ContentLoader
        height={'460'}
        width={'1200'}
        style={{width: '100%',maxWidth: "1200", height: '460', margin: '0 auto', display: 'table'}}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb">
        <rect x="0" y="0" rx="0" ry="0" width="1200" height="40" />
        <rect x="0" y="50" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="80" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="110" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="140" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="170" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="200" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="230" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="260" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="290" rx="0" ry="0" width="1200" height="40" />
        <rect x="0" y="340" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="370" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="400" rx="0" ry="0" width="1200" height="20" />
        <rect x="0" y="430" rx="0" ry="0" width="1200" height="20" />
        >
    </ContentLoader>
)

class Tutoriais extends Component {

    state = {
        sortBy: "title",
        updatedAt: 'desc',
        sorted: false
    }

    componentDidMount() {
        DocumentHead({
            title: 'Tutoriais – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });

        tutoriaisAction.getList()
    }

    componentWillMount() {
        this.props.dispatch(tutoriaisAction.getList())
    }

    handleActive(e){
        e.preventDefault();
        if(e.currentTarget){
            let list_active = e.currentTarget.closest('.accordion').querySelectorAll('.list-group-item.active');
            for(let i in list_active){
                if(list_active.hasOwnProperty(i)){
                    list_active[i].classList.remove('active');
                }
            }

            e.currentTarget.closest('.list-group-item').classList.add('active');
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const isPending = this.props.pending;
        if(!isPending && !this.state.sorted){
            this.sortBy(false, 'title', 'asc');
        }
    }

    sortBy(group, field, sort) {
        if(!group){
            for(let i in this.props.tutoriais){
                this.sortBy(i, field, sort);
            }
            return;
        }
        sort = sort ? sort : this.state.sortBy[field] === 'asc' ? 'desc' : 'asc';
        this.setState(prevState => ({
            sortBy: {
                ...prevState.sortBy,
                [field]: sort,
            },
            sorted: true
        }));
        
        let articles = this.props.tutoriais[group];
        let arrArticles = [];

        for (let articleId in articles){
            arrArticles.push(articles[articleId]);
        }

        arrArticles.sort((a, b) => {
            let _a = a[field];
            let _b = b[field];
            if (sort === 'asc'){
                if (_a < _b)
                    return -1;
                if ( _a > _b)
                    return 1;
            } else {
                if (_a > _b)
                    return -1;
                if ( _a < _b)
                    return 1;
            }

            return 0;
        });

        this.props.tutoriais[group] = arrArticles
    }

    articles() {
        return Object.keys(this.props.tutoriais).map((articleGroup, key) => (
            <div key={key} className='card'>
                <div className='card-header'>
                    <div className="row">
                        <Grid cols='12 12 10'>
                            <h3>
                                {articleGroup}
                                <button onClick={() => this.sortBy(articleGroup, 'title', 'asc')} className='ic-sort ic-sort-asc'>
                                    <i className='icon-caret-up' />
                                </button>
                                <button onClick={() => this.sortBy(articleGroup, 'title', 'desc')} className='ic-sort ic-sort-desc'>
                                    <i className='icon-caret-down' />
                                </button>
                            </h3>
                        </Grid>
                        <Grid cols='12 12 2'>
                            <span>
                                Atualização
                                <button onClick={() => this.sortBy(articleGroup, 'updatedAt', 'asc')} className='ic-sort ic-sort-asc'>
                                    <i className='icon-caret-up' />
                                </button>
                                <button onClick={() => this.sortBy(articleGroup, 'updatedAt', 'desc')} className='ic-sort ic-sort-desc'>
                                    <i className='icon-caret-down' />
                                </button>
                            </span>
                        </Grid>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='list-group'>
                        {
                            Object.keys(this.props.tutoriais[articleGroup]).map(key => (
                                <div key={key} className='list-group-item'>
                                    <a href={"#"+key} onClick={(e) => this.handleActive(e)}>
                                        <div className="row">
                                            <Grid cols='12 12 10' class='article-title'>
                                                <h3>
                                                    {this.props.tutoriais[articleGroup][key].title}
                                                </h3>
                                            </Grid>
                                            <Grid cols='12 12 2' class='article-updated-at'>
                                                <p><strong>{this.props.tutoriais[articleGroup][key].updatedAt}</strong></p>
                                            </Grid>
                                        </div>
                                    </a>
                                    <div className='article-content-wrapper'>
                                        <div className='article-content' dangerouslySetInnerHTML={{__html: this.props.tutoriais[articleGroup][key].content }} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        ))
    }

    render() {
        const isPending = this.props.pending;
        const articles = this.articles();
        return (
            <div id='tutoriais-page' className='container'>
                <section className='header-content row nomargin'>
                    <Grid cols='12 12 12 6 6' class='text-left nopadding welcome-wrapper align-center'>
                        <Titulo groupItem="Meus produtos" titulo="tutoriais"/>
                    </Grid>
                    <Grid cols='12 12 12 6 6' class='text-left nopadding text-right hidden-xs hidden-sm hidden-md'>
                        <Assinaturas />
                    </Grid>
                    <AlertMessage />
                </section>
                <div className='main-content'>
                    <div className="row">
                        <Grid cols='12'>
                            {isPending ? (
                                <TutoriaisLoader/>
                            ) : (
                                <div className="accordion">
                                    {articles}
                                </div>
                            )}
                        </Grid>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        pending: Object.keys(state.tutoriais) === 0,
        tutoriais:  state.tutoriais
    }
};

export default connect(mapStateToProps)(Tutoriais);