import { API, GET_FAQ } from '../utils/asyncActionType'

export default {
    getFAQ() {
        return {
            type: API,
            payload: {
                // url: '/data/faq.json',
                url: '/utils/list-faqs',
                ...GET_FAQ
            }
        }
    }
}