import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import alertMessageActions from "../../../actions/alertMessage";
import newsletterAction from "../../../actions/newsletter";
import env from "../../../env";
import DocumentHead from "../../../utils/DocumentHead";
import Loading from "../../../utils/Loading";
import AlertMessages from "../../alert-message/alertMessage";
import Assinatura from "../../assinaturas";
import ContentLoader from "../../content-loader/content-loader";
import Grid from "../../modules/Grid";
import NewsletterStatus from "../../modules/NewsletterStatus";
import Titulo from "../../modules/Titulo";
import NewsletterInscricao from "../modulos/newsletter-inscricao";
import NewsletterUltima from "../modulos/newsletter-ultima";
import { sendGTMGA4Event } from "../../../utils/tagmanager";

var newsletter_modal = {};

class NewsletterAssinante extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assinante_brp: false,
      open_modal: false,
      open_modal_ultima: false,
      url_modal_ultima: "",
    };
  }
  loader() {
    return (
      <ContentLoader
        height={"30vh"}
        width={"100%"}
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "30vh",
          float: "left",
          marginBottom: "15px",
        }}
        primaryColor="#eaeaea"
        secondaryColor="#e4e4e4"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
    );
  }
  openModal() {
    this.setState({
      open_modal: true,
    });
  }
  closeModal() {
    this.setState({
      open_modal: false,
    });
  }
  openModalUltima(e, url) {
    e.preventDefault();
    this.setState({
      open_modal_ultima: true,
      url_modal_ultima: url,
    });
  }
  closeModalUltima() {
    this.setState({
      open_modal_ultima: false,
      url_modal_ultima: "",
    });
  }
  componentDidMount() {
    DocumentHead({
      title: "Newsletter – meuEstadão | Portal do Assinante",
      description: "Painel do Estadão",
    });

    let location = window.location;

    let id_newsletter = 0;

    try {
      id_newsletter = location.search.match(
        new RegExp(/assinar=(.*?)($|&)/, "i")
      )[1];
    } catch (e) {}

    this.props.dispatch(newsletterAction.getNewsletter());
    this.changeAcordian = this.changeAcordian.bind(this);
    if (id_newsletter > 0) {
      this.updateStatus(id_newsletter, "S", () => {
        this.props.dispatch(
          alertMessageActions.setAlert(
            "green",
            "Inscrição na newsletter feita com sucesso!",
            8000
          )
        );
      });
    } else {
      this.props.dispatch(
        alertMessageActions.setAlert(
          "green",
          "Escolha sua newsletter preferida!",
          8000
        )
      );
    }

    this.checkAssinanteBRP();
  }

  componentWillUnmount() {
    this.props.dispatch(alertMessageActions.removeAlert());
  }
  checkAssinanteBRP() {
    const assinaturas = this.props.assinaturas.list;
    let aux = false;
    Object.keys(assinaturas).map(function(key, index) {
      if (
        assinaturas[key]["cd_produto_midia"] === "OBRP" ||
        assinaturas[key]["cd_produto_midia"] === "OBR1" ||
        assinaturas[key]["cd_produto_midia"] === "OISP" ||
        assinaturas[key]["cd_produto_midia"] === "OIBR" ||
        assinaturas[key].produto.indexOf("OBRP") !== -1
      ) {
        aux = true;
      }
      return true;
    });
    this.setState({
      assinante_brp: aux,
    });
  }
  openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  validaeditoria(event, url) {
    if (url.includes("cloud.jornal")) {
      this.openInNewTab(url);
    } else {
      this.openModalUltima(event, url);
    }
  }
  updateStatus(id_newsletter, aceite, callback) {
    Loading.add();
    var bodyFormData = new FormData();
    bodyFormData.set("id_newsletter", id_newsletter);
    bodyFormData.set("status", aceite);

    if (!this.props.user.assinante) {
      bodyFormData.set("email", this.props.user.email);
    }

    axios
      .post(env.API_URL + "/user/newsletter", bodyFormData, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      })
      .then((response) => {
        this.props.dispatch(newsletterAction.getNewsletter(true));
        //if(aceite === 'S'){
        //    this.props.dispatch(toastActions.show('green', 'Você receberá uma confirmação em ' + this.props.user.email, 800))
        //}
        if (typeof callback != "undefined") {
          callback();
        }
      })
      .catch((error) => {
        // adicionado a fim de debug em qa e local
        if (
          window.location.hostname === "devl.meu.estadao.com.br" ||
          window.location.hostname === "qameu.estadao.com.br"
        ) {
          console.log(error);
        }

        Loading.remove();
        alert(error.response.data.message);
        return false;
      });
  }

  changeAcordian(event) {
    let element = event.currentTarget;
    element.classList.add("activing");

    let itens = element
      .closest(".newsletter-items")
      .querySelectorAll(".newsletter-item.active:not(.activing)");
    Object.keys(itens).map((i) => {
      return itens[i].classList.remove("active");
    });

    if (!element.classList.contains("active")) {
      element.classList.add("active");
      setTimeout(() => {
        let posTop = element.getBoundingClientRect().top + window.scrollY - 80;
        window.scroll({
          top: posTop,
          left: 0,
          behavior: "smooth",
        });
      }, 300);
    } else {
      element.classList.remove("active");
    }

    element.classList.remove("activing");
  }

  sendGTMGA4Event = ({ key, status }) => {
    sendGTMGA4Event({
      event: "newsletter_assinar",
      secao: "newsletter",
      componente: "box",
      nome_newsletter: key,
      status_newsletter:
        status === "S" ? "inscricao" : status === "N" ? "desinscricao" : status,
    });
  };

  render() {
    let assinante = this.props.user.assinante && !this.props.user.processando;
    let assinante_brp = this.state.assinante_brp;
    let newsletter_list = this.props.newsletter,
      newsletter = [];
    if (newsletter_list) {
      newsletter = Object.values(newsletter_list);

      if (
        Object.keys(newsletter_modal).length === 0 &&
        this.props.modalChave !== "" &&
        typeof this.props.modalChave !== "undefined"
      ) {
        {
          Object.keys(newsletter_list).map((key) => {
            let chave = this.props.modalChave;
            if (chave === "saibaagora") {
              chave = "assinantes";
            } else if (chave === "hyperlink") {
              chave = "link";
            }

            if (chave === newsletter_list[key].chave) {
              if (
                ((newsletter_list[key].assinante === "E" ||
                  newsletter_list[key].assinante === "S") &&
                  assinante == true) ||
                newsletter_list[key].assinante === "N"
              ) {
                newsletter_modal = newsletter_list[key];
                this.openModal();
              }
            }
          });
        }
      }
    }
    return (
      <div
        className="container newsletter-wrapper"
        data-is-assinante={assinante ? "true" : "false"}
      >
        {Object.keys(newsletter_modal).length > 0 && (
          <Modal
            styles={{
              modal: {
                width: "100%",
                maxWidth: 638,
                height: "auto",
                maxHeight: 526,
              },
            }}
            open={this.state.open_modal}
            onClose={() => this.closeModal()}
            classNames={{
              overlay: "overlay-inscricao",
              modal: "modal-inscricao",
            }}
            center
          >
            <NewsletterInscricao
              modalChave={this.props.modalChave}
              newsletterSelecionada={newsletter_modal}
              assinanteOESP={assinante ? "S" : "N"}
              assinanteBRP={assinante_brp ? "S" : "N"}
            />
          </Modal>
        )}

        <Modal
          styles={{
            modal: { width: "100%", maxWidth: 620, height: "auto", padding: 0 },
          }}
          open={this.state.open_modal_ultima}
          onClose={() => this.closeModalUltima()}
          classNames={{ overlay: "overlay-newsletter-ultima" }}
          center
        >
          <NewsletterUltima chaveNewsletter={this.state.url_modal_ultima} />
        </Modal>
        <div className="header-content row">
          <Grid cols="12 12 12 6 6">
            <div className="titulo-header">
              <Titulo groupItem="Meus produtos" titulo="newsletter" />
            </div>
            <div className="titulo-header-mobile">
              <Titulo groupItem="Meus produtos" titulo="dashboard" />
            </div>
          </Grid>
          <Grid cols="12 12 12 6 6" class="hidden-xs hidden-sm hidden-md">
            <Assinatura />
          </Grid>
          <AlertMessages dtkey="barra_verde" />
        </div>
        <div className="row newsletter-container">
          <Grid cols="12 12 12 3 3">
            <div className="status-block alterar-email-news">
              <div className="content">
                <p>
                  Para trocar o e-mail de recebimento de newsletters, clique
                  abaixo.
                </p>
                <div className="button-wrapper">
                  <a href="meus-dados">
                    <button type="button" className="alterar-email-btn">
                      Alterar e-mail
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <NewsletterStatus />
          </Grid>
          <Grid cols="12 12 12 9 9">
            {!assinante ||
            (this.props.assinaturas &&
              this.props.assinaturas.loaded === true) ? (
              <div className="newsletter-items">
                <div className="newsletter-title">
                  <p>
                    Selecione as newsletters de interesse clicando sobre cada
                    uma delas
                  </p>
                </div>
                <div className="newsletter-lgpd">
                  <p>
                    Ao se cadastrar nas newsletters, você concorda com os
                    <a
                      href="https://www.estadao.com.br/termo-de-uso"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <b> Termos de Uso </b>
                    </a>
                    e
                    <a
                      href="https://www.estadao.com.br/termo-de-uso#politica-privacidade"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <b> Política de privacidade</b>
                    </a>
                  </p>
                </div>
                {Object.keys(newsletter).map((key) => {
                  let img_src =
                    window.screen.width > 786
                      ? newsletter[key].imagem
                      : newsletter[key].imagem_mobile;
                  let aceite = newsletter[key].aceite === "S" ? true : false;
                  let aceiteCSS = aceite;
                  console.log();
                  if (window.screen.width > 786) {
                    aceiteCSS = false;
                  }
                  return (
                    <div
                      key={key}
                      data-acordian={key}
                      className="newsletter-item"
                      data-checked={aceiteCSS}
                      onClick={this.changeAcordian}
                    >
                      <div className="inner">
                        <div className="item-acordian">
                          <img
                            alt={newsletter[key].nome_newsletter}
                            className={newsletter[key].chave}
                            src={img_src}
                            width="259"
                          />

                          <div className="item-acordian-bg">
                            <i className="fa icon-angle-down" />
                            <div className="checked-layer">
                              {window.screen.width < 786 ? (
                                <img
                                  src="/static/img/newsletter/checked.svg"
                                  alt="Subscribe"
                                  width="20"
                                  className="img-clean"
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="newsletter-content item-acordian-content">
                          <div className="title">
                            <span
                              className={`${
                                newsletter[key].assinante === "E" &&
                                !assinante &&
                                window.screen.width < 786
                                  ? "gratis30t"
                                  : ""
                              }`}
                            >
                              {newsletter[key].nome_newsletter}
                              {newsletter[key].assinante === "S" && (
                                <i
                                  className={
                                    assinante
                                      ? "icon-lock-open-alt"
                                      : "icon-round-lock"
                                  }
                                />
                              )}
                            </span>
                            {newsletter[key].assinante === "E" &&
                            !assinante &&
                            window.screen.width < 786 ? (
                              <span className="gratis30">
                                {newsletter[key].texto_botao_gratis != ""
                                  ? newsletter[key].texto_botao_gratis
                                  : "Grátis por 30 dias"}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="texto">
                            {newsletter[key].descricao_newsletter}
                          </div>
                          <div class="news-dias">{newsletter[key].dias}</div>
                          {(newsletter[key].assinante === "S" ||
                            newsletter[key].assinante === "E") && (
                            <div className="assinante">
                              Exclusivo para assinantes{" "}
                              <i
                                className={
                                  assinante
                                    ? "icon-lock-open-alt"
                                    : "icon-round-lock"
                                }
                              />
                            </div>
                          )}

                          {(newsletter[key].assinante !== "S" || assinante) &&
                            (newsletter[key].chave !== "pilula" || assinante) &&
                            !(
                              newsletter[key].chave ===
                                "brpoliticofiquedeolho" ||
                              newsletter[key].chave === "brpoliticoanalisa"
                            ) && (
                              <div>
                                <div className="ver-ultima">
                                  <button
                                    className="btn-ver-ultima"
                                    onClick={(e) => {
                                      this.validaeditoria(
                                        e,
                                        newsletter[key].url_newsletter
                                      );
                                      this.sendGTMGA4Event({
                                        key: newsletter[key].chave,
                                        status: "ver_ultima_edicao",
                                      });
                                    }}
                                  >
                                    <span>Ver última edição</span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="#005f93"
                                      width="5"
                                      height="9"
                                      viewBox="0 0 5 9"
                                    >
                                      <path
                                        className="prefix-link-arrow"
                                        d="M229.912 166.921a.281.281 0 0 1-.093.208l-4.352 4.2a.3.3 0 0 1-.43 0l-.466-.452a.276.276 0 0 1-.093-.205.281.281 0 0 1 .093-.208l3.669-3.545-3.669-3.545a.276.276 0 0 1-.093-.205.281.281 0 0 1 .093-.208l.466-.452a.3.3 0 0 1 .43 0l4.352 4.2a.281.281 0 0 1 .093.212z"
                                        data-name="prefix-link-arrow"
                                        transform="translate(-224.478 -162.421)"
                                      />
                                    </svg>
                                  </button>
                                </div>

                                <div className="button-wrapper">
                                  {aceite ? (
                                    <button
                                      type="button"
                                      className="subscribe btninscricao"
                                      data-dtkey="newsletter"
                                      data-inscrito="true"
                                      data-editoria={newsletter[key].chave}
                                      onClick={() => {
                                        this.updateStatus(
                                          newsletter[key].id_newsletter,
                                          "N"
                                        );
                                        this.sendGTMGA4Event({
                                          key: newsletter[key].chave,
                                          status: "N",
                                        });
                                      }}
                                    >
                                      <i className="fa icon-ok-circled" />{" "}
                                      Inscrito
                                    </button>
                                  ) : !aceite &&
                                  !assinante &&
                                  newsletter[key].assinante === "E" ? (
                                    <button
                                      type="button"
                                      className="subscribe true btninscricao"
                                      data-dtkey="newsletter"
                                      data-inscrito="false"
                                      data-editoria={newsletter[key].chave}
                                      onClick={() => {
                                        this.updateStatus(
                                          newsletter[key].id_newsletter,
                                          "S"
                                        );
                                        this.sendGTMGA4Event({
                                          key: newsletter[key].chave,
                                          status: "gratis_por_30_dias",
                                        });
                                      }}
                                    >
                                      {newsletter[key].texto_botao_gratis != ""
                                        ? newsletter[key].texto_botao_gratis
                                        : "Grátis por 30 dias"}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="subscribe true btninscricao"
                                      data-dtkey="newsletter"
                                      data-inscrito="false"
                                      data-editoria={newsletter[key].chave}
                                      onClick={() => {
                                        this.updateStatus(
                                          newsletter[key].id_newsletter,
                                          "S"
                                        );
                                        this.sendGTMGA4Event({
                                          key: newsletter[key].chave,
                                          status: "S",
                                        });
                                      }}
                                    >
                                      Inscrever
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              this.loader()
            )}
          </Grid>
        </div>

        {Loading.remove()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state };
};

export default connect(mapStateToProps)(NewsletterAssinante);
