import React, { Component } from 'react'
import Grid from '../modules/Grid'
import DocumentHead from '../../utils/DocumentHead'
import Assinatura from '../assinaturas'
import AlertMessage from '../alert-message/alertMessage'
import Titulo from "../modules/Titulo";
import Formulario from "./formulario";

class FaleConosco extends Component {
    componentDidMount() {
        DocumentHead({
            title: 'Fale Conosco – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });
    }

    render() {
        return (
            <div className="container fale-conosco justify-content-center">
                <div className="header-content row">
                    <Grid cols='12 12 12 6 6' class='text-left welcome-wrapper align-center'>
                        <Titulo groupItem="Fale conosco" titulo="Faça suas sugestões, dúvidas ou críticas"/>
                    </Grid>
                    <Grid cols='12 12 12 6 6' class='text-left text-right hidden-xs hidden-sm hidden-md'>
                        <Assinatura />
                    </Grid>
                    <Grid cols='12'>
                        <AlertMessage />
                    </Grid>
                </div>

                <div className="row data-form">
                    <Grid cols="12">
                        <Formulario/>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default FaleConosco;