import React, {Component} from 'react'
import {Select} from "../../utils/Form";

class Assinatura extends Component {
    render() {
        return (
            <div className="assinaturas text-right">
                <label htmlFor="assinatura-option">Assinatura:</label>
                <div className="select-box">
                    <Select name="assinatura-selected" data={this.props.assinaturaList} onChange={this.props.setSubscription}
                            value={this.props.assinaturaSelecionada}/>
                </div>
            </div>
        )
    }
}

export default Assinatura