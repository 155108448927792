import {API, GET_UTILS} from '../utils/asyncActionType'

export default {
    getAssuntosFaleConosco() {
        return {
            type: API,
            payload: {
                url: '/utils/assuntos-fale-conosco',
                cache: 1440,
                force_cache: 1440,
                ...GET_UTILS.ASSUNTOS_FALE_CONOSCO
            }
        }
    },

    getMotivosCancelamento() {
        return {
            type: API,
            payload: {
                url: '/utils/motivos-cancelamento',
                cache: 0,
                force_cache: 0,
                ...GET_UTILS.MOTIVOS_CANCELAMENTO
            }
        }
    },

    getTempo() {
        return {
            type: API,
            payload: {
                url: '/utils/tempo',
                cache: 1440,
                force_cache: 30,
                attempts: 2,
                attempts_error: true,
                test_response: (response) => {
                    return response.temperatura_maxima;
                },
                ...GET_UTILS.TEMPO
            }
        }
    },

    getJornalDeHoje() {
        return {
            type: API,
            payload: {
                url: '/utils/jornal-de-hoje',
                cache: 1440,
                attempts: 2,
                attempts_error: true,
                test_response: (response) => {
                    return response.image;
                },
                force_cache: (date_insert_to_expires) => {
                    date_insert_to_expires.setHours(23, 59,59);
                    return date_insert_to_expires;
                },
                ...GET_UTILS.JORNAL_DE_HOJE
            }
        }
    },

    getAssuntosDoMomento() {
        return {
            type: API,
            payload: {
                url: '/utils/assuntos-do-momento',
                cache: 1440,
                force_cache: 5,
                attempts: 2,
                attempts_error: true,
                test_response: (response) => {
                    return Object.keys(response).length > 0;
                },
                ...GET_UTILS.ASSUNTOS_DO_MOMENTO
            }
        }
    },

    getUltimasNoticias() {
        return {
            type: API,
            payload: {
                url: '/utils/ultimas-noticias',
                cache: 1440,
                force_cache: 5,
                attempts: 2,
                attempts_error: true,
                test_response: (response) => {
                    return Object.keys(response).length > 0;
                },
                ...GET_UTILS.ULTIMAS_NOTICIAS
            }
        }
    }
}