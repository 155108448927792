import React, { Component } from 'react';

class Titulo extends Component {
    render() {
        return (
            <div className="componente-titulo">
                <h1 className="titulo">
                    {this.props.groupItem} <span data-title-mobile={this.props.titleMobile}>{this.props.titulo}</span>
                </h1>
            </div>
        )
    }
};

export default Titulo;