let links = {
  meu_perfil: "/meus-dados",
  //'controle_de_acesso': "/controle-de-acesso",
  alterar_senha: "/alterar-senha",
  newsletter: "/newsletter",
  endereco: false,
  convite: false,
  tutoriais: false, //'/tutoriais',
  historico_de_pagamento: false,
  forma_de_pagamento: false,
  nota_fiscal: false,
  contrato_de_assinatura: false,
  transferencia_temporaria: false,
  reclamacao_de_entrega: false,
  alteracao_de_endereco: false,
  interrupcao_temporaria: false,
  alterar_plano: false,
  jornal_digital: false,
  podcasts: "https://eldorado.estadao.com.br/podcasts",
  tv_estadao: "https://tv.estadao.com.br/",
  acervo_estadao: "https://acervo.estadao.com.br/",
  apps: "https://www.estadao.com.br/aplicativos",
};

class FilerLinks {
  constructor(state = {}) {
    if (state.user) {
      if (!state.user.assinante) {
        links = {
          ...links,
          ...{
            endereco: false,
          },
        };
        if (state.user.block) {
          links = {
            ...links,
            ...{
              historico_de_pagamento: "/historico-de-pagamentos",
            },
          };
        }
      } else {
        if (
          typeof state.inadimplente !== "undefined" &&
          (state.inadimplente.hasOwnProperty("cod_retorno") &&
            state.inadimplente.cod_retorno === "1")
        ) {
          links = {
            ...links,
            ...{
              forma_de_pagamento: "/alterar-forma-de-pagamento",
            },
          };
        }
        if (state.assinatura) {
          if (["IMP", "DIG"].indexOf(state.assinatura.config.type) !== -1) {
            links = {
              ...links,
              ...{
                historico_de_pagamento: "/historico-de-pagamentos",
                nota_fiscal: "/nota-fiscal",
                contrato_de_assinatura: "/contratos-assinaturas",
                jornal_digital:
                  "https://digital.estadao.com.br/o-estado-de-s-paulo/",
              },
            };

            if (state.assinatura.config.type === "IMP") {
              links = {
                ...links,
                ...{
                  endereco: "/endereco",
                  transferencia_temporaria: "/transferencia-temporaria",
                  reclamacao_de_entrega: "/reclamacao-entrega/",
                  alteracao_de_endereco: "/alteracao-endereco",
                  interrupcao_temporaria: "/interrupcao-temporaria",
                  correcao_redacao: "/correcao-redacao",
                },
              };
            } else {
              links = {
                ...links,
                ...{
                  endereco: false,
                  transferencia_temporaria: false,
                  reclamacao_de_entrega: false,
                  alteracao_de_endereco: false,
                  interrupcao_temporaria: false,
                },
              };
            }
          } else {
            links = {
              ...links,
              ...{
                meu_perfil: "/meus-dados",
                endereco: false,
              },
            };
          }
        }
        if (
          typeof state.user.colaborador !== "undefined" &&
          state.user.colaborador
        ) {
          links = {
            ...links,
            ...{
              forma_de_pagamento: false,
            },
          };
        }
      }
    }
  }

  get(key) {
    if (links.hasOwnProperty(key)) {
      return links[key];
    }

    return false;
  }
}

const FilerLinksFactor = (state) => {
  return new FilerLinks(state);
};

export default FilerLinksFactor;
