if(!Element.prototype.setValue)
    Element.prototype.setValue = function(value){
        let prototype;
        switch (this.tagName.toLocaleLowerCase()) {
            case "select":
                prototype = window.HTMLSelectElement.prototype;
            break;
            case "textarea":
                prototype = window.HTMLTextAreaElement.prototype;
            break;
            default:
                prototype = window.HTMLInputElement.prototype;
            break;
        }
        var nativeInputValueSetter = Object.getOwnPropertyDescriptor(prototype, "value").set;
        nativeInputValueSetter.call(this, value);
        this.dispatchEvent(new Event('change', { bubbles: true}));
        return this;
    };

if(!Element.prototype.trigger)
    Element.prototype.trigger = function(event){
        this.dispatchEvent(new Event(event, { bubbles: true}));
        return this;
    };