import React from 'react';
import Input from './Input';
import InputMask from 'react-input-mask';

class InputCPF extends Input {
    render(){
        return (
            <div className={this.state.className.join(" ")+(this.state.isset ? " isset" : "")}>
                {!this.state.load ? (
                    <div>
                        <InputMask readOnly={this.state.readonly} ref="input" mask="999.999.999-99" type={this.state.type} id={this.state.id} name={this.state.name} className={(!this.state.label ? "no-label" : "")} onChange={(e) => this.handleChange(e)} defaultValue={this.state.value}/>
                        {this.state.label ? (
                            <label htmlFor={this.state.id}>{this.state.label}</label>
                        ) : ""}
                    </div>
                ) : this.loader()}
            </div>
        )
    }
}

export default InputCPF;
