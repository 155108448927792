import React, {Component} from 'react'
import {connect} from "react-redux";

import Grid from '../../modules/Grid'
import Assinatura from '../../assinaturas'
import Titulo from "../../modules/Titulo";
import AlertMessage from '../../alert-message/alertMessage'
import DocumentHead from "../../../utils/DocumentHead";
import Status from "./status";
import subscriptionActions from "../../../actions/subscription";
import Nfs from "./nfs";
import Fatura from "./fatura";
import GoogleSubscribe from "./google-subscribe";


class NotaFiscal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pag: '',
            descr: ''
        }
    }

    componentDidMount() {
        DocumentHead({
            title: 'meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });

        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if (!this.props.assinatura_dados && this.props.assinatura_selecionada) {
            this.props.dispatch(subscriptionActions.getDados(this.props.assinatura_selecionada))
        }
    }

    handlePageTitle(title, description) {
        if (this.state.pag === null || this.state.pag !== title) {
            this.setState({
                  pag: title,
                  descr: description
              });
            document.title = title + ' – meuEstadão | Portal do Assinante'
        }
    }

    render() {
        let exibir = '';
        if (this.props.assinatura_dados && this.props.assinatura_dados.tipo_publicacao === 'SWG') {
            this.handlePageTitle('Fatura', 'Histórico de pagamento');
            exibir = 'google';
        } else {
            if (this.props.assinatura && this.props.assinatura.config.type) {
                switch (this.props.assinatura.config.type) {
                    case 'IMP':
                        this.handlePageTitle('Nota Fiscal', 'Nota Fiscal Eletrônica');
                        exibir = 'nota';
                        break;
                    default:
                        this.handlePageTitle('Fatura', 'Fatura');
                        exibir = 'fatura';
                        break;
                }
            }
        }
        return (
          <div className="container nota-fiscal-container justify-content-center">
              <div className="row header-content">
                  <Grid cols='12 12 12 6 6'>
                      <Titulo groupItem="Meus pagamentos" titulo={this.state.descr}/>
                  </Grid>
                  <Grid cols='12 12 12 6 6' class='hidden-xs hidden-sm hidden-md'>
                      <Assinatura/>
                  </Grid>

                  <AlertMessage/>
              </div>
              <div className="row data-form">
                  <Grid cols='12 12 12 3 3' class={exibir+'-status'}>
                      <Status {...this.props}/>
                  </Grid>
                  <Grid cols='12 12 12 9 9'>
                      {
                          (() => {
                              switch (exibir) {
                                  case "nota":
                                      return  <Nfs {...this.props} />;
                                  case "fatura":
                                      return  <Fatura {...this.props} />;
                                  case "google":
                                    return  <GoogleSubscribe {...this.props} />
                                  //no default
                              }
                          })()
                      }
                  </Grid>
              </div>
          </div>

        )
    }
}

const mapStateToProps = state => {
    return {
        usuario: state.user,
        assinatura: Object.keys(state.assinaturas.list).length > 0 &&
        state.assinaturas.list.hasOwnProperty(state.assinaturas.assinatura_selecionada) ?
            state.assinaturas.list[state.assinaturas.assinatura_selecionada] : false,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura_dados: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.assinatura[state.assinaturas.assinatura_selecionada].assinatura : null,
        pagador: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.assinatura[state.assinaturas.assinatura_selecionada].pagador : null,
    }
}
export default connect(mapStateToProps)(NotaFiscal)