import React, { Component } from "react";
import ContentLoader from "react-content-loader";
import InputMask from "react-input-mask";

class Input extends Component {
  constructor(props) {
    super(props);
    this.id_input_random = Math.floor(Math.random() * 99999999);
    this.state = {
      isset:
        Object.keys(this.props.value ? this.props.value : "").length > 0
          ? true
          : false,
      value: this.props.value || "",
      className: [this.props.className || "", "input-control"],
      label: this.props.label || "",
      name: this.props.name,
      dataTeste: this.props.dataTeste || "",
      readonly: this.props.readonly || false,
      id: "input_" + (this.props.id || this.props.name || this.id_input_random),
      load: this.props.load || false,
      mask: this.props.mask || false,
      type: this.props.type || "text",
      passwordInput: this.props.passwordInput || false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  loader = (props) => (
    <div ref={(el) => (this.content_loader = el)}>
      <ContentLoader
        height={55}
        width={300}
        style={{ width: "100%", height: "55" }}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect x="0" y="0" rx="5" ry="5" width="300" height="55" />
      </ContentLoader>
    </div>
  );

  componentDidMount() {
    if (this.content_loader) {
      this.content_loader.querySelector("svg").removeAttribute("viewBox");
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let value = this.props.value || (this.input ? this.input.value : "");
    let new_state = {
      isset: Object.keys(value).length > 0 ? true : false,
      value: value,
      name: this.props.name,
      dataTeste: this.props.dataTeste,
      //   type: this.state.type || "text",
      label: this.props.label || "",
      load: this.props.load || false,
      readonly: this.props.readonly || false,
      mask: this.props.mask || false,
      id: "input_" + (this.props.id || this.props.name || this.id_input_random),
    };

    for (let i in new_state) {
      if (new_state[i] === prevState[i]) {
        delete new_state[i];
      }
    }
    if (Object.keys(new_state).length > 0) {
      this.setState(new_state);
    }
  }

  handleChange = (e) => {
    let value = e.target.value || "";
    let isset = false;
    if (Object.keys(value).length > 0) {
      isset = true;
    }
    if (this.props.type !== "checkbox") {
      if (this.state.isset !== isset) {
        this.setState({
          isset: isset,
        });
      }
    }

    if (
      this.props.inputProps &&
      this.props.inputProps.hasOwnProperty("onChange")
    ) {
      this.props.inputProps.onChange(e);
    }
  };

  togglePasswordVisibility = () => {
    this.setState(
      (prevState) => ({
        ...prevState,
        type: prevState.type === "password" ? "text" : "password",
      }),
      () => {
        this.input.focus();
      }
    );
  };

  render() {
    return (
      <div
        className={
          this.state.className.join(" ") + (this.state.isset ? " isset" : "")
        }
      >
        {!this.state.load ? (
          <div>
            {this.state.mask ? (
              <InputMask
                {...this.props.inputProps}
                mask={this.state.mask}
                ref={(el) => (this.input = el || this.input)}
                readOnly={this.state.readonly}
                type={this.state.type}
                id={this.state.id}
                data-teste={this.state.dataTeste}
                name={this.state.name}
                className={!this.state.label ? "no-label" : ""}
                onChange={(e) => this.handleChange(e)}
                defaultValue={this.state.value}
              />
            ) : (
              <>
                <input
                  {...this.props.inputProps}
                  ref={(el) => (this.input = el || this.input)}
                  readOnly={this.state.readonly}
                  type={this.state.type}
                  id={this.state.id}
                  data-teste={this.state.dataTeste}
                  name={this.state.name}
                  className={!this.state.label ? "no-label" : ""}
                  onChange={(e) => this.handleChange(e)}
                  defaultValue={this.state.value}
                />
              </>
            )}

            {this.state.label ? (
              <label
                htmlFor={this.state.id}
                dangerouslySetInnerHTML={{ __html: this.state.label }}
              />
            ) : (
              ""
            )}
            {this.props.icon && (
              <i
                className={"icon " + this.props.icon}
                onClick={() => this.input.focus()}
              />
            )}

            {this.props.passwordInput && (
              <img
                alt="eye-icon"
                src={
                  this.state.type === "password"
                    ? "https://cdn.zeplin.io/62bcba755a7dc02bd2c38941/assets/8deaed6f-35d4-4a3c-ac19-cd18b71dd51a.svg"
                    : "https://cdn.zeplin.io/62bcba755a7dc02bd2c38941/assets/e093c0d0-a4a3-4ab2-b8a3-7e1be28af4ba.svg"
                }
                onClick={this.togglePasswordVisibility}
                className="password-toggle-icon"
              />
            )}
          </div>
        ) : (
          this.loader()
        )}
      </div>
    );
  }
}

export default Input;
