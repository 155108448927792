import React, { Component } from 'react'
import ReactCrop from 'react-image-crop';
import Form from "../../utils/Form";
import Loading from "../../utils/Loading";
import alertMessageActions from "../../actions/alertMessage";
import userActions from "../../actions/user";
import store from "../../store";

class CropImage extends Component {
    constructor(props){
        super(props)

        this.state = {
            src: this.props.src,
            rotate: 0,
            croppedImage: {},
            crop: {
                aspect: 1,
                width: 99999,
                x: 0,
                y: 0,
            },
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let src = this.state.src || this.props.src;
        if(src !== prevState.src){
            this.setState({
                src: src,
                crop:{
                    aspect: 1,
                    width: 99999,
                    x: 0,
                    y: 0,
                }
            });
        }
    }

    onOpenInputFile = () => {
        if(this.refs.hasOwnProperty('input_file')){
            this.refs.input_file.click();
        }
    };

    onImageLoaded = (image, pixelCrop) => {
        this.imageRef = image;
    };

    onCropComplete = (crop, pixelCrop) => {
        this.makeClientCrop(crop, pixelCrop);
    };

    onCropChange = crop => {
        this.setState({ crop });
    };

    getCroppedImg(image, pixelCrop, fileName) {
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height,
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve({
                    url: this.fileUrl,
                    blob: blob
                });
            }, "image/jpeg");
        });
    }

    rotateImage(rotate){
        const canvas = document.createElement('canvas');
        var image =  new Image();
        image.onload = () => {
            let degrees = 0;

            canvas.width = image.width;
            canvas.height = image.height;

            switch (rotate) {
                case 1:
                    degrees = 90;
                    canvas.width = image.height;
                    canvas.height = image.width;
                break;
                case 2:
                    degrees = 180;
                break;
                case 3:
                case -1:
                    rotate = 3;
                    degrees = 270;
                    canvas.width = image.height;
                    canvas.height = image.width;
                break;
                default:
                    rotate = 0;
                break;
            }

            var ctx = canvas.getContext("2d");

            ctx.clearRect(0,0,canvas.width,canvas.height);

            //go to the center of the canvas and rotate
            ctx.translate(canvas.width/2,canvas.height/2);
            ctx.rotate(degrees*Math.PI/180);

            ctx.drawImage(image,-image.width / 2,-image.height / 2);

            this.setState({
                src: canvas.toDataURL("image/jpeg"),
                rotate: rotate
            });
        }

        image.src = this.props.src;
    }

    async makeClientCrop(crop, pixelCrop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImage = await this.getCroppedImg(
                this.imageRef,
                pixelCrop,
                'newFile.jpeg',
            );
            this.setState({ croppedImage });
        }
    }

    onSendImagem = e => {
        var data = new FormData();
        var blob = this.state.croppedImage.blob;
        data.append('imagem', blob);
        Loading.add();
        Form.send('/user/picture', data ).then((response) => {
            Loading.remove();
            let {data, status} = response;
            if(status === 200){
                store.dispatch(alertMessageActions.setAlert('green', 'Salvo com sucesso!'));
                store.dispatch(userActions.getUser(true));
                this.props.onCancel();
            }else if(data.error){
                let message = data.error.message || "Ops algo deu errado!";
                Form.showErrors(message);
            }
        });
    }

    render(){
        const {src, crop, croppedImage, rotate} = this.state;
        return(
            <div>
                <div className="control">
                    <button className="btn white cancel" onClick={() => {
                        this.rotateImage(rotate - 1)
                    }}><i className="icon-arrows-cw"/> Rodar imagem
                    </button>
                </div>
                <br/>


                <div className="row">
                    <div className="col-12 col-sm-6">
                        <div className="crop-image">
                            {src && (
                                <ReactCrop
                                    keepSelection={true}
                                    minWidth={30}
                                    minHeight={30}
                                    src={src}
                                    crop={crop}
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                />
                            )}
                        </div>
                    </div>
                    <div className="col-12 col-sm-6">
                        <div className="preview-image">
                            {croppedImage.url && (
                                <div className="image" style={{backgroundImage: "url("+croppedImage.url+")"}}></div>
                            )}
                        </div>
                    </div>
                </div>


                <div className="control">
                    <button className="btn red cancel" onClick={this.props.onCancel}>Cancelar</button>
                    <button className="btn blue confirm" onClick={this.onSendImagem}>Confirmar</button>
                </div>
            </div>
        )
    }

}

export default CropImage;