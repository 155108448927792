import React, {Component} from 'react'
import {connect} from "react-redux";
import Grid from '../../modules/Grid'

class Paypal extends Component {
    render() {
        return(
            <div className='google-container'>
                <div className='row align-content-center justify-content-center'>
                    <Grid cols='8 8 3 3'>
                        <div className='paypal_box'>
                            <h1 className='paypal_icon'>Paypal</h1>
                        </div>
                    </Grid>
                    <Grid cols='12 12 9 9'>
                        <p className='google-descr'>
                            <b>Sua assinatura no Estadão, está vinculada a sua conta PayPal</b>
                            <br />
                            <br />
                            Para gerenciar dados da sua conta, acesse seu painel de controle através do PayPal
                        </p>
                    </Grid>

                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        ...state,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.assinatura[state.assinaturas.assinatura_selecionada] : null
    }
};

export default connect(mapStateToProps)(Paypal)