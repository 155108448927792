import React, { Component } from 'react'
import Grid from '../../../modules/Grid'
import DocumentHead from '../../../../utils/DocumentHead'
import Assinatura from '../../../assinaturas'
import AlertMessage from '../../../alert-message/alertMessage'
import Titulo from "../../../modules/Titulo";
import Formulario from "./formulario";
import {connect} from "react-redux";
import FilterLinks from "../../../../utils/filterLinks";
import subscriptionActions from "../../../../actions/subscription";
import alertMessageActions from '../../../../actions/alertMessage';
import ContentLoader from "react-content-loader";

class PagarAgora extends Component {

    status = {
        EM_ANDAMENTO: 1,
        RENEGOCIADO: 2,
        APROVADO: 3,
        NOVO: 4,
        REJEITADO: 6,
    };

    forma_de_pagamento = {
        BOLETO_IMPRESSO: "D",
        BOLETO_POR_EMAIL: "Q",
        CARTAO_DE_CREDITO: "J",
        DEBITO_EM_CONTA: "W",
        CARTAO_DE_CREDITO_PAYPAL: "6",
        CARTEIRA_PAYPAL: "4",
    };

    state = {
        disabled: false
    };

    componentDidMount() {
        DocumentHead({
            title: 'Meus Pagamentos - Pagamento Online – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });

        this.componentDidUpdate();
    }

    componentWillUnmount() {
        if(this.state.disabled) {
            this.props.dispatch(alertMessageActions.removeAlert());
        }
    }

    componentDidUpdate() {
        if(!this.props.parcela_em_aberto && this.props.assinatura_selecionada){
            this.props.dispatch(subscriptionActions.consultaParcelaEmAberto(this.props.assinatura_selecionada))
        }

        let links = FilterLinks();
        if(this.props.assinatura_selecionada && !links.get('forma_de_pagamento')){
            if(window.location.href.indexOf('?t=') <= -1){
                this.props.dispatch({type: "REDIRECT", redirect: "/"});
            }
        }

        if(this.props.parcela_em_aberto){
            let disabled = !(
                (
                    [this.forma_de_pagamento.BOLETO_IMPRESSO, this.forma_de_pagamento.BOLETO_POR_EMAIL].indexOf(this.props.parcela_em_aberto.forma_pagamento) !== -1 &&
                    [this.status.EM_ANDAMENTO, this.status.NOVO, this.status.REJEITADO].indexOf(parseInt(this.props.parcela_em_aberto.status)) !== -1
                )
                    ||
                (
                    [this.forma_de_pagamento.CARTEIRA_PAYPAL, this.forma_de_pagamento.CARTAO_DE_CREDITO_PAYPAL, this.forma_de_pagamento.CARTAO_DE_CREDITO, this.forma_de_pagamento.DEBITO_EM_CONTA].indexOf(this.props.parcela_em_aberto.forma_pagamento) !== -1 &&
                    [this.status.EM_ANDAMENTO, this.status.NOVO, this.status.REJEITADO].indexOf(parseInt(this.props.parcela_em_aberto.status)) !== -1
                )
            );

            if(this.state.disabled !== disabled){
                this.setState({
                    disabled: disabled
                });

                if(disabled){
                    if(document.referrer.indexOf('historico-pagamentos') <= -1) {
                        this.props.dispatch(alertMessageActions.setAlert('blue', "Não é possivel fazer o pagamento agora.", false));
                    }
                }else{
                    this.props.dispatch(alertMessageActions.removeAlert());
                }
            }
        }
    }

    render() {
        let load = !this.props.parcela_em_aberto;

        return (
            <div className="container forma-de-pagamento justify-content-center">
                <div className="header-content row">
                    <Grid cols='12 12 7 6 6' class='text-left welcome-wrapper align-center'>
                        <Titulo groupItem="Meus pagamentos" titulo="pagar agora"/>
                    </Grid>
                    <Grid cols='12 12 5 6 6' class='text-right'>
                        <Assinatura />
                    </Grid>
                    <AlertMessage />
                </div>

                <div className="row data-form">
                    <Grid cols="12 12 12 3 3">
                        <div className="block-single text">
                            <h3>Status</h3>
                            <p>
                                Forma de Pagamento Atual:<br/>
                                {load ? (
                                    <ContentLoader
                                        height={30}
                                        width={300}
                                        style={{width: '100%', height: '30', marginTop: "2px"}}
                                        speed={1}
                                        primaryColor="#f3f3f3"
                                        secondaryColor="#ecebeb">
                                        <rect x="0" y="0" rx="5" ry="5" width="300" height="30" />
                                    </ContentLoader>
                                ) : (
                                    <b>{this.props.parcela_em_aberto.forma_pagamento_descricao}</b>
                                )}
                            </p>
                        </div>
                    </Grid>

                    <Grid cols="12 12 12 9 9" class="inner-content">
                        <Formulario disabled={load ? true : this.state.disabled} load={load} {...this.props}/>
                    </Grid>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    let parcelas_em_aberto = state.parcela_em_aberto.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.parcela_em_aberto[state.assinaturas.assinatura_selecionada] : null;
    return {
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        parcela_em_aberto: parcelas_em_aberto ? parcelas_em_aberto[Object.keys(parcelas_em_aberto)[Object.keys(parcelas_em_aberto).length - 1]] : null
    }
};

export default connect(mapStateToProps)(PagarAgora)