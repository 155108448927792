import { combineReducers, createStore, applyMiddleware } from 'redux'
import { apiMiddleware } from './middleware/api'
import { authMiddleware } from './middleware/auth'
import { asyncDispatch } from './middleware/asyncDispatch'

import utilsActions from "./actions/utils";
import userActions from "./actions/user";

import utilsReducer from './reducers/utils'
import faqReducer from './reducers/faq'
import tutoriaisReducer from './reducers/tutoriais'
import faqReducerUI from './reducers/faqUI'
import partnersReducer from './reducers/partners'
import userReducer from './reducers/user'
import newsletterReducer from './reducers/newsletter'
import aletMessageReducer from './reducers/aletMessage'
import toastReducer from './reducers/toast'
import currentURLReducer from './reducers/currentURL'
import subscriptionReducer from './reducers/subscription'


const reducers = combineReducers({
    utils: utilsReducer,
    tutoriais: tutoriaisReducer,
    faq: faqReducer,
    faqUI: faqReducerUI,
    assinatura: subscriptionReducer.assinatura,
    inadimplente: subscriptionReducer.getInadimplenteStatus,
    assinaturas: subscriptionReducer.assinaturas,
    partners: partnersReducer,
    user: userReducer.user,
    //user_sessions: userReducer.user_sessions,
    newsletter: newsletterReducer,
    alert: aletMessageReducer,
    toast: toastReducer,
    currentURL: currentURLReducer,
    subscriptionMessages: subscriptionReducer.messages,
    parcela_em_aberto: subscriptionReducer.consultaParcelaEmAberto,
    subscriptionHistoricoPagamento: subscriptionReducer.historicoPagamento,
    nfe: subscriptionReducer.buscarNFE,
    
    redirect: (state, action) => {
        return action.type === "REDIRECT" ? action.redirect : false;
    }
});

const store = createStore(reducers, applyMiddleware(apiMiddleware, authMiddleware, asyncDispatch));

const renderJornalBySize = function(){
    if(window.innerWidth >= 768){
        store.dispatch(utilsActions.getJornalDeHoje());
        window.removeEventListener('resize', renderJornalBySize);
    }
};
window.addEventListener('resize', renderJornalBySize);
renderJornalBySize();

store.dispatch(userActions.getUser());


export default store
