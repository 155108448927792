import { GET_NEWSLETTER } from '../utils/asyncActionType'

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_NEWSLETTER.SUCCESS:
        case GET_NEWSLETTER.CACHED:
            return { ...state, ...action.payload }
        default:
            return state
    }
}