import React, { Component } from 'react';
import ContentLoader from "./../../content-loader/content-loader"
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux'
import utilsActions from '../../../actions/utils'

const BlocoDeNoticiasLoader = props => (
    <ContentLoader
        height={'410'}
        width={'1000'}
        style={{width: '100%', height: '410'}}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}>
        <rect x="0" y="10" rx="5" ry="5" width="300" height="35"/>
        <rect x="0" y="65" rx="5" ry="5" width="100%" height="365"/>
    </ContentLoader>
)

class BlocoDeNoticias extends Component {
    componentDidMount() {
        this.props.dispatch(utilsActions.getUltimasNoticias());
    }

    render() {
        const content = this.props.utils.ultimas_noticias;
        const img_default = "/static/img/noticia_default.png";
        return Object.keys(content).length > 0 ? (
            <div className="block-cards-body" id="estadao-agora">
                <div className="header">
                    <h3 className="title">ESTADÃO agora</h3>
                </div>

                <div className="block">
                    <Scrollbars style={{ height: 280, right: 0,padding: "17px", margin:0}}
                                renderTrackHorizontal={({ style, ...props }) => <div {...props} style={{ ...style, display: 'none' }}/>}
                                renderThumbVertical={({ style, ...props }) => <div {...props} style={{ ...style, width: '8px', height: '150px', borderRadius: '5px', background: '#ced4da' }}/>
                                }>
                        <ul className="news">
                            {Object.keys(content).map((i, key) => {
                                let item = content[i];
                                return (
                                    <li key={key}>
                                        <a href={item.url} target="_blank" data-dtkey="news" rel="noopener noreferrer">
                                            <div className="image" style={{backgroundImage: "url("+(item.imagem ? item.imagem  : img_default)+")"}}>
                                                <img src={item.imagem ? item.imagem  : img_default} alt={item.imagem_credito} title={item.imagem_credito} />
                                            </div>
                                            <div className="text">
                                                <strong className="editoria">{item.chapeu}</strong>
                                                <p className="title">{item.titulo}</p>
                                            </div>
                                        </a>
                                    </li>
                                )
                            })}

                        </ul>
                    </Scrollbars>
                </div>
            </div>
        ) : (
            <div className="block-cards-body">
                <BlocoDeNoticiasLoader/>
            </div>
        );
    }
};

const mapStateToProps = state => {
    return state
}

export default connect(mapStateToProps)(BlocoDeNoticias)