export const APP_URL = window.location.protocol+'//qameu.estadao.com.br/app';
export const APP_ENV = 'local';
export const APP_DEBUG = false;
export const APP_DEBUG_TIMEOUT = 0;
export const API_URL = 'https://qameu.estadao.com.br/app';
export const URL_ACESSO = "https://dev.acesso.estadao.com.br";
export const URL_PORTAL_DO_ASSINANTE = "https://qa-assinante.estadao.com.br";
export const APP_ENGINE_URL = "https://estadao-gamification-qa.appspot.com/";
export const API_LOGIN =  "https://qa-api-users.estadao.com.br/";
export const API_RECOMENTATION =  "https://estadao-recommendation.appspot.com/";
export const IMG_URL =  "https://qa.img.estadao.com.br/qa/fotos/";
