export default class Events{
    constructor(){
        this.events = {};
    }

    add(key, callback){
        this.events[key] = callback;
    }

    run(key, context){
        if(this.events.hasOwnProperty(key)){
            this.events[key](context);
        }
    }

    create(callback){
        let key = Math.floor((Math.random() * 9999999) + 1);
        this.events[key] = callback;
        return "window.ESTADAO_MENU.events.run("+key+")";
    }

    clear(){
        this.events = {};
    }
}