export default {
    show(color, message, timeout = 0) {
        return {
            type: 'SET_TOAST',
            toast: {
                id: Date.now(),
                color,
                message,
                timeout: timeout !== 0 ? timeout : 5000
            }
        }
    },

    remove(id) {
        return {
            type: 'REMOVE_TOAST',
            id
        }
    }
}
