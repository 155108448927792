import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { sendGTMGA4Event } from '../../../utils/tagmanager';

window.faqContentSizes = {};

class BeforeFooter extends Component {
    state = {faq_active: false};

    sendGTMGA4Event = ({ channel }) => {        
        sendGTMGA4Event({
            event: 'meu_estadao',
            secao: 'central_de_atendimento',
            nome_campo: channel
        })
    }

    _openChat = () => {
        this.sendGTMGA4Event({ channel: 'chat' })
        window.open("https://www4.directtalk.com.br/chat31/?idd=B7C7001427B8600276A3", 'Atendimento Online - Estadão', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=500,height=600');
    }

    componentDidMount() {
        if(document.querySelector('.faq-game .close')){
            document.querySelector('.faq-game .close').addEventListener('click', (el) => {
                this.setState({
                    faq_active: false
                });
            });
        }
    }

    _openFaqGame() {

        let container = document.querySelector('.container').getClientRect();

        if (document.querySelector('.faq-game')) {
            let faqDiv = document.querySelector('.faq-game');
            faqDiv.style.width = (container.width + 2) + "px";
            faqDiv.style.left = container.x + "px";
            faqDiv.style.top = "10vh";
            faqDiv.style.display = "block";
        }


        setTimeout(() => {
            let i = 0;
            if (document.querySelectorAll('[data-content-key]').length > 0 && Object.keys(window.faqContentSizes).length <= 0) {
                [].forEach.call(document.querySelectorAll('[data-content-key]'), (content) => {

                    if (content) {
                        window.faqContentSizes[content.getAttribute('data-content-key')] = content.getClientRect().height + "px";
                    }
                    if (i !== 0) {
                        content.style.height = 0;
                    }
                    i++;
                });
                let group = document.querySelector('.faq-game .group-faq:first-child');
                group.querySelector('.title-i').setAttribute('class', 'title-i active');
            }

        }, 500)
    }

    render() {
        return (
            <div className="before-footer">
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <button onClick={this._openChat} className="item" data-dtkey="chat">
                                <i className="icon-commenting-o"/>
                                <div className="title title-mobile">
                                    Chat
                                </div>
                                <strong>Horário de atendimento</strong>
                                <p>
                                    Segunda à sexta-feira, das 7h às 18h.<br/>
                                    Sábados, domingos e feriados, das 7h às 13h.
                                </p>
                            </button>
                        </div>


                        <div className="col">
                            <a 
                                onClick={() => this.sendGTMGA4Event({ channel: 'whatsapp' })} 
                                href="https://api.whatsapp.com/send?phone=551140035323" 
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="item" data-dtkey="whatsapp">
                                    <i className="icon-whatsapp"/>
                                    <div className="title title-mobile">
                                        Whatsapp
                                    </div>
                                    <strong>Horário de atendimento</strong>
                                    <p>
                                        Segunda à sexta-feira, das 7h às 18h.<br/>
                                        Sábados, domingos e feriados, das 7h às 13h.
                                    </p>
                                </button>
                            </a>
                        </div>


                        <div className="col">
                            <Link 
                                onClick={() => this.sendGTMGA4Event({ channel: 'fale_conosco' })} 
                                to="/fale-conosco" 
                                className="item" 
                                data-dtkey="fale_conosco"
                            >
                                <i className="icon-mail"/>
                                <div className="title">
                                    Fale Conosco
                                </div>
                                <div className="title-mobile">
                                    Formulário
                                </div>
                                <strong>Envie uma mensagem</strong>
                                <p>
                                    Nosso tempo médio de resposta<br/>
                                    é de 1 dia útil.
                                </p>
                            </Link>
                        </div>
                        {(
                            <div className="col">
                                <Link 
                                    onClick={() => this.sendGTMGA4Event({ channel: 'perguntas_frequentes' })} 
                                    to="/perguntas-frequentes" 
                                    className="item" 
                                    data-dtkey="faq"
                                >
                                    <i className="icon-question-circle-o"/>
                                    <div className="title p-f">
                                        PERGUNTAS FREQUENTES
                                    </div>
                                    <div className="title-mobile">
                                        Dúvidas?
                                    </div>
                                    <strong>Saiba mais sobre sua assinatura</strong>
                                    <p>
                                    Respostas a perguntas frequentes para você conhecer melhor o
                                    Estadão.
                                    </p>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default BeforeFooter;
