import React, {Component} from 'react'
import Grid from '../modules/Grid'
import DocumentHead from '../../utils/DocumentHead'
import AlertMessage from '../alert-message/alertMessage'
import Titulo from "../modules/Titulo";
import env from "../../env";
import alertMessageActions from "../../actions/alertMessage";
import Loading from "../../utils/Loading";
import { connect } from 'react-redux';

class ValidarEmail extends Component {

    url = window.location.href;
    token = this.url.split('?token=')[1];

    emailValidateLink() {

        let xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                if(xhr.status === 200){
                    this.props.dispatch(alertMessageActions.setAlert('green', 'Seu e-mail foi validado com sucesso!', false,'/meus-dados'));
                }else{
                    this.props.dispatch(alertMessageActions.setAlert('red', 'Ops, algo de inesperado ocorreu, entre em contato com nosso SAC: 4003-5323(Capitais) ou 0800-014-7720 (Demais Localidades).', false));
                }
            }
        };
        xhr.open('GET', env.API_URL + "/utils/validate-email?token=" + this.token, false);
        xhr.send();

        setTimeout(() => {
            Loading.remove();
        }, 3000);
    }

    componentDidMount() {
        DocumentHead({
            title: 'Validação de e-mail – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });
        this.emailValidateLink();
    }

    render() {
        return (
            <div className="container validate-email justify-content-center">
                <div className="header-content row">
                    <Grid cols='12' class='text-left welcome-wrapper align-center'>
                        <Titulo groupItem="Validação de e-mail" titulo="Faça suas sugestões, dúvidas ou críticas"/>
                    </Grid>
                    <Grid cols='12'>
                        <AlertMessage/>
                    </Grid>
                </div>

                <div className="row data-form">
                    <Grid cols="12">

                    </Grid>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { state }
};
export default connect(mapStateToProps) (ValidarEmail);