import React, {Component} from 'react'
import {connect} from "react-redux";
import Input from "../../../utils/form/Input";
import Grid from "../../modules/Grid";
import Clipboard from 'react-clipboard.js';
import alertMessageActions from "../../../actions/alertMessage";
import subscriptionActions from "../../../actions/subscription";

class Nfs extends Component {
    constructor(props) {
        super(props);

        this.onSuccess = this.onSuccess.bind(this);
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    componentDidUpdate() {
        if(!this.props.nfe && this.props.assinatura_selecionada){
            this.props.dispatch(subscriptionActions.buscarNFE(this.props.assinatura_selecionada))
        }
    }

    onSuccess(){
        this.props.dispatch(alertMessageActions.setAlert('green','Chave copiada com sucesso!'));
    }

    render() {

        let load = !this.props.nfe;
        let chave = this.props.nfe ? this.props.nfe.chave : null;

        return (
          <div className='nota-fiscal'>
              <div className='nota-fiscal-wrapper'>
                  <div className='row justify-content-center'>
                      <Grid cols='11 11 8 8'>
                          <p className='descricao-nota'>
                              Para acessar sua nota fiscal, copie a chave abaixo e clique no link:
                              <br/>
                              <a href='http://www.nfe.fazenda.gov.br' target='_blank' rel='noopener noreferrer'>http://www.nfe.fazenda.gov.br</a>
                          </p>
                      </Grid>
                  </div>
                  <div className='row justify-content-center'>
                      <Grid cols='11 11 8 8'>
                          <div className='row nota-fiscal-fundo'>
                              <Grid cols='10 10 10 10 10'>
                                  <Input value={chave} load={load} name='chave' id='chave_id' readonly={true} label='Chave NF-e'/>
                              </Grid>
                              <Grid cols='2 2 2 2 2'>
                                  <Clipboard data-clipboard-text={chave} className='btn btn-copy' onSuccess={this.onSuccess}>
                                      <i className='icon icon-docs'> </i>
                                  </Clipboard>
                              </Grid>
                          </div>
                          <div className='row ajustes-btn hidden-xs hidden-sm'>
                              <Grid cols='12'>
                                  <a href='http://www.nfe.fazenda.gov.br/' target='_blank' rel='noopener noreferrer' className='btn float-right'><i className='icon icon-link-ext' /> Ir para o Portal da Nota Fiscal</a>
                              </Grid>
                          </div>
                      </Grid>
                  </div>
              </div>
              <div className='row espacamento-btn justify-content-end d-sm-block d-xs-block d-md-none d-xl-none d-lg-none '>
                  <Grid cols='12'>
                      <a href='http://www.nfe.fazenda.gov.br/' target='_blank' rel='noopener noreferrer' className='btn float-right'><i className='icon icon-link-ext' /> Ir para o Portal da Nota Fiscal</a>
                  </Grid>
              </div>
          </div>


        )
    }
}


const mapStateToProps = state => {
    return {
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        nfe: state.nfe.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.nfe[state.assinaturas.assinatura_selecionada] : null,
    }
};

export default connect(mapStateToProps)(Nfs)