import React, {Component} from 'react'
import Grid from '../../modules/Grid'
import alertMessageActions from "../../../actions/alertMessage";

class GoogleSubscribe extends Component {

    componentDidMount() {
        this.props.dispatch(alertMessageActions.setAlert('blue', 'Sua assinatura foi feita via Google',false));
    }

    componentWillUnmount() {
        this.props.dispatch(alertMessageActions.removeAlert());
    }

    render() {
        return(
          <div className='google-container'>
              <div className='row align-content-center justify-content-center'>
                  <Grid cols='8 8 3 3'>
                      <div className='google-icon'>
                        <div>
                            <span className='google-text color-1'>G</span>
                            <span className='google-text color-2'>o</span>
                            <span className='google-text color-3'>o</span>
                            <span className='google-text color-1'>g</span>
                            <span className='google-text color-4'>l</span>
                            <span className='google-text color-2'>e</span>
                        </div>
                      </div>
                  </Grid>
                  <Grid cols='12 12 9 9'>
                      <p className='google-descr bold'>
                          Sua assinatura do Estadão está vinculada à sua conta Google.
                      </p>
                      <p className='google-descr'>
                          Para desvincular, exclua o 'Estadão' na lista de contas vinculadas <a href="https://myaccount.google.com/connections?hl=pt_BR&utm_source=OGB&utm_medium=act" target="_blank" rel="noopener noreferrer">aqui</a>.
                      </p>
                      <p className='google-descr'>
                          Após esta ação, basta retornar à home e entrar com seu login e senha. Assim você terá acesso às suas informações originais e poderá editá-las no 'Meu Estadão'.
                      </p>
                      <p className='google-descr'>
                          Se a sua assinatura estiver vinculada a uma conta Apple, <a href="https://support.apple.com/pt-br/102571" target="_blank" rel="noopener noreferrer">clique aqui</a> e saiba mais.
                      </p>
                  </Grid>

              </div>
          </div>
        )
    }
}

export default GoogleSubscribe;