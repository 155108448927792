import React, { Component } from 'react'
import DocumentHead from '../../utils/DocumentHead'
import { Link } from 'react-router-dom'

class NotFound extends Component {
    componentDidMount() {
        DocumentHead({
            title: 'Ops, página não encontrada!',
            description: 'Painel do Estadão'
        })
    }

    render() {
        return (
            <div className="container wrapper-404">
                <div className="row">
                    <div className="col">
                        <div className="icon" />

                        <h1>Desculpe, página não encontrada.</h1>
                        <h2>A página que você tentou acessar não existe ou foi removida</h2>

                        <Link to="/" className="btn blue">
                            Voltar para Home
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default NotFound
