import Iframe from './iframe'
import configActions from './actions/config';
import userActions from "./actions/user";
import store from "./store";
import Events from './events';

export default class Menu {

    constructor(before_init = []) {
        this.events = new Events();
        this.config = {};
        this.iframe_append_scripts = [];
        for (let i in before_init) {
            if (before_init.hasOwnProperty(i)) {
                this.push(before_init[i]);
            }
        }
    }

    push(run_function) {
        if (typeof run_function === 'function') {
            run_function(this);
        }
    }

    init(config = {}) {
        this.config = {...this.config, ...config};
        configActions.setConfig(this.config);
        if (this.config.hasOwnProperty('user')) {
            store.dispatch({type: "SET_USER", payload: {...this.config.user}})
        }

        if (!this.iframe) {
            this.iframe = new Iframe(this.iframe_append_scripts);
            this.iframe_append_scripts = [];
        }
    }

    open() {
        if (!this.iframe) {
            this.init();
        }
        this.iframe.open();
    }

    openInSilence() {
        let state = store.getState();
        if (state.config && !state.config.hasOwnProperty('user')) {
            userActions.getUser();
        }
        this.init();
    }

    close() {
        if (!this.iframe) {
            this.init();
        }
        this.iframe.close();
    }

    toggle() {
        if (!this.iframe) {
            this.init();
        }
        this.iframe.toggle();
    }

    appendScript(script) {
        if (!this.iframe) {
            (this.iframe_append_scripts = this.iframe_append_scripts || []).push(script);
        } else {
            this.iframe.append_scripts.push(script);
        }
    }

}