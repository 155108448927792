import React, {Component} from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import store from '../../../store';
import alertMessage from '../../../actions/alertMessage';
import Grid from "../../modules/Grid";
import {Input} from "../../../utils/Form";


const WEEKDAYS_SHORT = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];
const WEEKDAYS_LONG = ['Domingo', 'Segunda', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'];
const MONTHS = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const formatDate = (date) => {
    let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    let month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    return day + "/" + month + "/" + date.getFullYear();
};

const parseDate = (date) => {
    let parts = date.split('/');
    if (parts.length === 3 && parseInt(parts[2]) > 1000 && parseInt(parts[1]) > 0 && parseInt(parts[0]) > 0)
        return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
};

class InputCalendar extends Component {
    render(){
        let props = {...this.props};
        delete props.value;
        return (<Input ref={el => this.input = el || this.input} readonly={true} {...this.props} inputProps={props} icon="icon-calendar" />);
    }
}


class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            from: "",
            to: "",
            disable_day: (new Date()).addDays(2, {uteis: true})
        };

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    }

    removeError(){
        let elements = document.querySelectorAll('#form-interrupcao-temporaria [data-error]');
        for(let i in elements){
            if(elements.hasOwnProperty(i)){
                elements[i].removeAttribute('data-error');
            }
        }
    }

    handleFromChange(from) {
        this.removeError();
        const {to} = this.state;
        this.setState({
            from: from,
            to: to > from ? to : ""
        });
    }

    handleToChange(to) {
        this.removeError();
        const {from, disable_day} = this.state;
        if (from && to >= from) {
            let maxDay = (new Date(from.getFullYear(), from.getMonth(), from.getDate())).addDays(30);

            let minDay = (new Date(from.getFullYear(), from.getMonth(), from.getDate())).addDays(1);

            if (to < minDay){
                window.scrollTo(0, 0);
                store.dispatch(alertMessage.setAlert('red', 'Data selecionada fora do período mínimo de 2 dias, selecione outra data.', 8000));
                this.setState({to: ""}, () => {
                    setTimeout(() => {
                        this.to.showDayPicker();
                    }, 100);
                });
                return;
            } else if (to > maxDay) {
                window.scrollTo(0, 0);
                store.dispatch(alertMessage.setAlert('red', 'Data selecionada fora do período máximo de 30 dias, selecione outra data.', 8000));
                this.setState({to: ""}, () => {
                    setTimeout(() => {
                        this.to.showDayPicker();
                    }, 100);
                });
                return;
            }
            store.dispatch(alertMessage.removeAlert());
            this.setState({to});
        } else if(to){
            this.setState({from: to >= disable_day ? to : "", to: ""}, () => {
                setTimeout(() => {
                    this.to.showDayPicker();
                }, 100);
            });
        }
    }

    handleDayMouseEnter(day, e) {
        const {from} = this.state;
        if (!e.disabled && from && from < day) {
            let maxDay = new Date(from.getFullYear(), from.getMonth(), from.getDate());
            maxDay.setHours(720);
            if (day < maxDay) {
                this.setState({
                    to: day
                })
            }
        }
    }

    render() {
        const {from, to, disable_day} = this.state;
        let modifiers_from = {
            start: from,
            end: to
        };
        let modifiers_to = {...modifiers_from};
        if (from) {
            let maxDay = new Date(from.getFullYear(), from.getMonth(), from.getDate());
            maxDay.setHours(696);
            modifiers_to.markGreen = maxDay;
        }
        return (
            <div className="row">
                <style>{`
                    .DayPicker-Day--markGreen{
                        position: relative;
                    }
                    .DayPicker-Day--markGreen:not(.DayPicker-Day--outside):before{
                        content: "MAX";
                        font-size: 6px;
                        position: absolute;
                        top: 7px;
                        right: 0;
                        width: 100%;
                        text-aling: center;
                    }
                    .DayPicker-interrupcao-temporaria{
                        position: absolute;
                        top: calc(100% + 2px);
                        left: 0;
                        z-index: 99999
                    }
                `}</style>
                <Grid cols="12 12 6 6 6">
                    <div className="calendar">
                        <DayPickerInput
                            classNames={{
                                'container': 'input-control-calendar',
                                'overlayWrapper': 'DayPicker-interrupcao-temporaria',
                                'overlay': ""
                            }}
                            component={InputCalendar}
                            placeholder={""}
                            ref={el => (this.from = el ? el : this.from)}
                            value={from}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                                className: "Range",
                                weekdaysShort: WEEKDAYS_SHORT,
                                weekdaysLong: WEEKDAYS_LONG,
                                months: MONTHS,
                                selectedDays: [from, {from, to}],
                                disabledDays: {before: disable_day},
                                fromMonth: disable_day,
                                month: disable_day,
                                modifiers: modifiers_from,
                                onDayClick: () => {this.from.getInput().input.input.trigger('change') && this.to.getInput().input.input.focus()},
                            }}
                            inputProps={{
                                type: "text",
                                name: "dt_inicio",
                                label: '* Data inicial:',
                            }}
                            onDayPickerShow={() => {this.to.hideDayPicker()}}
                            onDayChange={this.handleFromChange}
                        />
                    </div>
                </Grid>
                <Grid cols="12 12 6 6 6">
                    <div className="calendar">
                        <DayPickerInput
                            classNames={{
                                'container': 'input-control-calendar',
                                'overlayWrapper': 'DayPicker-interrupcao-temporaria',
                                'overlay': ""
                            }}
                            component={InputCalendar}
                            placeholder={""}
                            ref={el => {this.to = el ? el : this.to}}
                            value={to}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            hideOnDayClick={to !== ""}
                            dayPickerProps={{
                                className: "Range",
                                weekdaysShort: WEEKDAYS_SHORT,
                                weekdaysLong: WEEKDAYS_LONG,
                                months: MONTHS,
                                selectedDays: [from, {from, to}],
                                disabledDays: {before: disable_day},
                                modifiers: modifiers_to,
                                month: from,
                                fromMonth: disable_day,
                                onDayMouseEnter: this.handleDayMouseEnter,
                                onDayClick: () => { this.to.getInput().input.input.trigger('change') && this.to.hideDayPicker()}
                            }}
                            inputProps={{
                                type: "text",
                                name: "dt_fim",
                                label: '* Data final:'
                            }}
                            onDayPickerShow={() => this.from.hideDayPicker()}
                            onDayChange={this.handleToChange}
                        />
                    </div>
                </Grid>
            </div>
        );
    }
}

export default Calendar;