import React, { Component } from 'react';
import JornalDeHoje from '../../newspaper/newspaper';
//import { Link } from 'react-router-dom'
//import Menu from "../../menu/menu";
import BeforeFooter from './before-footer';
//import MenuPage from "../../menu/MenuPage";

class Footer extends Component {
    render() {
        let copyright = "Grupo Estado © 1995-"+((new Date()).getFullYear())+". Todos os direitos reservados.";
        return (
            <div ref="html">
                <BeforeFooter/>
                <footer>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-3 footer-brand-wrapper">
                                <a href="https://www.estadao.com.br/" title="Estadão - Portal do Estado de S. Paulo">
                                    <h1 className="logo">Estadão - Portal do Estado de S. Paulo</h1>
                                </a>
                                <p className="copyright">{copyright}</p>
                            </div>

                            <div className="col-md-12 col-lg-6">
                                <div className="sac-block">
                                    <h3>Central de Atendimento:</h3>
                                    <ul>
                                        <li><b>4003-5323</b> (Capitais e Regiões Metropolitanas)</li>
                                        <li><b>0800-014-7720</b> (Demais Localidades)</li>
                                    </ul>

                                    <h3>Horário de atendimento:</h3>
                                    <ul>
                                        <li>Seg a Sex, das 6h30 às 18h</li>
                                        <li>Sáb, Dom e Feriados, das 6h30 às 13h</li>
                                    </ul>
                                </div>

                                <div className="menu">
                                    <div className="menu-content">
                                        <ul>
                                            <li>
                                                <a href="https://www.estadao.com.br/termo-de-uso" target="_blank" rel="noopener noreferrer">Termos de uso</a>
                                            </li>
                                            <li>
                                                <a href="https://www.estadao.com.br/codigo-etica/codigo-de-etica.pdf" target="_blank" rel="noopener noreferrer">Código de ética</a>
                                            </li>
                                            <li>
                                                <a href="https://www.estadao.com.br/politica-anticorrupcao/politica-anticorrupcao.pdf" target="_blank" rel="noopener noreferrer">Política anticorrupção</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 copyright-mobile">
                                <p className="copyright">{copyright}</p>
                            </div>

                            <div className="col-12 col-sm-12 col-md-3 text-right footer-newspaper-wrapper">
                                <JornalDeHoje classExtra="footer" dtkey="jornal_digital_rodape"/>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }
}

export default Footer;
