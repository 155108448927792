
class DocumentHead {
    constructor(data){
        const {
            title = "",
            description = "",
            canonical = true,
            pageview = true,
            dataLayerEstadao = true
        } = data;

        if(title){
            this.setTitle(title);
        }

        if(description){
            this.setDescription(description);
        }

        if(canonical){
            this.setCanonical();
        }

        if(dataLayerEstadao){
            let dataLayer = {
                title: title,
                description: description
            };

            if(typeof dataLayerEstadao === 'object'){
                Object.assign(dataLayer, dataLayerEstadao);
            }

            this.setDataLayerEstadao(dataLayer);
        }

        if(pageview){
            //this.setCanonical();
        }
    }

    setTitle = (title) => {
        let titleHtml = document.head.querySelector('title');
        if(titleHtml){
            titleHtml.innerHTML = title;
        }
    };

    setDescription = (description) => {
        let meta = document.head.querySelector('meta[name="description"]');
        if(meta){
            meta.setAttribute('content',description);
        }else{
            meta = document.createElement('meta');
            meta.name = "description";
            meta.content = description;
            document.head.appendChild(meta);
        }
    };

    setCanonical = () => {
        let link = document.head.querySelector('link[rel="canonical"]');
        if(link){
            link.setAttribute('href',window.location.href);
        }else{
            link = document.createElement('link');
            link.rel = "canonical";
            link.href = window.location.href;
            document.head.appendChild(link)
        }
    }

    setDataLayerEstadao = (data) => {
        /*
        let dataLayerEstadao = document.head.querySelector('#dataLayerEstadao');
        if(dataLayerEstadao){
            dataLayerEstadao.remove();
        }
/*
        dataLayerEstadao = document.createElement('script');
        dataLayerEstadao.type = "text/javascript";
        dataLayerEstadao.id = "dataLayerEstadao";
        dataLayerEstadao.innerText = "let dataLayerEstadao = "+JSON.stringify([data])+";";
        document.head.appendChild(dataLayerEstadao);
        */
    }
}

const DocumentHeadFactor = (data) => {
    return new DocumentHead(data);
};

export default DocumentHeadFactor;