export default {
    get : function(c_name){
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x === c_name) {
                return decodeURIComponent(y);
            }
        }
    },

    set : function(c_name, value, exdays) {
        var domain = document.domain.split('.');
        while (domain.length > 3){
            domain.shift();
        }
        domain = domain.join('.');

        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = encodeURIComponent(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value + "; domain=."+domain+"; path=/"+(document.location.protocol === 'https:' ? '; SameSite=None; Secure' : '');
    }
};