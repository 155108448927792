export default {
    add(css_selector){
        let div = document.createElement('div');
        div.id = "loading-container";
        div.innerHTML = '<div class="loading"><div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>';

        if(!document.querySelector('.loading')){
            document.querySelector(css_selector || 'body').appendChild(div);
        }
    },
    remove(){
        let loading = document.querySelector('#loading-container');
        if(loading){
            loading.remove();
        }
    }
};