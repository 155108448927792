import React, { Component } from "react";
import Grid from "../../modules/Grid";
import DocumentHead from "../../../utils/DocumentHead";
import Assinatura from "../../assinaturas";
import AlertMessage from "../../alert-message/alertMessage";
import Titulo from "../../modules/Titulo";
import FotoUsuario from "../foto-usuario";
import Formulario from "./formulario";
import FormularioSocial from "./formulario-social";
import Paypal from "../../meus-pagamentos/historico-pagamento/paypal";
import Google from "../../meus-pagamentos/historico-pagamento/google-subscribe";
import Apple from "../../meus-pagamentos/historico-pagamento/apple";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Facebook from "../../meus-pagamentos/historico-pagamento/facebook";
import GoogleSubscribe from "../../meus-pagamentos/historico-pagamento/google-subscribe";
import subscriptionActions from "../../../actions/subscription";

class AlterarEmail extends Component {
  componentDidMount() {
    DocumentHead({
      title: "AlterarEmail – meuEstadão | Portal do Assinante",
      description: "Painel do Estadão",
    });
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (!this.props.assinatura_dados && this.props.assinatura_selecionada.assinatura_selecionada) {
      this.props.dispatch(subscriptionActions.getDados(this.props.assinatura_selecionada.assinatura_selecionada))
    }
  }

  // Method to render account type form

  accountType() {
    let type = "normal";
    let signatureType  = null;
    if (this.props.assinatura_dados) {
      if (this.props.assinatura_dados.tipo_publicacao === "SWG") {
        type = "google";
      } else if (this.props.assinatura_dados.tipo_publicacao === "APPL") {
        type = "apple";
      } else if (this.props.assinatura_dados.tipo_publicacao === "PAY") {
        type = "paypal";
      }
      signatureType = this.props.assinatura_dados.tipo_publicacao;
    }

    if (this.props.user.origem) {
      if (this.props.user.origem.substring(0, 6) == "GOOGLE") {
        type = "google";
      }
    }
    if (this.props.user.origem == "GoogleSubscribe") {
      type = "google";
    } else if (this.props.user.origem == "FACEBOOK") {
      type = "facebook";
    } else if (this.props.user.origem == "Apple") {
      type = "apple";
    }

    switch (type) {
      case "google":
        return (
          <GoogleSubscribe
            signatureType = {signatureType}
          />
        );
        break;
      case "paypal":
        return (
          <a href="https://paypal.com" target="_blank" className="a-non-d">
            <Paypal />
          </a>
        );
        break;
      case "apple":
        return (
          <Apple
            signatureType = {signatureType}
          />
        );
        break;

      case "facebook":
        return <FormularioSocial socialMediaType={type} />;
        break;
      case "normal":
        return (
          <div className="row data-user">
            <Grid cols="12 12 12 4 4">
              <FotoUsuario />
            </Grid>
            <Grid cols="12 12 12 8 8">
              <Formulario />
            </Grid>
          </div>
        );
        break;
    }
  }

  render() {
    return (
      <div className="container meus-dados justify-content-center">
        <div className="header-content row">
          <Grid
            cols="12 12 12 6 6"
            class="text-left welcome-wrapper align-center"
          >
            <Titulo groupItem="Meus dados" titulo="alterar email" />
          </Grid>
          {/* <Grid cols='12 12 12 6 6' class='text-left text-right hidden-xs hidden-sm hidden-md'>
                        <Assinatura />
                    </Grid> */}
          <AlertMessage />
        </div>

        {this.accountType()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.user,
    assinatura_selecionada: state.assinaturas,
    assinatura_dados: state.assinatura.hasOwnProperty(
      state.assinaturas.assinatura_selecionada
    )
      ? state.assinatura[state.assinaturas.assinatura_selecionada].assinatura
      : null,
    ...state,
  };
};

export default connect(mapStateToProps)(AlterarEmail);
