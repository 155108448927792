import env from '../env'
import { GET_PARTNERS } from '../utils/asyncActionType'

const INITIAL_STATE = {
    partners: []
}

export default (state = INITIAL_STATE, action) => {
    if (env.APP_DEBUG) {
        console.log('partnersReducer :: state ::', state)
        console.log('partnersReducer :: INITIAL_STATE ::', INITIAL_STATE)
        console.log('partnersReducer :: action ::', action)
    }

    switch (action.type) {
        case GET_PARTNERS.SUCCESS:
        case GET_PARTNERS.CACHED:
            return { ...state, ...action.payload }
        case GET_PARTNERS.PENDING:
        case GET_PARTNERS.FAILURE:
        default:
            return state
    }
}