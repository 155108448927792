import env from '../env'
import axios from 'axios'
import Input from './form/Input'
import Select from './form/Select'
import Radio from './form/Radio'
import Checkbox from './form/Checkbox'
import Textarea from './form/Textarea'
import InputPhone from './form/InputPhone'
import InputCustomDate from './form/InputCustomDate'
import InputDate from './form/InputDate'
import InputCPF from './form/InputCPF'
import InputCEP from './form/InputCEP'
import InputCNPJ from './form/InputCNPJ'
import File from './form/File'
import alertMessageActions from "../actions/alertMessage";
import store from '../store';
const Form = {
    clearErrors(){
        let element_list = document.querySelectorAll('[data-error]');
        for(let i in element_list){
            if(element_list.hasOwnProperty(i) && typeof element_list[i].removeDataError === 'function'){
                element_list[i].removeDataError();
            }
        }
    },
    showErrors(error_list, timeout = 10000){
        let alertMessage = false;
        if(typeof error_list === 'string'){
            alertMessage = error_list;
        }else{
            for(let i in error_list){

                let message;
                if(typeof error_list[i] === 'string'){
                    message = [error_list[i]];
                }else{
                    message = error_list[i];
                }

                let name = i;
                if(name.indexOf(".") !== -1){
                    let name_split = name.split(".");
                    let add = "[";
                    name = "";
                    // eslint-disable-next-line
                    name_split.map(function(value, i){
                        name += value + ((name_split.length - 1) === i ? "]" : add);
                        add = add === "[" ? "][" : "[";
                    });
                }

                let element_list = document.querySelectorAll('[name="'+name+'"]');

                /* eslint-disable no-loop-func */
                if(element_list){
                    let element;
                    for(let ei in element_list){
                        if(element_list.hasOwnProperty(ei)){
                            element = element_list[ei];
                            let tagName = element.tagName.toLocaleLowerCase();
                            let type = element.getAttribute('type');
                            let elementOriginal = element;

                            if(element.closest('.input-control')){
                                element = element.closest('.input-control');
                                if(element.querySelector("label")){
                                    let label = element.querySelector("label").innerText.toLocaleLowerCase();
                                    // eslint-disable-next-line
                                    message.map(function(m,m_i){
                                        message[m_i] = message[m_i].replace(i.replace(/_/g, " "), label);
                                    });
                                }
                            }

                            element.setAttribute('data-error', message.join("\n"));
                            let removeDataError;

                            switch (tagName) {
                                case "input":
                                case "textarea":
                                    switch (type) {
                                        default:
                                            removeDataError = function(){
                                                element_list = document.querySelectorAll('[name="'+name+'"]');
                                                for(let ei in element_list){
                                                    if(element_list.hasOwnProperty(ei)){
                                                        element_list[ei].closest('.input-control') &&
                                                            element_list[ei].closest('.input-control').removeAttribute('data-error');

                                                        element_list[ei].removeEventListener('keypress', removeDataError);
                                                        element_list[ei].removeEventListener('change', removeDataError);
                                                    }
                                                }
                                            };
                                            elementOriginal.addEventListener('keypress', removeDataError);
                                            elementOriginal.addEventListener('change', removeDataError);
                                            element.removeDataError = removeDataError;
                                        break;
                                    }
                                break;
                                case "select":
                                    removeDataError = function(){
                                        element_list = document.querySelectorAll('[name="'+name+'"]');
                                        for(let ei in element_list){
                                            if(element_list.hasOwnProperty(ei)){
                                                element_list[ei].closest('.input-control') &&
                                                element_list[ei].closest('.input-control').removeAttribute('data-error');

                                                element_list[ei].removeEventListener('change', removeDataError);
                                            }
                                        }
                                    };
                                    elementOriginal.addEventListener('change', removeDataError);
                                    element.removeDataError = removeDataError;
                                break;
                                // no default
                            }
                        }
                    }
                }
                /* eslint-enable no-loop-func */

                if(!alertMessage){
                    alertMessage = message;
                }
            }
        }
        if(alertMessage){
            store.dispatch(alertMessageActions.setAlert('red', alertMessage, timeout));
        }
    },

    objectToFormData(obj, form, namespace) {
        let fd = form || new FormData();
        let formKey;
        for(let property in obj) {
            if(obj.hasOwnProperty(property)) {
                if(namespace) {
                    formKey = namespace + '[' + property + ']';
                } else {
                    formKey = property;
                }
                // if the property is an object, but not a File,
                // use recursivity.
                if(typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                    this.objectToFormData(obj[property], fd, property);
                } else {
                    // if it's a string or a File object
                    fd.append(formKey, obj[property]);
                }
            }
        }
        return fd;
    },

    send(url, data) {
        this.clearErrors();

        let bodyFormData = data instanceof FormData ? data : new FormData();
        if(!(data instanceof FormData) && typeof data === 'object'){
            bodyFormData = this.objectToFormData(data, bodyFormData)
        }
        return axios.post(env.API_URL + url, bodyFormData, {
            headers: {
                "Content-type": "application/x-www-form-urlencoded"
            },
            withCredentials: true,
            validateStatus: function (status) {
                return true;
            }
        });
    }
};

export {Form, Select, Input, Radio, Textarea, Checkbox, InputPhone, InputDate, InputCPF,InputCNPJ, InputCEP, File, InputCustomDate};
export default Form;
