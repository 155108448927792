import { API, API_LOGIN, GET_NEWSLETTER } from '../utils/asyncActionType'

export default {
    getNewsletter(enable_reload) {
        return {
            type: API,
            payload: {
                enable_reload: enable_reload,
                url: '/user/newsletter',
                cache: 1440,
                ...GET_NEWSLETTER
            }
        }
    },    
    getNewsletterAnonimo(enable_reload) {
        return {
            type: API,
            payload: {
                enable_reload: enable_reload,
                url: '/anonimo/newsletter-list',
                cache: 1440,
                ...GET_NEWSLETTER
            }
        }
    },    
    getNewsletterChave($chave) {
        return {
            type: API,
            payload: {
                enable_reload: true,
                url: '/anonimo/newsletter-list/' + $chave,
                cache: 1440,
                ...GET_NEWSLETTER
            }
        }
    }
}