import React, { Component } from 'react'
import { connect } from 'react-redux'

import Grid from '../../modules/Grid'
import DocumentHead from '../../../utils/DocumentHead'
import Assinatura from '../../assinaturas'
import AlertMessage from '../../alert-message/alertMessage'
import Titulo from "../../modules/Titulo";
import FotoUsuario from "../foto-usuario";
import Formulario from "./formulario";
import FormularioAssinante from "./formulario-assinante";
import FilterLinks from "../../../utils/filterLinks";

class Endereco extends Component {

    componentDidMount() {
        DocumentHead({
            title: 'Endereço – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });
    }

    componentDidUpdate() {
        let links = FilterLinks();
        if(!links.get('endereco')){
            this.props.dispatch({type: "REDIRECT", redirect: "/"});
        }
    }

    render() {
        return (
            <div className="container meus-dados justify-content-center">
                <div className="header-content row">
                    <Grid cols='12 12 12 6 6' class='text-left welcome-wrapper align-center'>
                        <Titulo groupItem="Meus dados" titulo="endereço"/>
                    </Grid>
                    <Grid cols='12 12 12 6 6' class='text-left text-right hidden-xs hidden-sm hidden-md'>
                        <Assinatura />
                    </Grid>
                    <AlertMessage />
                </div>

                <div className="row data-user">
                    <Grid cols="12 12 12 4 4">
                        <FotoUsuario/>
                    </Grid>
                    <Grid cols="12 12 12 8 8">
                        {this.props.usuario.id && this.props.usuario.assinante && !this.props.usuario.processando ? (
                            <FormularioAssinante/>
                        ) : (
                            <Formulario/>
                        )}
                    </Grid>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        usuario: state.user,
        assinaturas: state.assinaturas
    }
}

export default connect(mapStateToProps)(Endereco)
