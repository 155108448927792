import React, { Component } from "react";
import ContentLoader from "./../../content-loader/content-loader";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import FilterLinks from "../../../utils/filterLinks";
import { sendGTMGA4Event } from "../../../utils/tagmanager";

const AtividadesLoader = (props) => (
  <ContentLoader
    height={"280"}
    width={"1000"}
    style={{ width: "100%", height: "280" }}
    speed={1}
    primaryColor="#f3f3f3"
    secondaryColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="10" rx="5" ry="5" width="300" height="35" />
    <rect x="0" y="65" rx="5" ry="5" width="100%" height="225" />
  </ContentLoader>
);

class Atividades extends Component {
  objetivos() {
    let links = FilterLinks();
    let objetivos = {
      dados_cadastrais: {
        icon: "icon-user-circle-o",
        text: "Atualizar meu perfil",
        url: links.get("meu_perfil"),
      },
      newsletter: {
        icon: "icon-mail",
        text: "Gerenciar Newsletters",
        url: links.get("newsletter"),
      },
      convites: {
        icon: "icon-user-plus",
        text: "Convidar amigos",
        url: links.get("convites"),
      },
      tutoriais: {
        icon: "icon-book",
        text: "Ver tutoriais",
        url: links.get("tutoriais"),
      },
      alterar_plano: {
        icon: "icon-up-circled",
        text: this.props.user.assinante
          ? "Alterar plano de assinatura"
          : "Adquirir plano de assinatura",
        url:
          this.props.user.assinante || this.props.user.block
            ? false
            : `https://assine.estadao.com.br/landingpage?referrer_url=${
                window.location.href
              }`,
        _blank: !this.props.user.assinante,
      },
    };

    return objetivos;
  }

  sendGTMGA4Event = ({ activity }) => {
    sendGTMGA4Event({
      event: "meu_estadao",
      secao: "minha_lista_atividades",
      nome_campo: activity,
    });
  };

  mostrarNoticiasSalvas = () => {
    if (!window) {
      return;
    }

    if (!window.ESTADAO_MENU) {
      return;
    }

    if (!window.ESTADAO_MENU.events) {
      return;
    }

    if (!window.ESTADAO_MENU.events.events) {
      return;
    }

    const events = window.ESTADAO_MENU.events.events;
    const runID = Object.keys(events).filter(
      (key) => events[key].name === "Notícias salvas"
    );

    if (runID.length === 0) {
      return;
    }

    window.ESTADAO_MENU.events.run(runID);

    this.sendGTMGA4Event({ activity: "noticias_salvas" });
  };

  render() {
    let objetivos = this.objetivos();
    const selecionada = this.props.selecionada;
    const assinaturas = this.props.assinaturas;
    const assinatura = assinaturas.hasOwnProperty(selecionada)
      ? assinaturas[selecionada]
      : { config: { type: "DIG" } };

    return assinatura || (this.props.user.id && !this.props.user.assinante) ? (
      <div className="block-cards-body" id="atividades-container">
        <div className="header">
          <h3 className="title">Minha lista de atividades</h3>
        </div>

        <div className="block">
          <ul className="links">
            {Object.keys(objetivos).map((i, key) => {
              let item = objetivos[i];
              let dtkey = "atividades_" + i;
              let disabled = !item.url;
              let gaLabel = i;
              return (
                <li key={key} className={item.url ? "" : "disabled"}>
                  {item._blank && item.url ? (
                    <a
                      href={item.url}
                      onClick={() =>
                        this.sendGTMGA4Event({ activity: gaLabel })
                      }
                      data-dtkey={disabled ? "" : dtkey}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={item.icon} />
                      <span>{item.text}</span>
                    </a>
                  ) : (
                    <Link
                      data-dtkey={disabled ? "" : dtkey}
                      to={typeof item.url === "string" ? item.url : "#"}
                      onClick={(e) => {
                        if (!item.url) {
                          e.preventDefault();
                          return false;
                        }
                        this.sendGTMGA4Event({ activity: gaLabel });
                      }}
                    >
                      <i className={item.icon} />
                      <span>{item.text}</span>
                    </Link>
                  )}
                </li>
              );
            })}
            <li className={this.props.user.id ? "" : "disabled"}>
              <a
                href="#"
                onClick={
                  this.props.user.id ? this.mostrarNoticiasSalvas : () => {}
                }
              >
                <i className="icon-bookmark-o" />
                <span>Notícias salvas</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    ) : (
      <div className="block-cards-body">
        <AtividadesLoader />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    assinaturas: state.assinaturas.list,
    selecionada: state.assinaturas.assinatura_selecionada,
  };
};

export default connect(mapStateToProps)(Atividades);
