import React, { Component } from 'react'
import Grid from "../../modules/Grid";
import {Input} from "../../../utils/Form";

class DebitoEmConta extends Component {

    constructor(props){
        super(props);

        this.changeBanco = this.changeBanco.bind(this);
    }

    state = {
        banco: null
    };

    changeBanco(e){
        let banco = e.currentTarget.value;
        if(document.querySelector('[data-error]')){
            [].forEach.call(document.querySelectorAll('[data-error]'), element => {
                element.removeAttribute('data-error');
            })
        }
        if(this.state.banco !== banco){
            this.setState({
                banco: banco
            });
        }
    }

    render(){
        let lista_bancos = ["bancobrasil", "bradesco", "itau", "santander"];
        return (
            <div className="direct-debit">
                <form id="form-alterar-forma-de-pagamento-debito-em-conta" action="" method="POST" className="content-form">
                    <input type="hidden" name="forma_pagamento_para" value="W" />
                    <div className="row">
                        <Grid cols="12">
                            <ul className="lista-de-bancos">
                                {lista_bancos.map((banco, key) => {
                                    return (
                                        <li key={key}>
                                            <div className="input-brand">
                                                <input type="radio" name="banco[codigo]" readOnly={this.props.disabled} checked={this.state.banco === banco} value={banco} onChange={this.changeBanco} />
                                                <i className="brand" />
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </Grid>
                    </div>
                    {this.state.banco ? (
                        <div>
                            <br/>
                            <div className="row">
                                <Grid cols="6 3 3 3 3">
                                    <Input readonly={this.props.disabled} name="banco[agencia][numero]" label="Agência" />
                                </Grid>

                                <Grid cols="6 3 3 3 3">
                                    <Input readonly={this.props.disabled} name="banco[agencia][digito]" label="Dígito" />
                                </Grid>

                                <Grid cols="6 3 3 3 3">
                                    <Input readonly={this.props.disabled} name="banco[conta][numero]" label="Conta" />
                                </Grid>

                                <Grid cols="6 3 3 3 3">
                                    <Input readonly={this.props.disabled} name="banco[conta][digito]" label="Dígito" />
                                </Grid>
                            </div>
                            <div className="row active-banco">
                                <Grid cols="12">
                                    <p>Obs: não pode ser conta poupança</p>
                                    <p><b>Importante:</b> o débito automático, do banco selecionado, necessita de autorização.</p>
                                    <p>O desbloqueio pode ser feito nos canais de atendimento, junto ao seu banco, antes da data do débito.</p>
                                </Grid>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <p>Obs: não pode ser conta poupança</p>
                        </div>
                    )}
                </form>
            </div>
        )
    }
}

export default DebitoEmConta