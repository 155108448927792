import * as env_production from "./env.production";
import * as env_qa from "./env.qa";
import * as env_local from "./env.local";

let env;
if (env_production.APP_URL.indexOf(window.location.host) > -1 || window.parent.location.host === 'www.estadao.com.br') {
    env = env_production;
} else if (env_qa.APP_URL.indexOf(window.location.host) > -1) {
    env = env_qa;
}  else {
    env = env_local;
}

export default env;
