import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "../../modules/Grid";
import Assinatura from "../../assinaturas";
import subscriptionActions from "../../../actions/subscription";
import Titulo from "../../modules/Titulo";
import AlertMessage from "../../alert-message/alertMessage";
import ReactDatatable from "../../react-datatable";
import Loading from "../../../utils/Loading";
import ReactTooltip from "react-tooltip";
import env from "../../../env";
import axios from "axios";
import alertMessageActions from "../../../actions/alertMessage";
import GooglePage from "./google-subscribe";
import Paypal from "./paypal";
import { sendGTMGA4Event } from "../../../utils/tagmanager";
import PagarBoleto from "./PagarBoleto";

class HistoricoPagamento extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(alertMessageActions.removeAlert());
    this.columns = [
      {
        text: "Fatura",
        key: "ciclo",
        className: "historico-pagamento ciclo",
        width: "20%",
        mobile: false,
        sortable: true,
        cell: (record) => {
          return <p>Fatura #{record.ciclo}</p>;
        },
      },
      {
        text: "Vencimento",
        key: "data_vencimento",
        className: "historico-pagamento data_vencimento",
        width: "20%",
        sortable: true,
      },
      {
        text: "Valor",
        key: "valor",
        className: "historico-pagamento valor",
        width: "20%",
        sortable: true,
        cell: (record) => {
          return <p>R$ {record.valor}</p>;
        },
      },
      {
        text: "Status",
        key: "status",
        className: "historico-pagamento status",
        width: "15%",
        sortable: true,
        cell: (record) => {
          return <span className={record.class}>{record.status}</span>;
        },
      },
      {
        key: "action",
        text: "Ação",
        mobile: false,
        className: "historico-pagamento action",
        align: "left",
        width: "25%",
        sortable: false,
        cell: (record) => {
          if (record.action === "pay-now") {
            let icons = record.icons;
            let iconsOrder = icons.sort((a, b, c, d) => {
              if (a === "icon-credit-card") {
                return -1;
              } else if (b === "icon-credit-card") {
                return 1;
              } else if (a === "icon-barcode") {
                return -1;
              } else if (b === "icon-barcode") {
                return 1;
              } else {
                return 0;
              }
            });

            return typeof iconsOrder !== undefined
              ? Object.keys(iconsOrder).map((key) => {
                  let icon = iconsOrder[key];
                  let title;
                  let codeButton;
                  switch (icon) {
                    case "icon-credit-card":
                      codeButton = <div className={"button-icon-credit-card bg-" + record.class}>
                                      <div className="text-button-icon-credit-card">Pagar com cartão</div>
                                   </div>;
                      break;
                    case "icon-barcode":
                      codeButton =  <div>
                                      <div onMouseEnter={this.changeView} className="button-icon-barcode" >
                                        <div className="text-button-icon-barcode">Pagar com boleto</div>                                    
                                      </div>
                                      {this.state.showMenu ? <PagarBoleto itens={iconsOrder} action={this.actionItem} record={record} mouseLeave={this.changeView} /> :  ""}
                                    </div>
                      break;
                    default:
                    //
                  }
                    return (
                      icon == "icon-credit-card" ?
                      <button
                        key={key}
                        className="pay-now"
                        id="pay-now"
                        onClick={() => this.actionItem(record, icon)}
                        data-tip={title}
                        data-place="bottom"
                        data-effect="solid"
                        data-offset="{'top':'-10px'}"
                        data-type="info"
                        style={{ marginRight: "5px" }}
                      >
                        {codeButton}
                        <ReactTooltip />
                      </button>:
                      <button
                        key={key}
                        className="pay-now"
                        id="pay-now"
                        data-tip={title}
                        data-place="bottom"
                        data-effect="solid"
                        data-offset="{'top':'-10px'}"
                        data-type="info"
                        style={{ marginRight: "5px" }}
                      >
                      {codeButton}
                      <ReactTooltip />
                    </button>
                    );
                })
              : "";
          }
        },
      },
      {
        key: "expand",
        align: "center",
        className: "historico-pagamento expand-arrow",
        cell: (record) => {
          if (record.status !== "Pago") {
            return <img src="/static/icons/icon_arrowup.svg" alt="arrow" />;
          }
          return "";
        },
      },
    ];
    this.config = {
      show_filter: false,
      show_pagination: false,
      sort: { column: "ciclo", order: "desc" },
    };
  }

  
  state = {
    showMenu: false
  }

  changeView = () => this.setState({showMenu: !this.state.showMenu})

  sendGTMGA4Event = ({ record, icon }) => {
    // const status = record.class && record.class === 'red' ? 'inadimplente' : 'adimplente'
    const adimplente =
      this.props.inadimplente &&
      this.props.inadimplente.cod_retorno === "1" &&
      this.props.inadimplente.txt_msg === "";
    const status = adimplente ? "adimplente" : "inadimplente";

    const actionLabel = {
      "icon-print": "acao_imprimir_boleto",
      "icon-credit-card": "acao_pagar_cartao",
      "icon-arrows-cw": "acao_reemitir_boleto",
      "icon-mail": "acao_envia_boleto_email",
      "icon-barcode": "acao_copiar_codigo_de_barras",
    };

    sendGTMGA4Event({
      event: "meu_estadao",
      secao: "meus_pagamentos_" + status,
      nome_campo: actionLabel[icon] || icon || "",
    });
  };

  actionItem = (record, icon) => {
    try {
      if (typeof record === "string") {
        record = JSON.parse(record);
      }
      this.sendGTMGA4Event({ record, icon });
      switch (icon) {
        case "icon-print":
          this.visualizarBoleto(record);
          break;
        case "icon-credit-card":
          this.pagarAgora(record);
          break;
        case "icon-arrows-cw":
          this.reemitirBoleto(record);
          break;
        case "icon-mail":
          this.enviaBoleto(record);
          break;
        case "icon-barcode":
          this.copiarCodigoDeBarras(record);
          break;
        default:
        //
      }
    } catch (error) {
      //
    }
  };

  reemitirBoleto(record) {
    Loading.add();

    this.props.dispatch(alertMessageActions.removeAlert());

    try {
      let data = new FormData();
      data.append("ciclo", record.ciclo);
      data.append("email", this.props.usuario.email);

      let url =
        env.API_URL +
        "/subscription/" +
        this.props.assinatura_selecionada +
        "/payment-reemissao";

      axios
        .post(url, data, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status) {
            if (response.data.status === "00") {
              this.props.dispatch(
                alertMessageActions.setAlert(
                  "green",
                  response.data.mensagem,
                  false
                )
              );
              this.props.dispatch(
                subscriptionActions.getHistoricoPagamentos(
                  this.props.assinatura_selecionada,
                  { enable_reload: false }
                )
              );
            } else {
              this.props.dispatch(
                alertMessageActions.setAlert(
                  "blue",
                  "Não foi possível concluir sua solicitação. Tente novamente ou entre em contato: Capital : (11) 4003-5323, Interior: 0800 014 77 20",
                  false
                )
              );
            }
          }
          Loading.remove();
        })
        .catch((error) => {
          Loading.remove();
        });
    } catch (error) {
      Loading.remove();
      this.props.dispatch(
        alertMessageActions.setAlert(
          "blue",
          "Não foi possível concluir sua solicitação. Tente novamente ou entre em contato: Capital : (11) 4003-5323, Interior: 0800 014 77 20",
          false
        )
      );
    }
  }

  enviaBoleto(record) {
    this.props.dispatch(alertMessageActions.removeAlert());
    Loading.add();
    try {
      let data = new FormData();
      data.append("ciclo", record.ciclo);
      data.append("email", this.props.usuario.email);

      let url =
        env.API_URL +
        "/subscription/" +
        this.props.assinatura_selecionada +
        "/payment-reimpressao";

      axios
        .post(url, data, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status) {
            if (response.data.status === "00") {
              this.props.dispatch(
                alertMessageActions.setAlert(
                  "green",
                  response.data.mensagem,
                  false
                )
              );
              this.props.dispatch(
                subscriptionActions.getHistoricoPagamentos(
                  this.props.assinatura_selecionada,
                  { enable_reload: false }
                )
              );
            } else {
              this.props.dispatch(
                alertMessageActions.setAlert(
                  "blue",
                  "Não foi possível concluir sua solicitação. Tente novamente ou entre em contato: Capital : (11) 4003-5323, Interior: 0800 014 77 20",
                  false
                )
              );
            }
          }
          Loading.remove();
        })
        .catch((error) => {
          Loading.remove();
        });
    } catch (error) {
      Loading.remove();
      this.props.dispatch(
        alertMessageActions.setAlert(
          "blue",
          "Não foi possível concluir sua solicitação. Tente novamente ou entre em contato: Capital : (11) 4003-5323, Interior: 0800 014 77 20",
          false
        )
      );
    }
  }

  pagarAgora(record) {
    this.props.dispatch({
      type: "REDIRECT",
      redirect: "/pagar-agora?t=" + Math.floor(Date.now() / 1000),
    });
  }

  visualizarBoleto(record) {
    this.props.dispatch(alertMessageActions.removeAlert());

    Loading.add();
    try {
      let data = new FormData();
      data.append("ciclo", record.ciclo);

      data.append("id_pagador", this.props.assinatura.pagador.id);

      let url =
        env.API_URL +
        "/subscription/" +
        this.props.assinatura_selecionada +
        "/payment-boleto";
      axios
        .post(url, data, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200) {
            if (
              typeof response.data.pdf_bin !== undefined &&
              response.data.status === "0"
            ) {
              let win = window.open("", "_blank"); // a window object
              win.document.open("text/html", "replace");
              win.document.write(
                "<html><body style='margin:0;overflow: hidden'><embed type='application/pdf' width='100%' height='100%' src='data:application/pdf;base64," +
                  response.data.pdf_bin +
                  "' /></body></html>"
              );
            }
            if (response.data.status !== 0) {
              this.props.dispatch(
                alertMessageActions.setAlert(
                  "blue",
                  "Não foi possível concluir sua solicitação. Tente novamente ou entre em contato: Capital : (11) 4003-5323, Interior: 0800 014 77 20",
                  false
                )
              );
            }
          }
          Loading.remove();
        })
        .catch((error) => {
          Loading.remove();
        });
    } catch (error) {
      Loading.remove();
      this.props.dispatch(
        alertMessageActions.setAlert(
          "blue",
          "Não foi possível concluir sua solicitação. Tente novamente ou entre em contato: Capital : (11) 4003-5323, Interior: 0800 014 77 20",
          false
        )
      );
    }
  }

  copiarCodigoDeBarras(record) {
    this.props.dispatch(alertMessageActions.removeAlert());
    let order = this.props.assinatura_selecionada.split("-");

    try {
      let url = env.API_URL + "/subscription/" + order[0] + "/linha-digitavel";
      axios
        .get(url, {
          headers: {
            "Content-type": "application/x-www-form-urlencoded",
          },
          withCredentials: true,
        })
        .then((response) => {
          if (
            typeof response.data.linha_digitavel !== undefined &&
            response.data.linha_digitavel != ""
          ) {
            navigator.clipboard.writeText(response.data.linha_digitavel);
            this.props.dispatch(
              alertMessageActions.setAlert(
                "green",
                "Código de barras " +
                  response.data.linha_digitavel +
                  " copiado com sucesso!",
                false
              )
            );
          } else {
            this.props.dispatch(
              alertMessageActions.setAlert(
                "blue",
                "Não foi possível concluir sua solicitação. Tente novamente ou entre em contato: Capital : (11) 4003-5323, Interior: 0800 014 77 20",
                false
              )
            );
          }
          Loading.remove();
        })
        .catch((error) => {
          Loading.remove();
        });
    } catch (error) {
      Loading.remove();
      this.props.dispatch(
        alertMessageActions.setAlert(
          "blue",
          "Não foi possível concluir sua solicitação. Tente novamente ou entre em contato: Capital : (11) 4003-5323, Interior: 0800 014 77 20",
          false
        )
      );
    }
  }

  expandRow(target, record) {
    let tr = target.closest("tr");
    let trClass = tr.getAttribute("class");
    let trExpand = target.closest("tr").nextSibling;
    try {
      if (!trExpand || !trExpand.getAttribute("class")) {
        return false;
      }
      if (trClass === null) {
        tr.setAttribute("class", "active");
        trExpand.setAttribute("class", "expanded-row active");
      } else {
        tr.removeAttribute("class");
        trExpand.setAttribute("class", "expanded-row");
      }

      return false;
    } catch (error) {
      //
      return false;
    }
  }

  componentDidMount() {}

  render() {
    if (!this.props.assinatura && this.props.assinatura_selecionada) {
      this.props.dispatch(
        subscriptionActions.getDados(this.props.assinatura_selecionada)
      );
    }
    if (!this.props.historico_pagamento[this.props.assinatura_selecionada]) {
      Loading.add();
      this.props.dispatch(
        subscriptionActions.getHistoricoPagamentos(
          this.props.assinatura_selecionada,
          { enable_reload: false }
        )
      );
    }
    if (this.props.historico_pagamento[this.props.assinatura_selecionada]) {
      Loading.remove();
      if (
        this.props.historico_pagamento[this.props.assinatura_selecionada]
          .length <= 0
      ) {
        this.props.dispatch(
          alertMessageActions.setAlert(
            "green",
            "Você ainda não possui faturas! Precisa de ajuda? entre em contato: Capital : (11) 4003-5323, Interior: 0800 014 77 20",
            false
          )
        );

        setTimeout(() => {
          if (document.querySelector("#as-react-datatable tbody td")) {
            document.querySelector("#as-react-datatable tbody td").innerText =
              "";
          }
        }, 1000);
      }
    }
    if (this.props.assinatura !== null) {
      if (
        this.props.assinatura.hasOwnProperty(this.props.assinatura_selecionada)
      ) {
        if (
          this.props.assinatura[this.props.assinatura_selecionada].assinatura
            .tipo_publicacao === "SWG"
        ) {
          return (
            <div className="container meus-pagamentos justify-content-center">
              <div className="header-content row">
                <Grid
                  cols="12 12 12 6 6"
                  class="text-left welcome-wrapper align-center"
                >
                  <Titulo
                    groupItem="Meus pagamentos"
                    titulo="Histórico de pagamentos"
                  />
                </Grid>
                <Grid
                  cols="12 12 12 6 6"
                  class="text-left text-right hidden-xs hidden-sm hidden-md"
                >
                  <Assinatura />
                </Grid>
                <AlertMessage />
              </div>
              <div className="row data-pagamento google">
                <Grid cols="12 12 12 3 3">
                  <div className="block-single">
                    <h1>Status</h1>
                    <h2>Assinatura via Google</h2>
                  </div>
                </Grid>
                <Grid cols="12 12 12 9 9">
                  <div className="block-single">
                    <GooglePage />
                  </div>
                </Grid>
              </div>
            </div>
          );
        }
      }
      /*
            if(this.props.assinatura.hasOwnProperty('assinatura')){
                if(this.props.assinatura.assinatura != null){
                    if(this.props.assinatura.assinatura.hasOwnProperty('codigo_meio_de_pagamento')){
                        if(this.props.assinatura.assinatura.codigo_meio_de_pagamento === '4'){
                            return (
                                <div className="container meus-pagamentos justify-content-center">
                                    <div className="header-content row">
                                        <Grid cols='12 12 12 6 6' class='text-left welcome-wrapper align-center'>
                                            <Titulo groupItem="Meus pagamentos" titulo="Histórico de pagamentos"/>
                                        </Grid>
                                        <Grid cols='12 12 12 6 6' class='text-left text-right hidden-xs hidden-sm hidden-md'>
                                            <Assinatura/>
                                        </Grid>
                                        <AlertMessage/>
                                    </div>
                                    <div className="row data-pagamento google">
                                        <Grid cols="12 12 12 3 3">
                                            <div className="block-single">
                                                <h1>Status</h1>
                                                <h2>Assinatura via Paypal</h2>
                                            </div>
                                        </Grid>
                                        <Grid cols="12 12 12 9 9">
                                            <div className="block-single">
                                                <Paypal/>
                                            </div>
                                        </Grid>
                                    </div>
                                </div>
                            );
                        }
                    }
                }
            }
            */
    }

    /*if(this.props.assinatura && this.props.assinatura.assinatura.codigo_meio_de_pagamento === '4'){
            return (
                <div className="container meus-pagamentos justify-content-center">
                    <div className="header-content row">
                        <Grid cols='12 12 12 6 6' class='text-left welcome-wrapper align-center'>
                            <Titulo groupItem="Meus pagamentos" titulo="Histórico de pagamentos"/>
                        </Grid>
                        <Grid cols='12 12 12 6 6' class='text-left text-right hidden-xs hidden-sm hidden-md'>
                            <Assinatura/>
                        </Grid>
                        <AlertMessage/>
                    </div>
                    <div className="row data-pagamento google">
                        <Grid cols="12 12 12 3 3">
                            <div className="block-single">
                                <h1>Status</h1>
                                <h2>Assinatura via Paypal</h2>
                            </div>
                        </Grid>
                        <Grid cols="12 12 12 9 9">
                            <div className="block-single">
                                <Paypal/>
                            </div>
                        </Grid>
                    </div>
                </div>
            );
        }*/
    return (
      <div className="container meus-pagamentos justify-content-center">
        <div className="header-content row">
          <Grid
            cols="12 12 12 6 6"
            class="text-left welcome-wrapper align-center"
          >
            <Titulo
              groupItem="Meus pagamentos"
              titulo="Histórico de pagamentos"
            />
          </Grid>
          <Grid
            cols="12 12 12 6 6"
            class="text-left text-right hidden-xs hidden-sm hidden-md"
          >
            <Assinatura />
          </Grid>
          <AlertMessage />
        </div>

        <div className="row data-pagamento">
          <Grid cols="12 12 12 12 12">
            <div className="block-single">
              {this.props.historico_pagamento[
                this.props.assinatura_selecionada
              ] ? (
                <ReactDatatable
                  columns={this.columns}
                  records={
                    this.props.historico_pagamento[
                      this.props.assinatura_selecionada
                    ]
                  }
                  config={this.config}
                  onRowClicked={(e, record) => {
                    this.expandRow(e.target, record);
                  }}
                  toAction={(e, record, icon) => {
                    this.actionItem(record, icon);
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.user,
    assinatura_selecionada: state.assinaturas.assinatura_selecionada,
    assinatura: state.assinatura.hasOwnProperty(
      state.assinaturas.assinatura_selecionada
    )
      ? state.assinatura[state.assinaturas.assinatura_selecionada]
      : null,
    historico_pagamento: state.subscriptionHistoricoPagamento,
    inadimplente: state.inadimplente,
  };
};
export default connect(mapStateToProps)(HistoricoPagamento);
