import React, {Component} from 'react'
import {connect} from "react-redux";
import Grid from '../../modules/Grid'

class GoogleSubscribe extends Component {

    signatureStore(){
        if (this.props.signatureType === 'SWG') {
            return <>
                <p className='google-descr'>
                    <b>Sua assinatura foi feita via Google. </b>
                </p>
                <p className='google-descr'>
                    Não é possível alterar seu e-mail no site do Estadão. Para gerenciar sua assinatura, acesse o painel de controle de sua conta Google.
                </p>
            </>
        }

        return <>
            <p className='google-descr'>
                <b>Sua assinatura do Estadão está vinculada à sua conta Google.</b>
            </p>
            <p className='google-descr'>
                Para desvincular, exclua o 'Estadão' na lista de contas vinculadas <a
                href="https://myaccount.google.com/connections?hl=pt_BR&utm_source=OGB&utm_medium=act"
                target="_blank" rel="noopener noreferrer">aqui</a>.
            </p>
            <p className='google-descr'>
                Após esta ação, basta retornar à home e entrar com seu login e senha.<br/>Assim você terá acesso
                às suas informações originais e poderá editá-las no 'Meu Estadão'.
            </p>
        </>
    }

    render() {
        return(
          <div className='google-container'>
              <div className='row align-content-center justify-content-center'>
                  <Grid cols='8 8 3 3'>
                      <div className='google-icon'>
                        <div>
                            <span className='google-text color-1'>G</span>
                            <span className='google-text color-2'>o</span>
                            <span className='google-text color-3'>o</span>
                            <span className='google-text color-1'>g</span>
                            <span className='google-text color-4'>l</span>
                            <span className='google-text color-2'>e</span>
                        </div>
                      </div>
                  </Grid>
                  <Grid cols='12 12 9 9'>
                    {this.signatureStore()}
                  </Grid>

              </div>
          </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        ...state,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.assinatura[state.assinaturas.assinatura_selecionada] : null
    }
};

export default connect(mapStateToProps)(GoogleSubscribe)