import React, {Component} from 'react';
import ContentLoader from "../../components/content-loader/content-loader"


class Select extends Component {
    constructor(props){
        super(props);
        this.id_input_random =  Math.floor(Math.random() * 99999999);
        this.state = {
            selected: Object.keys(this.props.value ? this.props.value : !this.props.label ? "true" : "").length > 0 ? this.props.value ? this.props.value : true : false,
            value: this.props.value,
            className: [this.props.className || "", 'input-control', 'select'],
            label: this.props.label || "",
            name: this.props.name,
            data: this.props.data,
            id: "select_"+(this.props.id || this.props.name || this.id_input_random).replace(/\[/g,".").replace(/\]/g,""),
            load: this.props.load || false,
            readonly: this.props.readonly || false,
            search: this.props.hasOwnProperty("search") ? this.props.search : true,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOption = this.handleClickOption.bind(this);
        this.handleChangeSearch = this.handleChangeSearch.bind(this);
    }

    loader = props => (
        <ContentLoader
            height={55}
            width={300}
            style={{width: '100%', height: '55'}}
            speed={1}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
            {...props}>
            <rect x="0" y="0" rx="5" ry="5" width="300" height="55" />
        </ContentLoader>
    );

    componentDidMount() {
        document.querySelector('body').addEventListener('click',  this.handleClickOut);
    }

    componentWillMount() {
        document.querySelector('body').removeEventListener('click',  this.handleClickOut);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let value = (this.select ? this.select.value : null) || this.props.value;
        let new_state = {
            selected: Object.keys(value ? value : !this.props.label ? "true" : "").length > 0 ? value ? value : true : false,
            value:  this.props.value,
            load:  this.props.load || false,
            data:  this.props.data || {},
            name: this.props.name,
            id: "input_"+(this.props.id || this.props.name || this.id_input_random).replace(/\[/g,".").replace(/\]/g,""),
            readonly: this.props.readonly || false,
            search: this.props.hasOwnProperty("search") ? this.props.search : true
        };

        for(let i in new_state){
            switch (typeof new_state[i]) {
                case "object":
                    if(JSON.stringify(new_state[i]) === JSON.stringify(prevState[i])){
                        delete new_state[i];
                    }
                break;
                default:
                    if(new_state[i] === prevState[i]){
                        delete new_state[i];
                    }
                break;
            }

        }

        if(Object.keys(new_state).length > 0){
            this.setState(new_state);
            if(this.select && new_state.hasOwnProperty('value')){
                this.select.setValue(new_state.value);
            }
        }
    }

    handleClickOut = (e) => {
        if (e.target && this.list_wrapper && !e.target.id !== this.state.id+"_wrapper" && !e.target.closest('#'+this.state.id+"_wrapper")) {
            this.list_wrapper.classList.remove('show')
        }
    }

    handleChange = (e) => {
        if(this.props.onChange){
            this.props.onChange(e);
        }

        if(Object.keys(this.select.value).length > 0){
            this.setState({
                selected: this.select.value
            });
        }else if( Object.keys(this.select.value).length <= 0 && this.state.isset !== false){
            this.setState({
                selected: false
            });
        }
    }

    handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(this.state.readonly){
            return;
        }
        if(this.list_wrapper.classList.contains('show')){
            this.list_wrapper.classList.remove('show');
        }else{
            this.list_wrapper.setAttribute('data-position', 'right');
            this.list_wrapper.classList.add('show');

            let client_rect = this.list_wrapper.getClientRect();
            let winWidth = window.innerWidth;

            if(winWidth < client_rect.x + client_rect.width ){
                this.list_wrapper.setAttribute('data-position', 'left');
            }
        }
    }

    handleClickOption = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if(this.search){
            this.search.setValue("");
        }
        let active = this.list.querySelector('.active');
        if(active){
            active.classList.remove('active');
        }
        e.target.classList.add('active');
        this.select.setValue(e.target.getAttribute('data-value'));
        this.list_wrapper.classList.remove('show')
    }

    handleChangeSearch = (e) => {
        let filter = e.target.value.toLowerCase().removeAssents();
        let itens = this.list.getElementsByTagName('li');
        for (let i = 0; i < itens.length; i++) {
            let txtValue = itens[i].textContent || itens[i].innerText;
            txtValue = txtValue.toLowerCase().removeAssents();
            if (txtValue.indexOf(filter) > -1) {
                itens[i].style.display = "";
            } else {
                itens[i].style.display = "none";
            }
        }
    }

    render(){
        let classNameSelect = [
            this.state.selected && this.state.data.hasOwnProperty(this.state.selected) ? "selected" : "", !this.state.label ? "no-label" : ""
        ];

        let data = [];
        for(let i in this.state.data){
            data.push({
                value: i,
                label: this.state.data[i]
            });
        }

        data.sort(function(a, b){
            if (a.label < b.label)
                return -1;
            if ( a.label > b.label)
                return 1;

            return 0;
        });

        return (
            <div className={this.state.className.join(" ")} id={this.state.id+"_wrapper"}>
                <div className="inner">
                    <select readOnly={this.state.readonly} ref={el => this.select = el || this.select} className={classNameSelect.join(" ")} id={this.state.id} name={this.state.name} onClick={(e) => this.handleClick(e)} onMouseDown={(e) => {e.preventDefault()}} onChange={this.handleChange} value={this.state.selected}>
                        {this.state.label ? (
                            <option value="">{this.state.label}</option>
                        ) : ""}
                        {Object.keys(this.state.data).map((i, key) => {
                            return (
                                <option key={key} value={i}>{this.state.data[i]}</option>
                            )
                        })}
                    </select>
                    {this.state.label ? (
                        <label htmlFor={this.state.id}>{this.state.label}</label>
                    ) : ""}

                    <div className="select_list" ref={el => this.list_wrapper = el || this.list_wrapper}>
                        {this.state.search && (
                            <div className="search">
                                <div style={{position:"absolute"}}>
                                    <i className={"icon-search"}/>
                                </div>
                                <input type="search" ref={el => this.search = el || this.search} onChange={(e) => this.handleChangeSearch(e)}  />
                            </div>
                        )}
                        <ul className="list" ref={el => this.list = el || this.list}>
                            {data.map((item, key) => {
                                let value = item.value;
                                let label = item.label;

                                return (
                                    <li key={key} className={this.state.value === value ? "active" : ""} data-value={value} onClick={(e) => this.handleClickOption(e)}>{label}</li>
                                )
                            })}
                        </ul>
                    </div>
                    {this.props.load && this.loader()}
                </div>
            </div>
        );
    }
}

export default Select;
