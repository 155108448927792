export default {

    filterList(state = null){
        let list = Array.of(this.getList()).shift();
        let filtred = {};
        let estado;
        if(typeof state === "string" && state.length > 0){
            Object.keys(list).map( key => {
                let k = parseInt(key);
                estado = list[key].estado;
                if(estado === state){
                    filtred[k] = list[key].faculdade;
                }
            })
        }
        return filtred;
    },

    getFaculdades(){

        let list = Array.of(this.getList()).shift();
        let faculdades = {};
        Object.keys(list).map( key => {
            faculdades[key] = list[key].faculdade;
        });
        return faculdades;
    },

    getList(){
        return [
            {
                "faculdade": "Ifac - Instituto Federal do Acre",
                "estado": "AC"
            },
            {
                "faculdade": "Ufac - Universidade Federal do Acre",
                "estado": "AC"
            },
            {
                "faculdade": "FADISI - Faculdade Diocesana São José",
                "estado": "AC"
            },
            {
                "faculdade": "ESAB – Escola Superior Aberta do Brasil",
                "estado": "AC"
            },
            {
                "faculdade": "ESB – Instituto de Ensino Superior Brasileiro",
                "estado": "AC"
            },
            {
                "faculdade": "FAAO - Faculdade da Amazônia Ocidental",
                "estado": "AC"
            },
            {
                "faculdade": "FCE - Faculdade Campos Elíseos",
                "estado": "AC"
            },
            {
                "faculdade": "FACEL – Faculdade de Administração, Ciências, Educação e Letras",
                "estado": "AC"
            },
            {
                "faculdade": "Faculdade Futura",
                "estado": "AC"
            },
            {
                "faculdade": "FAP - Faculdade Pitágoras",
                "estado": "AC"
            },
            {
                "faculdade": "FAVENI - Faculdade Venda Nova do Imigrante",
                "estado": "AC"
            },
            {
                "faculdade": "Iesacre - Instituição de Ensino Superior do Acre",
                "estado": "AC"
            },
            {
                "faculdade": "IEESE - Instituto de Educação e Ensino Superior Ebenézer",
                "estado": "AC"
            },
            {
                "faculdade": "UNAMA - Universidade da Amazônia",
                "estado": "AC"
            },
            {
                "faculdade": "UNESA - Universidade Estácio de Sá",
                "estado": "AC"
            },
            {
                "faculdade": "Uninorte - Centro Universitário Uninorte",
                "estado": "AC"
            },
            {
                "faculdade": "UNINTER - Grupo Educacional Uninter",
                "estado": "AC"
            },
            {
                "faculdade": "Uneal - Universidade Estadual de Alagoas",
                "estado": "AL"
            },
            {
                "faculdade": "Uncisal - Universidade Estadual de Ciências da Saúde de Alagoas",
                "estado": "AL"
            },
            {
                "faculdade": "Ifal - Instituto Federal de Alagoas",
                "estado": "AL"
            },
            {
                "faculdade": "Ufal - Universidade Federal de Alagoas",
                "estado": "AL"
            },
            {
                "faculdade": "CESMAC - Centro de Estudos Superiores de Maceió",
                "estado": "AL"
            },
            {
                "faculdade": "FAA & IESA - Associação de Ensino Superior de Alagoas",
                "estado": "AL"
            },
            {
                "faculdade": "FACIMA - Faculdade da Cidade de Maceió",
                "estado": "AL"
            },
            {
                "faculdade": "FAP - Faculdade Pitágoras",
                "estado": "AL"
            },
            {
                "faculdade": "FITS - Faculdade Integrada Tiradentes",
                "estado": "AL"
            },
            {
                "faculdade": "Ueap - Universidade Estadual do Amapá",
                "estado": "AP"
            },
            {
                "faculdade": "IFAP - Instituto Federal do Amapá",
                "estado": "AP"
            },
            {
                "faculdade": "UNIFAP - Universidade Federal do Amapá",
                "estado": "AP"
            },
            {
                "faculdade": "UNIFAAT - Centro Universitário UNIFAAT",
                "estado": "SP"
            },
            {
                "faculdade": "CEAP - Centro de Ensino Superior do Amapá",
                "estado": "AP"
            },
            {
                "faculdade": "FABRAN - Faculdade Brasil Norte",
                "estado": "AP"
            },
            {
                "faculdade": "FAMA - Faculdade do Amapá",
                "estado": "AP"
            },
            {
                "faculdade": "FESAM - Faculdade de Ensino Superior da Amazônia",
                "estado": "AP"
            },
            {
                "faculdade": "IESAP - Instituto de Ensino Superior do Amapá",
                "estado": "AP"
            },
            {
                "faculdade": "IMMES - Instituto Macapaense do Melhor Ensino Superior",
                "estado": "AP"
            },
            {
                "faculdade": "META - Faculdade Meta",
                "estado": "AP"
            },
            {
                "faculdade": "UEA - Universidade do Estado do Amazonas",
                "estado": "AM"
            },
            {
                "faculdade": "IFAM - Instituto Federal do Amazonas",
                "estado": "AM"
            },
            {
                "faculdade": "UFAM - Universidade Federal do Amazonas",
                "estado": "AM"
            },
            {
                "faculdade": "ULBRA - Universidade Luterana do Brasil",
                "estado": "AM"
            },
            {
                "faculdade": "FSDB - Faculdade Salesiana Dom Bosco",
                "estado": "AM"
            },
            {
                "faculdade": "Ciesa - Centro Universitário de Ensino Superior do Amazonas",
                "estado": "AM"
            },
            {
                "faculdade": "UNINILTONLINS - Centro Universitário Nilton Lins",
                "estado": "AM"
            },
            {
                "faculdade": "Cesf - Instituto de Ensino Superior Fucapi",
                "estado": "AM"
            },
            {
                "faculdade": "FT - Faculdade Táhirih",
                "estado": "AM"
            },
            {
                "faculdade": "Fametro - Faculdade Metropolitana de Manaus",
                "estado": "AM"
            },
            {
                "faculdade": "FMF - Faculdade Martha Falcão",
                "estado": "AM"
            },
            {
                "faculdade": "Fal - Faculdade Literatus",
                "estado": "AM"
            },
            {
                "faculdade": "Unisalle - Faculdade La Salle",
                "estado": "AM"
            },
            {
                "faculdade": "Iaes - Faculdade do Amazonas",
                "estado": "AM"
            },
            {
                "faculdade": "Fom - Faculdade de Odontologia de Manaus",
                "estado": "AM"
            },
            {
                "faculdade": "FBNCTSB - Faculdade Boas Novas de Ciências Teológicas, Sociais e Biotecnológicas",
                "estado": "AM"
            },
            {
                "faculdade": "Esbam - Escola Superior Batista do Amazonas",
                "estado": "AM"
            },
            {
                "faculdade": "Uninorte - Centro Universitário do Norte",
                "estado": "AM"
            },
            {
                "faculdade": "Unip - Universidade Paulista",
                "estado": "AM"
            },
            {
                "faculdade": "UEFS - Universidade Estadual de Feira de Santana",
                "estado": "BA"
            },
            {
                "faculdade": "UESB - Universidade Estadual do Sudoeste da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "UESC - Universidade Estadual de Santa Cruz",
                "estado": "BA"
            },
            {
                "faculdade": "UNEB - Universidade do Estado da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "IFBA - Instituto Federal da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "IF Baiano - Instituto Federal Baiano",
                "estado": "BA"
            },
            {
                "faculdade": "UFBA - Universidade Federal da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "UFSB - Universidade Federal do Sul da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "UFOB - Universidade Federal do Oeste da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "UFRB - Universidade Federal do Recôncavo da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "UNIVASF - Fundação Universidade Federal do Vale do São Francisco",
                "estado": "BA"
            },
            {
                "faculdade": "UNILAB - Universidade da Integração Internacional da Lusofonia Afro-Brasileira",
                "estado": "BA"
            },
            {
                "faculdade": "ESAEX - Escola de Administração do Exército",
                "estado": "BA"
            },
            {
                "faculdade": "ACADEPOL - Academia da Polícia Civil do Estado da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "APM - Academia de Polícia Militar",
                "estado": "BA"
            },
            {
                "faculdade": "EBMSP - Escola Bahiana de Medicina e Saúde Pública",
                "estado": "BA"
            },
            {
                "faculdade": "FBD - Faculdade Baiana de Direito",
                "estado": "BA"
            },
            {
                "faculdade": "FAMEC - Faculdade Metropolitana de Camaçari",
                "estado": "BA"
            },
            {
                "faculdade": "FBB - Faculdade Batista Brasileira",
                "estado": "BA"
            },
            {
                "faculdade": "FSBA - Faculdade Social da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FAMAM - Faculdade Maria Milza",
                "estado": "BA"
            },
            {
                "faculdade": "UCSAL - Universidade Católica de Salvador",
                "estado": "BA"
            },
            {
                "faculdade": "UNIVERSO - Universidade Salgado de Oliveira",
                "estado": "BA"
            },
            {
                "faculdade": "FACESA - Faculdade Evangélica de Salvador",
                "estado": "BA"
            },
            {
                "faculdade": "UNIJORGE - Centro Universitário Jorge Amado",
                "estado": "BA"
            },
            {
                "faculdade": "ESEEMB - Escola de Engenharia Eletromecânica da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FTC - Faculdade de Tecnologia e Ciências",
                "estado": "BA"
            },
            {
                "faculdade": "FADBA - Faculdade Adventista da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FAINOR - Faculdade Independente do Nordeste",
                "estado": "BA"
            },
            {
                "faculdade": "FIP Guanambi - Faculdades Integradas Padrão",
                "estado": "BA"
            },
            {
                "faculdade": "FAMETTIG - Faculdades Integradas Olga Mettig",
                "estado": "BA"
            },
            {
                "faculdade": "FBB - Faculdade Batista Brasileira",
                "estado": "BA"
            },
            {
                "faculdade": "FJT - Faculdade Juvêncio Terra",
                "estado": "BA"
            },
            {
                "faculdade": "FRB - Faculdade Ruy Barbosa",
                "estado": "BA"
            },
            {
                "faculdade": "SENAI/CIMATEC - Faculdade de Tecnologia SENAI CIMATEC",
                "estado": "BA"
            },
            {
                "faculdade": "UNIME - União Metropolitana de Educação e Cultura",
                "estado": "BA"
            },
            {
                "faculdade": "UNIFACS - Universidade Salvador",
                "estado": "BA"
            },
            {
                "faculdade": "ESTÁCIO FIB - Centro Universitário Estácio da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FHR - Faculdade Hélio Rocha",
                "estado": "BA"
            },
            {
                "faculdade": "ÁREA 1 - Faculdade Área 1",
                "estado": "BA"
            },
            {
                "faculdade": "AMAEC - Faculdade Amec Trabuco",
                "estado": "BA"
            },
            {
                "faculdade": "FABAC - Faculdade Baiana de Ciências Contábeis",
                "estado": "BA"
            },
            {
                "faculdade": "FABAPAR - Faculdades Batista do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "FCA - Faculdade Castro Alves",
                "estado": "BA"
            },
            {
                "faculdade": "FACCEBA - Faculdade Católica de Ciências Econômicas da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FACET - Faculdade de Artes, Ciências e Tecnologias",
                "estado": "BA"
            },
            {
                "faculdade": "FACIBA - Faculdade de Ciências da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FCCB - Faculdade de Ciências e Cultura da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FCCC - Faculdade de Ciências e Cultura de Cajazeiras",
                "estado": "BA"
            },
            {
                "faculdade": "FGG - Faculdade de Ciências Educacionais Capim Grosso",
                "estado": "BA"
            },
            {
                "faculdade": "FAESF - Faculdade de Ensino Superior da Cidade de Feira de Santana",
                "estado": "BA"
            },
            {
                "faculdade": "FAN - Faculdade Nobre de Feira de Santana",
                "estado": "BA"
            },
            {
                "faculdade": "Faculdade AGES - Privada comercial",
                "estado": "BA"
            },
            {
                "faculdade": "UNICENID - Faculdade de Ciências Gerenciais da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FTE-BA - Faculdade de Tecnologia Empresarial",
                "estado": "BA"
            },
            {
                "faculdade": "FACDELTA - Faculdade Delta",
                "estado": "BA"
            },
            {
                "faculdade": "F2J - Faculdade Dois de Julho",
                "estado": "BA"
            },
            {
                "faculdade": "FDPII - Faculdade Dom Pedro II",
                "estado": "BA"
            },
            {
                "faculdade": "FAMA - Faculdade Montessoriano de Salvador",
                "estado": "BA"
            },
            {
                "faculdade": "FASB - Faculdade do Sul da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FARB - Faculdade Regional da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FSBB - Faculdade São Bento da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "FSC - Faculdade São Camilo",
                "estado": "BA"
            },
            {
                "faculdade": "FSSAL - Faculdade São Salvador",
                "estado": "BA"
            },
            {
                "faculdade": "FSTA - Faculdade São Tomaz de Aquino",
                "estado": "BA"
            },
            {
                "faculdade": "FAVIV - Faculdade Visconde de Cairú",
                "estado": "BA"
            },
            {
                "faculdade": "IBES - Instituto Baiano de Ensino Superior",
                "estado": "BA"
            },
            {
                "faculdade": "IESUS - Instituto de Educação Superior Unyahna de Salvador",
                "estado": "BA"
            },
            {
                "faculdade": "UniFG - Faculdade de Guanambi",
                "estado": "BA"
            },
            {
                "faculdade": "FACEMP - Faculdade de Ciências Empresarias",
                "estado": "BA"
            },
            {
                "faculdade": "UNIRB - Faculdade Regional da Bahia",
                "estado": "BA"
            },
            {
                "faculdade": "UECE - Universidade Estadual do Ceará",
                "estado": "CE"
            },
            {
                "faculdade": "URCA - Universidade Regional do Cariri",
                "estado": "CE"
            },
            {
                "faculdade": "UVA - Universidade Estadual Vale do Acaraú",
                "estado": "CE"
            },
            {
                "faculdade": "IFCE - Instituto Federal do Ceará",
                "estado": "CE"
            },
            {
                "faculdade": "UFC - Universidade Federal do Ceará",
                "estado": "CE"
            },
            {
                "faculdade": "UNILAB - Universidade Federal de Integração Luso-Afro-Brasileira",
                "estado": "CE"
            },
            {
                "faculdade": "UFCA - Universidade Federal do Cariri",
                "estado": "CE"
            },
            {
                "faculdade": "CENTEC - Falcudade de Tecnologia",
                "estado": "CE"
            },
            {
                "faculdade": "FCC - Faculdade Católica do Ceará",
                "estado": "CE"
            },
            {
                "faculdade": "FCF - Faculdade Católica de Fortaleza",
                "estado": "CE"
            },
            {
                "faculdade": "FCRS - Faculdade Católica Rainha do Sertão",
                "estado": "CE"
            },
            {
                "faculdade": "INTA - Instituto Superior de Teologia Aplicada",
                "estado": "CE"
            },
            {
                "faculdade": "IESF - Instituto de Ensino Superior de Fortaleza",
                "estado": "CE"
            },
            {
                "faculdade": "UNICHRISTUS - Centro Universitário Christus",
                "estado": "CE"
            },
            {
                "faculdade": "UNIFAMETRO - Centro Universitário Fametro",
                "estado": "CE"
            },
            {
                "faculdade": "UNIFOR - Universidade de Fortaleza",
                "estado": "CE"
            },
            {
                "faculdade": "UNIFANOR - Centro Universitário Unifanor",
                "estado": "CE"
            },
            {
                "faculdade": "UNIGRANDE - Centro Universitário da Grande Fortaleza",
                "estado": "CE"
            },
            {
                "faculdade": "UPA - Universidade Patativa do Assaré",
                "estado": "CE"
            },
            {
                "faculdade": "ESTÁCIO FIC - Faculdade Integrada do Ceará",
                "estado": "CE"
            },
            {
                "faculdade": "FA7 - Faculdade 7 de Setembro",
                "estado": "CE"
            },
            {
                "faculdade": "FAC - Faculdades Cearenses",
                "estado": "CE"
            },
            {
                "faculdade": "FACE - Faculdade Evolutivo",
                "estado": "CE"
            },
            {
                "faculdade": "FAECE - Faculdade de Ensino e Cultura do Ceará",
                "estado": "CE"
            },
            {
                "faculdade": "FAFOR - Faculdade de Fortaleza",
                "estado": "CE"
            },
            {
                "faculdade": "FALS - Faculdade de Ciências Aplicadas Doutor Leão Sampaio",
                "estado": "CE"
            },
            {
                "faculdade": "FAP - Faculdade Paraíso do Ceará",
                "estado": "CE"
            },
            {
                "faculdade": "FAS - Faculdade Ari de Sá",
                "estado": "CE"
            },
            {
                "faculdade": "FATE - Faculdade Ateneu",
                "estado": "CE"
            },
            {
                "faculdade": "FATENE - Faculdade Terra Nordeste",
                "estado": "CE"
            },
            {
                "faculdade": "FFB - Faculdade Farias Brito",
                "estado": "CE"
            },
            {
                "faculdade": "FJN - Faculdade de Juazeiro do Norte",
                "estado": "CE"
            },
            {
                "faculdade": "FLF - Faculdade Luciano Feijão",
                "estado": "CE"
            },
            {
                "faculdade": "FMJ - Faculdade de Medicina de Juazeiro do Norte",
                "estado": "CE"
            },
            {
                "faculdade": "FTA - Faculdade de Tecnologia Apoena",
                "estado": "CE"
            },
            {
                "faculdade": "FVJ - Faculdade Vale do Jaguaribe",
                "estado": "CE"
            },
            {
                "faculdade": "UNIASSELVI - Centro Universitário Leonardo da Vinci",
                "estado": "CE"
            },
            {
                "faculdade": "ESCS - Escola Superior de Ciências da Saúde",
                "estado": "DF"
            },
            {
                "faculdade": "IFB - Instituto Federal de Brasília",
                "estado": "DF"
            },
            {
                "faculdade": "UnB - Universidade de Brasília",
                "estado": "DF"
            },
            {
                "faculdade": "Comunitárias e/ou Filantrópicas",
                "estado": "DF"
            },
            {
                "faculdade": "UCB - Universidade Católica de Brasília",
                "estado": "DF"
            },
            {
                "faculdade": "UPIS - União Pioneira de Integração Social",
                "estado": "DF"
            },
            {
                "faculdade": "Particulares em Sentido Estrito",
                "estado": "DF"
            },
            {
                "faculdade": "CEUB - Centro Universitário de Brasília",
                "estado": "DF"
            },
            {
                "faculdade": "FATE - Faculdade Ateneu",
                "estado": "DF"
            },
            {
                "faculdade": "FACIPLAC - Faculdade de Ciência e Tecnologia do Planalto Central",
                "estado": "DF"
            },
            {
                "faculdade": "FACITEC - Faculdade de Ciências Sociais e Tecnológicas",
                "estado": "DF"
            },
            {
                "faculdade": "FACICESP - FACULDADES INTEGRADAS ICESP - UNICESP - Brasília",
                "estado": "DF"
            },
            {
                "faculdade": "FASEP - Faculdade Serrana",
                "estado": "DF"
            },
            {
                "faculdade": "FTB - Faculdades Integradas da Terra de Brasília Descredenciada (Despacho n.º 59-SESu/Mec, de 2 de maio de 2011)",
                "estado": "DF"
            },
            {
                "faculdade": "IESB - Instituto de Educação Superior de Brasília",
                "estado": "DF"
            },
            {
                "faculdade": "SENAC - Faculdade Senac - Distrito Federal",
                "estado": "DF"
            },
            {
                "faculdade": "UDF - Centro Universitário do Distrito Federal",
                "estado": "DF"
            },
            {
                "faculdade": "UNIEURO - Centro Universitário Euroamericano",
                "estado": "DF"
            },
            {
                "faculdade": "UNIDESC - Centro Universitário de Desenvolvimento do Centro Oeste",
                "estado": "DF"
            },
            {
                "faculdade": "UNIP - Universidade Paulista",
                "estado": "DF"
            },
            {
                "faculdade": "UNIPLAN - Centro Universitário Planalto do Distrito Federal",
                "estado": "DF"
            },
            {
                "faculdade": "UNISEB - Centro Universitário Estácio UniSEB - Brasília",
                "estado": "DF"
            },
            {
                "faculdade": "UNOPAR - Universidade Norte do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "FACELI - Faculdade de Ensino Superior de Linhares",
                "estado": "ES"
            },
            {
                "faculdade": "FAMES - Faculdade De Musica do Espírito Santo\" Mauricio de Oliveira",
                "estado": "ES"
            },
            {
                "faculdade": "IFES - Instituto Federal do Espírito Santo",
                "estado": "ES"
            },
            {
                "faculdade": "UFES - Universidade Federal do Espírito Santo",
                "estado": "ES"
            },
            {
                "faculdade": "EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória",
                "estado": "ES"
            },
            {
                "faculdade": "FAPECS - Instituto de Pesquisa em Ciências da Saude",
                "estado": "ES"
            },
            {
                "faculdade": "UVV - Universidade Vila Velha",
                "estado": "ES"
            },
            {
                "faculdade": "UNESC - Centro Universitário do Espírito Santo",
                "estado": "ES"
            },
            {
                "faculdade": "FAESA - Faculdades Integradas Espírito-Santenses",
                "estado": "ES"
            },
            {
                "faculdade": "UCL - Faculdade do Centro Leste",
                "estado": "ES"
            },
            {
                "faculdade": "Favi/FACES - Faculdade do Espírito Santo",
                "estado": "ES"
            },
            {
                "faculdade": "ESFA - Escola Superior São Francisco de Assis",
                "estado": "ES"
            },
            {
                "faculdade": "CUSC - Centro Universitário São Camilo",
                "estado": "ES"
            },
            {
                "faculdade": "FAFIA - Faculdade de Filosofia Ciências e Letras de Alegre",
                "estado": "ES"
            },
            {
                "faculdade": "FCB - Faculdade Castelo Branco",
                "estado": "ES"
            },
            {
                "faculdade": "CESAT - Escola de Ensino Superior Anísio Teixeira",
                "estado": "ES"
            },
            {
                "faculdade": "CESV - Centro de Ensino Superior de Vitória",
                "estado": "ES"
            },
            {
                "faculdade": "DOCTUM - Rede de Ensino Doctum",
                "estado": "ES"
            },
            {
                "faculdade": "FABAVI - Faculdade Batista de Vitoria",
                "estado": "ES"
            },
            {
                "faculdade": "FAACZ - Faculdade de Aracruz",
                "estado": "ES"
            },
            {
                "faculdade": "FACE - Faculdade Casa do Estudante",
                "estado": "ES"
            },
            {
                "faculdade": "FACES - Faculdade do Espírito Santo",
                "estado": "ES"
            },
            {
                "faculdade": "FASE - Faculdade da Serra",
                "estado": "ES"
            },
            {
                "faculdade": "FAVI - Associação Vitoriana de Ensino Superior",
                "estado": "ES"
            },
            {
                "faculdade": "FCMV - Faculdade Candido Mendes de Vitória",
                "estado": "ES"
            },
            {
                "faculdade": "FDV - Faculdades Integradas de Vitória",
                "estado": "ES"
            },
            {
                "faculdade": "FUCAPE - Fundação Instituto Capixaba de Pesquisas em Contabilidade, Economia e Finanças",
                "estado": "ES"
            },
            {
                "faculdade": "Fundação Escola Superior do Ministério Público FMP",
                "estado": "RS"
            },
            {
                "faculdade": "MULTIVIX - Faculdade Brasileira",
                "estado": "ES"
            },
            {
                "faculdade": "PIO XII - Faculdade de Estudos Sociais do Espírito Santo",
                "estado": "ES"
            },
            {
                "faculdade": "SABERES - Faculdade Saberes",
                "estado": "ES"
            },
            {
                "faculdade": "UNESA - Universidade Estácio de Sá",
                "estado": "ES"
            },
            {
                "faculdade": "UniRV (FESURV) - Universidade de Rio Verde",
                "estado": "GO"
            },
            {
                "faculdade": "UEG - Universidade Estadual de Goiás",
                "estado": "GO"
            },
            {
                "faculdade": "IFG - Instituto Federal de Goiás",
                "estado": "GO"
            },
            {
                "faculdade": "UFG - Universidade Federal de Goiás",
                "estado": "GO"
            },
            {
                "faculdade": "IF Goiano - Instituto Federal Goiano",
                "estado": "GO"
            },
            {
                "faculdade": "UFCat - Universidade Federal de Catalão",
                "estado": "GO"
            },
            {
                "faculdade": "UFJat - Universidade Federal de Jataí",
                "estado": "GO"
            },
            {
                "faculdade": "Comunitárias e/ou Filantrópicas",
                "estado": "GO"
            },
            {
                "faculdade": "IPOG - Instituto de Pós-Graduação",
                "estado": "GO"
            },
            {
                "faculdade": "PUC - Pontifícia Universidade Católica de Goiás",
                "estado": "GO"
            },
            {
                "faculdade": "UNIVERSO - Universidade Salgado de Oliveira",
                "estado": "GO"
            },
            {
                "faculdade": "Fac - Faculdade Católica de Anápolis",
                "estado": "GO"
            },
            {
                "faculdade": "ULBRA - Universidade Luterana do Brasil",
                "estado": "GO"
            },
            {
                "faculdade": "Fasug - Faculdade do Sudeste Goiano",
                "estado": "GO"
            },
            {
                "faculdade": "ALFA - Faculdade Alves Faria",
                "estado": "GO"
            },
            {
                "faculdade": "CESUC - Centro de Ensino Superior de Catalão",
                "estado": "GO"
            },
            {
                "faculdade": "FACERES - Faculdade de Ceres",
                "estado": "GO"
            },
            {
                "faculdade": "FACER - Faculdade de Ciências de Rubiataba",
                "estado": "GO"
            },
            {
                "faculdade": "FACEG - Faculdade Evangelica de Goianesia",
                "estado": "GO"
            },
            {
                "faculdade": "Facmais - Faculdade de Inhumas",
                "estado": "GO"
            },
            {
                "faculdade": "FAA - Faculdade Anhanguera de Anápolis",
                "estado": "GO"
            },
            {
                "faculdade": "FASAM - Faculdade Sul-Americana",
                "estado": "GO"
            },
            {
                "faculdade": "IESRIVER - Instituto de Ensino Superior de Rio Verde",
                "estado": "GO"
            },
            {
                "faculdade": "INSJAR - Instituto Jordão de Arruda",
                "estado": "GO"
            },
            {
                "faculdade": "UNESA - Universidade Estácio de Sá",
                "estado": "GO"
            },
            {
                "faculdade": "UNIP - Universidade Paulista",
                "estado": "GO"
            },
            {
                "faculdade": "UNIFASC - Faculdade Santa Rita de Cássia",
                "estado": "GO"
            },
            {
                "faculdade": "UniEvangelica - Universidade Evangélica",
                "estado": "GO"
            },
            {
                "faculdade": "Unianhanguera - Universidade Anhanguera",
                "estado": "GO"
            },
            {
                "faculdade": "IAESUP - Faculdades e Colégio Aphonsiano",
                "estado": "GO"
            },
            {
                "faculdade": "IUESO - Instituto Unificado de Ensino Superior Objetivo",
                "estado": "GO"
            },
            {
                "faculdade": "FMB - Faculdade Montes Belos",
                "estado": "GO"
            },
            {
                "faculdade": "UEMA - Universidade Estadual do Maranhão",
                "estado": "MA"
            },
            {
                "faculdade": "UEMASUL - Universidade Estadual da Região Tocantina do Maranhão",
                "estado": "MA"
            },
            {
                "faculdade": "IEMA - Instituto Estadual do Maranhão",
                "estado": "MA"
            },
            {
                "faculdade": "IFMA - Instituto Federal do Maranhão",
                "estado": "MA"
            },
            {
                "faculdade": "UFMA - Universidade Federal do Maranhão",
                "estado": "MA"
            },
            {
                "faculdade": "CEST - Faculdade Santa Terezinha (São Luís)",
                "estado": "MA"
            },
            {
                "faculdade": "FACEM - Faculdade do Estado do Maranhão (São Luís)",
                "estado": "MA"
            },
            {
                "faculdade": "UNIFACEMA - Faculdade de Ciências e Tecnologia do Maranhão (Caxias)",
                "estado": "MA"
            },
            {
                "faculdade": "FACIMP - Faculdade de Imperatriz",
                "estado": "MA"
            },
            {
                "faculdade": "FAMA - Faculdade Atenas Maranhense",
                "estado": "MA"
            },
            {
                "faculdade": "FAP - Faculdade do Baixo Paranaíba (Chapadinha)",
                "estado": "MA"
            },
            {
                "faculdade": "FEST - Faculdade de Educação Santa Terezinha (Imperatriz)",
                "estado": "MA"
            },
            {
                "faculdade": "FSL - Faculdade São Luís",
                "estado": "MA"
            },
            {
                "faculdade": "IESF - Instituto de Ensino Superior Franciscano (Paço do Lumiar)",
                "estado": "MA"
            },
            {
                "faculdade": "IMEC - Instituto Maranhense de Ensino e Cultura (São Luís)",
                "estado": "MA"
            },
            {
                "faculdade": "UNDB - Centro Universitário Dom Bosco (São Luís)",
                "estado": "MA"
            },
            {
                "faculdade": "CEUMA - Universidade CEUMA (São Luís)",
                "estado": "MA"
            },
            {
                "faculdade": "UNEMAT - Universidade do Estado de Mato Grosso",
                "estado": "MT"
            },
            {
                "faculdade": "IFMT - Instituto Federal do Mato Grosso",
                "estado": "MT"
            },
            {
                "faculdade": "UFMT - Universidade Federal de Mato Grosso",
                "estado": "MT"
            },
            {
                "faculdade": "FAUC - Faculdade Cuiabá",
                "estado": "MT"
            },
            {
                "faculdade": "FCR - Faculdade Cândido Rondon",
                "estado": "MT"
            },
            {
                "faculdade": "FAUSB - Faculdade Desembargador Sávio Brandão",
                "estado": "MT"
            },
            {
                "faculdade": "CATHEDRAL - Faculdades Cathedral-Barra do Garças",
                "estado": "MT"
            },
            {
                "faculdade": "EDUVALE - Faculdade de Ciências Sociais Aplicadas do Vale do São Lourenço",
                "estado": "MT"
            },
            {
                "faculdade": "FADAF - Faculdade de Direito de Alta Floresta",
                "estado": "MT"
            },
            {
                "faculdade": "FAPAN - Faculdade do Pantanal",
                "estado": "MT"
            },
            {
                "faculdade": "FASIPE - Faculdade FASIPE",
                "estado": "MT"
            },
            {
                "faculdade": "ICEC - Instituto Cuiabá de Ensino e Cultura",
                "estado": "MT"
            },
            {
                "faculdade": "IESMT - Instituto de Ensino Superior de Mato Grosso",
                "estado": "MT"
            },
            {
                "faculdade": "LA SALLE - Faculdade La Salle",
                "estado": "MT"
            },
            {
                "faculdade": "ULBRA - Universidade Luterana do Brasil - EAD",
                "estado": "MT"
            },
            {
                "faculdade": "UNIC - Universidade de Cuiabá",
                "estado": "MT"
            },
            {
                "faculdade": "UNIP - Universidade Paulista",
                "estado": "MT"
            },
            {
                "faculdade": "UNIRONDON - Centro Universitário Cândido Rondon",
                "estado": "MT"
            },
            {
                "faculdade": "UNIVAG - Centro Universitário de Várzea Grande",
                "estado": "MT"
            },
            {
                "faculdade": "UNIVAR - Universidades Unidas do Vale do Araguaia-Barra do Garças",
                "estado": "MT"
            },
            {
                "faculdade": "UEMS - Universidade Estadual do Mato Grosso do Sul",
                "estado": "MS"
            },
            {
                "faculdade": "UFGD - Universidade Federal da Grande Dourados",
                "estado": "MS"
            },
            {
                "faculdade": "IFMS - Instituto Federal de Mato Grosso do Sul",
                "estado": "MS"
            },
            {
                "faculdade": "UFMS - Universidade Federal do Mato Grosso do Sul",
                "estado": "MS"
            },
            {
                "faculdade": "Comunitárias e/ou Filantrópicas",
                "estado": "MS"
            },
            {
                "faculdade": "UCDB - Universidade Católica Dom Bosco",
                "estado": "MS"
            },
            {
                "faculdade": "AEMS - Faculdades Integradas de Três Lagoas",
                "estado": "MS"
            },
            {
                "faculdade": "FCG - Faculdade Campo Grande",
                "estado": "MS"
            },
            {
                "faculdade": "FACSUL - Faculdade Mato Grosso do Sul",
                "estado": "MS"
            },
            {
                "faculdade": "UNAES - Centro Universitário de Campo Grande",
                "estado": "MS"
            },
            {
                "faculdade": "UNIDERP - Universidade para o Desenvolvimento do Estado e Região do Pantanal",
                "estado": "MS"
            },
            {
                "faculdade": "UNIGRAN - Centro Universitário da Grande Dourados",
                "estado": "MS"
            },
            {
                "faculdade": "FJP - Fundação João Pinheiro",
                "estado": "MG"
            },
            {
                "faculdade": "UEMG - Universidade do Estado de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "UNIMONTES - Universidade Estadual de Montes Claros",
                "estado": "MG"
            },
            {
                "faculdade": "CEFET-MG - Centro Federal de Educação Tecnológica de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "IFMG - Instituto Federal de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "IFNMG - Instituto Federal do Norte de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "IFSM - Instituto Federal do Sudeste de Minas",
                "estado": "MG"
            },
            {
                "faculdade": "IFSULDEMINAS - Instituto Federal do Sul de Minas",
                "estado": "MG"
            },
            {
                "faculdade": "IFTM - Instituto Federal do Triângulo Mineiro",
                "estado": "MG"
            },
            {
                "faculdade": "UFJF - Universidade Federal de Juiz de Fora",
                "estado": "MG"
            },
            {
                "faculdade": "UFLA - Universidade Federal de Lavras",
                "estado": "MG"
            },
            {
                "faculdade": "UFMG - Universidade Federal de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "UFOP - Universidade Federal de Ouro Preto",
                "estado": "MG"
            },
            {
                "faculdade": "UFSJ - Universidade Federal de São João del-Rei",
                "estado": "MG"
            },
            {
                "faculdade": "UFTM - Universidade Federal do Triângulo Mineiro",
                "estado": "MG"
            },
            {
                "faculdade": "UFU - Universidade Federal de Uberlândia",
                "estado": "MG"
            },
            {
                "faculdade": "UFV - Universidade Federal de Viçosa",
                "estado": "MG"
            },
            {
                "faculdade": "UFVJM - Universidade Federal dos Vales do Jequitinhonha e Mucuri",
                "estado": "MG"
            },
            {
                "faculdade": "UNIFAL - Universidade Federal de Alfenas",
                "estado": "MG"
            },
            {
                "faculdade": "UNIFEI - Universidade Federal de Itajubá",
                "estado": "MG"
            },
            {
                "faculdade": "APM-MG - Academia de Polícia Militar de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "EEK - Escola de Engenharia Kennedy",
                "estado": "MG"
            },
            {
                "faculdade": "EEWB - Escola de Enfermagem Wenceslau Braz",
                "estado": "MG"
            },
            {
                "faculdade": "ESDHC - Escola Superior Dom Helder Câmara",
                "estado": "MG"
            },
            {
                "faculdade": "FACAPA - Faculdade Católica de Pouso Alegre",
                "estado": "MG"
            },
            {
                "faculdade": "FACSFX - Faculdade São Francisco Xavier [5]",
                "estado": "MG"
            },
            {
                "faculdade": "FADIPA - Faculdade de Direito de Ipatinga [6]",
                "estado": "MG"
            },
            {
                "faculdade": "FAC - Faculdade Arquidiocesana de Curvelo [7]",
                "estado": "MG"
            },
            {
                "faculdade": "FAP - Faculdade Arquidiocesana de Pirapora [8]",
                "estado": "MG"
            },
            {
                "faculdade": "FADMINAS - Faculdades Integradas Adventistas de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "FAGAMMON - Faculdade Presbiteriana Gammon",
                "estado": "MG"
            },
            {
                "faculdade": "FDSM - Faculdade de Direito do Sul de Minas",
                "estado": "MG"
            },
            {
                "faculdade": "FAC FUNAM - Faculdade de Tecnologia Alto e Médio São Francisco [9]",
                "estado": "MG"
            },
            {
                "faculdade": "FACECA - Faculdade Cenecista de Varginha",
                "estado": "MG"
            },
            {
                "faculdade": "FASF/Unisa - Faculdade de Filosofia, Ciências e Letras do Alto São Francisco",
                "estado": "MG"
            },
            {
                "faculdade": "FAZU - Faculdades Associadas de Uberaba",
                "estado": "MG"
            },
            {
                "faculdade": "FCMMG - Faculdade de Ciências Médicas de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "FCMS/JF - Faculdade de Ciências Médicas e da Saúde de Juiz de Fora",
                "estado": "MG"
            },
            {
                "faculdade": "FMG - Faculdade Metodista Granbery",
                "estado": "MG"
            },
            {
                "faculdade": "FMIT - Faculdade de Medicina de Itajubá",
                "estado": "MG"
            },
            {
                "faculdade": "FUNJOB - Faculdade de Medicina de Barbacena",
                "estado": "MG"
            },
            {
                "faculdade": "FMS - Faculdade Machado Sobrinho",
                "estado": "MG"
            },
            {
                "faculdade": "FUMEC - Fundação Mineira de Educação e Cultura",
                "estado": "MG"
            },
            {
                "faculdade": "FUNCESI - Fundação Comunitária de Ensino Superior de Itabira",
                "estado": "MG"
            },
            {
                "faculdade": "INATEL - Instituto Nacional de Telecomunicações",
                "estado": "MG"
            },
            {
                "faculdade": "IESP - Instituto de Educação Superior de Patrocínio",
                "estado": "MG"
            },
            {
                "faculdade": "PUCMG - Pontifícia Universidade Católica de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "UIT - Universidade de Itaúna",
                "estado": "MG"
            },
            {
                "faculdade": "UNIARAXÁ - Centro Universitário do Planalto de Araxá",
                "estado": "MG"
            },
            {
                "faculdade": "UNIBH - Centro Universitário de Belo Horizonte",
                "estado": "MG"
            },
            {
                "faculdade": "UNICERP - Centro Universitário do Cerrado - Patrocínio",
                "estado": "MG"
            },
            {
                "faculdade": "UNIFEMM - Centro Universitário de Sete Lagoas \"Monsenhor Messias",
                "estado": "MG"
            },
            {
                "faculdade": "UNIFORMG - Centro Universitário de Formiga",
                "estado": "MG"
            },
            {
                "faculdade": "UNILESTE - Centro Universitário do Leste de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "UNIPAC - Universidade Presidente Antônio Carlos",
                "estado": "MG"
            },
            {
                "faculdade": "UNIPAM - Centro Universitário de Patos de Minas",
                "estado": "MG"
            },
            {
                "faculdade": "UNITRI - Centro Universitário do Triângulo",
                "estado": "MG"
            },
            {
                "faculdade": "UNIUBE - Universidade de Uberaba",
                "estado": "MG"
            },
            {
                "faculdade": "UNIVAS - Universidade do Vale do Sapucaí",
                "estado": "MG"
            },
            {
                "faculdade": "UNIVALE - Universidade Vale do Rio Doce",
                "estado": "MG"
            },
            {
                "faculdade": "UNIVERSO - Universidade Salgado de Oliveira",
                "estado": "MG"
            },
            {
                "faculdade": "CUEA - Centro Universitário Eaprenda Elearning",
                "estado": "MG"
            },
            {
                "faculdade": "ASMEC - Associação Sul Mineira de Educação e Cultura",
                "estado": "MG"
            },
            {
                "faculdade": "CECON - Centro Educacional Conceição Nunes",
                "estado": "MG"
            },
            {
                "faculdade": "CENSI - Centro de Ensino Superior de Itabira",
                "estado": "MG"
            },
            {
                "faculdade": "DOCTUM - Rede de Ensino Doctum",
                "estado": "MG"
            },
            {
                "faculdade": "FACEMG - Faculdade de Ensino de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "FACESM - Faculdade de Ciências Sociais Aplicadas do Sul de Minas",
                "estado": "MG"
            },
            {
                "faculdade": "FACICA - Faculdade de Ciências da Saúde de Campos Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "FACED - Faculdade de Ciências Contabeis de Divinópolis",
                "estado": "MG"
            },
            {
                "faculdade": "FACIC - Faculdade de Ciências Humanas de Curvelo [10]",
                "estado": "MG"
            },
            {
                "faculdade": "FACSUM - Faculdade do Sudeste Mineiro",
                "estado": "MG"
            },
            {
                "faculdade": "FACTU - Faculdade de Ciências e Tecnologia de Unaí",
                "estado": "MG"
            },
            {
                "faculdade": "FAF - Faculdade de Frutal [11]",
                "estado": "MG"
            },
            {
                "faculdade": "FAJESSEN - Faculdade Arnaldo Jessen [12]",
                "estado": "MG"
            },
            {
                "faculdade": "FAVENORTE - Instituto Superior de Educação Verde Norte",
                "estado": "MG"
            },
            {
                "faculdade": "FEMG - Faculdade de Engenharia de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "FJF - Faculdade Juiz de Fora",
                "estado": "MG"
            },
            {
                "faculdade": "FMC - Faculdades Milton Campos",
                "estado": "MG"
            },
            {
                "faculdade": "FPAS - Faculdade Pitágoras de Administração Superior",
                "estado": "MG"
            },
            {
                "faculdade": "FPMG - Faculdade Promove de Minas Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "FPU - Faculdade Politécnica de Uberlândia",
                "estado": "MG"
            },
            {
                "faculdade": "FSA - Faculdades Santo Agostinho",
                "estado": "MG"
            },
            {
                "faculdade": "FUC - Faculdades Unificadas de Cataguases [13]",
                "estado": "MG"
            },
            {
                "faculdade": "FUNEDI - Fundação Educacional de Divinópolis",
                "estado": "MG"
            },
            {
                "faculdade": "IBHES - Instituto Belo Horizonte de Ensino Superior",
                "estado": "MG"
            },
            {
                "faculdade": "INESC - Instituto de Ensino Superior Cenecista",
                "estado": "MG"
            },
            {
                "faculdade": "IPTAN - Instituto de Ensino Superior Presidente Tancredo Neves",
                "estado": "MG"
            },
            {
                "faculdade": "ISEC - Instituto Superior de Educação de Campos Gerais",
                "estado": "MG"
            },
            {
                "faculdade": "SECAL - Faculdade Santa Amélia",
                "estado": "MG"
            },
            {
                "faculdade": "UNA - Centro Universitário UNA",
                "estado": "MG"
            },
            {
                "faculdade": "UNIFENAS - Universidade de Alfenas José do Rosário Vellano",
                "estado": "MG"
            },
            {
                "faculdade": "UNILAVRAS - Centro Universitário de Lavras",
                "estado": "MG"
            },
            {
                "faculdade": "UNIVERSITAS - Centro Universitário de Itajubá",
                "estado": "MG"
            },
            {
                "faculdade": "UNIVIÇOSA - União de Ensino Superior de Viçosa",
                "estado": "MG"
            },
            {
                "faculdade": "UFRA - Universidade Federal Rural da Amazônia",
                "estado": "PA"
            },
            {
                "faculdade": "UFPA - Universidade Federal do Pará",
                "estado": "PA"
            },
            {
                "faculdade": "IFPA - Instituto Federal do Pará",
                "estado": "PA"
            },
            {
                "faculdade": "UFOPA - Universidade Federal do Oeste do Pará",
                "estado": "PA"
            },
            {
                "faculdade": "UNIFESSPA - Universidade Federal do Sul e Sudeste do Pará",
                "estado": "PA"
            },
            {
                "faculdade": "UEPA - Universidade do Estado do Pará",
                "estado": "PA"
            },
            {
                "faculdade": "CIABA - Centro de Instrução Almirante Braz de Aguiar",
                "estado": "PA"
            },
            {
                "faculdade": "CEAMA - Centro de Educação da Amazônia",
                "estado": "PA"
            },
            {
                "faculdade": "CESUPA - Centro Universitário do Pará",
                "estado": "PA"
            },
            {
                "faculdade": "ESMAC - Escola Superior Madre Celeste",
                "estado": "PA"
            },
            {
                "faculdade": "ESAMAZ - Escola Superior da Amazônia",
                "estado": "PA"
            },
            {
                "faculdade": "ESTÁCIO FAP - Estácio de Sá Faculdade do Pará",
                "estado": "PA"
            },
            {
                "faculdade": "FAAMA - Faculdade Adventista da Amazônia",
                "estado": "PA"
            },
            {
                "faculdade": "FAAM - Faculdade da Amazônia",
                "estado": "PA"
            },
            {
                "faculdade": "FAMAZ - Faculdade Metropolitana da Amazônia",
                "estado": "PA"
            },
            {
                "faculdade": "FABEL - Faculdade de Belém",
                "estado": "PA"
            },
            {
                "faculdade": "FACETE - Faculdade de Ensino Tecnológico do Estado do Pará",
                "estado": "PA"
            },
            {
                "faculdade": "FACI - Faculdade Ideal",
                "estado": "PA"
            },
            {
                "faculdade": "FACIP - Faculdade Ipiranga",
                "estado": "PA"
            },
            {
                "faculdade": "FAPAN - Faculdade Pan Amazônica",
                "estado": "PA"
            },
            {
                "faculdade": "FAPEN - Faculdade Paraense de Ensino",
                "estado": "PA"
            },
            {
                "faculdade": "FCAT - Faculdade de Castanhal",
                "estado": "PA"
            },
            {
                "faculdade": "FEAPA - Faculdade de Estudos Avançados do Pará",
                "estado": "PA"
            },
            {
                "faculdade": "FIT - Faculdades Integradas do Tapajós (Santarém)",
                "estado": "PA"
            },
            {
                "faculdade": "FIBRA - Faculdade Integrada Brasil Amazônia",
                "estado": "PA"
            },
            {
                "faculdade": "FMM - Faculdade Metropolitana de Marabá",
                "estado": "PA"
            },
            {
                "faculdade": "FPA - Faculdade Pan Americana",
                "estado": "PA"
            },
            {
                "faculdade": "IESAM - Instituto de Ensinos Superiores da Amazônia",
                "estado": "PA"
            },
            {
                "faculdade": "UNAMA - Universidade da Amazônia",
                "estado": "PA"
            },
            {
                "faculdade": "UNIP - Universidade Paulista",
                "estado": "PA"
            },
            {
                "faculdade": "UNIPLAN",
                "estado": "PA"
            },
            {
                "faculdade": "UEPB - Universidade Estadual da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "IFPB - Instituto Federal da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "UFPB - Universidade Federal da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "UFCG - Universidade Federal de Campina Grande",
                "estado": "PB"
            },
            {
                "faculdade": "UNIPÊ - Centro Universitário de João Pessoa",
                "estado": "PB"
            },
            {
                "faculdade": "Particulares em Sentido Estrito",
                "estado": "PB"
            },
            {
                "faculdade": "ASPER - Associação Paraibana de Ensino Renovado",
                "estado": "PB"
            },
            {
                "faculdade": "DEVRY - DeVry João Pessoa",
                "estado": "PB"
            },
            {
                "faculdade": "ESAC - Escola Superior de Aviação Civil",
                "estado": "PB"
            },
            {
                "faculdade": "ESBJ - Faculdade Maurício de Nassau",
                "estado": "PB"
            },
            {
                "faculdade": "FAC - Faculdade de Campina Grande",
                "estado": "PB"
            },
            {
                "faculdade": "FACENE - Faculdade de Enfermagem Nova Esperança",
                "estado": "PB"
            },
            {
                "faculdade": "UNIFUTURO - Faculdades Integradas do Brasil",
                "estado": "PB"
            },
            {
                "faculdade": "FAFIC - Faculdade de Filosofía, Ciências e Letras de Cajazeiras",
                "estado": "PB"
            },
            {
                "faculdade": "FAFIL - Faculdade de Filosofía Ciências e Letras",
                "estado": "PB"
            },
            {
                "faculdade": "FAP - Faculdade Paraibana",
                "estado": "PB"
            },
            {
                "faculdade": "FARR - Faculdade Reinaldo Ramos",
                "estado": "PB"
            },
            {
                "faculdade": "FASER - Faculdade Santa Emília de Rodat",
                "estado": "PB"
            },
            {
                "faculdade": "FASP - Faculdade São Francisco da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "FATEC - Faculdade de Tecnologia de João Pessoa",
                "estado": "PB"
            },
            {
                "faculdade": "FATECPB - Faculdade de Tecnología da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "FAVAP - Faculdades Vale do Piancó",
                "estado": "PB"
            },
            {
                "faculdade": "FCM - Faculdade de Ciências Médicas de Campina Grande",
                "estado": "PB"
            },
            {
                "faculdade": "FCM-PB - Faculdade de Ciências Médicas da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "FEF - Faculdade Evilásio Formiga",
                "estado": "PB"
            },
            {
                "faculdade": "FESVIP - Faculdade de Enfermagem São Vicente de Paula",
                "estado": "PB"
            },
            {
                "faculdade": "FESP - Faculdade de Ensino Superior da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "FIAA - Faculdades Integradas Anglo-Americano",
                "estado": "PB"
            },
            {
                "faculdade": "FPB - Faculdade Potiguar da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "FPB - Faculdade Internacional da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "FPPD - Faculdade Paraibana de Processamento de Dados",
                "estado": "PB"
            },
            {
                "faculdade": "FSM - Faculdade Santa María",
                "estado": "PB"
            },
            {
                "faculdade": "IESP - Instituto de Educação Superior da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "INPER - Insituto Paraibano de Ensino Renovado",
                "estado": "PB"
            },
            {
                "faculdade": "IPOG - Instituto de Pós-Graduação & Graduação",
                "estado": "PB"
            },
            {
                "faculdade": "ISEC - Instituto Superior de Educação de Cajazeiras",
                "estado": "PB"
            },
            {
                "faculdade": "LUMEN FAC - Faculdade de Ciências Contábeis Luiz Mendes",
                "estado": "PB"
            },
            {
                "faculdade": "UNIBRATEC - Faculdade de Tecnologia Ibratec",
                "estado": "PB"
            },
            {
                "faculdade": "UNIFIP - Centro Universitário de Patos",
                "estado": "PB"
            },
            {
                "faculdade": "UNIPB - Faculdade Unida da Paraíba",
                "estado": "PB"
            },
            {
                "faculdade": "UNIUOL - Faculdade de Tecnologia do Uniuol",
                "estado": "PB"
            },
            {
                "faculdade": "UNESPAR - Universidade Estadual do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "UEL - Universidade Estadual de Londrina",
                "estado": "PR"
            },
            {
                "faculdade": "UEM - Universidade Estadual de Maringá",
                "estado": "PR"
            },
            {
                "faculdade": "UENP - Universidade Estadual do Norte do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "UEPG - Universidade Estadual de Ponta Grossa",
                "estado": "PR"
            },
            {
                "faculdade": "UNICENTRO - Universidade Estadual do Centro-Oeste",
                "estado": "PR"
            },
            {
                "faculdade": "UNIOESTE - Universidade Estadual do Oeste do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "IFPR - Instituto Federal do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "UTFPR - Universidade Tecnológica Federal do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "UFPR - Universidade Federal do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "UNILA - Universidade Federal da Integração Latino-Americana",
                "estado": "PR"
            },
            {
                "faculdade": "UFFS - Universidade Federal da Fronteira do Sul",
                "estado": "PR"
            },
            {
                "faculdade": "FAE - FAE Centro Universitário",
                "estado": "PR"
            },
            {
                "faculdade": "FAG - Fundação Assis Gurgacz",
                "estado": "PR"
            },
            {
                "faculdade": "FEPAR - Faculdade Evangélica do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "FPP - Faculdades Pequeno Príncipe",
                "estado": "PR"
            },
            {
                "faculdade": "FTSA - Faculdade Teológica Sul Americana",
                "estado": "PR"
            },
            {
                "faculdade": "PUCPR - Pontifícia Universidade Católica do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "UNIFIL - Centro Universitário Filadélfia",
                "estado": "PR"
            },
            {
                "faculdade": "Univel - União Educacional de Cascavel",
                "estado": "PR"
            },
            {
                "faculdade": "UNINGÁ - Faculdade Ingá",
                "estado": "PR"
            },
            {
                "faculdade": "Particulares em Sentido Estrito",
                "estado": "PR"
            },
            {
                "faculdade": "CAMPO REAL - Faculdade Campo Real",
                "estado": "PR"
            },
            {
                "faculdade": "CESCAGE - Centro de Ensino Superior dos Campos Gerais",
                "estado": "PR"
            },
            {
                "faculdade": "CESUFOZ - Centro de Ensino Superior de Foz do Iguaçu",
                "estado": "PR"
            },
            {
                "faculdade": "FAAB - Faculdade Alfa Brasil",
                "estado": "PR"
            },
            {
                "faculdade": "FAC - Faculdade Curitibana",
                "estado": "PR"
            },
            {
                "faculdade": "FAFIG - Faculdade de Foz do Iguaçu",
                "estado": "PR"
            },
            {
                "faculdade": "FAG - Faculdade Guairacá",
                "estado": "PR"
            },
            {
                "faculdade": "FAJAR - Faculdade Jaguariaíva",
                "estado": "PR"
            },
            {
                "faculdade": "FATEB - Faculdade de Telêmaco Borba",
                "estado": "PR"
            },
            {
                "faculdade": "FANP - Faculdade do Noroeste Paranaense",
                "estado": "PR"
            },
            {
                "faculdade": "FAPAR - Faculdade Paranaense",
                "estado": "PR"
            },
            {
                "faculdade": "FAPI - Faculdades de Pinhais",
                "estado": "PR"
            },
            {
                "faculdade": "FASF - Faculdade Sagrada Família",
                "estado": "PR"
            },
            {
                "faculdade": "FARESC - Faculdades Integradas Santa Cruz de Curitiba",
                "estado": "PR"
            },
            {
                "faculdade": "FEITEP - Faculdade de Engenharia e Inovação Técnico Profissional",
                "estado": "PR"
            },
            {
                "faculdade": "FESC - Fundação de Ensino Superior de Clevelândia",
                "estado": "PR"
            },
            {
                "faculdade": "FG - Faculdade Guarapuava",
                "estado": "PR"
            },
            {
                "faculdade": "FIES - Faculdades Integradas Espírita",
                "estado": "PR"
            },
            {
                "faculdade": "FIPECAFI - Fundação Instituto de Pesquisas Contábeis, Atuariais e Financeiras",
                "estado": "SP"
            },
            {
                "faculdade": "FML - Faculdade Metropolitana Londrinense",
                "estado": "PR"
            },
            {
                "faculdade": "OPET - Organização Paranaense de Ensino Técnico",
                "estado": "PR"
            },
            {
                "faculdade": "SECAL - Faculdade Santa Amélia",
                "estado": "PR"
            },
            {
                "faculdade": "UDC - Centro Universitário União Dinâmica das Cataratas",
                "estado": "PR"
            },
            {
                "faculdade": "UEB - Universidade Eletrônica do Brasil",
                "estado": "PR"
            },
            {
                "faculdade": "UNIANDRADE - Centro Universitário Campos de Andrade",
                "estado": "PR"
            },
            {
                "faculdade": "UNICAMPO - Faculdade União de Campo Mourão",
                "estado": "PR"
            },
            {
                "faculdade": "UniCesumar - Centro Universitário de Maringá",
                "estado": "PR"
            },
            {
                "faculdade": "UNICURITIBA - Centro Universitário Curitiba",
                "estado": "PR"
            },
            {
                "faculdade": "UNINTER - Grupo Educacional Uninter",
                "estado": "PR"
            },
            {
                "faculdade": "UNIPAR - Universidade Paranaense",
                "estado": "PR"
            },
            {
                "faculdade": "UCP - Faculdades do Centro do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "UP - Universidade Positivo",
                "estado": "PR"
            },
            {
                "faculdade": "UTP - Universidade Tuiuti do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "UNISEP - União de Ensino do Sudoeste do Paraná",
                "estado": "PR"
            },
            {
                "faculdade": "UFPI - Universidade Federal do Piauí",
                "estado": "PI"
            },
            {
                "faculdade": "IFPI - Instituto Federal do Piauí",
                "estado": "PI"
            },
            {
                "faculdade": "UFPDra - Universidade Federal do Delta do Parnaíba",
                "estado": "PI"
            },
            {
                "faculdade": "UESPI - Universidade Estadual do Piauí",
                "estado": "PI"
            },
            {
                "faculdade": "ISEAF - Instituto de Educação Antonino Freire",
                "estado": "PI"
            },
            {
                "faculdade": "ICESPI - Instituto Católico de Estudos de Ensino Superior do Piauí",
                "estado": "PI"
            },
            {
                "faculdade": "UNINOVAFAPI - Centro Universitário Uninovafapi",
                "estado": "PI"
            },
            {
                "faculdade": "UNIFSA - Centro Universitário Santo Agostinho",
                "estado": "PI"
            },
            {
                "faculdade": "UNIFAPI - Centro Universitário do Piauí (Aespi/Fapi)",
                "estado": "PI"
            },
            {
                "faculdade": "UNINASSAU - Centro Universitário Maurício de Nassau",
                "estado": "PI"
            },
            {
                "faculdade": "UNIFACID - Faculdade Integral Diferencial",
                "estado": "PI"
            },
            {
                "faculdade": "CESVALE - Centro de Ensino Superior Vale do Parnaíba",
                "estado": "PI"
            },
            {
                "faculdade": "CEUT - Centro de Ensino Unificado de Teresina",
                "estado": "PI"
            },
            {
                "faculdade": "ICF - Instituto Camilo Filho",
                "estado": "PI"
            },
            {
                "faculdade": "CET - Centro de Educação Tecnológico",
                "estado": "PI"
            },
            {
                "faculdade": "IERSA - Instituto Educacional Raimundo Sá (Faculdade Raimundo Sá)",
                "estado": "PI"
            },
            {
                "faculdade": "FAETE - Faculdade das atividades empresariais de Teresina",
                "estado": "PI"
            },
            {
                "faculdade": "FAST - Faculdade Santa Teresa D'avilla",
                "estado": "PI"
            },
            {
                "faculdade": "CHISFAPI - Christus Faculdade do Piauí",
                "estado": "PI"
            },
            {
                "faculdade": "FCPI - Faculdade do Cerrado Piauiense",
                "estado": "PI"
            },
            {
                "faculdade": "FeMASS - Faculdade Professor Miguel Ângelo da Silva Santos - Macaé",
                "estado": "RJ"
            },
            {
                "faculdade": "ISERJ - Instituto Superior de Educação do Rio de Janeiro",
                "estado": "RJ"
            },
            {
                "faculdade": "FAETERJ - Faculdade de Educação Tecnológica do Estado do Rio de Janeiro[2]",
                "estado": "RJ"
            },
            {
                "faculdade": "UENF - Universidade Estadual do Norte Fluminense",
                "estado": "RJ"
            },
            {
                "faculdade": "UERJ - Universidade do Estado do Rio de Janeiro",
                "estado": "RJ"
            },
            {
                "faculdade": "UEZO - Universidade Estadual da Zona Oeste[3]",
                "estado": "RJ"
            },
            {
                "faculdade": "IFF - Instituto Federal Fluminense",
                "estado": "RJ"
            },
            {
                "faculdade": "IFRJ - Instituto Federal do Rio de Janeiro",
                "estado": "RJ"
            },
            {
                "faculdade": "CEFET/RJ - Centro Federal de Educação Tecnológica Celso Suckow da Fonseca",
                "estado": "RJ"
            },
            {
                "faculdade": "ENCE - Escola Nacional de Ciências Estatísticas",
                "estado": "RJ"
            },
            {
                "faculdade": "UFF - Universidade Federal Fluminense",
                "estado": "RJ"
            },
            {
                "faculdade": "UFRJ - Universidade Federal do Rio de Janeiro",
                "estado": "RJ"
            },
            {
                "faculdade": "UFRRJ - Universidade Federal Rural do Rio de Janeiro",
                "estado": "RJ"
            },
            {
                "faculdade": "UNIRIO - Universidade Federal do Estado do Rio de Janeiro",
                "estado": "RJ"
            },
            {
                "faculdade": "UNIRIOS - Centro Universitário do Rio São Francisco",
                "estado": "BA"
            },
            {
                "faculdade": "ABMDP II - Academia de Bombeiro Militar Dom Pedro II",
                "estado": "RJ"
            },
            {
                "faculdade": "AMAN - Academia Militar de Agulhas Negras",
                "estado": "RJ"
            },
            {
                "faculdade": "APMDJVI - Academia de Polícia Militar Dom João VI",
                "estado": "RJ"
            },
            {
                "faculdade": "CIAGA - Centro de Instrução Almirante Graça Aranha",
                "estado": "RJ"
            },
            {
                "faculdade": "EN - Escola Naval",
                "estado": "RJ"
            },
            {
                "faculdade": "IME - Instituto Militar de Engenharia",
                "estado": "RJ"
            },
            {
                "faculdade": "MACKENZIE-RIO - Faculdade Presbiteriana Mackenzie Rio",
                "estado": "RJ"
            },
            {
                "faculdade": "IBMEC - Instituto Brasileiro de Mercado e Capitais",
                "estado": "RJ"
            },
            {
                "faculdade": "FGV - Fundação Getulio Vargas",
                "estado": "RJ"
            },
            {
                "faculdade": "ESPM-RIO - Escola Superior de Propaganda e Marketing",
                "estado": "RJ"
            },
            {
                "faculdade": "PUC-RIO - Pontifícia Universidade Católica do Rio de Janeiro",
                "estado": "RJ"
            },
            {
                "faculdade": "UNIGRANRIO - Universidade do Grande Rio",
                "estado": "RJ"
            },
            {
                "faculdade": "CELSO LISBOA - Centro Universitário Celso Lisboa",
                "estado": "RJ"
            },
            {
                "faculdade": "FUNENSEG - Escola Nacional de Seguros",
                "estado": "RJ"
            },
            {
                "faculdade": "FIJ - Faculdades Integradas de Jacarepaguá",
                "estado": "RJ"
            },
            {
                "faculdade": "FACHA - Faculdades Integradas Hélio Alonso",
                "estado": "RJ"
            },
            {
                "faculdade": "FFSD - Faculdade de Filosofia Santa Doroteia",
                "estado": "RJ"
            },
            {
                "faculdade": "FMC - Faculdade de Medicina de Campos",
                "estado": "RJ"
            },
            {
                "faculdade": "FSMA - Faculdade Salesiana de Macaé",
                "estado": "RJ"
            },
            {
                "faculdade": "FTESM - Fundação Técnico Educacional Souza Marques",
                "estado": "RJ"
            },
            {
                "faculdade": "FSB/RJ - Faculdade de São Bento do Rio de Janeiro",
                "estado": "RJ"
            },
            {
                "faculdade": "UCAM - Universidade Cândido Mendes",
                "estado": "RJ"
            },
            {
                "faculdade": "IBMR - Centro Universitário IBMR",
                "estado": "RJ"
            },
            {
                "faculdade": "UCB - Universidade Castelo Branco",
                "estado": "RJ"
            },
            {
                "faculdade": "UCP - Universidade Católica de Petrópolis",
                "estado": "RJ"
            },
            {
                "faculdade": "UGB - Centro Universitário Geraldo Di Biase",
                "estado": "RJ"
            },
            {
                "faculdade": "UGS - Universidade Gama e Souza",
                "estado": "RJ"
            },
            {
                "faculdade": "UNISUAM - Centro Universitário Augusto Motta",
                "estado": "RJ"
            },
            {
                "faculdade": "UNIABEU - UNIABEU Centro Universitário",
                "estado": "RJ"
            },
            {
                "faculdade": "UNICARIOCA - Centro Universitário Carioca",
                "estado": "RJ"
            },
            {
                "faculdade": "UNIFESO - Centro Universitário Serra dos Órgãos",
                "estado": "RJ"
            },
            {
                "faculdade": "UNIVERSO - Universidade Salgado de Oliveira",
                "estado": "RJ"
            },
            {
                "faculdade": "USS - Universidade Severino Sombra",
                "estado": "RJ"
            },
            {
                "faculdade": "USU - Universidade Santa Úrsula",
                "estado": "RJ"
            },
            {
                "faculdade": "UVA - Universidade Veiga de Almeida",
                "estado": "RJ"
            },
            {
                "faculdade": "REDENTOR - Sociedade Universitária Redentor (Faculdade Redentor)",
                "estado": "RJ"
            },
            {
                "faculdade": "UNIVERITAS - Centro Universitário Universus Veritas",
                "estado": "RJ"
            },
            {
                "faculdade": "INFNET - Instituto Infnet",
                "estado": "RJ"
            },
            {
                "faculdade": "SENAC - Faculdade Senac Rio de Janeiro",
                "estado": "RJ"
            },
            {
                "faculdade": "UBM - Centro Universitário de Barra Mansa",
                "estado": "RJ"
            },
            {
                "faculdade": "UNESA - Universidade Estácio de Sá",
                "estado": "RJ"
            },
            {
                "faculdade": "UNIFOA - Centro Universitário de Volta Redonda",
                "estado": "RJ"
            },
            {
                "faculdade": "UERN - Universidade do Estado do Rio Grande do Norte",
                "estado": "RN"
            },
            {
                "faculdade": "IFRN - Instituto Federal do Rio Grande do Norte",
                "estado": "RN"
            },
            {
                "faculdade": "UFERSA - Universidade Federal Rural do Semi-Árido",
                "estado": "RN"
            },
            {
                "faculdade": "UFRN - Universidade Federal do Rio Grande do Norte",
                "estado": "RN"
            },
            {
                "faculdade": "ULBRA - Universidade Luterana do Brasil (Polo Natal)",
                "estado": "RN"
            },
            {
                "faculdade": "UNP - Universidade Potiguar",
                "estado": "RN"
            },
            {
                "faculdade": "FARN - Faculdade Natalense para o Desenvolvimento do Rio Grande do Norte",
                "estado": "RN"
            },
            {
                "faculdade": "Fatern Gama Filho - Faculdade de Excelência do Rio Grande do Norte",
                "estado": "RN"
            },
            {
                "faculdade": "FAL - Faculdade de Natal",
                "estado": "RN"
            },
            {
                "faculdade": "Facex - Faculdade de Ciências, Cultura e Extensão do RN",
                "estado": "RN"
            },
            {
                "faculdade": "FCC - Faculdade Câmara Cascudo",
                "estado": "RN"
            },
            {
                "faculdade": "Fanec - Faculdade Natalense de Ensino e Cultura",
                "estado": "RN"
            },
            {
                "faculdade": "FCNSN - Faculdade Católica Nossa Senhora Das Neves",
                "estado": "RN"
            },
            {
                "faculdade": "UNINASSAU - Faculdade Maurício de Nassau - (Pólo Natal)",
                "estado": "RN"
            },
            {
                "faculdade": "IPOG - Instituto de Pós-Graduação (Pólo Natal)",
                "estado": "RN"
            },
            {
                "faculdade": "FCRN - Faculdade Católica do Rio Grande do Norte",
                "estado": "RN"
            },
            {
                "faculdade": "Facene/RN - Faculdade de Enfermagem e Medicina Nova Esperança de Mossoró",
                "estado": "RN"
            },
            {
                "faculdade": "Mater Christi - Faculdade Mater Christi",
                "estado": "RN"
            },
            {
                "faculdade": "UERGS - Universidade Estadual do Rio Grande do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "FURG - Universidade Federal do Rio Grande",
                "estado": "RS"
            },
            {
                "faculdade": "IFFarroupilha - Instituto Federal Farroupilha",
                "estado": "RS"
            },
            {
                "faculdade": "IFRS - Instituto Federal do Rio Grande do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "IFSul - Instituto Federal Sul-rio-grandense",
                "estado": "RS"
            },
            {
                "faculdade": "UFCSPA - Universidade Federal de Ciências da Saúde de Porto Alegre",
                "estado": "RS"
            },
            {
                "faculdade": "UFPEL - Universidade Federal de Pelotas",
                "estado": "RS"
            },
            {
                "faculdade": "UFRGS - Universidade Federal do Rio Grande do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "UFSM - Universidade Federal de Santa Maria",
                "estado": "RS"
            },
            {
                "faculdade": "UNIPAMPA - Universidade Federal do Pampa",
                "estado": "RS"
            },
            {
                "faculdade": "UFFS - Universidade Federal da Fronteira do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "CNECSAN - Faculdade CNEC Santo Ângelo",
                "estado": "RS"
            },
            {
                "faculdade": "FACCAT - Faculdades de Taquara",
                "estado": "RS"
            },
            {
                "faculdade": "FACOS - Faculdade Cenecista de Osório",
                "estado": "RS"
            },
            {
                "faculdade": "FISMA - Faculdade Integrada de Santa Maria",
                "estado": "RS"
            },
            {
                "faculdade": "FAPA - Faculdade Porto-Alegrense",
                "estado": "RS"
            },
            {
                "faculdade": "FEEVALE - Universidade Feevale",
                "estado": "RS"
            },
            {
                "faculdade": "FACENSA - Faculdade Cenecista Nossa Senhora dos Anjos",
                "estado": "RS"
            },
            {
                "faculdade": "IACS - Instituto Adventista Cruzeiro do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "MARQUÊS - Instituto de Educação Cenecista Marquês de Herval",
                "estado": "RS"
            },
            {
                "faculdade": "IPA - Centro Universitário Metodista do Instituto Porto Alegre",
                "estado": "RS"
            },
            {
                "faculdade": "PUCRS - Pontifícia Universidade Católica do Rio Grande do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "UCPEL - Universidade Católica de Pelotas",
                "estado": "RS"
            },
            {
                "faculdade": "UCS - Universidade de Caxias do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "ULBRA - Universidade Luterana do Brasil",
                "estado": "RS"
            },
            {
                "faculdade": "UNICRUZ - Universidade de Cruz Alta",
                "estado": "RS"
            },
            {
                "faculdade": "UFN - Universidade Franciscana",
                "estado": "RS"
            },
            {
                "faculdade": "UNIJUÍ - Universidade Regional do Noroeste do Estado do Rio Grande do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "UNIRITTER - Centro Universitário Ritter dos Reis",
                "estado": "RS"
            },
            {
                "faculdade": "UNILASALLE-RJ - Universidade La Salle - RJ",
                "estado": "RS"
            },
            {
                "faculdade": "UNISC - Universidade de Santa Cruz do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "UNISINOS - Universidade do Vale do Rio dos Sinos",
                "estado": "RS"
            },
            {
                "faculdade": "UNIVATES - Centro Universitário UNIVATES",
                "estado": "RS"
            },
            {
                "faculdade": "UPF - Universidade de Passo Fundo",
                "estado": "RS"
            },
            {
                "faculdade": "URCAMP - Universidade da Região da Campanha",
                "estado": "RS"
            },
            {
                "faculdade": "URI - Universidade Regional Integrada do Alto Uruguai e das Missões",
                "estado": "RS"
            },
            {
                "faculdade": "EST - Faculdades EST",
                "estado": "RS"
            },
            {
                "faculdade": "CESF - Centro de Ensino Superior Cenecista de Farroupilha",
                "estado": "RS"
            },
            {
                "faculdade": "CASTELLI - Escola Superior de Hotelaria Castelli",
                "estado": "RS"
            },
            {
                "faculdade": "ESMARGS - Escola Superior de Música e Artes do Rio Grande do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "FADERGS - Faculdade de Desenvolvimento do Rio Grande do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "FAE - Faculdade Anglicana de Erechim",
                "estado": "RS"
            },
            {
                "faculdade": "FAE - Faculdades Equipe - Sapucaia do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "FAM - Faculdade Antonio Meneghetti",
                "estado": "RS"
            },
            {
                "faculdade": "FSG - Faculdade da Serra Gaúcha",
                "estado": "RS"
            },
            {
                "faculdade": "FTEC - Faculdade de Tecnologia TecBrasil",
                "estado": "RS"
            },
            {
                "faculdade": "IDEAU - Instituto de Desenvolvimento Educacional do Alto Uruguai - Getúlio Vargas",
                "estado": "RS"
            },
            {
                "faculdade": "SENAC - Faculdade Senac - Rio Grande do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "SJT - Instituição Educacional São Judas Tadeu",
                "estado": "RS"
            },
            {
                "faculdade": "UNIANHANGUERA - Anhanguera Educacional S.A.",
                "estado": "RS"
            },
            {
                "faculdade": "UNIRITTER - Centro Universitário Ritter dos Reis",
                "estado": "RS"
            },
            {
                "faculdade": "FEMA - Escola Técnica e Superior Machado de Assis - Santa Rosa",
                "estado": "RS"
            },
            {
                "faculdade": "FADISMA - Faculdade de Direito de Santa Maria",
                "estado": "RS"
            },
            {
                "faculdade": "FAACS - Faculdade Anglo-Americano de Caxias do Sul",
                "estado": "RS"
            },
            {
                "faculdade": "UNIR - Universidade Federal de Rondônia",
                "estado": "RO"
            },
            {
                "faculdade": "IFRO - Instituto Federal de Rondônia",
                "estado": "RO"
            },
            {
                "faculdade": "FCR - Faculdade Católica de Rondônia",
                "estado": "RO"
            },
            {
                "faculdade": "UNIFSL - Centro Universitário São Lucas de Porto Velho",
                "estado": "RO"
            },
            {
                "faculdade": "UNIFSL - Centro Universitário São Lucas de Ji-paraná",
                "estado": "RO"
            },
            {
                "faculdade": "AVEC - Associação Vilhenense de Educação e Cultura",
                "estado": "RO"
            },
            {
                "faculdade": "FAMA - Faculdade da Amazônia",
                "estado": "RO"
            },
            {
                "faculdade": "FAEV/UNESC - Faculdade de Educação e Cultura de Vilhena",
                "estado": "RO"
            },
            {
                "faculdade": "FARO - Faculdade de Ciências Humanas, Exatas e Letras de Rondônia",
                "estado": "RO"
            },
            {
                "faculdade": "UNIRON - Faculdade Interamericana de Porto Velho",
                "estado": "RO"
            },
            {
                "faculdade": "CEULJI/ULBRA - Centro Universitário Luterano de Ji-Paraná",
                "estado": "RO"
            },
            {
                "faculdade": "FIMCA - Faculdades Integradas Aparício Carvalho",
                "estado": "RO"
            },
            {
                "faculdade": "FIAR - Faculdades Integradas de Ariquemes",
                "estado": "RO"
            },
            {
                "faculdade": "FAAR - Faculdades Associadas de Ariquemes",
                "estado": "RO"
            },
            {
                "faculdade": "ILES/ULBRA - Instituto Luterano de Ensino Superior de Porto Velho",
                "estado": "RO"
            },
            {
                "faculdade": "FAEMA - Faculdade de Educação e Meio Ambiente",
                "estado": "RO"
            },
            {
                "faculdade": "FACIMED - Faculdade de Ciências Biomédicas de Cacoal",
                "estado": "RO"
            },
            {
                "faculdade": "UNESC - Faculdades Integradas de Cacoal",
                "estado": "RO"
            },
            {
                "faculdade": "UNIJIPA - Faculdade Panamericana de Ji-Paraná",
                "estado": "RO"
            },
            {
                "faculdade": "UERR - Universidade Estadual de Roraima",
                "estado": "RR"
            },
            {
                "faculdade": "UFRR - Universidade Federal de Roraima",
                "estado": "RR"
            },
            {
                "faculdade": "IFRR - Instituto Federal de Roraima",
                "estado": "RR"
            },
            {
                "faculdade": "Estácio - Estácio Atual",
                "estado": "RR"
            },
            {
                "faculdade": "FACETEN - Faculdade de Ciências, Educação e Teologia do Norte do Brasil",
                "estado": "RR"
            },
            {
                "faculdade": "Cathedral - Faculdades Cathedral",
                "estado": "RR"
            },
            {
                "faculdade": "FARES - Faculdade Roraimense de Ensino Superior",
                "estado": "RR"
            },
            {
                "faculdade": "FURB - Fundação Universidade Regional de Blumenau",
                "estado": "SC"
            },
            {
                "faculdade": "SOCIESC - Sociedade Educacional de Santa Catarina",
                "estado": "SC"
            },
            {
                "faculdade": "UNESC - Universidade do Extremo Sul Catarinense",
                "estado": "SC"
            },
            {
                "faculdade": "USJ - Universidade Municipal de São José",
                "estado": "SC"
            },
            {
                "faculdade": "FMP - Faculdade Municipal de Palhoça",
                "estado": "SC"
            },
            {
                "faculdade": "UDESC - Universidade do Estado de Santa Catarina",
                "estado": "SC"
            },
            {
                "faculdade": "UFFS - Universidade Federal da Fronteira Sul",
                "estado": "SC"
            },
            {
                "faculdade": "IFSC - Instituto Federal de Santa Catarina",
                "estado": "SC"
            },
            {
                "faculdade": "IFC - Instituto Federal Catarinense",
                "estado": "SC"
            },
            {
                "faculdade": "UFSC - Universidade Federal de Santa Catarina",
                "estado": "SC"
            },
            {
                "faculdade": "ACE - Associação Catarinense de Ensino",
                "estado": "SC"
            },
            {
                "faculdade": "FACC - Faculdade Concórdia",
                "estado": "SC"
            },
            {
                "faculdade": "FAACH - Faculdade Santa Rita de Chapecó",
                "estado": "SC"
            },
            {
                "faculdade": "FAP - Faculdade Santa Rita Palmitos",
                "estado": "SC"
            },
            {
                "faculdade": "SENAC - Faculdade Senac - Santa Catarina",
                "estado": "SC"
            },
            {
                "faculdade": "UNOESC - Universidade do Oeste de Santa Catarina",
                "estado": "SC"
            },
            {
                "faculdade": "UNIASSELVI - Centro Universitário Leonardo da Vinci",
                "estado": "SC"
            },
            {
                "faculdade": "UNIBAVE - Universidade Barriga Verde",
                "estado": "SC"
            },
            {
                "faculdade": "UNIFEBE - Centro Universitário de Brusque",
                "estado": "SC"
            },
            {
                "faculdade": "UNISUL - Universidade do Sul de Santa Catarina",
                "estado": "SC"
            },
            {
                "faculdade": "UNC - Universidade do Contestado",
                "estado": "SC"
            },
            {
                "faculdade": "UNIARP - Universidade Alto Vale do Rio do Peixe",
                "estado": "SC"
            },
            {
                "faculdade": "UNERJ - Centro Universitário de Jaraguá do Sul",
                "estado": "SC"
            },
            {
                "faculdade": "UNIVALI - Universidade do Vale do Itajaí",
                "estado": "SC"
            },
            {
                "faculdade": "UNIVILLE - Universidade da Região de Joinville",
                "estado": "SC"
            },
            {
                "faculdade": "UNOCHAPECÓ - Universidade Comunitária Regional de Chapecó",
                "estado": "SC"
            },
            {
                "faculdade": "UNIPLAC - Universidade do Planalto Catarinense",
                "estado": "SC"
            },
            {
                "faculdade": "UFS - Universidade Federal de Sergipe",
                "estado": "SE"
            },
            {
                "faculdade": "IFS - Instituto Federal de Sergipe",
                "estado": "SE"
            },
            {
                "faculdade": "Unit - Universidade Tiradentes",
                "estado": "SE"
            },
            {
                "faculdade": "FPD - Faculdade Pio Décimo",
                "estado": "SE"
            },
            {
                "faculdade": "ESTÁCIO - Faculdade Estácio de Sergipe",
                "estado": "SE"
            },
            {
                "faculdade": "FANESE - Faculdade de Administração e Negócios de Sergipe",
                "estado": "SE"
            },
            {
                "faculdade": "FAMA - Faculdade Amadeus",
                "estado": "SE"
            },
            {
                "faculdade": "FSLF - Faculdade São Luis de França",
                "estado": "SE"
            },
            {
                "faculdade": "FASER - Faculdade Sergipana",
                "estado": "SE"
            },
            {
                "faculdade": "FACAR - Faculdade de Aracaju",
                "estado": "SE"
            },
            {
                "faculdade": "UNIRB - Faculdade Serigy",
                "estado": "SE"
            },
            {
                "faculdade": "FAJAR - Faculdade Jardins",
                "estado": "SE"
            },
            {
                "faculdade": "FA - Faculdade Atlântico",
                "estado": "SE"
            },
            {
                "faculdade": "UNIPLAN - Universidade do Planalto de Brasília",
                "estado": "SE"
            },
            {
                "faculdade": "UNIDOM - Universidade Dom Pedro II",
                "estado": "SE"
            },
            {
                "faculdade": "UniCOC - Sistema COC de Educação",
                "estado": "SE"
            },
            {
                "faculdade": "UNINASSAU - Faculdade Maurício de Nassau",
                "estado": "SE"
            },
            {
                "faculdade": "Reitoria da Universidade de Taubaté (UNITAU).",
                "estado": "SP"
            },
            {
                "faculdade": "UNITAU - Universidade de Taubaté",
                "estado": "SP"
            },
            {
                "faculdade": "USCS - Universidade Municipal de São Caetano do Sul",
                "estado": "SP"
            },
            {
                "faculdade": "Uni-FACEF (Centro Universitário Municipal de Franca).",
                "estado": "SP"
            },
            {
                "faculdade": "UNIFAE - Centro Universitário das Faculdades Associadas de Ensino",
                "estado": "SP"
            },
            {
                "faculdade": "Uni-FACEF - Centro Universitário de Franca",
                "estado": "SP"
            },
            {
                "faculdade": "CUFSA - Centro Universitário Fundação Santo André",
                "estado": "SP"
            },
            {
                "faculdade": "FACCAMP - Faculdade de Campo Limpo Paulista",
                "estado": "SP"
            },
            {
                "faculdade": "EEP/FUMEP - Escola de Engenharia de Piracicaba",
                "estado": "SP"
            },
            {
                "faculdade": "ESEFIC - Escola Superior de Cruzeiro \"Prefeito Hamilton Vieira Mendes",
                "estado": "SP"
            },
            {
                "faculdade": "ESEFJ - Escola Superior de Educação Física de Jundiaí",
                "estado": "SP"
            },
            {
                "faculdade": "FITO - Fundação Instituto Tecnológico de Osasco",
                "estado": "SP"
            },
            {
                "faculdade": "FATEB - Faculdade de Ciências e Tecnologia de Birigui",
                "estado": "SP"
            },
            {
                "faculdade": "FDF - Faculdade de Direito de Franca",
                "estado": "SP"
            },
            {
                "faculdade": "FDSBC - Faculdade de Direito de São Bernardo do Campo",
                "estado": "SP"
            },
            {
                "faculdade": "FAIBI - Faculdade de Filosofia, Ciências e Letras de Ibitinga",
                "estado": "SP"
            },
            {
                "faculdade": "FFCL - Faculdade de Filosofia Ciências e Letras de São José do Rio Pardo",
                "estado": "SP"
            },
            {
                "faculdade": "FMABC - Faculdade de Medicina do ABC",
                "estado": "SP"
            },
            {
                "faculdade": "FMJ - Faculdade de Medicina de Jundiaí",
                "estado": "SP"
            },
            {
                "faculdade": "FMPFM - Faculdade Municipal Professor Franco Montoro de Mogi Guaçu",
                "estado": "SP"
            },
            {
                "faculdade": "FAI - Faculdades Adamantinenses Integradas",
                "estado": "SP"
            },
            {
                "faculdade": "FAFEM - Faculdades da Fundação de Ensino de Mococa",
                "estado": "SP"
            },
            {
                "faculdade": "FUNEC - Faculdades Integradas de Santa Fé do Sul",
                "estado": "SP"
            },
            {
                "faculdade": "IESF - Instituto de Ensino Superior da Fupesp",
                "estado": "SP"
            },
            {
                "faculdade": "IMMES - Instituto Matonense Municipal de Ensino Superior",
                "estado": "SP"
            },
            {
                "faculdade": "IMESB - Instituto Municipal de Ensino Superior de Bebedouro Victório Cardassi",
                "estado": "SP"
            },
            {
                "faculdade": "IMES Catanduva - Instituto Municipal de Ensino Superior de Catanduva",
                "estado": "SP"
            },
            {
                "faculdade": "IMESSM - Instituto Municipal de Ensino Superior de São Manuel",
                "estado": "SP"
            },
            {
                "faculdade": "IMESA - Instituto Municipal de Ensino Superior de Assis",
                "estado": "SP"
            },
            {
                "faculdade": "FEA - Fundação Educacional Araçatuba",
                "estado": "SP"
            },
            {
                "faculdade": "USP - Universidade de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "UNICAMP - Universidade Estadual de Campinas",
                "estado": "SP"
            },
            {
                "faculdade": "UNESP - Universidade Estadual Paulista",
                "estado": "SP"
            },
            {
                "faculdade": "FAMEMA - Faculdade de Medicina de Marília",
                "estado": "SP"
            },
            {
                "faculdade": "FAMERP - Faculdade de Medicina de São José do Rio Preto",
                "estado": "SP"
            },
            {
                "faculdade": "FATEC - Faculdade de Tecnologia do Estado de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "APMBB - Academia de Polícia Militar do Barro Branco",
                "estado": "SP"
            },
            {
                "faculdade": "UFSCar - Universidade Federal de São Carlos",
                "estado": "SP"
            },
            {
                "faculdade": "UNIFESP - Universidade Federal de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "UFABC - Universidade Federal do ABC",
                "estado": "SP"
            },
            {
                "faculdade": "IFSP - Instituto Federal de Educação, Ciência e Tecnologia de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "ITA - Instituto Tecnológico de Aeronáutica",
                "estado": "SP"
            },
            {
                "faculdade": "AFA - Academia da Força Aérea",
                "estado": "SP"
            },
            {
                "faculdade": "PUC-SP - Pontifícia Universidade Católica de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "PUC-Campinas - Pontifícia Universidade Católica de Campinas",
                "estado": "SP"
            },
            {
                "faculdade": "UNISANTOS - Universidade Católica de Santos",
                "estado": "SP"
            },
            {
                "faculdade": "UNISA - Universidade de Santo Amaro[30]",
                "estado": "SP"
            },
            {
                "faculdade": "UNIVAP - Universidade do Vale do Paraíba",
                "estado": "SP"
            },
            {
                "faculdade": "UNOESTE - Universidade do Oeste Paulista",
                "estado": "SP"
            },
            {
                "faculdade": "USF - Universidade São Francisco",
                "estado": "SP"
            },
            {
                "faculdade": "UNISANT'ANNA - Universidade Sant'Anna",
                "estado": "SP"
            },
            {
                "faculdade": "UMESP - Universidade Metodista de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "UNAERP - Universidade de Ribeirão Preto",
                "estado": "SP"
            },
            {
                "faculdade": "MACK - Universidade Presbiteriana Mackenzie",
                "estado": "SP"
            },
            {
                "faculdade": "UNIMEP - Universidade Metodista de Piracicaba",
                "estado": "SP"
            },
            {
                "faculdade": "UNICASTELO - Universidade Camilo Castelo Branco",
                "estado": "SP"
            },
            {
                "faculdade": "UNISAL - Centro Universitário Salesiano de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "CBM - Centro Universitário Barão de Mauá",
                "estado": "SP"
            },
            {
                "faculdade": "UNISALESIANO - Centro Universitário Católico Salesiano Auxilium",
                "estado": "SP"
            },
            {
                "faculdade": "CUSC - Centro Universitário São Camilo",
                "estado": "SP"
            },
            {
                "faculdade": "FEBASP - Centro Universitário Belas Artes de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "FEI - Centro Universitário FEI",
                "estado": "SP"
            },
            {
                "faculdade": "FMU - Centro Universitário das Faculdades Metropolitanas Unidas",
                "estado": "SP"
            },
            {
                "faculdade": "SENAC - Centro Universitário Senac - São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "UNASP - Centro Universitário Adventista de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "UNIARA - Universidade de Araraquara",
                "estado": "SP"
            },
            {
                "faculdade": "UNICEP - Centro Universitário Central Paulista",
                "estado": "SP"
            },
            {
                "faculdade": "UNIFAI - Centro Universitário Assunção",
                "estado": "SP"
            },
            {
                "faculdade": "UNIFEB - Centro Universitário da Fundação Educacional de Barretos",
                "estado": "SP"
            },
            {
                "faculdade": "UNIFEOB - Centro Universitário Octávio Bastos",
                "estado": "SP"
            },
            {
                "faculdade": "UNIFIEO - Centro Universitário FIEO",
                "estado": "SP"
            },
            {
                "faculdade": "UNILAGO - União das Faculdades dos Grandes Lagos",
                "estado": "SP"
            },
            {
                "faculdade": "UNIMONTE - Centro Universitário Monte Serrat",
                "estado": "SP"
            },
            {
                "faculdade": "UNORP - Centro Universitário do Norte Paulista",
                "estado": "SP"
            },
            {
                "faculdade": "UNIRP - Centro Universitário de Rio Preto",
                "estado": "SP"
            },
            {
                "faculdade": "UNIVEM - Centro Universitário Eurípedes de Marília",
                "estado": "SP"
            },
            {
                "faculdade": "FCN - Faculdade Canção Nova",
                "estado": "SP"
            },
            {
                "faculdade": "BSP - Business School São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "FGV - Fundação Getúlio Vargas",
                "estado": "SP"
            },
            {
                "faculdade": "FATEC - Faculdade de Teologia e Ciências",
                "estado": "SP"
            },
            {
                "faculdade": "FECAP - Fundação Escola de Comércio Álvares Penteado",
                "estado": "SP"
            },
            {
                "faculdade": "ESPM - Escola Superior de Propaganda e Marketing",
                "estado": "SP"
            },
            {
                "faculdade": "ESAGS - Escola Superior de Administração e Gestão",
                "estado": "SP"
            },
            {
                "faculdade": "IMT - Instituto Mauá de Tecnologia",
                "estado": "SP"
            },
            {
                "faculdade": "FCL - Faculdade Cásper Líbero",
                "estado": "SP"
            },
            {
                "faculdade": "FAAP - Fundação Armando Álvares Penteado",
                "estado": "SP"
            },
            {
                "faculdade": "Insper - Instituto de Ensino e Pesquisa",
                "estado": "SP"
            },
            {
                "faculdade": "IMT - Instituto Mauá de Tecnologia",
                "estado": "SP"
            },
            {
                "faculdade": "FESPSP - Fundação Escola de Sociologia e Política de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "FRB - Faculdades Integradas Rio Branco",
                "estado": "SP"
            },
            {
                "faculdade": "DOMPEDRO - Faculdades Integradas Dom Pedro II",
                "estado": "SP"
            },
            {
                "faculdade": "FIO - Faculdades Integradas de Ourinhos",
                "estado": "SP"
            },
            {
                "faculdade": "FIRB - Faculdades Integradas Rio Branco",
                "estado": "SP"
            },
            {
                "faculdade": "FTML - Faculdade de Teologia Metodista Livre",
                "estado": "SP"
            },
            {
                "faculdade": "FTT - Faculdade de Tecnologia Termomecânica",
                "estado": "SP"
            },
            {
                "faculdade": "FAPCOM - Faculdade Paulus de Comunicação e Tecnologia",
                "estado": "SP"
            },
            {
                "faculdade": "UCESP - Faculdade União Cultural do Estado de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "UniSãopaulo - Instituto São Paulo de Formação e Especialização Profissional",
                "estado": "SP"
            },
            {
                "faculdade": "FAZP - Faculdade Zumbi dos Palmares",
                "estado": "SP"
            },
            {
                "faculdade": "FEF - Fundação Educacional de Fernandópolis",
                "estado": "SP"
            },
            {
                "faculdade": "FCMSCSP - Faculdade de Ciências Médicas da Santa Casa de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "FD - Faculdade Dehoniana",
                "estado": "SP"
            },
            {
                "faculdade": "UNINOVE - Campus Memorial - São Paulo/SP.",
                "estado": "SP"
            },
            {
                "faculdade": "Universidade Paulista - Campus Paraíso - São Paulo/SP.",
                "estado": "SP"
            },
            {
                "faculdade": "CEUNSP - Centro Universitário Nossa Senhora do Patrocínio",
                "estado": "SP"
            },
            {
                "faculdade": "FCDA - Faculdade Drummond",
                "estado": "SP"
            },
            {
                "faculdade": "ISES - Centro Universitário Sumaré",
                "estado": "SP"
            },
            {
                "faculdade": "ITE - Centro Universitário de Bauru",
                "estado": "SP"
            },
            {
                "faculdade": "UAM - Universidade Anhembi Morumbi",
                "estado": "SP"
            },
            {
                "faculdade": "UBC - Universidade Braz Cubas",
                "estado": "SP"
            },
            {
                "faculdade": "UMC - Universidade de Mogi das Cruzes",
                "estado": "SP"
            },
            {
                "faculdade": "UNG - Universidade Guarulhos",
                "estado": "SP"
            },
            {
                "faculdade": "UniABC - Universidade do Grande ABC",
                "estado": "SP"
            },
            {
                "faculdade": "UNIBAN - Universidade Bandeirante de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "UNIBERO - Centro Universitário Anhanguera de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "UNICSUL - Universidade Cruzeiro do Sul",
                "estado": "SP"
            },
            {
                "faculdade": "UNISEB - Centro Universitário Estácio UniSEB -São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "UNIFRAN - Universidade de Franca",
                "estado": "SP"
            },
            {
                "faculdade": "UNIMAR - Universidade de Marília",
                "estado": "SP"
            },
            {
                "faculdade": "UNINOVE - Universidade Nove de Julho",
                "estado": "SP"
            },
            {
                "faculdade": "UNIP - Universidade Paulista",
                "estado": "SP"
            },
            {
                "faculdade": "UNIMES - Universidade Metropolitana de Santos",
                "estado": "SP"
            },
            {
                "faculdade": "UNISANTA - Universidade Santa Cecília",
                "estado": "SP"
            },
            {
                "faculdade": "UNISO - Universidade de Sorocaba",
                "estado": "SP"
            },
            {
                "faculdade": "USJT - Universidade São Judas Tadeu",
                "estado": "SP"
            },
            {
                "faculdade": "AESA - Anhanguera Educacional",
                "estado": "SP"
            },
            {
                "faculdade": "FACAMP - Faculdades de Campinas",
                "estado": "SP"
            },
            {
                "faculdade": "FACIC - Faculdade de Ciências Humanas de Cruzeiro",
                "estado": "SP"
            },
            {
                "faculdade": "FACMIL - Faculdade de Administração de São José do Rio Preto",
                "estado": "SP"
            },
            {
                "faculdade": "FADI - Faculdade de Direito de Sorocaba",
                "estado": "SP"
            },
            {
                "faculdade": "FADISC - Faculdades Integradas de São Carlos",
                "estado": "SP"
            },
            {
                "faculdade": "FALS - Faculdade do Litoral Sul Paulista",
                "estado": "SP"
            },
            {
                "faculdade": "FAM - Faculdade de Americana",
                "estado": "SP"
            },
            {
                "faculdade": "FIAP - Faculdade de Informática e Administração Paulista",
                "estado": "SP"
            },
            {
                "faculdade": "FIRB - Faculdades Integradas Rio Branco",
                "estado": "SP"
            },
            {
                "faculdade": "FIT - Faculdade Impacta Tecnologia",
                "estado": "SP"
            },
            {
                "faculdade": "FMS - Faculdade Mario Schenberg",
                "estado": "SP"
            },
            {
                "faculdade": "FNC - Faculdade Nossa Cidade",
                "estado": "SP"
            },
            {
                "faculdade": "FOC - Faculdades Oswaldo Cruz",
                "estado": "SP"
            },
            {
                "faculdade": "JAPI - Faculdade Japi",
                "estado": "SP"
            },
            {
                "faculdade": "SLMANDIC - Faculdade de Odontologia e Centro de Pesquisas Odontológicas São Leopoldo Mandic",
                "estado": "SP"
            },
            {
                "faculdade": "TECMED - Faculdade de Tecnologia Tecmed",
                "estado": "SP"
            },
            {
                "faculdade": "BANDTEC - Faculdade de Tecnologia Bandeirantes",
                "estado": "SP"
            },
            {
                "faculdade": "FLAMINGO - Faculdade Flamingo",
                "estado": "SP"
            },
            {
                "faculdade": "UnirG - Centro Universitário UnirG (cidade de Gurupi-TO)",
                "estado": "TO"
            },
            {
                "faculdade": "UNITINS - Fundação Universidade do Tocantins",
                "estado": "TO"
            },
            {
                "faculdade": "IFTO - Instituto Federal do Tocantins",
                "estado": "TO"
            },
            {
                "faculdade": "UFT - Universidade Federal do Tocantins",
                "estado": "TO"
            },
            {
                "faculdade": "UNICATÓLICA - Centro Universitário Católica do Tocantins",
                "estado": "TO"
            },
            {
                "faculdade": "FACDO - Faculdade Católica Dom Orione",
                "estado": "TO"
            },
            {
                "faculdade": "FACIT - Faculdade de Ciências do Tocantins",
                "estado": "TO"
            },
            {
                "faculdade": "FECIPAR - Faculdade de Educação Ciências e Letras de Paraíso",
                "estado": "TO"
            },
            {
                "faculdade": "FAPAL - Faculdade de Palmas",
                "estado": "TO"
            },
            {
                "faculdade": "FAG - Faculdade Guaraí",
                "estado": "TO"
            },
            {
                "faculdade": "FIESC - Faculdade Integrada de Ensino Superior de Colinas",
                "estado": "TO"
            },
            {
                "faculdade": "FASEC - Faculdade Serra do Carmo",
                "estado": "TO"
            },
            {
                "faculdade": "IEPO - Instituto de Ensino e Pesquisa Objetivo",
                "estado": "TO"
            },
            {
                "faculdade": "ITPAC - Instituto Tocantinense Presidente Antônio Carlos",
                "estado": "TO"
            },
            {
                "faculdade": "CEULP/ULBRA - Centro Universitário Luterano de Palmas",
                "estado": "TO"
            },
            {
                "faculdade": "ITPAC Porto - Instituto Tocantinense Presidente Antônio Carlos de Porto Nacional",
                "estado": "TO"
            },
            {
                "faculdade": "UNEST - União Educacional de Ensino Superior do Médio Tocantins",
                "estado": "TO"
            },
            {
                "faculdade": "Universidade Federal Rural de Pernambuco (UFRPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Federal de Pernambuco (IFPE), antigo CEFET-PE",
                "estado": "PE"
            },
            {
                "faculdade": "Universidade Federal do Vale do São Francisco (UNIVASF)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Federal do Sertão Pernambucano (IFSertão-PE), antigo (CEFET-Petrolina)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Federal de Pernambuco (IFPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Federal de Pernambuco (IFPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Federal de Pernambuco (IFPE), antiga EAF-Barreiros",
                "estado": "PE"
            },
            {
                "faculdade": "Universidade Federal Rural de Pernambuco (UFRPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Federal de Pernambuco (IFPE), antiga (EAFBJ)",
                "estado": "PE"
            },
            {
                "faculdade": "Universidade Federal do Agreste de Pernambuco (UFAPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Federal de Pernambuco (IFPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Universidade Federal Rural de Pernambuco (UFRPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Federal do Sertão Pernambucano (IF sertão-PE)",
                "estado": "PE"
            },
            {
                "faculdade": "Universidade Federal Rural de Pernambuco (UFRPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Federal de Pernambuco (IFPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Escola Politécnica de Pernambuco (POLI)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências da Administração de Pernambuco (FCAP)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Médicas de Pernambuco (FCM)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Enfermagem Nossa Senhora das Graças (FENSG)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto de Ciências Biológicas (ICB)",
                "estado": "PE"
            },
            {
                "faculdade": "Escola Superior de Educação Física (ESEF)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Odontologia de Pernambuco (FOP)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências, Educação e Tecnologia de Garanhuns (FACETEG)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Formação de Professores de Nazaré da Mata (FFPNM)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências e Tecnologia de Caruaru (FACITEC)",
                "estado": "PE"
            },
            {
                "faculdade": "Universidade de Pernambuco (UPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Agrárias de Araripina (FACIAGRA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Humanas e Sociais de Araripina (FACISA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Formação de Professores de Araripina (FAFOPA)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Educacional Vitória (INEVI)",
                "estado": "PE"
            },
            {
                "faculdade": "Centro de Ensino Superior de Arcoverde (CESA)",
                "estado": "PE"
            },
            {
                "faculdade": "Escola Superior de Saúde de Arcoverde (ESSA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Formação de Professores de Afogados da Ingazeira (FAFOPAI)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Superior de Educação do Sertão do Pajeú (ISESP)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências da Administração de Garanhuns (FAGA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Direito de Garanhuns (FDG)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Formação de Professores de Goiana (FFPG)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Superior de Educação de Goiana (ISEG)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Sociais dos Palmares (FACIP)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Formação de Professores da Mata Sul (FAMASUL)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Formação de Professores de Serra Talhada (FAFOPST)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências e Saúde de Serra Talhada (FACISST)",
                "estado": "PE"
            },
            {
                "faculdade": "Centro de Ensino Superior do Vale do São Francisco (CESVASF)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Formação de Professores de Belo Jardim (FABEJA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Humanas e Sociais Aplicadas do Cabo de Santo Agostinho (FACHUCA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências da Administração do Limoeiro (FACAL)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Aplicadas e Sociais de Petrolina (FACAPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Humanas do Sertão Central (FACHUSC)",
                "estado": "PE"
            },
            {
                "faculdade": "Universidade Católica de Pernambuco (UNICAP)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Pernambucana de Saúde (FPS)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Senac Pernambuco (SENAC)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Marista (FMR)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Damas da Instrução Cristã (FADIC)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Salesiana do Nordeste (FASNE)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Salesiano de Filosofia (INSAF)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Olinda (FOCCA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Humanas de Olinda (FACHO)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Comunicação e Turismo de Olinda (FACOTTUR)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto de Ensino Superior de Olinda (IESO)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Contábeis de Recife (FACCOR)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Informática do Recife (FACIR)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Humanas e Sociais de Igarassu (FACIG)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Humanas e Exatas do Sertão de Pernambuco (FACESF)",
                "estado": "PE"
            },
            {
                "faculdade": "Centro Universitário São Miguel (UNISÃOMIGUEL)",
                "estado": "PE"
            },
            {
                "faculdade": "Centro Universitário Mauricio de Nassau (UNINASSAU)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Boa Viagem (FBV)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Odontologia do Recife (FOR)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade do Recife (FAREC)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Nova Roma (FNR)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Joaquim Nabuco (FJN)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Estácio do Recife (ESTÁCIO-FIR)",
                "estado": "PE"
            },
            {
                "faculdade": "Universidade Salgado de Oliveira (UNIVERSO)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Integrada de Pernambuco (FACIPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Centro Universitário Brasileiro (UNIBRA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Humanas Esuda (ESUDA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Tecnologia e Ciências de Pernambuco (FATEC)",
                "estado": "PE"
            },
            {
                "faculdade": "Escola Superior de Marketing (ESM)",
                "estado": "PE"
            },
            {
                "faculdade": "Escola Superior de Relações Públicas (Esurp)",
                "estado": "PE"
            },
            {
                "faculdade": "Escola Superior de Secretariado de Pernambuco (ESUSPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Arquitetura e Urbanismo de Pernambuco (FAUPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências Humanas de Pernambuco (FCHPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Tecnologia Fama (FAMA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Tecnologia Ibratec (UNIBRATEC)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade para o Desenvolvimento de Pernambuco (FADEPE)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Santa Catarina (FASC)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Santa Helena (FASH)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Santa Maria (FSM)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto de Ensino Superior de Piedade (IESP)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Pernambucano de Ensino Superior (IPESU)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Superior de Educação Anchieta (ISEA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Pernambucana (Fape)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdades Integradas Barros Melo (AESO)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Medicina de Olinda (FMO)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Joaquim Nabuco (FJN)",
                "estado": "PE"
            },
            {
                "faculdade": "Centro Universitário Tabosa de Almeida (ASCES-UNITA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Filosofia, Ciências e Letras de Caruaru (FAFICA)",
                "estado": "PE"
            },
            {
                "faculdade": "Centro Universitário do Vale do Ipojuca (UNIFAVIP | Devry)",
                "estado": "PE"
            },
            {
                "faculdade": "Universidade Paulista (UNIP)",
                "estado": "PE"
            },
            {
                "faculdade": "Centro Universitário Mauricio de Nassau (UNINASSAU)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade dos Guararapes (FG)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Européia de Administração e Marketing (FEPAM)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Metropolitana da Grande Recife (UNESJ)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto de Ensino Superior de Piedade (IESP)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Desenvolvimento e Integração Regional (FADIRE)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Santa Cruz (FACRUZ)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Superior de Educação Santa Cruz (ISED)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Ciências de Timbaúba (FACET)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Educação Superior de Timbaúba (FAEST)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Escritor Osman da Costa Lins (FACOL)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdades Integradas da Vitória de Santo Antão (FAINTVISA)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Decisão (FADE)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Joaquim Nabuco",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Teologia Integrada (FATIN)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Luso-Brasileira (FALUB)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade de Enfermagem de Belo Jardim (FAEB)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade da Escada (FAESC)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Superior de Educação de Floresta (ISEF)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade José Lacerda Filho de Ciências Aplicadas (FAJOLCA)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Superior de Educação de Pesqueira (ISEP)",
                "estado": "PE"
            },
            {
                "faculdade": "Instituto Superior de Educação de Salgueiro (ISES)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Joaquim Nabuco (FJN)",
                "estado": "PE"
            },
            {
                "faculdade": "Faculdade Duarte Coelho (FDC)",
                "estado": "PE"
            },
            {
                "faculdade": "QI Faculdade & Escola Técnica",
                "estado": "RS"
            },
            {
                "faculdade": "Faculdade Sapiens",
                "estado": "RO"
            },
            {
                "faculdade": "FACENS",
                "estado": "SP"
            },
            {
                "faculdade": "Faculdade Piaget",
                "estado": "SP"
            },
            {
                "faculdade": "IBMEC",
                "estado": "SP"
            },
            {
                "faculdade": "Claretiano - Centro Universitário",
                "estado": "SP"
            },
            {
                "faculdade": "FLAM - Faculdade Latino Americana",
                "estado": "SP"
            },
            {
                "faculdade": "FIB - Faculdades Integradas de Bauru",
                "estado": "SP"
            },
            {
                "faculdade": "Faculdade Futura",
                "estado": "SP"
            },
            {
                "faculdade": "UNIVESP - Univerdade Virtual do Estado de São Paulo",
                "estado": "SP"
            },
            {
                "faculdade": "FPG - Faculdade Pinheiro Guimarães ",
                "estado": "RJ"
            },
            {
                "faculdade": "UFPE - Universidade Federal de Pernambuco",
                "estado": "PE"
            }
        ]
    },

    getFaculdadeKey(value){
        let faculdades = this.getFaculdades();
        let k = null;
        Object.keys(faculdades).map(key => {
            if(faculdades[key].indexOf(value)> -1){
                k = key
            }
        });
        return k;
    },

    getCursoKey(value){
        let k = null;
        let cursos = this.getCursos();
        Object.keys(cursos).map(key => {
            if(cursos[key].indexOf(value) > -1){
                k = key
            }
        });
        return k;
    },
    getCursos(){
        let cursos = ["Administração","Agronomia","Arqueologia","Arquitetura e Urbanismo","Arquivologia","Artes Visuais","Biblioteconomia","Biomedicina","Ciência da Computação",
            "Ciências Biológicas","Ciências Atuariais","Ciências Contábeis","Ciências Econômicas","Ciências Naturais","Ciências Sociais","Cinema e Audiovisual","Dança","Design","Direito",
            "Educação Física","Enfermagem","Engenharia Aeronáutica","Engenharia Agrícola","Engenharia Ambiental e Sanitária","Engenharia Cartográfica e de Agrimensura","Engenharia Civil",
            "Engenharia de Alimentos","Engenharia de Bioprocessos","Engenharia de Computação","Engenharia de Controle e Automação","Engenharia de Fortificação e Construção",
            "Engenharia de Materiais","Engenharia de Minas","Engenharia de Pesca","Engenharia de Petróleo","Engenharia de Produção","Engenharia de Telecomunicações","Engenharia Elétrica",
            "Engenharia Eletrônica","Engenharia Florestal","Engenharia Mecânica","Engenharia Mecânica de Armamentos","Engenharia Mecânica de Veículos Militares","Engenharia Metalúrgica",
            "Engenharia Naval","Engenharia Nuclear","Engenharia Química","Engenharia Têxtil","Estatística","Farmácia","Filosofia","Física","Fisioterapia","Fonoaudiologia","Geografia",
            "Geologia","História","Informática","Jornalismo","Letras","Matemática","Medicina","Medicina Veterinária","Meteorologia","Museologia","Música","Nutrição","Odontologia","Pedagogia",
            "Psicologia","Publicidade e Propaganda","Química","Radio, TV, Internet (Comunicação Audiovisual e Multimídia)","Relações Internacionais","Relações Públicas","Secretariado Executivo",
            "Serviço Social","Sistemas da Informação","Teatro","Teologia","Terapia Ocupacional","Turismo","Zootecnia","Gestão de Cooperativas","Administração Pública","Agrimensura","Agroecologia",
            "Agronegócios e Agropecuária","Alimentos","Análise e Desenvolvimento de Sistemas","Animação","Aquicultura","Artes","Astronomia","Automação Industrial","Banco de Dados","Biocombustíveis",
            "Biossistemas","Biotecnologia","Biotecnologia e Bioquímica","Ciência da Terra","Ciência e Economia","Ciência e Tecnologia","Ciência e Tecnologia das Águas/do Mar","Ciência e Tecnologia de Alimentos",
            "Ciências Aeronáuticas","Ciências Agrárias","Ciências da Natureza e suas Tecnologias","Ciências do Consumo","Ciências Humanas","Comércio Exterior","Computação","Comunicação das Artes do Corpo",
            "Comunicação em Mídias Digitais","Comunicação Institucional","Comunicação Organizacional","Conservação e Restauro","Construção Civil","Construção Naval","Cooperativismo","Cultura, Linguagens e Tecnologias Aplicadas",
            "Defesa e Gestão Estratégica Internacional","Design de Games","Design de Interiores","Design de Moda","Ecologia","Educomunicação","Eletrônica Industrial","Eletrotécnica Industrial","Energia e Sustentabilidade",
            "Energias Renováveis","Engenharia Acústica","Engenharia Biomédica","Engenharia Bioquímica e de Biotecnologia","Engenharia de Biossistemas","Engenharia de Energia","Engenharia de Inovação","Engenharia de Segurança no Trabalho",
            "Engenharia de Sistemas","Engenharia de Software","Engenharia de Transporte e da Mobilidade","Engenharia Física","Engenharia Hídrica","Engenharia Industrial Madeireira","Engenharia Mecatrônica",
            "Escrita Criativa","Esporte","Estética e Cosmética","Estudos de Gênero e Diversidade","Estudos de Mídia","Eventos", "Fabricação Mecânica","Fotografia","Gastronomia","Geofísica","Geoprocessamento","Gerontologia","Gestão Ambiental",
            "Gestão Comercial","Gestão da Informação","Gestão da Produção Industrial","Gestão da Qualidade","Gestão da Tecnologia da Informação","Gestão de Recursos Humanos","Gestão de Segurança Privada",
            "Gestão de Seguros","Gestão de Turismo","Gestão Desportiva e de Lazer",
            "Gestão em Saúde","Gestão Financeira","Gestão Hospitalar","Gestão Pública","História da Arte","Hotelaria","Humanidades","Informática Biomédica","Irrigação e Drenagem","Jogos Digitais","Libras",
            "Linguagens e Códigos e suas Tecnologias","Linguística","Logística","Luteria","Manutenção de Aeronaves","Manutenção Industrial","Marketing","Matemática e Computação e suas Tecnologias","Materiais","Mecatrônica Industrial","Mineração","Musicoterapia",
            "Nanotecnologia","Naturologia","Negócios Imobiliários","Obstetrícia","Oceanografia","Oftálmica","Optometria","Papel e Celulose","Petróleo e Gás",
            "Pilotagem Profissional de Aeronaves","Processos Gerenciais","Processos Metalúrgicos","Processos Químicos","Produção Audiovisual","Produção Cênica","Produção Cultural","Produção de Bebidas","Produção Editorial",
            "Produção Fonográfica","Produção Multimídia","Produção Publicitária","Produção Sucroalcooleira","Produção Têxtil","Psicopedagogia","Quiropraxia","Radiologia","Redes de Computadores","Rochas Ornamentais","Saneamento Ambiental",
            "Saúde","Saúde Coletiva","Secretariado","Segurança da Informação","Segurança no Trabalho","Segurança Pública","Serviços Judiciários e Notariais","Silvicultura","Sistemas Biomédicos","Sistemas de Telecomunicações",
            "Sistemas Elétricos","Sistemas Embarcados","Sistemas para Internet","Tecnologia da Informação","Tradutor e Intérprete","Transporte","Agroindústria" ,"Aquacultura","Cafeicultura","Ciência dos Alimentos",
            "Ciência dos materiais","Construção de Edifícios","Construção de Estradas","Cosmetologia","Design de Jogos Digitais","Design de produto","Design gráfico","Drenagem e Irrigação","Enologia","Esporte e Lazer",
            "Fruticultura","Gestão de Petróleo e Gás","Gestão de Recursos Hídricos","Gestão do Agronegócio","Gestão em Serviços Jurídicos e Notariais","Gestão Portuária",
            "Horticultura","Meliponicultura","Mercadologia","Processamento de Dados","Processos Escolares","Produção de Aguardente","Produção de Laticínios","Produção de Materiais Plásticos",
            "Produção Sucroalcooeira","Projetos de Estruturas Aeronáuticas","Recursos Humanos","Redes de Telecomunicações","Sistemas de Navegação Fluvial","Telemática","Transporte Aéreo", "Outro"];
        let obj = {};
        cursos = Array.of(cursos).shift();
        Object.keys(cursos).map((key) => {
            obj[key] = cursos[key];
        });
        return obj;
    }
}
