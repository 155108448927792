import React, { Component } from "react";
import { connect } from "react-redux";
import ContentLoader from "./../content-loader/content-loader";
import Modal from "react-responsive-modal";
import CropImage from "./crop-image";
import alertMessageActions from "../../actions/alertMessage";

class FotoUsuario extends Component {
  constructor(props) {
    super(props);

    this.state = {
      src: null,
      cropImageModal: false,
    };
  }

  loader = (props) => (
    <div ref="content_loader">
      <ContentLoader
        height={280}
        width={300}
        style={{ width: "100%", height: "280" }}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect x="85" y="0" rx="130" ry="130" width="130" height="130" />
        <rect x="85" y="135" rx="5" ry="5" width="130" height="50" />
        <rect x="80" y="190" rx="5" ry="5" width="140" height="30" />
        <rect x="85" y="225" rx="5" ry="5" width="130" height="20" />
      </ContentLoader>
    </div>
  );

  onOpenCropImageModal = () => {
    this.setState({ cropImageModal: true });
  };

  onCloseCropImageModal = () => {
    this.setState({ cropImageModal: false });
  };

  onOpenInputFile = () => {
    if (this.refs.hasOwnProperty("input_file")) {
      this.refs.input_file.click();
    }
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].type.includes("image/")) {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          this.setState({ src: reader.result })
        );
        reader.readAsDataURL(e.target.files[0]);
        this.onOpenCropImageModal();
      } else {
        this.props.dispatch(
          alertMessageActions.setAlert(
            "red",
            "Formato do arquivo não suportado"
          )
        );
      }
    }
  };

  render() {
    const { cropImageModal, src } = this.state;
    let usuario = this.props.usuario;
    let load = !usuario.id;

    const foto_url =
      usuario.foto_url && Object.keys(usuario.foto_url).length > 0
        ? usuario.foto_url
        : "/static/img/perfil.png";
    const nome = usuario.nome;
    const sobrenome = usuario.sobrenome;
    const nome_completo = nome + " " + sobrenome;

    return (
      <div
        className={`foto-do-usuario-container block-single row justify-content-center ${window.location.href.includes(
          "alterar-email"
        ) && "change-email-screen"}`}
      >
        {!load ? (
          <div className="block-content">
            <Modal
              styles={{ modal: { width: "100%", maxWidth: 800 } }}
              open={cropImageModal}
              onClose={this.onCloseCropImageModal}
              center
            >
              <div id="trocar-foto-do-usuario">
                <div className="title">Ajuste sua imagem</div>
                <CropImage src={src} onCancel={this.onCloseCropImageModal} />
              </div>
            </Modal>
            <div className="row nomargin justify-content-center">
              <img
                src={foto_url}
                alt={nome_completo}
                width="132"
                height="132"
                className="foto-usuario"
              />
            </div>
            <div className="row nomargin justify-content-center">
              {this.props.hidden_alterar_foto ? (
                <div className="space-between" />
              ) : (
                <button
                  className="btn white alterar-foto"
                  onClick={this.onOpenInputFile}
                >
                  Alterar foto
                </button>
              )}
              <input
                ref="input_file"
                type="file"
                onChange={this.onSelectFile}
                style={{ display: "none" }}
                accept="image/png,image/jpeg,image/bmp"
              />
            </div>
            <div className="row nomargin justify-content-center">
              <h1 className="nomargin">{nome}</h1>
            </div>
            <div className="row nomargin justify-content-center">
              <h2 className="nomargin">{sobrenome}</h2>
            </div>
          </div>
        ) : (
          this.loader()
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.user,
  };
};

export default connect(mapStateToProps)(FotoUsuario);
