import React, {Component} from 'react'
import {connect} from "react-redux";
import subscriptionsActions from "../../../actions/subscription";

class Status extends Component {
    render() {
        let meioPagamento = 'Não foi possivel recuperar esta informação';
        let plano = 'Não foi possivel recuperar esta informação';
        let defined = false;

        if(!this.props.assinatura && this.props.assinatura_selecionada){
            this.props.dispatch(subscriptionsActions.getDados(this.props.assinatura_selecionada))
        }
        if(this.props.assinatura != null && this.props.assinatura.assinatura != null ){
            meioPagamento = this.props.assinatura.assinatura.meio_de_pagamento;
            defined = true;
        }
        if(this.props.user != null && this.props.user.subscriptions != null && typeof this.props.user.subscriptions[this.props.assinatura_selecionada] !== 'undefined'){
            plano = this.props.user.subscriptions[this.props.assinatura_selecionada].label_produto_midia;
            defined = true;
        }

        if(!defined){
            window.location.href = window.location.origin;
        }

        return (
          <div className='block-single text contratos-status'>
              <h3>Status</h3>
              <ul className='status-list'>
                  <li>
                      <h4 className='status-titulo'>Plano:</h4>
                      <p className='status-valor'>{plano}</p>
                  </li>
                  <li>
                      <h4 className='status-titulo'>Forma de Pagamento:</h4>
                      <p className='status-valor'>{meioPagamento} </p>

                  </li>
              </ul>
          </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ?  state.assinatura[state.assinaturas.assinatura_selecionada] : null
    }
}

export default connect(mapStateToProps)(Status)
