// eslint-disable-next-line no-extend-native
Date.prototype.nextBusinessDay = function() {
    while (this.getDay() === 0 || this.getDay() === 6){
        this.setHours(24);
    }
    return this;
};

// eslint-disable-next-line no-extend-native
Date.prototype.prevBusinessDay = function() {
    while (this.getDay() === 0 || this.getDay() === 6){
        this.setHours(-24);
    }
    return this;
};

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function(days, config = {}) {
    config = {...{
            'uteis': false,
        }, ...config};

    config.uteis && this.nextBusinessDay();

    while (days !== 0){
        if(days > 0){
            this.setHours(24);
            config.uteis && this.nextBusinessDay();
            --days;
        }else{
            this.setHours(-24);
            config.uteis && this.prevBusinessDay();
            ++days;
        }
    }

    return this;
};