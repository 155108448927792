import Calendar from "./calendar";
import {Form, Radio, Select, Textarea} from "../../../utils/Form";
import React, {Component} from 'react';
import Grid from "../../modules/Grid";
import {connect} from "react-redux";
import subscriptionActions from "../../../actions/subscription";
import Loading from "../../../utils/Loading";
import alertMessageActions from "../../../actions/alertMessage";

class Formulario extends Component {
    opcoes = {'FE': 'JORNAL INCOMPLETO', 'F3': 'JORNAL RASGADO/MOLHADO', 'F4': 'NÃO RECEBEU EDIÇÃO DO DIA', 'F5': 'NÃO RECEBEU EDIÇÕES ANTERIORES', 'F7': 'RECEBEU COM ATRASO'};

    sendMessage() {
        let form = document.getElementById('form-relamacao-entrega');
        if (form) {
            let bodyFormData = new FormData(form);
            bodyFormData.append('pessoa_contato', this.props.assinatura.recebedor.documento);
            Loading.add();
            this.props.dispatch(alertMessageActions.removeAlert());
            Form.send("/subscription/" + this.props.assinatura_selecionada + "/delivery-complaint", bodyFormData).then((response) => {
                Loading.remove();
                window.scrollTo(0, 0);
                let {data, status} = response;
                if (status === 200) {
                    this.props.dispatch(alertMessageActions.setAlert('green', data.message || 'Reclamação enviada com sucesso!', 120000), false);
                } else if (data.error) {
                    let message = data.error.message || "Ops algo deu errado!";
                    Form.showErrors(message);
                }
            }).catch((e) => {
                Loading.remove();
            });

        }
    }

    render() {
        if (!this.props.assinatura && this.props.assinatura_selecionada) {
            this.props.dispatch(subscriptionActions.getDados(this.props.assinatura_selecionada))
        }
        return (
          <div>
              <div className='reclamacao-entrega-content'>
                  <form id='form-relamacao-entrega' action='' className="block-single">
                      <div className='row'>
                          <Calendar name='data_ocorrencia' id='data_ocorrencia'/>
                          <Grid cols='12 12 12 7 7'>
                              <Select data={this.opcoes} id='motivo' name="motivo" label="* Irregularidade na Entrega:"/>
                          </Grid>
                          <Grid cols='12 12 12 12 12'>
                              <Textarea name='descricao' id='descricao' label="* Detalhe o que aconteceu, por favor:"/>
                          </Grid>
                          <Grid cols='12 12 12 12 12'>
                              <Radio name="acao" value="3" label="Prorrogação do exemplar no final de cada ciclo de pagamento " id='duaslinhas' checked='true'/>
                          </Grid>
                      </div>
                  </form>
              </div>
              <button className="btn blue salvar-dados ajustes" data-dtkey="salvar_dados" onClick={() => {
                  this.sendMessage()
              }}><i className="icon-paper-plane"/> Enviar
              </button>
          </div>)
    }
}

const mapStateToProps = state => {
    return {
        usuario: state.user,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.assinatura[state.assinaturas.assinatura_selecionada] : null
    }
}

export default connect(mapStateToProps)(Formulario)