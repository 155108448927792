import React, { Component } from 'react'
import Grid from "../../modules/Grid";
import {Input, Select, InputCEP} from "../../../utils/Form";
import axios from "axios";
import env from "../../../env";

class Endereco extends Component {
    state = {
        uf: null,
        states: [],
        state_load: false,
        citys: [],
        city_load: false,
        show_dica_input: false,
    }

    componentDidMount() {
        this._updateStates();
    }

    _updateStates(){
        return new Promise((resolve, reject) => {
            if(!this.state.state_load){
                this.setState({
                    state_load: true
                });
                axios.get(env.API_URL + "/utils/address/states", {withCredentials: true})
                    .then(response => {
                        let {data} = response, states = {};
                        for (let i in data) {
                            if(data.hasOwnProperty(i))
                                states[data[i].uf] = data[i].uf;
                        }

                        this.setState({
                            states: states,
                            state_load: false
                        });

                        resolve(states);

                    }).catch((response) => {
                        this.setState({
                            state_load: false
                        });
                        reject(response);
                    });
            }else{
                resolve(this.state.states);
            }
        });
    }

    _updateCitys(uf){
        return new Promise((resolve, reject) => {
            if(uf && !this.state.city_load){
                this.setState({
                    city_load: true
                });
                axios.get(env.API_URL+"/utils/address/"+uf+"/citys", {withCredentials: true})
                    .then(response => {
                        let {data} = response, citys = {};
                        for (let i in data) {
                            if(data.hasOwnProperty(i))
                                citys[data[i].cidade] = data[i].cidade;
                        }

                        this.setState({
                            citys: citys,
                            city_load: false
                        });

                        resolve(citys);
                    }).catch((response) => {
                        this.setState({
                            city_load: false
                        });
                        reject(response);
                    });
            }else{
                resolve(this.state.citys);
            }
        })
    }

    changeState = e => {
        return this._updateCitys(this.uf.select.value);
    }

    setCitySerchValue = () => {}

    render(){
        return(
            <div>
                <div className="row">
                    <Grid cols="12 12 4 4 3">
                        <InputCEP search={{
                            'endereco': 'logradouro',
                            'bairro': 'bairro',
                            'estado': 'uf',
                            'cidade': 'localidade'
                        }} callback={(response) => {
                            this.setCitySerchValue = (citys) => {
                                if(!response.erro){
                                    let city = response.localidade.toLowerCase().removeAssents();
                                    for(let i in citys){
                                        if(citys.hasOwnProperty(i)){
                                            if(citys[i].toLowerCase().removeAssents().indexOf(city) > -1){
                                                document.querySelector('#input_cidade').setValue(i);
                                            }
                                        }
                                    }
                                }
                            };
                            if(!this.state.city_load){
                                this.setCitySerchValue(this.state.citys);
                            }
                        }} name="cep" label="CEP" />
                    </Grid>
                    <Grid cols="12 12 6 5 7" >
                        <Input name="endereco" label="Endereço" />
                    </Grid>
                    <Grid cols="12 12 2 3 2">
                        <Input name="numero" label="Número" />
                    </Grid>
                </div>
                <div className="row">
                    <Grid cols="12 12 4 4 4">
                        <Input name="complemento" label="Complemento"  />
                    </Grid>
                    <Grid cols="12 12 3 3 3">
                        <Input name="bairro" label="Bairro" />
                    </Grid>
                    <Grid cols="12 12 2 2 2" >
                        <Select ref={el => this.uf = el} data={this.state.states} onChange={
                            (e) => {
                                this.changeState(e).then((citys) => {
                                    this.setCitySerchValue(citys);
                                });
                            }
                        } name="estado" label="UF" load={this.state.state_load} />
                    </Grid>
                    <Grid cols="12 12 3 3 3">
                        <Select data={this.state.citys} name="cidade" label="Cidade" load={this.state.city_load} />
                    </Grid>
                </div>
                <div className="row">
                    <Grid cols="12 12 6 6 6" >
                        <Input name="referencia" label="Ponto de referência" />
                    </Grid>
                    <Grid cols="12 12 6 6 6">
                        <Select label="Dica de entrega" name={this.state.show_dica_input ? "" : "dica"}
                                data={{
                                    'Deixar o jornal na portaria': 'Deixar o jornal na portaria',
                                    'Jogar o jornal por cima do portao': 'Jogar o jornal por cima do portão',
                                    'Jogar o jornal na garagem': 'Jogar o jornal na garagem',
                                    'Jogar o jornal na varanda': 'Jogar o jornal na varanda',
                                    'Deixar o jornal na caixa do correio': 'Deixar o jornal na caixa do correio',
                                    'outros': 'Outros'
                                }}
                                onChange={(e) => {
                                    if (e.target.value === 'outros' && !this.state.show_dica_input) {
                                        this.setState({
                                            show_dica_input: true
                                        });
                                    } else if(e.target.value !== 'outros' && this.state.show_dica_input) {
                                        this.setState({
                                            show_dica_input: false
                                        });
                                    }
                                }}/>
                        {this.state.show_dica_input && (
                            <Input name="dica" label="Descrição"/>
                        )}
                    </Grid>
                </div>
            </div>
        )
    }
}

export default Endereco