import noScroll from 'no-scroll';
import store from './store'
import configAction from './actions/config';
import userActions from "./actions/user";
import './less/outside.less';
import {sendGTMGA4Event} from '../../../src/utils/tagmanager';

export default class Iframe {
    constructor(append_scripts) {
        this.loaded = false;
        this.append_scripts = append_scripts || [];
        this.createIframe();
    }

    createIframe() {
        if (!this.iframe) {
            let state = store.getState();
            let config = state.config;
            this.iframe = document.createElement('iframe');
            this.iframe.id = config.idIframe;
            this.iframe.srcdoc = "";

            this.wrapper = document.createElement('div');
            this.wrapper.id = 'meu-estadao-menu-lateral-wrapper';


            this.wrapper.appendChild(this.iframe);

            if (document.readyState === "complete"
                || document.readyState === "loaded"
                || document.readyState === "interactive") {
                document.querySelector('body').appendChild(this.wrapper);
            } else {
                document.addEventListener('DOMContentLoaded', () => {
                    document.querySelector('body').appendChild(this.wrapper);
                }, false);
            }

            this.iframe.onload = () => {
                this.iframe.contentWindow.ESTADAO_MENU = window.ESTADAO_MENU;
                this.loaded = true;

                this.iframe.contentWindow.CLEAR_SAVES = () => {
                    window.localStorage.removeItem("noticias_salvas");
                }

                this.iframe.contentWindow.sendGTMGA4Event = (obj) => {
                    sendGTMGA4Event(obj);
                }

                let append_script = this.append_scripts;
                this.append_scripts = {
                    push: (script) => {
                        script(this.iframe.contentWindow);
                    }
                };

                for (let i in append_script) {
                    if (append_script.hasOwnProperty(i))
                        this.append_scripts.push(append_script[i])
                }

                this._writeIframe();
                config.onLoadMenu();
                store.subscribe((a, b) => {
                    this._writeIframe();
                });

            };

            this.wrapper.addEventListener('click', () => {
                this.close();
            });
        }

    }

    orderSubscription(order){
        const {config} = store.getState();
        const appUrl = `/app/subscription/${order}/payment-history`

        const xhttp = new XMLHttpRequest()

        xhttp.withCredentials = true

        xhttp.onload = function () {
            window.RES_MENU_SUBS = JSON.parse(this.responseText);
        }
        
        xhttp.open("GET", config.meuEstadaoUrl + appUrl, true);
        xhttp.send(null)
    }

    _writeIframe() {
        let state = store.getState();
        window.ESTADAO_MENU.events.clear();
        this.iframe.contentWindow.document.documentElement.innerHTML = (require('./html/index.html')({
            style: require('./less/main.less'),
            state: state,
            content: () => {
                let html = require('./html/header.html')({
                    state: state,
                    is_game: () => {
                        return '';
                    },
                    hidden: () => {
                        return '';
                    },
                });

                if (state.user.id) {
                    html += "<div class='content'>";

                    window.ESTADAO_MENU.events.add('updateAssinatura', (e) => {
                        let value = e.value;
                        if (value) {
                            state.config.updateAssinatura(value);
                            configAction.setConfig({
                                assinatura_selecionada: value
                            });
                        }
                    });

                    html += require('./html/user-container.html')({
                        state: state,
                        show_cta: !state.user.assinante && !state.user.block && !state.user.colaborador,
                        htmlAssinaturas: () => {
                            if (state.user.colaborador) {
                                return `<span class="btn gray">Você está logado como colaborador</span>`;
                            } else if (!state.user.assinante && !state.user.block) {
                                return `<span class="btn gray">Você ainda não é assinante</span>`;
                            } else if (Object.keys(state.user.subscriptions).length > 0 && state.user.subscriptions[0]) {
                                return ''
                            } else {
                                let assinaturas = ``;

                                if (Object.keys(state.user.subscriptions).length > 0) {
                                    for (let i in state.user.subscriptions) {
                                        const item = state.user.subscriptions[i];
                                        const option = `${i} - ${item.label_produto_midia}`;
                                        const isSelected = state.config.assinatura_selecionada === i ? "selected" : "";

                                        assinaturas += `<option value='${i}' ${isSelected}>${option}</option>`;
                                    }

                                    return `<div class="assinaturas"><select name="assinatura" onchange="window.ESTADAO_MENU.events.run('updateAssinatura', this)">${assinaturas}</select></div>`;
                                }

                                return `<span class="btn transparent"><i class="icon load small"></i>Carregando assinaturas...</span>`;
                            }
                        },
                        htmlMeuMenu: () => {
                            let html = ``;
                            const assinante = state.user.assinante && state.config.assinatura_selecionada !== "PROCESSANDO";
                            let historico_de_pagamento = (assinante || state.user.block) ? (
                                typeof state.config.meuEstadaoMenu.historico_de_pagamento === 'string' ?
                                    state.config.meuEstadaoMenu.historico_de_pagamento.replace('{assinatura_selecionada}', state.config.assinatura_selecionada) :
                                    state.config.meuEstadaoMenu.historico_de_pagamento
                            ) : false;

                            let menuEstadaoMenu = {
                                "Atualizar dados cadastrais": state.config.meuEstadaoMenu.dados_cadastrais,
                                "Notícias salvas": () => {
                                    const iframe = document.getElementById("meu-estadao-menu-lateral-salvos-iframe");

                                    if (!iframe) {
                                        let loaded = false
                                        let initialized = false

                                        const open = (linkListEstadao, logout) => {
                                            if (!loaded) load();

                                            if (!initialized) init({linkListEstadao, logout});

                                            (window.ESTADAO_SALVOS = window.ESTADAO_SALVOS || []).push(function (menu) {
                                                menu.open()
                                            })
                                        }

                                        const init = ({linkListEstadao, logout}) => {
                                            (window.ESTADAO_SALVOS = window.ESTADAO_SALVOS || []).push(function (menu) {
                                                menu.init({
                                                    linkListEstadao,
                                                    logout
                                                })
                                            })

                                            initialized = true
                                        }

                                        const load = () => {
                                            const s = document.createElement('script')
                                            s.setAttribute('src', 'https://meu.estadao.com.br/static/js/salvos.js')
                                            document.head.append(s)
                                            s.addEventListener('load', () => (loaded = true))
                                        }

                                        open();
                                    } else {
                                        iframe.contentWindow.ESTADAO_SALVOS.open();
                                    }
                                },
                                "Gerenciar Newsletters": state.config.meuEstadaoMenu.newsletter,
                                "Histórico de Pagamentos": historico_de_pagamento,
                                "Alterar senha": state.config.meuEstadaoMenu.alterar_senha,
                            };

                            if (state.config.meuEstadaoMenu.extra) {
                                for (let i in state.config.meuEstadaoMenu.extra) {
                                    menuEstadaoMenu[i] = state.config.meuEstadaoMenu.extra[i];
                                }
                            }

                            for (let i in menuEstadaoMenu) {
                                let link = menuEstadaoMenu[i];

                                let href = typeof link === 'string' ? link :
                                    typeof link === 'function' ? "javascript:" + window.ESTADAO_MENU.events.create(link) : 'javascript:void(0)';
                                html += `<li><a data-dtkey="menu_lateral" class="${!link ? 'lock' : ''}" href="${href}">${i + (!link ? '<i class="icon lock"></i>' : "")}</a></li>`;
                            }

                            return html;
                        },
                        htmlFoto: () => {
                            let img = `<div class="icon user"></div>`;
                            if (state.user.foto_url) {
                                img = `<div class="cover" style="background-image: url(${state.user.foto_url})"></div>`;
                            }
                            return `<a href="https://meu.estadao.com.br/" target="_blank">${img}</a>`;
                        },
                        htmlLinkList: () => {
                            let html = ``;

                            let linkList = state.config.linkListEstadao;
                            if (linkList) {
                                Object.keys(linkList).map((label, key) => {
                                    html += `<li><a href='${linkList[label]}'>${label}</a></li>`;
                                });
                            }

                            return html;
                        }
                    });

                    html += "</div>";

                    return html
                }
                html += "<div class='loading-user-info'><div class='center'><i class='icon load'></i></div></div>";
                html += require('./html/button-sair.html')({state: state});
                return html;
            }
        }));
    }

    open() {
        let state = store.getState();
        if (state.config && !state.config.hasOwnProperty('user')) {
            userActions.getUser();
        }
        if (this.wrapper) {
            this.wrapper.style.display = 'inline-block';
            setTimeout(() => {
                this.wrapper.classList.add('show')
            }, 50);
            noScroll.on();
        }
    }

    close() {
        if (this.wrapper) {
            this.wrapper.classList.remove('show');
            setTimeout(() => {
                this.wrapper.style.display = '';
            }, 550);
            noScroll.off();
        }
    }

    toggle() {
        if (this.wrapper) {
            if (this.wrapper.classList.contains('show')) {
                this.close();
            } else {
                this.open();
            }
        }
    }

    destroy() {
        if (this.iframe) {
            this.iframe.remove();
            this.iframe = null;
        }

        if (this.wrapper) {
            this.wrapper.remove();
            this.wrapper = null;
        }

        noScroll.off();
    }
}
