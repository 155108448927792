import env from '../env'
import axios from 'axios'
import { API, APP_ENGINE, API_LOGIN } from '../utils/asyncActionType'
import CacheControl from '../utils/CacheControl';
import Cookie from '../utils/Cookie';
import alertMessageAction from './../actions/alertMessage';

let sent_requests = [];
let connection = true;

export const apiMiddleware = ({ dispatch }) => next => action => {
    if (action.type !== API && action.type !== APP_ENGINE && action.type !== API_LOGIN)
        return next(action);

    const {payload} = action;

    let base_url = '';

    switch (action.type) {

        case API_LOGIN:
            base_url = env.API_LOGIN;
            break;

        case APP_ENGINE:
            base_url = env.APP_ENGINE_URL;
            break;

        default:
            base_url = payload.url.indexOf('/data/') !== -1 ? env.APP_URL : env.API_URL;

    }

    let URL = base_url + payload.url;

    if(!payload.enable_reload){
        if(sent_requests.indexOf(URL) > -1){
            return next(action);
        }
        sent_requests.push(URL);
    }

    dispatch({ type: payload.PENDING, request: payload });

    if (env.APP_DEBUG)
        console.log('apiMiddleware :: payload ::', payload);

    if(payload.force_cache){
        let response = new CacheControl(URL, Cookie.get('PORTAL_AUTH')).get(payload.force_cache);
        if(response && (!payload.test_response || payload.test_response(response))){
            dispatch({ type: payload.SUCCESS, payload: {...response}, request: payload });
            return;
        }
    }

    let cache;
    if(payload.cache){
        cache =  new CacheControl(URL, Cookie.get('PORTAL_AUTH'));
        cache =  cache.get(payload.cache);
        if(cache && (!payload.test_response || payload.test_response(cache))){
            dispatch({ type: payload.CACHED , payload: {...cache}, request: payload});
        }
    }

    let withCredentials = payload.withCredentials === undefined || payload.withCredentials;
    const axiosGetCall = () => {
        axios.get(URL, {withCredentials: withCredentials,'headers':payload.headers})
            .then(response => {
                if(!connection){
                    dispatch(alertMessageAction.setAlert('green', 'Sua conexão com a internet voltou!', 5000))
                    connection = true;
                }
                // Quantidade de minutos para salvar no cache
                if((!payload.test_response || payload.test_response(response.data))){
                    if(payload.cache){
                        new CacheControl(URL, Cookie.get('PORTAL_AUTH')).set(response.data);
                    }
                }else if(payload.attempts && payload.attempts > 0){
                    --payload.attempts;
                    axiosGetCall();
                }

                dispatch({ type: payload.SUCCESS, payload: {...response.data}, request: payload });
            })
            .catch((err) => {
                if(String(err).indexOf('Network Error') > -1){
                    connection = false;
                    dispatch(alertMessageAction.setAlert('red', 'Atenção, você está sem conexão à internet!', false))
                    setTimeout(() => {
                        dispatch(action)
                    },5000);
                }
                let index = sent_requests.indexOf(URL);
                if (index > -1) {
                    sent_requests.splice(index, 1);
                }

                if(payload.attempts_error && payload.attempts && payload.attempts > 0){
                    --payload.attempts;
                    axiosGetCall();
                }else if(cache && (!payload.test_response || payload.test_response(cache))){
                    dispatch({ type: payload.CACHED , payload: {...cache}, request: payload});
                }else{
                    dispatch({ type: payload.FAILURE, request: payload});
                }
            })
    };

    if (env.APP_ENV === 'local')
        setTimeout(() => axiosGetCall(), env.APP_DEBUG_TIMEOUT);
    else
        axiosGetCall()
};
