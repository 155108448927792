import React, {Component} from 'react'
import {connect} from 'react-redux';
import alertMessageActions from '../../actions/alertMessage';
import Cookie from '../../utils/Cookie';

class AlertMessage extends Component {

    timeout = null;

    constructor(props) {
        super(props);

        this.componentDidUpdate();
    }

    handleRemove(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.refs.alert_row) {
            this.refs.alert_row.classList.remove('show');
        }
    }

    componentDidUpdate() {
        if (this.props.alert.message && Object.keys(this.props.alert.message).length > 0) {
            setTimeout(() => {
                if (this.refs.alert_row) {
                    this.refs.alert_row.classList.add('show');
                }
            }, 100);
        }
    }

    redirectLink(link) {
        this.props.dispatch({type: "REDIRECT", redirect: link});
    }

    render() {
        const alert = this.props.alert;
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        if (alert.timeout) {
            this.timeout = setTimeout(() => {
                if (this.refs.alert_row) {
                    this.refs.alert_row.classList.remove('show');
                }
            }, alert.timeout)
        }

        if (alert.message && Object.keys(alert.message).length > 0 && typeof alert.message === 'string') {
            alert.message = [alert.message];
        }

        return (
            <div className='alert-row' ref="alert_row">
                {alert.message && Object.keys(alert.message).length > 0 ? Object.keys(alert.message).map((i) => {

                    let alertPush;
                    let obj = {};
                    obj['genericCategory'] = 'engajamento';
                    obj['genericAction'] = 'meu_estadao';
                    if (this.props.dtkey === "barra_vermelha") {
                        if (typeof alert.message[i] !== 'undefined' && alert.message[i].indexOf('não identificamos o pagamento da sua assinatura') > -1) {
                            obj['genericLabel'] = 'barra_vermelha_show_inadimplente';
                            if (!Cookie.get('barra_vermelha_show_inadimplente')) {
                                alertPush = new CustomEvent('alertPush', {'detail': obj});
                                window.dispatchEvent(alertPush);
                                Cookie.set('barra_vermelha_show_inadimplente', 'true');
                            }
                        } else {
                            obj['genericLabel'] = 'barra_vermelha_show_não_assinante';
                            if (!Cookie.get('barra_vermelha_show_nao_assinante')) {
                                alertPush = new CustomEvent('alertPush', {'detail': obj});
                                window.dispatchEvent(alertPush);
                                Cookie.set('barra_vermelha_show_nao_assinante', 'true');
                            }
                        }
                    } 
                    // else if (this.props.dtkey === "barra_verde") {
                    //     if (!Cookie.get('barra_vermelha_show_inadimplente')) {
                    //         obj['genericLabel'] = 'barra_verde_show_newsletter';
                    //         alertPush = new CustomEvent('alertPush', {'detail': obj});
                    //         window.dispatchEvent(alertPush);
                    //         Cookie.set('barra_verde_show_newsletter', 'true');
                    //     }
                    // }

                    return (
                        <div key={i} className={"alert-mensagem" + (alert.link ? " link" : "")} data-color={alert.color}
                             onClick={(e) => {
                                 if (alert.link) {
                                     e.preventDefault();
                                     this.redirectLink(alert.link);
                                 }
                             }}>
                            <span dangerouslySetInnerHTML={{__html: alert.message[i]}}/>
                            {alert.link ? (
                                <button className="btn-clique-aqui">Clique aqui</button>
                            ) : (
                                ''
                            )}
                            <i className='icon-cancel right'
                               onClick={(e) => this.handleRemove(e)}></i>
                        </div>
                    )
                }) : ''}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        alert: state.alert
    }
}

export default connect(mapStateToProps)(AlertMessage)