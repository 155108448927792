import Cookie from './../utils/Cookie';
import env from '../env'
import axios from 'axios'

let is_estadao_domain = window.location.host.indexOf('estadao.com.br') > -1;

const getNewslettersAllFromAPI = () => new Promise((res, rej) => {
    try {
        if (window.location.href.indexOf('devl.meu.estadao') > -1) {
            axios.get(env.API_URL + '/utils/links-logout').then(newsletterAPI => {
                res(newsletterAPI.data) 
            })
        } else {
            axios.get(window.location.origin+'/app/utils/links-logout').then(newsletterAPI => {
                res(newsletterAPI.data) 
            })
        }
    } catch (error) {
        rej([])
    }
})  

export const authMiddleware = () => next => action => {
    getNewslettersAllFromAPI().then(paths => {
        let PORTAL_AUTH = Cookie.get('PORTAL_AUTH');

        let enable_list_path = Array.from(paths).map(pathObj => {
            return pathObj.path
        })

        if(is_estadao_domain && enable_list_path.indexOf(window.location.pathname) === -1 && !PORTAL_AUTH){
            window.location = env.URL_ACESSO+"/login?r="+window.location.href;
            return
        }
    
        return next(action);
    })
}