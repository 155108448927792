import React, {Component} from 'react'
import DocumentHead from "../../utils/DocumentHead"
import {connect} from "react-redux"
import partnersActions from '../../actions/partners'
import AlertMessage from "../alert-message/alertMessage";
import Assinaturas from "../assinaturas";
import Titulo from "../modules/Titulo";
import Grid from '../modules/Grid'
import BlocoDeParceiros from "../blocks/partners/partners";

class ParceirosServicos extends Component {

    componentDidMount() {
        DocumentHead({
            title: 'Parceiros e Serviços – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });
        this.props.dispatch(partnersActions.getPartners());
    }


    render() {
        const parceiros = this.props.partners.parceiros;

        return (
            <div className="container justify-content-center">
                <div className="header-content row nomargin">
                    <Grid cols='12 12 12 6 6' class='text-left nopadding welcome-wrapper align-center'>
                        <Titulo groupItem="Parceiros e Serviços"/>
                    </Grid>
                    <Grid cols='12 12 12 6 6' class='text-left nopadding text-right hidden-xs hidden-sm hidden-md'>
                        <Assinaturas />
                    </Grid>
                    <AlertMessage />
                </div>

                <div className="partners-container">
                    {parceiros && Object.keys(parceiros).map((key) => {
                        return (
                            <div className="item">
                                <BlocoDeParceiros key={key} content={parceiros[key]} />
                            </div>
                        )
                    })}
                </div>
            </div>
        )

    }
}

const mapStateToProps = state => {
    return {...state}
}

export default connect(mapStateToProps)(ParceirosServicos);