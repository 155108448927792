import React, { Component } from 'react'
import Grid from '../../modules/Grid'
import DocumentHead from '../../../utils/DocumentHead'
import Assinatura from '../../assinaturas'
import AlertMessage from '../../alert-message/alertMessage'
import Titulo from "../../modules/Titulo";
import FotoUsuario from "../foto-usuario";
import Formulario from "./formulario";

class AlterarSenha extends Component {
    componentDidMount() {
        DocumentHead({
            title: 'Alterar Senha – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        })
    }

    render() {
        return (
            <div className="container meus-dados justify-content-center">
                <div className="header-content row">
                    <Grid cols='12 12 12 6 6' class='text-left welcome-wrapper align-center'>
                        <Titulo groupItem="Meus dados" titulo="alterar senha"/>
                    </Grid>
                    <Grid cols='12 12 12 6 6' class='text-left text-right hidden-xs hidden-sm hidden-md'>
                        <Assinatura />
                    </Grid>
                    <AlertMessage />
                </div>

                <div className="row data-user">
                    <Grid cols="12 12 12 4 4">
                        <FotoUsuario/>
                    </Grid>
                    <Grid cols="12 12 12 8 8">
                        <Formulario/>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default AlterarSenha;