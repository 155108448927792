import React, { Component } from 'react';
import ContentLoader from "./../content-loader/content-loader"
import {connect} from "react-redux";
import env from "../../env";

const NotificaUsuarioLoader = props => (
    <ContentLoader
        height={35}
        width={85}
        style={{maxWidth: '85px', width: '100%', height: '35px', float: 'right'}}
        primaryColor="#eaeaea"
        secondaryColor="#e4e4e4"
        mobileprops={{
            height: 20,
            width: 20,
            style:{ width: '20px', height: '20px', float: 'right '},
            children: (
                <rect x="0" y="0" rx="20" ry="20" width="20" height="20" />
            )
        }}
    >
        <rect x="0" y="0" rx="3" ry="3" width="85" height="35" />
    </ContentLoader>
)

class Notificacao extends Component {

    render() {
        return (
            this.props.user.id ? (
            <div className="user-items">
                <div className="user-informations" onClick={() => {
                    if(this.props.user.has_cookie){
                        (window.ESTADAO_MENU || []).push((menu) => {
                            menu.toggle();
                        })
                    }else{
                        window.location = env.URL_ACESSO+"/login?r="+window.location.href;
                    }
                }}>
                    <div className="user-image">
                        {this.props.user.foto_url ? (
                            <div className="image" style={{backgroundImage: "url("+this.props.user.foto_url+")"}} />
                        ):(
                            <i className="fa icon-user-circle-o" />
                        )}
                    </div>
                    <div className="user-name hidden-xs">
                        {this.props.user.has_cookie ? this.props.user.nome : "Entrar"}
                    </div>
                </div>
            </div>
            ) : (
                <NotificaUsuarioLoader />
            )
        )
    }
}

const mapStateToProps = state => {
    return { ...state }
}

export default connect(mapStateToProps)(Notificacao);