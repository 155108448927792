import React, { Component } from 'react'
import { connect } from 'react-redux'

import toastActions from '../../actions/toast';

class Toast extends Component {
    timeout = 0;

    componentWillUnmount() {
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
    }

    render() {
        const { toast, dispatch } = this.props;

        // if (this.timeout) {
        //     clearTimeout(this.timeout);
        // }

        if (toast.timeout) {
            this.timeout = setTimeout(() => {
                dispatch(toastActions.remove(toast.id));
            }, toast.timeout)
        }

        return (
            <div className="toast show">
                <div className="alert-mensagem" data-color={toast.color}>
                    <span dangerouslySetInnerHTML={{ __html: toast.message }}></span>
                    <i className="icon-cancel right" onClick={() => dispatch(toastActions.remove(toast.id))}></i>
                </div>
            </div>
        );
    }
}

class ToastContainer extends Component {
    render() {
        const { toast, dispatch } = this.props;

        if (!toast || !toast.messages) return null;

        return (
            <div className="toast-container">{toast.messages.map(toast => (
                    <Toast key={toast.id} toast={toast} dispatch={dispatch} />
                ))}
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        toast: state.toast
    }
}


export default connect(mapStateToProps)(ToastContainer);