import React, {Component} from 'react';

class BlocoDePublicacao extends Component {
    render() {
        const content = this.props.content;
        return (
            <div className="slide-item publishing" style={{
                height: "194px",
                backgroundImage: "url('" + content.img + "')"
            }} data-dtkey="publicacoes" title={content.title}>
                <a href={content.link ? content.link : "#"} rel="noopener noreferrer" target="_blank" onClick={e => {
                    !content.link && e.preventDefault()
                }} className="link" title={content.title} style={{height: "194px", width: "206px"}}>
                </a>
            </div>
        )
    }
}

export default BlocoDePublicacao;