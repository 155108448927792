import React, { Component } from 'react'
import {Form} from "../../../utils/Form";
import Loading from "../../../utils/Loading";
import alertMessageActions from '../../../actions/alertMessage';
import Calendar from './calendar'
import {connect} from "react-redux";
import subscriptionActions from "../../../actions/subscription";
import FilterLinks from "../../../utils/filterLinks";

class Formulario extends Component {

    componentDidUpdate() {
        let links = FilterLinks();
        if(this.props.assinatura_selecionada && !links.get('interrupcao_temporaria')){
            this.props.dispatch({type: "REDIRECT", redirect: "/"});
        }
    }

    sendMessage(){
        if(!(this.props.assinatura && this.props.assinatura.recebedor && this.props.assinatura.recebedor.id)){
            this.props.dispatch(alertMessageActions.setAlert('red', "Ops algo deu errado! Não foi possível concluir sua solicitação. Tente novamente ou entre em contato: Capital : (11) 4003-5323, Interior: 0800 014 77 20", 10000));
            if(this.props.assinatura_selecionada){
                this.props.dispatch(subscriptionActions.getDados(this.props.assinatura_selecionada, {enable_reload: true, attempts: 0}))
            }
            return;
        }
        let form = document.getElementById('form-interrupcao-temporaria');
        if(form){
            let bodyFormData = new FormData(form);
            bodyFormData.append('pessoa_contato', this.props.assinatura.recebedor.id);

            Loading.add();
            this.props.dispatch(alertMessageActions.removeAlert());
            Form.send("/subscription/"+this.props.assinatura_selecionada+"/temporary-interruption-delivery", bodyFormData).then((response) => {
                Loading.remove();
                window.scrollTo(0, 0);
                let {data, status} = response;
                if(status === 200){
                    this.props.dispatch(alertMessageActions.setAlert('green', data.message || 'Salvo com sucesso!', false));
                    this.props.dispatch({type: "REDIRECT", redirect: "/"});
                }else if(data.error){
                    let message = data.error.message || "Ops algo deu errado!";
                    Form.showErrors(message,20000);
                }
            }).catch((e) => {
                Loading.remove();
            });
        }
    }

    render(){
        if(!this.props.assinatura && this.props.assinatura_selecionada){
            this.props.dispatch(subscriptionActions.getDados(this.props.assinatura_selecionada))
        }

        return (
            <div>
                <div className="block-single text">
                    <h3>* Data de ínicio e fim da interrupção de entrega: </h3>
                    <form id="form-interrupcao-temporaria" action="" method="POST" className="content-form">
                        <Calendar />
                    </form>
                </div>

                <button className="btn blue salvar-dados" data-dtkey="salvar_dados" onClick={() => {this.sendMessage()}}><i className="icon-floppy" /> Salvar data de interrupção de entrega</button>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        usuario: state.user,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ?  state.assinatura[state.assinaturas.assinatura_selecionada] : null
    }
}

export default connect(mapStateToProps)(Formulario)