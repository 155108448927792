import React, {Component} from 'react'

class Clausulas extends Component {
    render() {
        return (

            <div className='texto'>
                <h3>
                    Condições Gerais de Contratação de Assinaturas - Estadão
                </h3>
                <p>A S.A."O Estado de S. Paulo" (“ESTADÃO”), situada na Av. Engenheiro Caetano
                    Álvares, 55, São Paulo - SP, CNPJ no. 61.533.949/0001-41, vem estabelecer, pela presente, as
                    Condições Gerais de Contratação de Assinaturas (“Condições Gerais”).
                </p>

                <p className='identacao'>As presentes Condições Gerais são aplicáveis para a disponibilização das versões
                impressa e digital do Jornal “O Estado de S.Paulo” e do Portal estadao.com.br, ambos de
                titularidade do ESTADÃO (“Itens Estadão”). O cliente interessado em contratar os Itens Estadão
                (o “Assinante”) deverá concordar expressamente com todos os termos aqui estipulados. <strong>
                Caso haja oferta agregada aos Itens Estadão, o ESTADÃO poderá estabelecer regras
                específicas, que deverão ser aceitas pelo Assinante.</strong></p>

                <ol>
                    <p><strong>1. Objeto</strong></p>
                </ol>


                <p>1.1. As presentes Condições Gerais aplicam-se ao Assinante que contratar qualquer das
                assinaturas dos Itens Estadão através do site https://assine.estadao.com.br/ ou pelos canais de
                vendas com atendimento ativo e/ou receptivo ao Assinante.</p>

                <ol start="2">
                    <p><strong>2. Do Prazo e da Forma de Pagamento</strong></p>
                </ol>


                <p>2.1. O Assinante poderá optar pela contratação de quaisquer Itens Estadão estabelecidos
                    nestas Condições Gerais, sendo que o Item Estadão contratado pelo Assinante estará disponível
                    para consulta no Meu Estadão (<a href="https://meu.estadao.com.br" target="_blank">https://meu.estadao.com.br</a>).</p>


                <p>2.2. As regras de prazo e forma de pagamento dos Itens Estadão serão regidas pelos
                    subitens abaixo.</p>


                <p>2.2.1. A assinatura de quaisquer Itens Estadão (“Assinatura”) poderá ser contratada através
                    dos canais mencionados no item 1.1 acima.</p>


                <p>2.2.2. Os Assinantes que realizarem a contratação da Assinatura por telefone também deverão
                    tomar ciência das presentes Condições Gerais através do Meu Estadão (<a href="https://meu.estadao.com.br" target="_blank">https://meu.estadao.com.br</a>)</p>


                <p>2.2.3. No momento da contratação da Assinatura, o Assinante deverá informar todos os dados que lhe forem solicitados (“Dados”).</p>


                <p>2.2.4. A modalidade de Assinatura contratada pelo Assinante, bem como seu valor e vigência estarão disponíveis para consulta no Meu Estadão (<a href="https://meu.estadao.com.br">https://meu.estadao.com.br</a>).</p>


                <p>2.2.5. A Assinatura somente poderá ser paga por meio de cartão de crédito ou débito em conta corrente, sendo que a forma de pagamento deverá ser informada pelo Assinante no momento de sua contratação.</p>


                <p>2.2.6. O débito da Assinatura será efetuado no primeiro dia útil seguinte à data de contratação da assinatura, a depender da forma de pagamento escolhida pelo Assinante.</p>


                <p>2.3. O valor da Assinatura poderá ser reajustado ou alterado pelo ESTADÃO, que deverá informar ao Assinante sobre a alteração do valor.</p>


                <p>2.3.1. Caso o atraso no pagamento da Assinatura, seja superior a 15 (quinze) dias, a referida Assinatura poderá ser suspensa até o adimplemento das obrigações em atraso, sendo facultado ao ESTADÃO, ainda, considerar rescindido o contrato por justo motivo.</p>


                <p>2.3.2. O ESTADÃO emitirá o documento fiscal ou recibo competente, em consonância com o disposto na Legislação Estadual ou Municipal, a depender da modalidade de Assinatura contratada. O Assinante poderá ter acesso a tal documentação e/ou informações adicionais através do Meu Estadão (https://meu.estadao.com.br).</p>


                <p>2.3.3. Todo e qualquer tributo que incida ou venha a incidir, em razão da presente contratação, será de inteira responsabilidade do contribuinte tributário, conforme definido na legislação em vigor.</p>


                <ol start="3">
                    <p><strong>3. Condições da Contratação de assinaturas do Portal estadao.com.br e das versões
                    impressa e digital do Jornal “O Estado de S.Paulo”
                    </strong></p>
                </ol>

                <ol>
                    <p><strong>I. Assinatura da versão impressa do Jornal “O Estado de S.Paulo”</strong></p>
                </ol>


                <p>3.1. As condições de contratação da Assinatura da versão impressa do Jornal “O Estado de S.Paulo” serão regidas pelos subitens abaixo.</p>


                <p>3.1.1. A primeira entrega do exemplar do Jornal “O Estado de S. Paulo” ocorrerá dentro de um prazo de até 07 (sete) dias úteis contados da efetivação da Assinatura.</p>


                <p>3.1.2. O Assinante deverá informar, no momento da contratação da Assinatura, os dados para entrega dos exemplares impressos do Jornal “O Estado de S. Paulo”, ficando o ESTADÃO isento de qualquer responsabilidade por eventuais incorreções dos dados de entrega fornecidos pelo Assinante.</p>


                <p>3.1.3. Fica desde já estabelecido que a efetivação da Assinatura será analisada pelo
                ESTADÃO e, caso o ESTADÃO não realize a entrega do Jornal “O Estado de S. Paulo”, na
                região solicitada pelo Assinante, o Assinante será comunicado da não efetivação da Assinatura.
                </p>


                <p>3.1.4. Não caracteriza inadimplemento contratual por parte do ESTADÃO, a não entrega de
                exemplares impressos do Jornal “O Estado de S. Paulo”, em decorrência de caso fortuito ou
                força maior, bem como na hipótese de incorreções nos dados fornecidos pelo Assinante para
                entrega dos exemplares impressos do Jornal “O Estado de S. Paulo”. </p>


                <p>3.1.5. Em caso de alteração de endereço para entrega, o ESTADÃO deverá ser comunicado
                imediatamente, através do Meu Estadão (<a href="https://meu.estadao.com.br">https://meu.estadao.com.br</a>) ou dos telefones 4003-
                5323 (Capitais e Regiões Metropolitanas) e 0800-014-7720 (Demais Localidades e para ligações
                realizadas de telefone fixo), de segunda à sexta feira das 6h30min as 18h00min e sábados
                domingos e feriados, das 6h30min às 13h00min, sendo facultado ao ESTADÃO o disposto no
                item 3.1.3 supra.</p>


                <p>3.1.6. Caso ocorra a descontinuidade da entrega do Jornal “O Estado de S. Paulo” no
                endereço do Assinante, a Assinatura será automaticamente cancelada, sem que quaisquer
                multas e/ou indenizações, de qualquer natureza, sejam imputados ao ESTADÃO.</p>


                <p>3.1.6.1. Na hipótese supramencionada, caso o pagamento da Assinatura tenha sido realizado à
                vista, o ESTADÃO se compromete a devolver o valor pago pelo Assinante, de forma proporcional.</p>


                <ol>
                    <p><strong>II. Assinaturas da versão digital do jornal “O Estado S.Paulo” e do Portal estadao.com.br</strong></p>
                </ol>


                <p>3.2. Para fins do exposto nas presentes Condições Gerais, cumpre estabelecer as seguintes
                modalidades de Assinaturas da versão digital do jornal “O Estado S.Paulo” e do Portal
                estadao.com.br:
                </p>


                <p>(a) <b>Assinatura Digital Completa</b>: consiste em disponibilização, sem cessão
                definitiva, ao jornal “O Estado de S.Paulo”, via Web, Tablets e Aplicativo Móvel e
                os conteúdos do Portal estadao.com.br via Web e aplicativo móvel; e
                </p>


                <p>(b) <b>Assinatura Digital Básica</b>: dará ao Assinante acesso aos conteúdos do Portal 
                estadao.com.br via Web e Aplicativo Móvel.</p>

                <p>3.2.1. Além das Assinaturas acima mencionadas, o Assinante poderá optar por contratar a 
                <b>Assinatura Impresso + Digital</b> l que dará ao Assinante direito a versão impressa do Jornal “O
                Estado de S.Paulo”, assim como acesso ao jornal “O Estado de S.Paulo”, via Web, Tablets e
                Aplicativo Móvel e os conteúdos do Portal estadao.com.br via Web e Aplicativo Móvel.</p>


                <p>3.3. As condições de aquisição das Assinaturas da versão digital do jornal “O Estado
                S.Paulo” e do Portal estadao.com.br serão regidas pelos subitens abaixo.</p>


                <p>3.3.1. O Assinante receberá, no momento da contratação da Assinatura, um login e uma senha
                para ter acesso aos conteúdos disponibilizados pelo ESTADÃO, de acordo com a modalidade de
                Assinatura contratada pelo Assinante. Caso o Assinante já seja cadastrado no Portal
                estadao.com.br, no momento da aquisição da Assinatura, poderá permanecer utilizando o
                mesmo login e senha, não sendo necessário o envio de um novo login e senha pelo ESTADÃO.
                </p>


                <p>3.3.2. O Assinante será responsável pela guarda e confidencialidade de seu login e senha de
                acesso aos conteúdos disponibilizados pelo ESTADÃO, devendo tomar todas as precauções
                necessárias para impedir o uso de sua senha por terceiros, sob pena de imediata rescisão da
                presente contratação.</p>


                <p>3.3.3. Os conteúdos são disponibilizados sem cessão definitiva (licenciamento) ao Assinante
                para uso próprio. Fica expressamente vedado ao Assinante: (i) qualquer tipo de distribuição ou
                redistribuição direta (terceiros) ou indireta (internet/intranet/extranet/telão e outros meios que
                existam ou que possam vir a existir) dos conteúdos e informações contidos no Jornal “O Estado
                de S.Paulo” e no Portal estadao.com.br e/ou em qualquer outro item de titularidade do
                ESTADÃO que o Assinante venha a ter acesso (“Conteúdos Estadão”); (ii) reprodução,
                veiculação e/ou divulgação dos Conteúdos Estadão; (iii) comercialização, cessão e/ou
                licenciamento dos Conteúdos Estadão; (iv) armazenamento dos Conteúdos Estadão; (v)
                utilização dos Conteúdos Estadão, como fonte de informação para elaboração de clipping de
                notícias ou qualquer outro tipo de informativo ou documento; sob pena de cancelamento da
                Assinatura e indenização pelos danos causados.
                </p>

                <p>3.3.4. O Assinante declara-se ciente de que os Conteúdos Estadão constituem-se propriedade
                exclusiva do ESTADÃO e de seus fornecedores, consoante as normas de propriedade intelectual
                e direitos autorais.
                </p>

                <p><strong>3.3.5. O ESTADÃO PODERÁ ALTERAR, A SEU CRITÉRIO, AS CARACTERÍSTICAS,
                APRESENTAÇÃO OU QUALQUER ASPECTO DOS CONTEÚDOS ESTADÃO,
                ACRESCENTANDO OU SUPRIMINDO INFORMAÇÕES, COM O QUE DESDE JÁ
                CONCORDA O ASSINANTE</strong></p>


                <p><strong>3.3.6. ALÉM DO EXPOSTO ACIMA, O ESTADÃO, A QUALQUER TEMPO, PODERÁ
                EXCLUIR E/OU ALTERAR O ACESSO A DETERMINADOS CONTEÚDOS ESTADÃO QUE A
                ASSINATURA PERMITA O ACESSO. </strong></p>


                <p><strong>3.3.7. EM NENHUMA HIPÓTESE O ESTADÃO OU SEUS FORNECEDORES PODERÃO SER
                RESPONSABILIZADOS POR QUAISQUER PERDAS E DANOS (INCLUINDO, MAS NÃO
                LIMITADOS, AOS LUCROS CESSANTES, INTERRUPÇÃO DE NEGÓCIOS, 
                PERDAS DE INFORMAÇÕES E OUTROS PREJUÍZOS PECUNIÁRIOS, DE QUALQUER NATUREZA)
                RESULTANTES DO USO OU DA IMPOSSIBILIDADE DE USO DOS CONTEÚDOS ESTADÃO.</strong>
                </p>


                <p>3.3.8. O Assinante reconhece e aceita que a isenção de responsabilidade do ESTADÃO
                prevista no item 3.3.7 supra, constitui fator determinante para a disponibilização dos Conteúdos
                Estadão, bem como tal hipótese foi devidamente considerada na fixação do preço da Assinatura.</p>


                <p><strong>3.3.9. O ESTADÃO NÃO TERÁ QUALQUER RESPONSABILIDADE POR PROBLEMAS
                OCORRIDOS NO ACESSO AOS CONTEÚDOS ESTADÃO, EM DECORRÊNCIA DE
                EVENTOS QUE SE CARACTERIZEM COMO CASO FORTUITO OU FORÇA MAIOR, ASSIM
                ENTENDIDAS AS CIRCUNSTÂNCIAS IMPREVISÍVEIS E INEVITÁVEIS QUE IMPEÇAM,
                TOTAL OU PARCIALMENTE, A EXECUÇÃO DAS OBRIGAÇÕES ORA ASSUMIDAS, E/OU
                QUE DEPENDAM DE SERVIÇOS DE RESPONSABILIDADE DE TERCEIROS E/OU DA
                ADMINISTRAÇÃO PÚBLICA, DIRETA OU INDIRETA.</strong></p>

                <p><strong>3.3.10. O ESTADÃO NÃO RESPONDE POR QUAISQUER DANOS OU PREJUÍZOS
                DECORRENTES VÍRUS, SPAMS, WORMS, DEFEITOS, CAVALOS DE TRÓIA, ARQUIVOS
                CORROMPIDOS, FALSIFICAÇÕES OU QUAISQUER ITENS DE NATUREZA DESTRUTIVA
                OU ENGANOSA OU OUTROS ELEMENTOS FÍSICOS OU ELETRÔNICOS QUE POSSAM
                DANIFICAR OU IMPEDIR O NORMAL FUNCIONAMENTO E ACESSO AOS CONTEÚDOS
                ESTADÃO, BEM COMO NA REDE, SISTEMA OU DOS EQUIPAMENTOS (HARDWARE E SOFTWARE) DOS ASSINANTES.
                </strong></p>

                <ol start="4">
                    <p>
                        <strong>4. Disposições Gerais.</strong>
                    </p>
                </ol>

                <p>4.1. As modalidades “Assinatura Digital Completa” e a “Assinatura Impressa + Digital” darão
                acesso ao Acervo Estadão constante no endereço <a href="https://acervo.estadao.com.br/">http://acervo.estadao.com.br/</a>.
                </p>
                
                <p>4.2. O Assinante reconhece que o acesso ao Portal estadao.com.br e/ou Acervo Estadão
                está condicionado ao cumprimento de seus Termos Uso disponíveis em: 
                <a href="https://www.estadao.com.br/termo-de-uso#">https://www.estadao.com.br/termo-de-uso#</a>.
                </p>

                <p><strong>4.3. O ESTADÃO, A QUALQUER TEMPO, PODERÁ ALTERAR O NOME E/OU AS MODALIDADES DE ASSINATURAS OFERTADAS AOS ASSINANTES. </strong></p>

                <p><strong>4.4. TODOS OS DADOS PESSOAIS COLETADOS DO ASSINANTE SERÃO INCORPORADOS DE FORMA DEFINITIVA A BASE DE DADOS DA S.A.
                "O ESTADO DE S. PAULO", SITUADA NA AV. ENGENHEIRO CAETANO ÁLVARES, 55, SÃO PAULO - SP,
                CNPJ Nº. 61.533.949/0001-41. O TRATAMENTO DOS DADOS PESSOAIS COLETADOS SE
                DARÁ CONFORME A POLÍTICA DE PRIVACIDADE DA S.A. “O ESTADO DE S. PAULO”,
                DISPONÍVEL EM:
                <a href="https://www.estadao.com.br/termo-de-uso#politica-privacidade">https://www.estadao.com.br/termo-de-uso#politica-privacidade</a>.</strong></p>

                <p><strong>4.4.1. O Assinante garante a veracidade e exatidão dos dados que fornecer ao ESTADÃO, ou
                seja, o ESTADÃO não tem qualquer responsabilidade no caso de inserção de dados falsos ou
                inexatidão dos dados pessoais informados pelo Assinante ao ESTADÃO..</strong></p>


                <p>4.5. A Assinatura poderá ser cancelada pelo Assinante, mediante notificação, através do
                site <a href="https://meu.estadao.com.br/fale-conosco">https://meu.estadao.com.br/fale-conosco</a>
                ou dos telefones 4003-5323 (Capitais e Regiões
                Metropolitanas) e 0800-014-7720 (Demais Localidades e apenas ligações realizadas de telefone fixo) de
                segunda à sexta feira das 8h00min as 18h00min, exceto em feriados locais e nacionais.</p>


                <p>4.5.1. Caso o pagamento da Assinatura tenha sido realizado à vista, o ESTADÃO se
                compromete a devolver o valor pago pelo Assinante, de forma proporcional, exceto se houver a
                ocorrência de inadimplemento contratual pelo Assinante ou em casos de ofertas promocionais
                por prazo determinado.
                </p>

                <p>
                Para eventuais dúvidas e/ou solicitações sobre as assinaturas dos Itens Estadão, exceto
                o pedido de cancelamento que se dará de acordo com exposto no item 4.5, o Assinante poderá
                contatar o ESTADÃO, através do Meu Estadão 
                (<a href="https://meu.estadao.com.br">https://meu.estadao.com.br</a>) ou dos telefones
                4003-5323 (Capitais e Regiões Metropolitanas) e 0800-014-7720 (Demais Localidades e para
                ligações realizadas de telefone fixo), de segunda à sexta feira das 6h30min as 18h00min e
                sábados domingos e feriados, das 6h30min às 13h00min.
                </p>

                <p>4.7. Na eventualidade de qualquer das disposições deste documento vir a ser considerada
                nula, anulável ou inaplicável, por qualquer razão, as demais disposições deste contrato
                permanecerão em vigor e inalteradas, continuando a vincular as partes</p>

                <p>4.8. Qualquer omissão ou tolerância do ESTADÃO, em exigir o estrito cumprimento das
                obrigações contratadas ou em exercer qualquer direito decorrente destas Condições, não
                constituirá novação ou renúncia, nem afetará o seu direito de exercê-los a qualquer tempo
                </p>

                <p>4.9. O ESTADÃO se reserva ao direito de modificar a qualquer momento, visando uma
                melhoria contínua, de forma unilateral, as presentes Condições Gerais. Ao contratar a
                Assinatura, o Assinante aceita reger-se pelas Condições Gerais que se encontram vigentes,
                portanto, deve consultá-las regularmente.</p>

                <p>4.10. Este instrumento será regido e interpretado de acordo com a legislação brasileira, sendo
                eleito o Foro da Comarca de São Paulo, Estado de São Paulo, para questões a ele relativas,
                com renúncia expressa a qualquer outro.</p>

            </div>
        )
    }
}

export default Clausulas