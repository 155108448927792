import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import alertMessageActions from "../../../actions/alertMessage";
import newsletterAction from "../../../actions/newsletter";
import env from "../../../env";
import DocumentHead from "../../../utils/DocumentHead";
import Loading from "../../../utils/Loading";
import Assinatura from "../../assinaturas";
import ContentLoader from "../../content-loader/content-loader";
import Grid from "../../modules/Grid";
import NewsletterInscricao from "../modulos/newsletter-inscricao";
import NewsletterUltima from "../modulos/newsletter-ultima";
import { sendGTMGA4Event } from "../../../utils/tagmanager";
import Titulo from "../../modules/Titulo";

const box_inscricao_ref = React.createRef();
var newsletter_modal = {};

class NewsletterAnonimo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email_input: "",
      id_newsletter: 0,
      chave_newsletter: "",
      ativo_newsletter: "",
      open_modal: false,
      open_modal_ultima: false,
      url_modal_ultima: "",
      inscrito: {},
      inscrito_total: 0,
      errors: {
        email: "",
      },
    };

    this.changeEmail = this.changeEmail.bind(this);
    this.cleanEmail = this.cleanEmail.bind(this);
    this.cleanErrors = this.cleanErrors.bind(this);
    this.setChaveNewsletter = this.setChaveNewsletter.bind(this);
    this.handleClickBoxInscricao = this.handleClickBoxInscricao.bind(this);
  }
  loader() {
    return (
      <ContentLoader
        height={"30vh"}
        width={"100%"}
        style={{
          width: "100%",
          maxWidth: "100%",
          height: "30vh",
          float: "left",
          marginBottom: "15px",
        }}
        primaryColor="#eaeaea"
        secondaryColor="#e4e4e4"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="100%" />
      </ContentLoader>
    );
  }
  openModal() {
    this.setState({
      open_modal: true,
    });
  }
  closeModal() {
    this.setState({
      open_modal: false,
    });
  }

  openModalUltima(e, url) {
    e.preventDefault();
    this.setState({
      open_modal_ultima: true,
      url_modal_ultima: url,
    });
  }
  closeModalUltima() {
    this.setState({
      open_modal_ultima: false,
      url_modal_ultima: "",
    });
  }

  openSubscribe(id, chave) {
    this.setState({
      id_newsletter: id,
      chave_newsletter: chave,
      ativo_newsletter: chave,
    });
  }
  cleanErrors(event) {
    let errors = this.state.errors;

    errors.email = "";
    this.setState({
      errors: errors,
    });
  }
  setChaveNewsletter(chave) {
    this.setState({
      chave_newsletter: chave,
    });
    this.setAtivoNewsletter(chave);
  }
  setAtivoNewsletter(chave) {
    this.setState({
      ativo_newsletter: chave,
    });
  }
  handleClickBoxInscricao(e) {
    if (box_inscricao_ref.current !== e.target.closest(".box-inscricao")) {
      this.setChaveNewsletter("");
    }
  }
  componentDidMount() {
    DocumentHead({
      title: "Newsletter – meuEstadão | Portal do Assinante",
      description: "Painel do Estadão",
    });

    this.props.dispatch(newsletterAction.getNewsletterAnonimo());
    this.changeAcordian = this.changeAcordian.bind(this);

    document.documentElement.addEventListener(
      "click",
      this.handleClickBoxInscricao
    );
  }

  componentWillUnmount() {
    this.props.dispatch(alertMessageActions.removeAlert());
    document.documentElement.removeEventListener(
      "click",
      this.handleClickBoxInscricao
    );
  }

  sendSubscribeAnonimous = (
    e,
    id_newletter,
    chave_newsletter,
    callback,
    free
  ) => {
    e.preventDefault();
    let errors = this.state.errors;

    if (!this.validaEmail(this.state.email_input)) {
      errors.email = "Por favor, digite um email válido";
      this.setState({
        errors: errors,
      });

      sendGTMGA4Event({
        event: 'newsletter_assinar',
        status_newsletter: 'erro',
      });
      
      return false;
    }

    Loading.add();

    var bodyFormData = new FormData();

    bodyFormData.set("id_newsletter", id_newletter);
    bodyFormData.set("status", "S");
    bodyFormData.set("email", this.state.email_input);

    axios
      .post(env.API_URL + "/anonimo/newsletter", bodyFormData, {
        headers: {
          "Content-type": "application/x-www-form-urlencoded",
        },
        withCredentials: true,
      })
      .then((response) => {
        this.updateStateInscrito(chave_newsletter, true);
        this.setAtivoNewsletter("");
        this.addTotalInscrito();
        this.closeModal();

        this.sendGTMGA4Event({
          key: chave_newsletter,
          status: free ? "F" : "S",
        });
        //this.props.dispatch(toastActions.show('green', 'Você receberá uma confirmação em ' + this.state.email_input, 800))
        if (typeof callback != "undefined") {
          callback();
        }
      })
      .catch((error) => {
        Loading.remove();

        // adicionado a fim de debug em qa e local
        if (
          window.location.hostname === "devl.meu.estadao.com.br" ||
          window.location.hostname === "qameu.estadao.com.br"
        ) {
          console.log(error);
        }

        errors.email = error.response.data.message;
        this.setState({
          errors: errors,
        });
        this.sendGTMGA4Event({
          key: chave_newsletter,
          status: 'erro',
        });
        return false;
      });
  };

  updateStateInscrito(chave_newsletter, value) {
    let items = { ...this.state.inscrito };
    let item = { ...items[chave_newsletter] };
    item = value;

    let key = chave_newsletter;
    items[key] = item;
    this.setState({ inscrito: items });
  }

  addTotalInscrito() {
    this.setState({ inscrito_total: this.state.inscrito_total + 1 });
  }

  changeAcordian(event) {
    let element = event.currentTarget;
    let item = element.closest(".newsletter-item");

    item.classList.add("activing");

    let itens = element
      .closest(".newsletter-items")
      .querySelectorAll(".newsletter-item .active:not(.activing)");

    //let itens = document.querySelectorAll('.newsletter-item.active:not(.activing)');
    Object.keys(itens).map((i) => {
      return itens[i].classList.remove("active");
    });

    if (!item.classList.contains("active")) {
      item.classList.add("active");
      setTimeout(() => {
        let posTop = item.getBoundingClientRect().top + window.scrollY - 80;
        window.scroll({
          top: posTop,
          left: 0,
          behavior: "smooth",
        });
      }, 300);
    } else {
      item.classList.remove("active");
    }

    item.classList.remove("activing");
  }

  changeEmail(event) {
    event.preventDefault();
    if (event.key !== "Enter" && event.shiftKey !== false) {
      this.setState(
        {
          email_input: event.target.value,
        },
        () => {}
      );
    }
  }
  openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
  validaeditoria(event, url) {
    if (url.includes("cloud.jornal")) {
      this.openInNewTab(url);
    } else {
      this.openModalUltima(event, url);
    }
  }

  cleanEmail(e, chave_newsletter) {
    e.preventDefault();
    this.updateStateInscrito(chave_newsletter, false);
    this.setState(
      {
        email_input: "",
        chave_newsletter: "",
      },
      () => {}
    );
  }

  validaEmail(email) {
    var emailValue = email;

    if (emailValue.length <= 0) {
      return false;
    }
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(emailValue)) {
      return false;
    }

    return true;
  }

  sendGTMGA4Event = ({ key, status }) => {
    sendGTMGA4Event({
      event: "newsletter_assinar",
      secao: "newsletter",
      componente: "box",
      nome_newsletter: key,
      status_newsletter:
        status === "S" ? "inscricao" : status === "N" ? "desinscricao" : status,
    });
  };

  render() {
    let assinante = this.props.user.assinante && !this.props.user.processando;
    let newsletter_list = this.props.newsletter,
      newsletter = [];

    const { errors } = this.state;

    if (newsletter_list) {
      newsletter = Object.values(newsletter_list);
      if (
        Object.keys(newsletter_modal).length === 0 &&
        this.props.modalChave !== "" &&
        typeof this.props.modalChave !== "undefined"
      ) {
        Object.keys(newsletter_list).map((key) => {
          let chave = this.props.modalChave;
          if (chave === "saibaagora") {
            chave = "assinantes";
          } else if (chave === "hyperlink") {
            chave = "link";
          }

          if (chave === newsletter_list[key].chave) {
            if (newsletter_list[key].assinante === "N") {
              newsletter_modal = newsletter_list[key];
              this.openModal();
            }
          }
        });
      }
    }

    let myAttr = {};
    if (errors.email.length > 0) {
      myAttr = { "data-error": errors.email };
    } else {
      myAttr = {};
    }

    return (
      <div
        className="container newsletter-wrapper newsletter-anonimo"
        data-is-assinante="false"
      >
        {Object.keys(newsletter_modal).length > 0 && (
          <Modal
            styles={{
              modal: {
                width: "100%",
                maxWidth: 638,
                height: "auto",
                maxHeight: 526,
              },
            }}
            open={this.state.open_modal}
            onClose={() => this.closeModal()}
            classNames={{
              overlay: "overlay-inscricao",
              modal: "modal-inscricao",
            }}
            center
          >
            <NewsletterInscricao
              modalChave={this.props.modalChave}
              newsletterSelecionada={newsletter_modal}
              assinanteOESP="N"
              assinanteBRP="N"
            />
          </Modal>
        )}

        <Modal
          styles={{
            modal: { width: "100%", maxWidth: 620, height: "auto", padding: 0 },
          }}
          open={this.state.open_modal_ultima}
          onClose={() => this.closeModalUltima()}
          classNames={{ overlay: "overlay-newsletter-ultima" }}
          center
        >
          <NewsletterUltima chaveNewsletter={this.state.url_modal_ultima} />
        </Modal>
        {/* <div className="header-content row">
          <Grid cols="12 12 12 6 6">
            <h3 className="logo">Estadão</h3>
            <h4 className="titulo-topo">Newsletter</h4>
          </Grid>
          <Grid cols="12 12 12 6 6" class="hidden-xs hidden-sm hidden-md">
            <Assinatura />
          </Grid>
        </div> */}
        <div className="header-content row">
          <Grid cols="12 12 12 6 6">
            <div className="titulo-header">
              <Titulo groupItem="Meus produtos" titulo="newsletter" />
            </div>
            <div className="titulo-header-mobile">
              <Titulo groupItem="Meus produtos" titulo="dashboard" />
            </div>
          </Grid>
        </div>
        <div className="row newsletter-container ">
          <Grid cols="12 12 12 3 3">
            <div className="newsletter-status">
              <div className="newsletter-desktop">
                <div className="status-block status-inscrever">
                  Para receber nossas newsletters, clique no botão “Inscrever” e
                  digite seu email.
                </div>
              </div>

              <div className="newsletter-head-mobile">
                <h4 className="titulo">Newsletter</h4>
                {this.state.inscrito_total > 0 && (
                  <p className="box">
                    Você se inscreveu em {this.state.inscrito_total} newsletter
                    {this.state.inscrito_total > 1 ? "s" : ""}
                  </p>
                )}
                <p>
                  Para receber nossas newsletters, clique no botão “Inscrever” e
                  digite seu email.
                </p>
              </div>
            </div>
          </Grid>
          <Grid cols="12 12 12 9 9">
            <div className="newsletter-items">
              <div className="newsletter-title">
                <p>
                  Selecione as newsletters de interesse clicando sobre cada uma
                  delas
                </p>
              </div>
              <div className="newsletter-lgpd">
                <p>
                  Ao se cadastrar nas newsletters, você concorda com os
                  <a
                    href="https://www.estadao.com.br/termo-de-uso"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <b> Termos de Uso </b>
                  </a>
                  e
                  <a
                    href="https://www.estadao.com.br/termo-de-uso#politica-privacidade"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <b> Política de privacidade</b>
                  </a>
                </p>
              </div>
              {Object.keys(newsletter).map((key) => {
                let img_src =
                  window.screen.width > 786
                    ? newsletter[key].imagem
                    : newsletter[key].imagem_mobile;
                let aceite = newsletter[key].aceite === "S" ? true : false;
                let aceiteCSS = aceite;
                if (window.screen.width > 786) {
                  aceiteCSS = false;
                }
                return (
                  <div
                    key={key}
                    data-acordian={key}
                    className="newsletter-item"
                    data-checked={aceiteCSS}
                  >
                    <div className="inner">
                      <div className="item-acordian">
                        <img
                          alt={newsletter[key].nome_newsletter}
                          className={newsletter[key].chave}
                          src={img_src}
                          width="259"
                        />

                        <div className="item-acordian-bg">
                          <i className="fa icon-angle-down" />
                          <div className="checked-layer">
                            {window.screen.width < 786 ? (
                              <img
                                src="/static/img/newsletter/checked.svg"
                                alt="Subscribe"
                                width="20"
                                className="img-clean"
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="newsletter-content item-acordian-content">
                        <div className="title" onClick={this.changeAcordian}>
                          <span
                            className={`${
                              newsletter[key].assinante === "E" &&
                              window.screen.width < 786
                                ? "gratis30t"
                                : ""
                            }`}
                          >
                            {newsletter[key].nome_newsletter}
                            {newsletter[key].assinante === "S" && (
                              <i className="icon-round-lock" />
                            )}
                          </span>
                          {newsletter[key].assinante === "E" &&
                          window.screen.width < 786 ? (
                            <span className="gratis30">
                              {newsletter[key].texto_botao_gratis !== ""
                                ? newsletter[key].texto_botao_gratis
                                : "Grátis por 30 dias"}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="texto">
                          {newsletter[key].descricao_newsletter}
                        </div>
                        <div class="news-dias">{newsletter[key].dias}</div>
                        {(newsletter[key].assinante === "S" ||
                          newsletter[key].assinante === "E") && (
                          <div className="assinante">
                            Exclusivo para assinantes{" "}
                            <i
                              className={
                                assinante
                                  ? "icon-lock-open-alt"
                                  : "icon-round-lock"
                              }
                            />
                          </div>
                        )}
                        {newsletter[key].assinante !== "S" && (
                          <div>
                            <div className="ver-ultima">
                              <button
                                className="btn-ver-ultima"
                                onClick={(e) => {
                                  this.validaeditoria(
                                    e,
                                    newsletter[key].url_newsletter
                                  );
                                  this.sendGTMGA4Event({
                                    key: newsletter[key].chave,
                                    status: "ver_ultima_edicao",
                                  });
                                }}
                              >
                                <span>Ver última edição</span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="#005f93"
                                  width="5"
                                  height="9"
                                  viewBox="0 0 5 9"
                                >
                                  <path
                                    className="prefix-link-arrow"
                                    d="M229.912 166.921a.281.281 0 0 1-.093.208l-4.352 4.2a.3.3 0 0 1-.43 0l-.466-.452a.276.276 0 0 1-.093-.205.281.281 0 0 1 .093-.208l3.669-3.545-3.669-3.545a.276.276 0 0 1-.093-.205.281.281 0 0 1 .093-.208l.466-.452a.3.3 0 0 1 .43 0l4.352 4.2a.281.281 0 0 1 .093.212z"
                                    data-name="prefix-link-arrow"
                                    transform="translate(-224.478 -162.421)"
                                  />
                                </svg>
                              </button>
                            </div>
                            {this.state.inscrito[newsletter[key].chave] ? (
                              <div>
                                <div className="button-wrapper">
                                  <button
                                    className="alterar-email"
                                    onClick={(e) =>
                                      this.cleanEmail(e, newsletter[key].chave)
                                    }
                                  >
                                    Alterar email
                                  </button>
                                  <button
                                    type="button"
                                    className={`subscribe-anonimo ${
                                      window.innerWidth <= 767
                                        ? "btninscricaoa"
                                        : "btninscricao"
                                    }`}
                                    data-dtkey="newsletter"
                                    data-inscrito="true"
                                  >
                                    <i className="fa icon-ok-circled" />{" "}
                                    Inscrito
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div className="form-newsletter">
                                {(this.state.chave_newsletter ===
                                  newsletter[key].chave ||
                                  window.innerWidth <= 767) && (
                                  <div
                                    className="box-inscricao"
                                    ref={box_inscricao_ref}
                                  >
                                    <button
                                      className="inscricao-close"
                                      onClick={() =>
                                        this.setChaveNewsletter("")
                                      }
                                    >
                                      <svg
                                        className="styles_closeIcon__1QwbI"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="28"
                                        height="28"
                                        viewBox="0 0 36 36"
                                      >
                                        <path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" />
                                      </svg>
                                    </button>
                                    <div>
                                      <p className="inscricao-info">
                                        Digite seu email para receber a
                                        newsletter
                                      </p>
                                      <form
                                        className="form-news"
                                        name="formNewsletter"
                                        onSubmit={(e) =>
                                          this.sendSubscribeAnonimous(
                                            e,
                                            newsletter[key].id_newsletter,
                                            newsletter[key].chave,
                                            undefined,
                                            newsletter[key].assinante === "E"
                                          )
                                        }
                                      >
                                        <div className="group-field">
                                          <div
                                            className={
                                              this.state.email_input !== ""
                                                ? "form-field isset"
                                                : "form-field"
                                            }
                                            {...myAttr}
                                          >
                                            <input
                                              type="text"
                                              value={this.state.email_input}
                                              onChange={this.changeEmail}
                                              onFocus={this.cleanErrors}
                                            />
                                            <label
                                              className="field-label-floating"
                                              htmlFor="email"
                                            >
                                              E-mail
                                            </label>
                                          </div>
                                        </div>
                                        {this.state.inscrito[
                                          newsletter[key].chave
                                        ] ? (
                                          <button
                                            type="button"
                                            className="subscribe-anonimo btninscricaom true"
                                            data-dtkey="newsletter"
                                            data-inscrito="true"
                                          >
                                            <i className="fa icon-ok-circled" />{" "}
                                            Inscrito
                                          </button>
                                        ) : !this.state.inscrito[
                                          newsletter[key].chave
                                        ] &&
                                        newsletter[key].assinante === "E" ? (
                                          <button
                                            type="submit"
                                            className="bt-news btninscricaom true"
                                            data-dtkey="newsletter"
                                            data-inscrito="false"
                                            data-editoria={
                                              newsletter[key].chave
                                            }
                                          >
                                            {newsletter[key]
                                              .texto_botao_gratis !== ""
                                              ? newsletter[key]
                                                  .texto_botao_gratis
                                              : "Grátis por 30 dias"}
                                          </button>
                                        ) : (
                                          <button
                                            type="submit"
                                            className="bt-news btninscricaom true"
                                            data-dtkey="newsletter"
                                            data-inscrito="false"
                                            data-editoria={
                                              newsletter[key].chave
                                            }
                                          >
                                            Inscrever
                                          </button>
                                        )}
                                      </form>
                                    </div>
                                  </div>
                                )}
                                <div className="button-wrapper hidden-xs hidden-sm">
                                  {newsletter[key].assinante === "E" ? (
                                    <button
                                      type="button"
                                      className="subscribe true btninscricao"
                                      data-dtkey="newsletter"
                                      data-inscrito="false"
                                      data-editoria={newsletter[key].chave}
                                      onClick={() =>
                                        this.openSubscribe(
                                          newsletter[key].id_newsletter,
                                          newsletter[key].chave
                                        )
                                      }
                                    >
                                      {newsletter[key].texto_botao_gratis !== ""
                                        ? newsletter[key].texto_botao_gratis
                                        : "Grátis por 30 dias"}
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="subscribe true btninscricao"
                                      data-dtkey="newsletter"
                                      data-inscrito="false"
                                      data-editoria={newsletter[key].chave}
                                      onClick={() =>
                                        this.openSubscribe(
                                          newsletter[key].id_newsletter,
                                          newsletter[key].chave
                                        )
                                      }
                                    >
                                      Inscrever
                                    </button>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
        </div>

        {Loading.remove()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state };
};

export default connect(mapStateToProps)(NewsletterAnonimo);
