import React, {Component} from 'react';
import ContentLoader from 'react-content-loader'

const InputLoader = props => (
    <ContentLoader
        height={'200'}
        style={{width: '100%', height: '200'}}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}>
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="200" />
    </ContentLoader>
);

class Textarea extends Component {
    constructor(props){
        super(props);
        this.id_input_random =  Math.floor(Math.random() * 99999999);
        this.state = {
            isset: Object.keys(this.props.value ? this.props.value : "").length > 0 ? true : false,
            value: this.props.value || "",
            className: [this.props.className || "", 'input-control'],
            label: this.props.label || "",
            name: this.props.name,
            id: "textarea_"+(this.props.id || this.props.name || this.id_input_random),
            load: this.props.load || false,
            readonly: this.props.readonly || false,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let value = this.props.value || this.refs.textarea.value;
        let new_state = {
            isset: Object.keys(value).length > 0 ? true : false,
            value:  value,
            load:  this.props.load || false,
            name: this.props.name,
            readonly: this.props.readonly || false
        };

        for(let i in new_state){
            if(new_state[i] === prevState[i]){
                delete new_state[i];
            }
        }
        if(Object.keys(new_state).length > 0){
            this.setState(new_state);
        }
    }

    handleChange = () => (e) => {
        if(Object.keys(e.target.value).length > 0 && this.state.isset === false){
            this.setState({
                isset: true
            });
        }else if( Object.keys(e.target.value).length <= 0 && this.state.isset === true){
            this.setState({
                isset: false
            });
        }
    }

    render(){
        return !this.state.load ? (
            <div className={this.state.className.join(" ")+(this.state.isset ? " isset" : "")}>
                <textarea readOnly={this.state.readonly} ref="textarea" id={this.state.id} name={this.state.name} onChange={this.handleChange()} defaultValue={this.state.value} />
                <label htmlFor={this.state.id}>{this.state.label}</label>
            </div>
        ) : (<InputLoader />);
    }
}

export default Textarea;
