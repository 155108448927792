import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import FilterLinks from '../../../utils/filterLinks'
import subscriptionActions from "../../../actions/subscription";
import Tutorial from "../../tutorial/tutorialScripts";
import { sendGTMGA4Event } from '../../../utils/tagmanager';
import BotaoAssine from "./botao-assine";

class Menu extends Component {
    constructor(props) {
        super(props);
        this.tutorial = new Tutorial();
    }

    componentDidMount() {
        document.querySelector('body').addEventListener('click', this.handleClickOut);
    }

    componentWillMount() {
        document.querySelector('body').removeEventListener('click', this.handleClickOut);
    }

    handleClickOut = (e) => {
        if (this.refs.menu && !e.target.closest('header>.menu')) {
            let li_focus = this.refs.menu.querySelectorAll('.li-main.focus');
            for (let i in li_focus) {
                if (li_focus.hasOwnProperty(i))
                    li_focus[i].classList.remove('focus');
            }
        }

        if (this.refs.menu) {
            let li_focus = this.refs.menu.querySelectorAll('.focusout');
            for (let i in li_focus) {
                if (li_focus.hasOwnProperty(i))
                    li_focus[i].classList.remove('focusout');
            }
        }

    }

    handleClick = e => {
        if (e.currentTarget) {
            let li_focus = this.refs.menu.querySelectorAll('.li-main.focus');
            for (let i in li_focus) {
                if (li_focus.hasOwnProperty(i))
                    li_focus[i].classList.remove('focus');
            }
            if (!e.currentTarget.parentElement.classList.contains('submenu') && e.currentTarget.parentElement.querySelector('.submenu')) {
                e.currentTarget.closest('.li-main').classList.add('focus');
            } else {
                e.currentTarget.closest('.li-main').classList.add('focusout');
            }
        }
    }

    sendGTMGA4Event = ({ menu_label, submenu_label }) => {
        sendGTMGA4Event({
            event: 'meu_estadao',
            acesso_header: menu_label + '_' + submenu_label
        })
    }

    render() {
        let showMenu = false;
        const user = this.props.user;
        let links = FilterLinks({
            user: user,
            assinatura: this.props.assinatura_d,
            inadimplente: this.props.inadimplente,
            assinatura_selecionada: this.props.assinatura_selecionada,
        });

        let currentURL = this.props.currentURL;

        let menu_list = {
            'meus_dados': {
                label: "Meus Dados",
                mLabel: "Dados",
                icon: "icon-perfil",
                link: false,
                assine: user.id && !user.assinante,
                submenu: false,
                dtkey: "meus_dados"
            },
            'pagamentos': {
                label: "Meus Pagamentos",
                mLabel: "Pagamentos",
                icon: "icon-credit-card",
                link: false,
                dtkey: "meus_pagamentos",
                assine: user.id && !user.assinante,
                submenu: {
                    "historico_de_pagamento": {
                        label: "Histórico de Pagamentos",
                        icon: "icon-list-alt",
                        link: links.get('historico_de_pagamento')
                    },
                    "forma_de_pagamento": {
                        label: "Alterar Forma de Pagamento",
                        icon: "icon-credit-card",
                        link: links.get('forma_de_pagamento')
                    },
                    'nota_fiscal': {
                        label: "Nota Fiscal Eletrônica",
                        icon: "icon-barcode",
                        link: links.get('nota_fiscal')
                    },
                    'contrato_de_assinatura': {
                        label: "Contrato de Assinatura",
                        icon: "icon-doc-text",
                        link: links.get('contrato_de_assinatura')
                    }
                }
            },
            'produtos': {
                label: "Meus Produtos",
                mLabel: "Produtos",
                icon: "icon-shopping-basket",
                link: false,
                dtkey: "meus_produtos",
                assine: user.id && !user.assinante,
                submenu: {
                    'transferencia_temporaria': {
                        label: "Transferência Temporária de Entrega",
                        icon: "icon-arrows-cw",
                        link: links.get('transferencia_temporaria')
                    },
                    'reclamacao_de_entrega': {
                        label: "Reclamação de Entrega",
                        icon: "icon-megaphone",
                        link: links.get('reclamacao_de_entrega')
                    },
                    'alteracao_de_endereco': {
                        label: "Alteração de Endereço de entrega",
                        icon: "icon-building",
                        link: links.get('alteracao_de_endereco')
                    },
                    'interrupcao_temporaria': {
                        label: "Interrupção Temporária de Entrega",
                        icon: "icon-calendar-check-o",
                        link: links.get('interrupcao_temporaria')
                    },
                    'jornal_digital': {
                        label: "Estadão Jornal Digital",
                        icon: "icon-newspaper",
                        external: true,
                        blank: true,
                        link: links.get('jornal_digital')
                    },
                    'newsletter': {
                        label: "Newsletter",
                        icon: "icon-envelope-open-o",
                        link: links.get('newsletter')
                    },
                    'apps': {
                        label: "Aplicativos",
                        icon: "icon-check-empty",
                        external: true,
                        blank: true,
                        link: links.get('apps')
                    },
                    'acervo_estadao': {
                        label: "Acervo Estadão",
                        icon: "icon-folder-open-empty",
                        external: true,
                        blank: true,
                        link: links.get('acervo_estadao')
                    },
                    'podcasts': {
                        label: "Podcasts",
                        icon: "icon-podcast",
                        external: true,
                        blank: true,
                        link: links.get('podcasts')
                    },
                    'tv': {
                        label: "TV Estadão",
                        icon: "icon-television",
                        external: true,
                        blank: true,
                        link: links.get('tv_estadao')
                    },
                    'correcao_redacao': {
                        label: "Correção da Redação",
                        icon: "icon-television",
                        link: links.get('correcao_redacao')
                    }
                }
            }
        };

        if (user.id) {
            menu_list['meus_dados'].submenu = {
                'meu_perfil': {
                    label: "Meu perfil",
                    icon: "icon-user-circle-o",
                    link: links.get('meu_perfil')
                },

                "endereco": links.get('endereco') ? {
                    label: "Endereço",
                    icon: "icon-location",
                    link: links.get('endereco')
                } : false,
                'alterar_senha': {
                    label: "Alterar senha",
                    icon: "icon-lock-open-alt",
                    link: links.get('alterar_senha')
                },
                /*'controle_acesso': {
                    label: "Gerenciar acessos",
                    icon: "icon-lock-open-alt",
                    link: links.get('controle_de_acesso')
                },*/
                'sair': {
                    label: "Sair da Conta",
                    icon: "icon-login",
                    external: true,
                    mobile: true,
                    link: 'https://acesso.estadao.com.br/login/logout'
                }
            };

            if (this.props.assinatura_d && this.props.assinatura_d.config.type === 'DIG') {
                menu_list['pagamentos'].submenu['nota_fiscal']['label'] = "Fatura"
            }

        }

        if (user.id) {
            (window.ESTADAO_MENU || []).push((menu) => {
                user.subscriptions = Object.keys(this.props.assinaturas.list).length > 0 ? this.props.assinaturas.list : [{'': 'Carregando...'}];
    
                if (user.email) {
                    this.props.dispatch(subscriptionActions.getInadimplenteStatus());
                }

                menu.init({
                    user: user,
                    updateAssinatura: (value) => {
                        this.props.dispatch(subscriptionActions.setSelectedSubscription(value));
                    },
                    assinatura_selecionada: this.props.assinatura_selecionada,
                    meuEstadaoMenu: {

                        dados_cadastrais: () => {
                            this.props.dispatch({type: "REDIRECT", redirect: links.get('meu_perfil')});
                            menu.close();
                        },
                        alterar_senha: () => {
                            this.props.dispatch({type: "REDIRECT", redirect: links.get('alterar_senha')});
                            menu.close();
                        },
                        newsletter: () => {
                            this.props.dispatch({type: "REDIRECT", redirect: links.get('newsletter')});
                            menu.close();
                        },
                        historico_de_pagamento: links.get('historico_de_pagamento') ? () => {
                            this.props.dispatch({type: "REDIRECT", redirect: links.get('historico_de_pagamento')});
                            menu.close();
                        } : false,
                        extra: {
                            "Conheça o meuEstadão": () => {
                                this.props.dispatch({type: "REDIRECT", redirect: "/"});
                                menu.close();
                                this.tutorial.start()
                            }
                        }
                    }
                });
                if(user.hasOwnProperty('subscriptions') && user.subscriptions.hasOwnProperty(this.props.assinatura_selecionada)){
                    if (user.subscriptions[this.props.assinatura_selecionada].hasOwnProperty('produto') && user.subscriptions[this.props.assinatura_selecionada].produto.indexOf('GAME') > -1) {
                        this.props.dispatch(subscriptionActions.getDados(this.props.assinatura_selecionada));
                        if(typeof this.props.assinatura[this.props.assinatura_selecionada] !== "undefined"){
                            let assinaturaFullData = this.props.assinatura[this.props.assinatura_selecionada].assinatura;
                            menu_list = {
                                'meus_dados': {
                                    label: "Meus Dados",
                                    mLabel: "Dados",
                                    icon: "icon-perfil",
                                    link: false,
                                    assine: user.id && !user.assinante,
                                    dtkey: "meus_dados",
                                    submenu: {
                                        'meu_perfil': {
                                            label: "Meu perfil",
                                            icon: "icon-user-circle-o",
                                            link: links.get('meu_perfil')
                                        },
                                        'alterar_senha': {
                                            label: "Alterar senha",
                                            icon: "icon-lock-open-alt",
                                            link: links.get('alterar_senha')
                                        },
                                        'sair': {
                                            label: "Sair da Conta",
                                            icon: "icon-login",
                                            external: true,
                                            mobile: true,
                                            link: 'https://acesso.estadao.com.br/login/logout'
                                        }
                                    }
                                },
                                'pagamentos': {
                                    label: "Meu Jogo",
                                    mLabel: "Meu Jogo",
                                    icon: "icon-award",
                                    link: false,
                                    dtkey: "meus_pagamentos",
                                    assine: user.id && !user.assinante,
                                    submenu: {
                                        'contrato_de_assinatura': {
                                            label: "Contrato do Jogo",
                                            icon: "icon-doc-text",
                                            link: links.get('contrato_de_assinatura')
                                        }
                                    }
                                },
                                'produtos': {
                                    label: "Meus Produtos",
                                    mLabel: "Produtos",
                                    icon: "icon-shopping-basket",
                                    link: false,
                                    dtkey: "meus_produtos",
                                    assine: user.id && !user.assinante,
                                    submenu: {
                                        'transferencia_temporaria': {
                                            label: "Transferência Temporária de Entrega",
                                            icon: "icon-arrows-cw",
                                            link: false
                                        },
                                        'reclamacao_de_entrega': {
                                            label: "Reclamação de Entrega",
                                            icon: "icon-megaphone",
                                            link: false
                                        },
                                        'alteracao_de_endereco': {
                                            label: "Alteração de Endereço de entrega",
                                            icon: "icon-building",
                                            link: false
                                        },
                                        'interrupcao_temporaria': {
                                            label: "Interrupção Temporária de Entrega",
                                            icon: "icon-calendar-check-o",
                                            link: false
                                        },
                                        'jornal_digital': {
                                            label: "Estadão Jornal Digital",
                                            icon: "icon-newspaper",
                                            external: true,
                                            blank: true,
                                            link: false
                                        },
                                        'newsletter': {
                                            label: "Newsletter",
                                            icon: "icon-envelope-open-o",
                                            link: links.get('newsletter')
                                        },
                                        'apps': {
                                            label: "Aplicativos",
                                            icon: "icon-check-empty",
                                            external: true,
                                            blank: true,
                                            link: links.get('apps')
                                        },
                                        'acervo_estadao': {
                                            label: "Acervo Estadão",
                                            icon: "icon-folder-open-empty",
                                            external: true,
                                            blank: true,
                                            link: links.get('acervo_estadao')
                                        },
                                        'podcasts': {
                                            label: "Podcasts",
                                            icon: "icon-podcast",
                                            external: true,
                                            blank: true,
                                            link: links.get('podcasts')
                                        },
                                        'tv': {
                                            label: "TV Estadão",
                                            icon: "icon-television",
                                            external: true,
                                            blank: true,
                                            link: links.get('tv_estadao')
                                        },
                                        'correcao_da_redacao': {
                                            label: "Correção da Redação",
                                            icon: "icon-megaphone",
                                            link: false
                                        },
                                    }
                                }
                            };
                            if(assinaturaFullData !== null && typeof assinaturaFullData.codigo_meio_de_pagamento !== 'undefined' && assinaturaFullData.codigo_meio_de_pagamento.length > 0 && assinaturaFullData.codigo_meio_de_pagamento !== "E") {
                                try{

                                    menu_list['pagamentos']['submenu'] = {
                                        'historico_de_pagamento': {
                                            label: "Histórico de Pagamento",
                                            icon: "icon-list-alt",
                                            link: links.get('historico_de_pagamento')
                                        },
                                        'contrato_de_assinatura': {
                                            label: "Contrato do Jogo",
                                            icon: "icon-doc-text",
                                            link: links.get('contrato_de_assinatura')
                                        }
                                    }

                                }catch(error){
                                    console.error(error);
                                }
                            }
                            menu.init({
                                user: user,
                                updateAssinatura: (value) => {
                                    this.props.dispatch(subscriptionActions.setSelectedSubscription(value));
                                },
                                assinatura_selecionada: this.props.assinatura_selecionada,
                                meuEstadaoMenu: {

                                    dados_cadastrais: () => {
                                        this.props.dispatch({type: "REDIRECT", redirect: links.get('meu_perfil')});
                                        menu.close();
                                    },
                                    alterar_senha: () => {
                                        this.props.dispatch({type: "REDIRECT", redirect: links.get('alterar_senha')});
                                        menu.close();
                                    },
                                    newsletter: () => {
                                        this.props.dispatch({type: "REDIRECT", redirect: links.get('newsletter')});
                                        menu.close();
                                    }
                                    /*
                                    historico_de_pagamento: links.get('historico_de_pagamento') ? () => {
                                        this.props.dispatch({type: "REDIRECT", redirect: links.get('historico_de_pagamento')});
                                        menu.close();
                                    } : false,*/

                                }
                            });
                        }
                    }
                }

            });
        }
        if(user.has_cookie){
            showMenu = true;
        }
        return (
            (showMenu) ? (
                <div ref="menu" className="menu">
                    <nav className="menu-principal">
                        <ul className="list">
                            <li className="home li-main" data-key="home">
                                <Link to="/" onClick={(e) => {
                                    this.handleClick(e)
                                }} className={currentURL === '/' ? "active" : ""}>
                                    <i className="icon-shape"/> <span>Home</span>
                                </Link>
                            </li>
                            {Object.keys(menu_list).map((index, key) => {
                                let menu = menu_list[index];
                                var active = menu.link ? (menu.link === currentURL) : false;

                                let showButton = false;
                                if (menu.assine && user.email.indexOf('estadao.com') === -1 && !user.assinante && !user.block) {
                                    showButton = true;
                                }
                                return (
                                    <li key={key} data-key={index} data-dtkey={menu.dtkey || ""} className="li-main">
                                        {menu.submenu ?
                                            (<nav className="submenu">
                                                {Object.keys(menu.submenu).filter((i) => {
                                                    return menu.submenu[i]
                                                }).map((i, key_2) => {
                                                    var submenuActive = menu.submenu[i].link ? (menu.submenu[i].link === currentURL) : false;
                                                    active = !active ? submenuActive : true;
                                                    return menu.submenu[i].external ? (
                                                        <a key={key_2} data-key={i} data-dtkey={menu.dtkey || ""}
                                                           href={menu.submenu[i].link ? menu.submenu[i].link : ""}
                                                           target={menu.submenu[i].blank ? "_blank" : ""} onClick={(e) => {
                                                            if (!menu.submenu[i].link) {
                                                                e.preventDefault()
                                                            }
                                                            this.handleClick(e)
                                                            this.sendGTMGA4Event({ menu_label: menu.dtkey, submenu_label: i })

                                                        }}
                                                           className={(!menu.submenu[i].link ? "lock" : "") + " " + (submenuActive ? "active" : "") + " " + (menu.submenu[i].mobile ? "mobile" : "")}>
                                                            {menu.submenu[i].icon && (
                                                                <i className={menu.submenu[i].icon}/>)} <span
                                                            data-mlabel={menu.submenu[i].mLabel || false}>{menu.submenu[i].label}</span> {!menu.submenu[i].link && (
                                                            <span className="lock"/>)}
                                                        </a>
                                                    ) : (
                                                        <Link key={key_2} data-key={i} data-dtkey={menu.dtkey || ""}
                                                              to={menu.submenu[i].link ? menu.submenu[i].link : ""}
                                                              onClick={(e) => {
                                                                  if (!menu.submenu[i].link) {
                                                                      e.preventDefault()
                                                                  }
                                                                  this.handleClick(e)
                                                                  this.sendGTMGA4Event({ menu_label: menu.dtkey, submenu_label: i })
                                                              }}
                                                              className={(!menu.submenu[i].link ? "lock" : "") + " " + (submenuActive ? "active" : "") + " " + (menu.submenu[i].mobile ? "mobile" : "")}>
                                                            {menu.submenu[i].icon && (
                                                                <i className={menu.submenu[i].icon}/>)} <span
                                                            data-mlabel={menu.submenu[i].mLabel || false}>{menu.submenu[i].label}</span> {!menu.submenu[i].link && (
                                                            <span className="lock"/>)}
                                                        </Link>
                                                    )
                                                })}

                                                {showButton && <BotaoAssine dtkey={menu.dtkey} />}

                                            </nav>)
                                            : ""}
                                        {menu.external ? (
                                            <a className={active ? "active" : ""} href={menu.link ? menu.link : ""}
                                               onClick={(e) => {
                                                   if (!menu.link) {
                                                       e.preventDefault()
                                                   }
                                                   this.handleClick(e)
                                               }} data-mlabel={menu.mLabel || false}>
                                                {menu.icon && (<i className={menu.icon}/>)} <span>{menu.label}</span>
                                            </a>
                                        ) : (
                                            <Link className={active ? "active" : ""} to={menu.link ? menu.link : ""}
                                                  onClick={(e) => {
                                                      if (!menu.link) {
                                                          e.preventDefault()
                                                      }
                                                      this.handleClick(e)
                                                  }} data-mlabel={menu.mLabel || false}>
                                                {menu.icon && (<i className={menu.icon}/>)} <span>{menu.label}</span>
                                            </Link>
                                        )}

                                    </li>
                                )
                            })}
                        </ul>
                    </nav>
                </div>
            ):('')
        )
    }
}

const mapStateToProps = state => {
    return {
        currentURL: state.currentURL,
        user: state.user,
        links: state.links,
        assinatura: state.assinatura,
        assinatura_d: Object.keys(state.assinaturas.list).length > 0 &&
        state.assinaturas.list.hasOwnProperty(state.assinaturas.assinatura_selecionada) ?
            state.assinaturas.list[state.assinaturas.assinatura_selecionada] : false,
        assinaturas: state.assinaturas,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        inadimplente: state.inadimplente
    }
};

export default connect(mapStateToProps)(Menu)
