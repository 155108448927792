import React, { Component } from 'react';
import ContentLoader from "./../content-loader/content-loader"
import { connect } from 'react-redux'

const JornalDeHojeLoader = props => (
    <ContentLoader
        height={105}
        width={231}
        style={{width: '231', height: '105'}}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}>
        <rect x="0" y="0" rx="5" ry="5" width="120" height="105" />
        <rect x="118" y="35" rx="3" ry="3" width="105" height="33" />
    </ContentLoader>
)

class JornalDeHoje extends Component {
    constructor(props){
        super(props);
        (window.AUTH_PRESSREADER || []).push((authPressReader)=> {
            authPressReader.init();
        });
    }
    render() {
        const classExtra = this.props.classExtra || "";
        return (
            <div className={"jornal-de-hoje " + classExtra}>
                {this.props.utils.jornal_de_hoje.image ? (
                   <a data-dtkey={this.props.dtkey || ""} href="https://digital.estadao.com.br/o-estado-de-s-paulo/"  onClick={(e) => {
                    // e.preventDefault();
                    // (window.AUTH_PRESSREADER || []).push((bannerPressreader) => {
                    //     bannerPressreader.toggle();
                    // })
                    }} rel="noopener noreferrer">
                        <div className="image">
                            <figure>
                                <img src={this.props.utils.jornal_de_hoje.image} alt={this.props.utils.jornal_de_hoje.alt_image} />
                            </figure>
                        </div>
                        <div className="title">
                            Jornal de Hoje
                        </div>
                    </a>
                ) : ( <JornalDeHojeLoader /> )}
            </div>
        )
    }
};

const mapStateToProps = state => {
    return { ...state }
}

export default connect(mapStateToProps)(JornalDeHoje)