import React, { Component } from 'react'
import { connect } from 'react-redux'
import Grid from "../../modules/Grid";
import {Input, InputCEP} from "../../../utils/Form";
import subscriptionActions from "../../../actions/subscription";
import alertMessageActions from "../../../actions/alertMessage";

class FormularioAssinante extends Component {
    componentDidMount() {
        this.props.dispatch(alertMessageActions.setAlert('blue', 'Dúvidas sobre sua conta? Entre em contato com nosso SAC: 4003-5323(Capitais) ou 0800-014-7720 (Demais Localidades).', false));
    }

    componentWillUnmount() {
        this.props.dispatch(alertMessageActions.removeAlert());
    }

    render(){
        let usuario_assinatura = this.props.assinatura && this.props.assinatura.recebedor ? this.props.assinatura.recebedor : {};
        let load = !usuario_assinatura.endereco;
        let endereco = usuario_assinatura.endereco || {};

        if(!this.props.assinatura && this.props.assinatura_selecionada){
            this.props.dispatch(subscriptionActions.getDados(this.props.assinatura_selecionada))
        }

        return(
            <div>
                <div className="title-mobile">Endereço</div>
                <div className="block-single">
                    <form id="form-meus-dados" action="" method="POST" className="content-form">
                        <div className="row">
                            <Grid cols="12 12 4 4 3">
                                <InputCEP readonly={true} label="CEP" value={endereco.cep} load={load}  />
                            </Grid>
                            <Grid cols="12 12 6 5 7" >
                                <Input readonly={true} label="Endereço" value={endereco.logradouro} load={load} />
                            </Grid>
                            <Grid cols="12 12 2 3 2">
                                <Input readonly={true} label="Número" value={endereco.numero} load={load} />
                            </Grid>
                        </div>
                        <div className="row">
                            <Grid cols="12 12 4 4 4">
                                <Input readonly={true} label="Complemento" value={endereco.complemento} load={load}  />
                            </Grid>
                            <Grid cols="12 12 3 3 3">
                                <Input readonly={true} label="Bairro" value={endereco.bairro} load={load} />
                            </Grid>
                            <Grid cols="12 12 2 2 2" >
                                <Input readonly={true} label="UF" value={endereco.estado} load={load} />
                            </Grid>
                            <Grid cols="12 12 3 3 3">
                                <Input readonly={true} label="Cidade" value={endereco.cidade} load={load} />
                            </Grid>
                        </div>
                        <div className="row">
                            <Grid cols="12 12 6 6 6">
                                <Input readonly={true} label="Dica de entrega" value={endereco.dica_entrega} load={load}  />
                            </Grid>
                            <Grid cols="12 12 6 6 6">
                                <Input readonly={true} label="Ponto de referência:" value={endereco.ponto_referencia} load={load}  />
                            </Grid>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        usuario: state.user,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ?  state.assinatura[state.assinaturas.assinatura_selecionada] : null
    }
};

export default connect(mapStateToProps)(FormularioAssinante)