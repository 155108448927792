import React, {Component} from 'react';
import ContentLoader from 'react-content-loader'

const InputLoader = props => (
    <ContentLoader
        height={'55'}
        style={{width: '100%', height: '55'}}
        speed={1}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}>
        <rect x="0" y="0" rx="5" ry="5" width="100%" height="55" />
    </ContentLoader>
);

class Checkbox extends Component {
    constructor(props){
        super(props);
        this.id_input_random =  Math.floor(Math.random() * 99999999);

        this.state = {
            checked: this.props.checked ? true : false,
            value: this.props.value || "",
            className: [this.props.className || "", 'input-control', 'checkbox'],
            label: this.props.label || "",
            name: this.props.name,
            id: "input_"+(this.props.id || this.props.name || this.id_input_random),
            load: this.props.load || false,
            readonly: this.props.readonly || false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let new_state = {
            checked: this.props.checked ? true : false,
            value:  this.props.value || "",
            name: this.props.name,
            label: this.props.label || "",
            load:  this.props.load || false,
            id: "input_"+(this.props.id || this.props.name || this.id_input_random),
            readonly: this.props.readonly || false
        };

        for(let i in new_state){
            if(new_state[i] === prevState[i]){
                delete new_state[i];
            }
        }
        if(Object.keys(new_state).length > 0){
            this.setState(new_state);
        }
    }

    handleClick = (e) => {
        if(this.props.onClick){
            this.props.onClick(e);
        }
    }

    render(){
        return !this.state.load ? (
            <div className={this.state.className.join(" ")+(this.state.readonly ? " readonly" : "")}>
                <input readOnly={this.state.readonly} type="checkbox" id={this.state.id} name={this.state.name} defaultChecked={this.state.checked} defaultValue={this.state.value} onClick={(e) => this.handleClick(e)} />
                <i className="square"/>
                <label style={{ "margin-left": "-60px", "padding-left":"60px", "width": "100%" }} htmlFor={this.state.id}><span>{this.state.label}</span></label>
            </div>
        ) : (<InputLoader />);
    }
}

export default Checkbox;
