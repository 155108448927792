import {
  GET_HISTORICO_PAGAMENTO,
  GET_SUBSCRIPTION,
  GET_USER,
} from "../utils/asyncActionType";
import alertMessageActions from "../actions/alertMessage";
import FilterLinks from "../utils/filterLinks";
import CacheControl from "../utils/CacheControl";
import subscriptionActions from "../actions/subscription";

export default {
  messages(state = [], action) {
    switch (action.type) {
      case GET_SUBSCRIPTION.MESSAGES.SUCCESS:
        if (Object.keys(action.payload).length > 0) {
          let links = FilterLinks();
          if (!links.get("historico_de_pagamento")) {
            setTimeout(function() {
              action.asyncDispatch(
                alertMessageActions.setAlert(
                  "red",
                  action.payload,
                  false,
                  links.get("historico_de_pagamento")
                )
              );
            }, 1000);
          } else {
            action.asyncDispatch(
              alertMessageActions.setAlert(
                "red",
                action.payload,
                false,
                links.get("historico_de_pagamento")
              )
            );
          }
        }
        return { ...state, ...action.payload };
      default:
        return state;
    }
  },

  historicoPagamento(state = [], action) {
    switch (action.type) {
      case GET_HISTORICO_PAGAMENTO.SUCCESS:
      case GET_HISTORICO_PAGAMENTO.CACHED:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  },

  assinatura(state = {}, action) {
    switch (action.type) {
      case GET_SUBSCRIPTION.ASSINATURA.SUCCESS:
      case GET_SUBSCRIPTION.ASSINATURA.CACHED:
        let ordem = {};
        ordem[action.request.ordem] = action.payload;
        return { ...state, ...ordem };
      case GET_SUBSCRIPTION.ASSINATURA.PENDING:
      case GET_SUBSCRIPTION.ASSINATURA.FAILURE:
      default:
        return state;
    }
  },

  assinaturas(
    state = {
      list: {},
      assinatura_selecionada: "",
    },
    action
  ) {
    switch (action.type) {
      case GET_SUBSCRIPTION.ASSINATURAS.SUCCESS:
      case GET_SUBSCRIPTION.ASSINATURAS.CACHED:
        let assinatura_selecionada_cache = new CacheControl(
          "assinatura_selecionada"
        );
        let assinatura_selecionada = assinatura_selecionada_cache.get(43200);

        if (
          Object.keys(action.payload).length > 0 &&
          !action.payload.hasOwnProperty(assinatura_selecionada)
        ) {
          assinatura_selecionada = Object.keys(action.payload)[0];
          assinatura_selecionada_cache.set(assinatura_selecionada);
        }

        if (assinatura_selecionada && assinatura_selecionada === "TEMPORARIO") {
          action.asyncDispatch({
            type: GET_USER.USER.SUCCESS,
            payload: { processando: true, assinante: false },
          });
        } else if (
          assinatura_selecionada &&
          assinatura_selecionada !== "PROCESSANDO"
        ) {
          action.asyncDispatch(
            subscriptionActions.getMessages(assinatura_selecionada)
          );
          subscriptionActions.getInadimplenteStatus();
        } else if (assinatura_selecionada === "PROCESSANDO") {
          action.asyncDispatch(
            alertMessageActions.setAlert(
              "yellow",
              [
                "Seu acesso já está ativo. Aproveite todo conteúdo do Estadão! Logo que seu pagamento for processado, aparecerá no Histórico de Pagamento.",
              ],
              false
            )
          );
          action.asyncDispatch({
            type: GET_USER.USER.SUCCESS,
            payload: { processando: true },
          });
        }

        return {
          ...state,
          list: action.payload,
          loaded: true,
          assinatura_selecionada: assinatura_selecionada,
        };
      case GET_SUBSCRIPTION.ASSINATURAS.PENDING:
        return state;
      case GET_SUBSCRIPTION.ASSINATURAS.FAILURE:
        return { ...state, loaded: true };
      case "SET_SELECTED_SUBSCRIPTION":
        action.asyncDispatch(
          subscriptionActions.getMessages(action.payload.subscription)
        );
        new CacheControl("assinatura_selecionada").set(
          action.payload.subscription
        );
        return {
          ...state,
          assinatura_selecionada: action.payload.subscription,
        };
      default:
        return state;
    }
  },

  consultaParcelaEmAberto(state = [], action) {
    switch (action.type) {
      case GET_SUBSCRIPTION.PARCELA_EM_ABERTO.SUCCESS:
      case GET_SUBSCRIPTION.PARCELA_EM_ABERTO.CACHED:
        let ordem = {};
        ordem[action.request.ordem] = action.payload;
        return { ...state, ...ordem };
      case GET_SUBSCRIPTION.PARCELA_EM_ABERTO.PENDING:
      case GET_SUBSCRIPTION.PARCELA_EM_ABERTO.FAILURE:
      default:
        return state;
    }
  },

  buscarNFE(state = {}, action) {
    switch (action.type) {
      case GET_SUBSCRIPTION.BUSCAR_NFE.SUCCESS:
      case GET_SUBSCRIPTION.BUSCAR_NFE.CACHED:
        let ordem = {};
        ordem[action.request.ordem] = action.payload;
        return { ...state, ...ordem };
      case GET_SUBSCRIPTION.BUSCAR_NFE.PENDING:
      case GET_SUBSCRIPTION.BUSCAR_NFE.FAILURE:
      default:
        return state;
    }
  },

  getInadimplenteStatus(state = {}, action) {
    switch (action.type) {
      case GET_SUBSCRIPTION.INADIMPLENTE_STATUS.SUCCESS:
      case GET_SUBSCRIPTION.INADIMPLENTE_STATUS.CACHED:
        return { ...state, ...action.payload };
      case GET_SUBSCRIPTION.INADIMPLENTE_STATUS.PENDING:
        action.asyncDispatch(subscriptionActions.getInadimplenteStatus());
      case GET_SUBSCRIPTION.INADIMPLENTE_STATUS.FAILURE:
      default:
        return state;
    }
  },
};
