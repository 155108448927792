import React, {Component} from 'react'
import {connect} from 'react-redux'
import Grid from "../../modules/Grid";
import {Form, Input, InputCEP, Select} from "../../../utils/Form";
import subscriptionActions from "../../../actions/subscription";
import alertMessageActions from "../../../actions/alertMessage";
import Loading from "../../../utils/Loading";
import Calendar from "./calendar";
import axios from "axios";
import env from "../../../env";

class FormularioAssinante extends Component {


    constructor(props){
        super(props);
        this.state ={
            uf: null,
            states: [],
            state_load: false,
            citys: [],
            city_load: false
        };
    }

    componentDidMount() {
        this._updateStates();
    }

    componentWillUnmount() {
        this.props.dispatch(alertMessageActions.removeAlert());
    }

    sendMessage() {

        if(document.querySelector('.input-control[data-error]')){

            [].forEach.call(document.querySelector('.input-control[data-error]'), (element)=>{
                element.removeAttribute('data-error');
            });

        }

        let form = document.getElementById('form-alteracao-endereco');
        if (form) {
            let bodyFormData = new FormData(form);
            let nome_solicitante = this.props.usuario.nome + ' ' + this.props.usuario.sobrenome;
            let sexo = this.props.usuario.sexo === 'F'? '02':'01';
            let cd_produto_midia = this.props.usuario.subscriptions[this.props.assinatura_selecionada].cd_produto_midia;
            let tipo_aquisicao = this.props.assinatura.assinatura.tipo_aquisicao;
            let tipo_remessa = this.props.assinatura.assinatura.tipo_remessa;
            let id_recebedor = this.props.assinatura.recebedor.id;

            bodyFormData.append('nome_solicitante', nome_solicitante);
            bodyFormData.append('sexo', sexo);
            bodyFormData.append('data_de', document.querySelector('[name="data_inicial"]').value);
            bodyFormData.append('cd_produto_midia', cd_produto_midia);
            bodyFormData.append('tipo_aquisicao', tipo_aquisicao);
            bodyFormData.append('tipo_remessa', tipo_remessa);
            bodyFormData.append('id_recebedor', id_recebedor);

            Loading.add();
            this.props.dispatch(alertMessageActions.removeAlert());
            Form.send("/subscription/" + this.props.assinatura_selecionada + "/change-address-delivery", bodyFormData).then((response) => {
                Loading.remove();
                window.scrollTo(0, 0);
                let {data, status} = response;

                if (status === 200) {
                    this.props.dispatch(alertMessageActions.setAlert('green', data.message || 'Salvo com sucesso!',false));
                }else if(data.error){
                    if(data.error.validator === true){
                        this.props.dispatch(alertMessageActions.setAlert('red',data.error.message,false));
                    }
                    for( let i in data.error.message){
                        if(data.error.message.hasOwnProperty(i)){
                            switch(i){

                                case 'endereco_referencia':
                                    data.error.message[i] = ["O campo ponto de referência é obrigatório"];
                                    break;
                                case 'endereco_dica':
                                    data.error.message[i] = ["O campo dica de entrega é obrigatório"];
                                    break;
                                case 'endereco_cep':
                                    data.error.message[i] = ["O campo CEP é obrigatório"];
                                    break;
                                default:
                                    data.error.message[i][0] = data.error.message[i][0].replace('endereco ','');

                            }
                        }
                    }
                    let message = data.error.message || "Ops algo deu errado!";
                    Form.showErrors(message);
                }
            }).catch((e) => {
                Loading.remove();
            });
        }
    }

    _updateStates(){
        return new Promise((resolve, reject) => {
            if(!this.state.state_load){
                this.setState({
                    state_load: true
                });
                axios.get(env.API_URL + "/utils/address/states", {withCredentials: true})
                    .then(response => {
                        let {data} = response, states = {};
                        for (let i in data) {
                            if(data.hasOwnProperty(i))
                                states[data[i].uf] = data[i].uf;
                        }

                        this.setState({
                            states: states,
                            state_load: false
                        });

                        resolve(states);

                    }).catch((response) => {
                    this.setState({
                        state_load: false
                    });
                    reject(response);
                });
            }else{
                resolve(this.state.states);
            }
        });
    }

    _updateCitys(uf){
        return new Promise((resolve, reject) => {
            if(uf && !this.state.city_load){
                this.setState({
                    city_load: true
                });
                axios.get(env.API_URL+"/utils/address/"+uf+"/citys", {withCredentials: true})
                    .then(response => {
                        let {data} = response, citys = {};
                        for (let i in data) {
                            if(data.hasOwnProperty(i))
                                citys[data[i].cidade] = data[i].cidade;
                        }

                        this.setState({
                            citys: citys,
                            city_load: false
                        });

                        resolve(citys);

                    }).catch((response) => {
                    this.setState({
                        city_load: false
                    });
                    reject(response);
                });
            }else{
                resolve(this.state.citys);
            }
        })
    }

    changeState = uf => {
        this._updateCitys(uf);
        return this._updateCitys(this.uf.select.value);
    }

    setCitySerchValue = () => {}

    render() {

        let usuario_assinatura = this.props.assinatura && this.props.assinatura.recebedor ? this.props.assinatura.recebedor : {};

        let endereco = usuario_assinatura.endereco || {};

        if (!this.props.assinatura && this.props.assinatura_selecionada) {
            this.props.dispatch(subscriptionActions.getDados(this.props.assinatura_selecionada))
        }

        return (
            <div>
                <style>
                    {`.block-single{padding:21px;}`}
                </style>
                <div className="block-single text">
                    <form id="form-alteracao-endereco" action="" method="POST" className="content-form">
                        <div className="row">
                            <h3>* Data de ínicio da alteração de endereço</h3>
                        </div>
                        <Calendar/>
                        <div className="row" style={{marginTop: '43px'}}>
                            <Grid cols="12 12 12 12 12">
                                <div className="title-row">
                                    <h3>Informe o endereço ao qual o jornal será entregue
                                        a partir da data definida
                                        acima</h3>
                                </div>
                            </Grid>
                            <Grid cols="12 12 4 4 3">
                                <InputCEP label="CEP" name="endereco_cep" dataTeste="testid_campo_endereco_cep"
                                          search={{
                                              'endereco_rua': 'logradouro',
                                              'endereco_bairro': 'bairro',
                                              'endereco_estado': 'uf',
                                              'endereco_cidade': 'localidade'
                                          }} callback={(response) => {
                                    this.setCitySerchValue = (citys) => {
                                        if (!response.erro) {
                                            let city = response.localidade.toLowerCase().removeAssents();
                                            if(city.length <= 0 && this.state.uf !== null){
                                                this._updateCitys(this.state.uf);
                                            }
                                            for (let i in citys) {
                                                if (citys.hasOwnProperty(i)) {
                                                    if (citys[i].toLowerCase().removeAssents().indexOf(city) > -1) {
                                                        document.querySelector('#input_endereco_cidade').setValue(i);
                                                    }
                                                }
                                            }
                                        }
                                    };
                                    if (!this.state.city_load) {
                                        this.setCitySerchValue(this.state.citys);
                                    }
                                }}/>
                            </Grid>
                            <Grid cols="12 12 6 5 7">
                                <Input label="Endereço" name="endereco_rua" dataTeste="testid_campo_endereco_rua" readonly="readonly" />
                            </Grid>
                            <Grid cols="12 12 2 3 2">
                                <Input label="Número" name="endereco_numero"
                                       dataTeste="testid_campo_endereco_numero"/>
                            </Grid>
                        </div>
                        <div className="row">
                            <Grid cols="12 12 4 4 4">
                                <Input label="Complemento" name="endereco_complemento"
                                       dataTeste="testid_campo_endereco_complemento"/>
                            </Grid>
                            <Grid cols="12 12 3 3 3">
                                <Input label="Bairro" name="endereco_bairro" dataTeste="testid_campo_endereco_bairro" readonly="readonly" />
                            </Grid>
                            <Grid cols="12 12 2 2 2">
                                <Select ref={el => this.uf = el || this.uf} data={this.state.states} onChange={
                                    (e) => {
                                        this.changeState(e.target.value || endereco.estado).then((citys) => {
                                            this.setCitySerchValue(citys);
                                        });
                                    }
                                } name="endereco_estado" value={this.state.uf} label="UF" load={this.state.state_load} readonly="readonly" />
                            </Grid>
                            <Grid cols="12 12 3 3 3">
                                <Select data={this.state.citys} name="endereco_cidade"
                                        label="Cidade"
                                        value={endereco.cidade}
                                        load={this.state.city_load} readonly="readonly" />
                            </Grid>
                        </div>
                        <div className="row">
                            <Grid cols="12 12 6 6 6">
                                <Input label="Ponto de referência" name="endereco_referencia"
                                       dataTeste="testid_campo_endereco_referencia"
                                       required="true"/>
                            </Grid>
                            <Grid cols="12 12 6 6 6">
                                <Select label="Dica de entrega" name="endereco_dica" required="true"
                                        data={{
                                            'Deixar o jornal na portaria': 'Deixar o jornal na portaria',
                                            'Jogar o jornal por cima do portao': 'Jogar o jornal por cima do portão',
                                            'Jogar o jornal na garagem': 'Jogar o jornal na garagem',
                                            'Jogar o jornal na varanda': 'Jogar o jornal na varanda',
                                            'Deixar o jornal na caixa do correio': 'Deixar o jornal na caixa do correio',
                                            'outros': 'Outros'
                                        }}
                                        onChange={(e) => {
                                            if (e.target.value === 'outros') {
                                                document.getElementById('desc_input_endereco_dica').setAttribute('class', '');
                                            } else {
                                                document.getElementById('desc_input_endereco_dica').setAttribute('class', 'hidden');
                                            }
                                        }}
                                        dataTeste="testid_campo_endereco_dica"/>
                                <div id='desc_input_endereco_dica' className='hidden'>
                                    <Input name="input_endereco_dica" label="Descrição"/>
                                </div>
                                <style>{`.hidden{display:none}`}</style>
                            </Grid>
                        </div>
                        <Grid col="12" class="text-right">
                            <button type="button" className="btn blue salvar-dados"
                                    data-teste="testid_botao_endereco_submit" data-dtkey="salvar_dados"
                                    onClick={() => {
                                        this.sendMessage()
                                    }}><i className="icon-arrows-cw"/> Atualizar endereço de entrega
                            </button>
                        </Grid>
                        <style>
                            {`.salvar-dados{margin-right:0 !important; line-height:inherit}`}
                        </style>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        usuario: state.user,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.assinatura[state.assinaturas.assinatura_selecionada] : null
    }
};

export default connect(mapStateToProps)(FormularioAssinante)