import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './components/home/home'
import Newsletter from './components/newsletter/newsletter'
import DadosPessoais from './components/meus-dados/dados-pessoais/dados-pessoais'
import Endereco from './components/meus-dados/endereco/endereco'
import AlteracaoEndereco from './components/meus-produtos/alteracao-endereco'
import AlterarSenha from './components/meus-dados/alterar-senha/alterar-senha'
import AlterarEmail from './components/meus-dados/alterar-email/alterar-email'
import FAQ from './components/faq/faq'
import Tutoriais from './components/tutoriais'
import PortalDoAssinanteIframe from './components/portal-do-assinante-iframe/portal-do-assinante-iframe'
import NotFound from './components/404/404'
import ParceirosServicos from './components/parceiros-e-servicos/parceiros-e-servicos'
import EventosEstadao from './components/eventos-estadao/eventos-estadao'
import Publicacoes from './components/publicacoes/publicacoes'
import FaleConosco from './components/fale-conosco'
import InterrupcaoTemporaria from './components/meus-produtos/interrupcao-temporaria'
import ReclamacaoDeEntrega from './components/meus-produtos/reclamacao-entrega'
import CorrecaoDaRedacao from './components/meus-produtos/correcao-redacao'
import TransferenciaTemporariaEndereco from './components/meus-produtos/transferencia-temporaria-endereco'
import HistoricoPagamento from './components/meus-pagamentos/historico-pagamento'
import AlterarFormaDePagamento from './components/meus-pagamentos/pagamento/alterar'
import PagarAgora from './components/meus-pagamentos/pagamento/pagar-agora'
import ValidarEmail from "./components/validate-email";
import ControleDeAcesso from "./components/meus-dados/controle-de-acesso";

import currentURLAction from './actions/currentURL'
import store from './store'
import { authMiddleware } from "./middleware/auth";
import Contratos from "./components/meus-pagamentos/contratos/contratos";
import NotaFiscal from "./components/meus-pagamentos/nota-fiscal";

class RoutesConfig extends Component {
    routes = [
        {
            path: '/',
            exact: true,
            component: Home
        },
        {
            path: '/newsletter/:chave?',
            exact: true,
            component: Newsletter
        },
        {
            path: '/perguntas-frequentes',
            exact: true,
            component: FAQ
        },
        {
            path: '/tutoriais',
            exact: true,
            component: Tutoriais
        },
        {
            path: '/assinante',
            component: PortalDoAssinanteIframe
        },
        {
            path: '/meus-dados',
            exact: true,
            component: DadosPessoais
        },
        {
            path: '/controle-de-acesso',
            exact: true,
            component: ControleDeAcesso
        },
        {
            path: '/endereco',
            exact: true,
            component: Endereco
        },
        {
            path: '/alteracao-endereco',
            component: AlteracaoEndereco
        },
        {
            path: '/alterar-senha',
            exact: true,
            component: AlterarSenha
        },
        {
            path: '/parceiros-e-servicos',
            exact: true,
            component: ParceirosServicos
        },
        {
            path: '/eventos-estadao',
            exact: true,
            component: EventosEstadao
        },
        {
            path: '/publicacoes',
            exact: true,
            component: Publicacoes
        },
        {
            path: '/fale-conosco/:assunto?',
            exact: true,
            component: FaleConosco
        },
        {
            path: '/interrupcao-temporaria',
            exact: true,
            component: InterrupcaoTemporaria
        },
        {
            path: '/reclamacao-entrega',
            exact: true,
            component: ReclamacaoDeEntrega
        },
        {
            path: '/transferencia-temporaria',
            exact: true,
            component: TransferenciaTemporariaEndereco
        },
        {
            path: '/nota-fiscal',
            exact: true,
            component: NotaFiscal
        },
        {
            path: '/contratos-assinaturas',
            exact: true,
            component: Contratos
        },
        {
            path: '/historico-de-pagamentos',
            exact: true,
            component: HistoricoPagamento
        },
        {
            path: '/alterar-forma-de-pagamento',
            exact: true,
            component: AlterarFormaDePagamento
        },
        {
            path: '/pagar-agora',
            exact: true,
            component: PagarAgora
        },
        {
            path: '/validar-email',
            exact: true,
            component: ValidarEmail
        },
        {
            path: '/alterar-email',
            exact: true,
            component: AlterarEmail
        },
        {
            path: '/correcao-redacao',
            exact: true,
            component: CorrecaoDaRedacao
        },
    ]


    componentDidUpdate() {
        authMiddleware();
        store.dispatch(currentURLAction.setURL(window.location.pathname));
        window.scrollTo(0, 0);
    }

    RouteRender = route => {
        // const Component = route.component;



        return (
            <Route
                path={route.path}
                exact={route.exact || false}
                component={route.component}
            // children={<><Component /><PageView /></>}
            />
        )
    }

    render() {
        return (
            <Switch>
                {this.routes.map((route, i) => <this.RouteRender key={i} {...route} />)}
                <Route component={NotFound} />
            </Switch>
        )
    }
}

export default RoutesConfig;

