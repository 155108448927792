import React, {Component} from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Grid from "../../modules/Grid";
import {Input} from "../../../utils/Form";

const WEEKDAYS_SHORT = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SÁB'];
const WEEKDAYS_LONG = ['Domingo', 'Segunda', 'Terça - Feira', 'Quarta - Feira', 'Quinta - Feira', 'Sexta - Feira', 'Sábado'];
const MONTHS = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const formatDate = (date) => {
    let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    let month = date.getMonth() + 1;
    month = month > 9 ? month : "0" + month;
    return day + "/" + month + "/" + date.getFullYear();
};

class InputCalendar extends Component {
    render() {
        let props = {...this.props};
        delete props.value;
        return (<Input ref={el => this.input = el ? el : this.input} readonly={true} {...this.props} inputProps={props}
                       icon="icon-calendar"/>);
    }
}


const parseDate = (date) => {
    let parts = date.split('/');
    if (parts.length === 3 && parseInt(parts[2]) > 1000 && parseInt(parts[1]) > 0 && parseInt(parts[0]) > 0)
        return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
};

class Calendar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            from: "",
            disable_day: (new Date()).addDays(3, {uteis: true})
        };

        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    }

    removeError() {
        let elements = document.querySelectorAll('#form-alteracao-endereco [data-error]');
        for (let i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].removeAttribute('data-error');
            }
        }
    }

    handleFromChange(from) {
        this.setState({
            from: from,
        });
    }

    handleDayMouseEnter(day,e) {
        this.setState({
            from: day
        })
    }

    render() {
        const {from, disable_day} = this.state;
        return (
            <div className="row">
                <style>{`
                    .DayPicker-Day--markGreen{
                        position: relative;
                    }
                    .DayPicker-Day--markGreen:not(.DayPicker-Day--outside):before{
                        content: "MAX";
                        font-size: 6px;
                        position: absolute;
                        top: 7px;
                        right: 0;
                        width: 100%;
                        text-aling: center;
                    }
                    .DayPicker-interrupcao-temporaria{
                        position: absolute;
                        top: calc(100% + 2px);
                        left: 0;
                        z-index: 99999
                    }
                    .calendar{
                        width:100%;
                    }
                `}</style>
                <Grid cols="12 12 6 3 3">
                    <div className="calendar">
                        <DayPickerInput
                            classNames={{
                                'container': 'input-control-calendar',
                                'overlayWrapper': 'DayPicker-interrupcao-temporaria',
                                'overlay': ""
                            }}
                            data-teste="testid_campo_data_de"
                            component={InputCalendar}
                            placeholder={""}
                            ref={el => {
                                this.from = el || this.from
                            }}
                            value={from}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            dayPickerProps={{
                                className: "Range",
                                weekdaysShort: WEEKDAYS_SHORT,
                                weekdaysLong: WEEKDAYS_LONG,
                                months: MONTHS,
                                month: disable_day,
                                disabledDays: {before: disable_day},
                                fromMonth: disable_day,
                                onDayClick: () => {
                                    this.from.getInput().input.input.trigger('change')
                                },
                            }}
                            inputProps={{
                                type: "text",
                                name: "data_inicial",
                                label: '* Data inicial:',
                            }}
                            onDayChange={this.handleFromChange}
                        />
                    </div>
                </Grid>
            </div>
        );
    }
}

export default Calendar;