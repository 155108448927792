import { GET_TUTORIAIS } from '../utils/asyncActionType'

const INITIAL_STATE = {}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_TUTORIAIS.SUCCESS:
        case GET_TUTORIAIS.CACHED:
            return { ...state, ...action.payload };
        case GET_TUTORIAIS.PENDING:
        case GET_TUTORIAIS.FAILURE:
        default:
            return state
    }
}