import React, {Component} from 'react'
import {connect} from 'react-redux'

import Grid from '../../modules/Grid'
import DocumentHead from '../../../utils/DocumentHead'
import Assinatura from '../../assinaturas'
import AlertMessage from '../../alert-message/alertMessage'
import Titulo from "../../modules/Titulo";
import Formulario from "./formulario";
import FormularioAssinante from "./formulario-assinante";
import FilterLinks from "../../../utils/filterLinks";
import utilsActions from "../../../actions/utils";

class Index extends Component {

    componentDidMount() {
        DocumentHead({
            title: 'Endereço – meuEstadão | Portal do Assinante',
            description: 'Painel do Estadão'
        });
        this.props.dispatch(utilsActions.getAssuntosFaleConosco());
    }

    componentDidUpdate() {
        let links = FilterLinks();

        if (!links.get('endereco')) {
            this.props.dispatch({type: "REDIRECT", redirect: "/"});
        }
    }

    render() {
        return (
            <div className="container meus-produtos justify-content-center">
                <div className="header-content row">
                    <Grid cols='12 12 12 6 6' class='text-left welcome-wrapper align-center'>
                        <Titulo groupItem="Meus produtos" titulo="Alteração de endereço de entrega"/>
                    </Grid>
                    <Grid cols='12 12 12 6 6' class='text-left text-right hidden-xs hidden-sm hidden-md'>
                        <Assinatura/>
                    </Grid>
                    <AlertMessage/>
                </div>

                <div className="row data-user">
                    <Grid cols="12 12 12 12 12">
                        {this.props.usuario.id && this.props.usuario.assinante && !this.props.usuario.processando ? (
                            <FormularioAssinante/>
                        ) : (
                            <Formulario/>
                        )}
                    </Grid>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        usuario: state.user,
        assinaturas: state.assinaturas
    }
}

export default connect(mapStateToProps)(Index)
